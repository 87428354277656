import Print from "../print/print";
import Settings from "../settings/settings";


export default class PrinterTest {
    static printPrinterTest(printers: any[]) {
        const data: any[] = [
            {
                type: "text",
                value: `
                    <div style={height:"15vw", width:"10vw"}>
                        <h3>Digigarson Yazıcı Test</h3>
                        <hr />
                        <div>Masa: Test Bölüm - Test Masa<div>
                        <div>Tarih: ${new Date().toLocaleString()}</div>
                        <hr />
                        <h4>Ürünler</h4>
                        <div>Ürün 1 x2 100 TL<div>
                        <div>Ürün 2 x3 115 TL<div>
                        <div>Ürün 3 x1 225 TL<div>
                    </div>

                `
            }
        ];
        printers.map((printer: any) => {
            Print.print(Settings.payload_with_settings(data), 5, printer.printer);
        });
    }
}