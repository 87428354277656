import LocalStorages from "../../localStorages";

export enum SaveSettings {
    General,
    Groups,
}

export const PrinterDefaultColumnHeight = 2;

export default class Settings {
    static CurrentPrinter: string | null = null;
    static CurrentScreen: string | null = null;
    static CurrentSafePrinterName: string | null = null;
    static Groups: any[] = [];
    static IsPreview: boolean = false;
    static IsScreen: boolean = false;

    static saveSettings(type: SaveSettings) {
        if (type === SaveSettings.General) {
            LocalStorages.GeneralPrinter.set({
                printer: this.CurrentPrinter || "",
                name: this.CurrentSafePrinterName || "",
            })
            LocalStorages.NewScreen.set({
                id: this.CurrentScreen || "",
                bool: this.IsScreen || false,
            })
        }

        if (type === SaveSettings.Groups) {
            LocalStorages.PrinterGroups.set(this.Groups);
        }
    }

    static loadSettings() {
        const printerSettings = LocalStorages.GeneralPrinter.get;
        const sreenSettings = LocalStorages.NewScreen.get;
        if (printerSettings) {
            this.CurrentPrinter = printerSettings.printer;
            this.CurrentSafePrinterName = printerSettings.name;
        }
        if (sreenSettings) {
            this.CurrentScreen = sreenSettings.id;
            this.IsScreen = sreenSettings.bool;
        }
        this.Groups = LocalStorages.PrinterGroups.get;
    }

    static get settings() {
        return {
            printer: this.CurrentPrinter,
            preview: this.IsPreview,
            screen: this.CurrentScreen,
            isScreen: this.IsScreen,
        };
    }

    static payload_with_settings(data: any) {
        return {
            data,
            settings: this.settings,
        };
    }
}
