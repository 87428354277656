import GlobalStates from "config/global/states";
import Swal from "sweetalert2";
import Printer from "../../printers/index";
import Functions from "../index";
import { MODAL } from "../../../../constants/modalTypes";
import PageOrder, { PageOrderState } from "../../../../app/views/pages/orders";
import clone from "clone";
import Table, {
  TableState,
} from "../../../../app/views/pages/orders/check/components/table";
import { PagePropCommonDocument } from "../../../../modules/views/pages/pageProps";
import Services from "services/index";
import { cancelCatering } from "modules/services/orders";
import { toast } from "react-toastify";
import printers from "../../printers/index";
import { getGlobalAuthData } from "config/global/auth";
import Print from "../../printers/print/print";
import Settings, {
  PrinterDefaultColumnHeight,
} from "../../printers/settings/settings";
import GetirService from "services/getir-service";

const handleClick = async (
  functionName: string,
  commonProp: PagePropCommonDocument,
  pageOrder: PageOrder,
  pageOrderTable: Table
) => {
  GlobalStates.Type = functionName;
  let table = commonProp.getGlobalData.AllTables.findSingle(
    "_id",
    GlobalStates.SelectedTableId
  );
  function closeTable() {
    let tables = pageOrder.props.getGlobalData.AllTables.map((table) => {
      if (table._id == GlobalStates.SelectedTableId) {
        table.paid_orders = [];
        table.payments = [];
        table.orders = [];
        table.cover = [];
        table.discount = [];
        table.busy = false;
        table.totalPrice = 0;
        table.remainingPrice = 0;
        table.checkNo = 0;
      }
      return table;
    });
    pageOrderTable.props.setGlobalData({
      AllTables: tables,
    });
    commonProp.router.navigate("/");
  }

  function checkDateDiff(date: any) {
    let diff = new Date().getTime() - new Date(date).getTime();
    let diffInMinutes = diff / 1000 / 60;
    return diffInMinutes > 1;
  }
  switch (functionName) {
    case "checkout":
      if (!Functions.checkPerm("507")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (GlobalStates.CurrentTable.order_type == 3) {
        if (pageOrderTable.state.or.onWay.length == 0) {
          Swal.fire({
            title:
              "Ödemeye uygun ürün yok. Ürünlerin ödenebilmesi için önce kuryeye teslim edilmesi gerekir.",
            icon: "error",
          });
          return;
        }
        if (
          pageOrderTable.state.or.unapproved.length == 0 &&
          pageOrderTable.state.or.products.length == 0
        ) {
          Swal.fire({
            title: commonProp.router.t("mark-order-complete"),
            text: commonProp.router.t("mark-order-complete-text"),
            icon: "question",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: commonProp.router.t("yes"),
            cancelButtonText: commonProp.router.t("no"),
          }).then(async (result) => {
            if (result.isConfirmed) {
              let params = {
                orderNum: pageOrderTable.props.or.orderNum,
                completeOrder: true,
              };
              Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                  Swal.showLoading();
                },
              });
              const response = await Services.Put.payHomeDelivery(params);
              if (response.status) {
                commonProp.setGlobalData({ AllCourier: response.data.data });
                pageOrderTable.setState({
                  or: {
                    paid: pageOrderTable.props.or.paid.concat(
                      pageOrderTable.props.or.onWay
                    ),
                    onWay: [],
                    products: pageOrderTable.props.or.products,
                    unapproved: pageOrderTable.props.or.unapproved,
                    cancelled: pageOrderTable.props.or.cancelled,
                  },
                });
                (pageOrderTable.props.or.paid =
                  pageOrderTable.props.or.paid.concat(
                    pageOrderTable.props.or.onWay
                  )),
                  (pageOrderTable.props.or.onWay = []);
                Swal.fire({
                  title: commonProp.router.t("order-completed"),
                  icon: "success",
                });
                commonProp.router.navigate("/");
                return;
              }
              Swal.fire({
                title: commonProp.router.t("error-try-again"),
                icon: "error",
              });
              return;
            } else {
              let params = {
                orderNum: pageOrderTable.props.or.orderNum,
                completeOrder: false,
              };
              Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                  Swal.showLoading();
                },
              });
              const response = await Services.Put.payHomeDelivery(params);
              if (response.status) {
                commonProp.setGlobalData({ AllCourier: response.data.data });
                pageOrderTable.setState({
                  or: {
                    paid: pageOrderTable.props.or.paid.concat(
                      pageOrderTable.props.or.onWay
                    ),
                    onWay: [],
                    products: pageOrderTable.props.or.products,
                    unapproved: pageOrderTable.props.or.unapproved,
                    cancelled: pageOrderTable.props.or.cancelled,
                  },
                });
                pageOrderTable.props.or.paid =
                  pageOrderTable.props.or.paid.concat(
                    pageOrderTable.props.or.onWay
                  );
                pageOrderTable.props.or.onWay = [];
                Swal.fire({
                  title: commonProp.router.t("payment-success"),
                  icon: "success",
                });
                return;
              }
              Swal.fire({
                title: commonProp.router.t("error-try-again"),
                icon: "error",
              });
              return;
            }
          });
          return;
        }
        Swal.fire({
          title: commonProp.router.t("complete-home-delivery"),
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: commonProp.router.t("no"),
          confirmButtonText: commonProp.router.t("yes"),
        }).then(async (result) => {
          if (result.isConfirmed) {
            let params = {
              orderNum: pageOrderTable.props.or.orderNum,
              completeOrder: false,
            };
            Swal.fire({
              title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
              html: commonProp.router.t("uploading") + "...",
              showConfirmButton: false,
              showCancelButton: false,
              allowOutsideClick: false,
              willOpen(popup: HTMLElement) {
                Swal.showLoading();
              },
            });
            const response = await Services.Put.payHomeDelivery(params);
            if (response.status) {
              commonProp.setGlobalData({ AllCourier: response.data.data });
              pageOrderTable.setState({
                or: {
                  paid: pageOrderTable.props.or.paid.concat(
                    pageOrderTable.props.or.onWay
                  ),
                  onWay: [],
                  products: pageOrderTable.props.or.products,
                  unapproved: pageOrderTable.props.or.unapproved,
                  cancelled: pageOrderTable.props.or.cancelled,
                },
              });
              (pageOrderTable.props.or.paid =
                pageOrderTable.props.or.paid.concat(
                  pageOrderTable.props.or.onWay
                )),
                (pageOrderTable.props.or.onWay = []);

              Swal.fire({
                title: commonProp.router.t("payment-success"),
                icon: "success",
              });
              return;
            }
            Swal.fire({
              title: commonProp.router.t("error-try-again"),
              icon: "error",
            });
          }
        });
        return;
      }
      if (!GlobalStates.CurrentTable.isSafeSales) {
        if (table.orders.length > 0 || table.cover.length > 0) {
          commonProp.openModal(MODAL.CHECKOUT, pageOrder);
        } else {
          Swal.fire({
            icon: "error",
            title: commonProp.router.t("nopayment-closed-table"),
          });
          return;
        }
      } else {
        if (pageOrder.state.newOrders.length > 0) {
          commonProp.openModal(MODAL.CHECKOUT, pageOrder);
        } else {
          Swal.fire({
            icon: "warning",
            title: commonProp.router.t("please-add-product"),
          });
        }
      }
      break;
    case "moveTable":
      if (!Functions.checkPerm("508")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        if (table.orders.length > 0) {
          GlobalStates.ProductsToBeMoving = GlobalStates.CurrentTable.orders;
          GlobalStates.Type = "moveTable";
          commonProp.router.navigate("/", { replace: true });
        } else {
          Swal.fire({
            title: commonProp.router.t("empty-table-move"),
            icon: "warning",
          });
        }
      }
      break;
    case "splitProducts":
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        Functions.splitProducts();
      }
      break;
    case "moveProduct":
      if (!Functions.checkPerm("513")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        if (pageOrderTable.state.selectedProductId.length > 0) {
          GlobalStates.Type = "moveProduct";
          let orders: any[] = [];
          GlobalStates.CurrentTable.orders.forEach((order: any) => {
            order.products.forEach((p: any) => {
              if (pageOrderTable.state.selectedProductId.includes(p._id)) {
                orders.push(p);
              }
            });
          });
          GlobalStates.ProductsToBeMoving =
            pageOrderTable.state.selectedProductId;
          commonProp.router.navigate("/", { replace: true });
          //Functions.moveProduct(router, products, selectedProductId || [], tableid);
        } else {
          Swal.fire({
            icon: "warning",
            title: commonProp.router.t("please-select-product"),
          });
        }
      }
      break;
    case "mergeCheck":
      if (GlobalStates.CurrentTable.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        Functions.mergeCheck();
      }
      break;
    case "sendMainOrder":
      if (!Functions.checkPerm("501")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (table.orders.length < 1) {
        Swal.fire({
          title: commonProp.router.t("edit-customer-count-warning"),
          icon: "error",
        });
        return;
      }
      Functions.SendMainOrder(commonProp);
      break;
    case "sendOrder":
      if (!Functions.checkPerm("501")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        if (pageOrder.state.newOrders.length < 1) {
          Swal.fire({
            title: commonProp.router.t("please-add-product"),
            icon: "warning",
          });
          return;
        }
        Functions.SendOrder(commonProp, pageOrder, pageOrderTable);
      }
      break;
    case "nameTable":
      //FIXME: Backendi yok
      Functions.nameTable();
      Functions.inactiveButton(commonProp.router.t);
      break;
    case "changePrice":
      if (!Functions.checkPerm("520")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProductId.length === 0) {
        Swal.fire({
          title: commonProp.router.t("select-change"),
          icon: "error",
        });
        return;
      }
      commonProp.openModal(
        MODAL.CHANGE_PRICE,
        pageOrderTable.state.selectedProductId
      );
      break;
    case "changePersonPrice":
      if (!Functions.checkPerm("520")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProducts.length > 0) {
        const data: any = {
          tableId: table._id,
          orders: [],
        };

        data.orders = table.orders.map((o: any) => {
          const newOrder = { ...o, products: [] };
          const getPrices = (product: any) => {
            const foundProduct = commonProp.getGlobalData.Products?.find(
              (_: any) => _._id === product.product
            );
            return foundProduct?.prices || [];
          };
          o.products.forEach((p: any) => {
            if (
              pageOrderTable.state.selectedProducts.some(
                (selectedP: any) =>
                  selectedP._id === p._id && selectedP.status === 0
              )
            ) {
              Swal.fire({
                icon: "error",
                title: commonProp.router.t("check-selected-products"),
                timer: 3000,
              });
              return;
            }

            if (
              pageOrderTable.state.selectedProducts.some(
                (selectedP: any) => selectedP._id === p._id
              )
            ) {
              const priceList: any[] = [];
              let priceInfo: any;
              getPrices(p).forEach((price: any) => {
                if (p.priceId === price._id) {
                  priceInfo = price;
                }
                if (price.order_type?.some((type: any) => type === 10)) {
                  priceList.push(price);
                }
              });

              if (priceList.length === 1) {
                p.priceId = priceList[0]._id;
                p.price =
                  p.price -
                  priceInfo.price * p.quantity +
                  priceList[0].price * p.quantity;
              }

              if (
                priceList.length > 1 &&
                priceList.some(
                  (price: any) => price.priceName === priceInfo.priceName
                )
              ) {
                p.priceId = priceList.find(
                  (price: any) => price.priceName === priceInfo.priceName
                )._id;
                p.price =
                  p.price -
                  priceInfo.price * p.quantity +
                  priceList.find(
                    (price: any) => price.priceName === priceInfo.priceName
                  ).price *
                    p.quantity;
              }
            }

            newOrder.products.push(p);
          });

          return newOrder;
        });

        if (data.orders.some((o: any) => o.products.length > 0)) {
          Services.Put.updateOrders(data).then((resData) => {
            if (resData.status) {
              Swal.fire({
                title: commonProp.router.t("change-price-success"),
                icon: "success",
              });

              commonProp.setGlobalData({
                AllTables: commonProp.getGlobalData.AllTables.map((t: any) =>
                  t._id === data.tableId ? resData.data : t
                ),
              });
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: commonProp.router.t("no-changes-to-apply"),
            timer: 3000,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("select-change"),
          timer: 3000,
        });
      }
      break;
    case "discount":
      if (Functions.checkPerm("505")) {
        pageOrderTable.setState({
          discountDialog: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("not-permission"),
        });
      }
      break;
    case "cover":
      if (Functions.checkPerm("506")) {
        pageOrderTable.setState({
          coverDialog: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("not-permission"),
        });
      }
      break;
    case "catering":
      if (!Functions.checkPerm("514")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProductId.length < 1) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("please-select-product"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProducts.find((p: any) => p.isServe)) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("already-catering"),
        });
        return;
      }

      pageOrderTable.setState({
        cateringDialog: true,
      });
      break;
    case "readbarcode":
      //TODO: bekleyebilir
      Functions.inactiveButton(commonProp.router.t);
      Functions.readbarcode();
      break;
    case "fastcheckout":
      if (!Functions.checkPerm("507")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      GlobalStates.isFastPayment = true;
      handleClick("checkout", commonProp, pageOrder, pageOrderTable);
      break;
    case "cashCaseOpen":
      if (!localStorage.getItem("barcodePrinter")) {
        toast.error("Barkod yazıcısı seçilmemiş!");
        return false;
      }
      let generalPrinter = localStorage.getItem("barcodePrinter")!.toString();
      Print.print(Settings.payload_with_settings([]), -20, generalPrinter);
      break;
    case "print":
      if (table.isSafeSales) {
        if (pageOrder.state.newOrders.length == 0) {
          Swal.fire({
            title: commonProp.router.t("please-add-product"),
            icon: "warning",
          });
          return;
        }
        let totalDiscount = 0;
        table.discount.map((d: any) => (totalDiscount += d.amount));
        let printData = {
          checkNo: commonProp.router.t("case-sale"),
          discount: table.discount,
          covers: [],
          tableInfo: table,
          orders: [
            {
              orderNum: commonProp.router.t("case-sale"),
              products: pageOrder.state.newOrders.map((product: any) => ({
                productId: product.productId,
                id: product._id,
                productName: product.productName,
                quantity: product.quantity,
                priceId: product.priceId,
                price: product.price,
                isFirst: product.isFirst,
                status: product.status,
                isServe: product.isServe,
                options: product.options,
              })),
            },
          ],
          total: {
            amount: pageOrder.state.totalAmount - totalDiscount,
            currency:
              commonProp.getGlobalData?.CurrencySettings?.defaultCurrency ||
              "TL",
          },
          table: commonProp.router.t("case-sale"),
          section: commonProp.router.t("case-sale"),
          caseSale: true,
        };
        try {
          Printer.ProductsInOrder.printProductsInOrder(
            printData,
            commonProp.getGlobalData?.Products,
            commonProp.router.t
          );
        } catch (e: any) {
          Swal.fire({
            title: e.message,
            icon: "error",
          });
        }
        Swal.fire({
          title: commonProp.router.t("print-product-success"),
          icon: "success",
        });
        return;
      }
      if (GlobalStates.CustomerId) {
        //paket servis oluşturma sayfasındaysa
        if (pageOrder.state.newOrders.length == 0) {
          Swal.fire({
            title: commonProp.router.t("please-add-product"),
            icon: "warning",
          });
          return;
        }
        Swal.fire({
          title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
          html: commonProp.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });

        let printData = {
          checkNo: commonProp.router.t("take-away"),
          discounts: pageOrder.state.newDiscounts,
          covers: [],
          tableInfo: table,
          orders: [
            {
              orderNum: 1,
              products: pageOrder.state.newOrders.map((product: any) => ({
                productId: product.productId,
                id: product._id,
                productName: product.productName,
                quantity: product.quantity,
                priceId: product.priceId,
                price: product.price,
                isFirst: product.isFirst,
                status: product.status,
                isServe: product.isServe,
                options: product.options,
              })),
            },
          ],
          total: {
            amount: pageOrder.state.totalAmount,
            currency:
              commonProp.getGlobalData?.CurrencySettings?.defaultCurrency ||
              "TL",
          },
          table: commonProp.router.t("take-away"),
          section: commonProp.router.t("take-away"),
          homeDelivery: true,
        };
        try {
          Printer.ProductsInOrder.printProductsInOrder(
            printData,
            commonProp.getGlobalData?.Products,
            commonProp.router.t
          );
        } catch (e: any) {
          Swal.fire({
            title: e.message,
            icon: "error",
          });
        }
        Swal.fire({
          title: commonProp.router.t("print-product-success"),
          icon: "success",
        });
        return;
      }
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        if (table.orders.length > 0) {
          if (table.order_type == 3) {
            //paket servis masasıysa müşteri adı ve adresi bilgileride yazdırılacak
            Swal.fire({
              icon: "question",
              title: commonProp.router.t("not-print-question"),
              showCancelButton: true,
            }).then((result: any) => {
              if (result.isConfirmed) {
                let customer = pageOrderTable.state.or?.customer;
                let printData = {
                  discount: table.discount,
                  checkNo: commonProp.router.t("take-away"),
                  cover: table.cover,
                  tableInfo: table,
                  orders: [
                    {
                      orderNum: pageOrderTable.state.or.orderNum,
                      products: pageOrderTable.state.or.products
                        .concat(pageOrderTable.state.or.onWay)
                        .concat(pageOrderTable.state.or.unapproved),
                    },
                  ],
                  total: {
                    amount: pageOrderTable.state.or.totalPrice,
                    currency:
                      commonProp.getGlobalData?.CurrencySettings
                        ?.defaultCurrency || "TL",
                  },
                  homeDelivery: true,
                  address:
                    pageOrderTable.state.or.address ||
                    customer?.address[0].address,
                  phone: customer?.gsm_no,
                  name:
                    customer?.full_name ||
                    customer?.name + " " + customer?.lastname,
                };

                let sectionTitle = commonProp.getGlobalData.Sections.findSingle(
                  "_id",
                  table.section
                )?.title;
                printData = Object.assign(printData, {
                  table: `${sectionTitle} - ${table.title}`,
                });
                Printer.ProductsInOrder.printProductsInOrder(
                  printData,
                  commonProp.getGlobalData?.Products,
                  commonProp.router.t
                );
                Swal.fire({
                  title: commonProp.router.t("print-product-success"),
                  icon: "success",
                });
              }
            });
            return;
          }
          if (pageOrder.state.newOrders.length > 0) {
            Swal.fire({
              icon: "question",
              title: commonProp.router.t("not-print-question"),
              showCancelButton: true,
            }).then((result: any) => {
              if (result.isConfirmed) {
                Services.Put.table({
                  tableId: GlobalStates.SelectedTableId,
                  print: true,
                }).then((resData) => {
                  let serviceFee = 0;
                  let serviceFeeCount = 0;
                  table.serviceFee?.map((fee: any) => {
                    serviceFee += fee.amount;
                    serviceFeeCount++;
                  });
                  let printData = {
                    discount: table.discount,
                    checkNo: table.checkNo,
                    cover: table.cover,
                    serviceFee: serviceFee,
                    serviceFeeCount: serviceFeeCount,
                    tableInfo: table,
                    orders: table.orders.map((order: any) => ({
                      orderNum: order.orderNum,
                      products: order.products.map((product: any) => ({
                        productId: product.product,
                        id: product._id,
                        productName: product.productName,
                        quantity: product.quantity,
                        priceId: product.priceId,
                        price: product.price,
                        isFirst: product.isFirst,
                        status: product.status,
                        isServe: product.isServe,
                        options: product.options,
                      })),
                    })),
                    total: {
                      amount: table.totalPrice,
                      currency:
                        commonProp.getGlobalData?.CurrencySettings
                          ?.defaultCurrency || "TL",
                    },
                  };
                  let sectionTitle =
                    commonProp.getGlobalData.Sections.findSingle(
                      "_id",
                      GlobalStates.CurrentTable.section
                    )?.title;
                  printData = Object.assign(printData, {
                    table: `${sectionTitle} - ${GlobalStates.CurrentTable.title}`,
                  });

                  Printer.ProductsInOrder.printProductsInOrder(
                    printData,
                    commonProp.getGlobalData?.Products,
                    commonProp.router.t
                  );
                  Swal.fire({
                    title: commonProp.router.t("print-product-success"),
                    icon: "success",
                  });
                });
              }
            });
          } else {
            Services.Put.table({
              tableId: GlobalStates.SelectedTableId,
              print: true,
            }).then((resData) => {
              let serviceFee = 0;
              let serviceFeeCount = 0;
              table.serviceFee?.map((fee: any) => {
                serviceFee += fee.amount;
                serviceFeeCount++;
              });
              let printData = {
                discount: table.discount,
                checkNo: table.checkNo,
                cover: table.cover,
                serviceFee: serviceFee,
                serviceFeeCount: serviceFeeCount,
                tableInfo: table,
                orders: table.orders.map((order: any) => ({
                  orderNum: order.orderNum,
                  products: order.products.map((product: any) => ({
                    productId: product.product,
                    id: product._id,
                    productName: product.productName,
                    quantity: product.quantity,
                    priceId: product.priceId,
                    price: product.price,
                    isFirst: product.isFirst,
                    status: product.status,
                    isServe: product.isServe,
                    options: product.options,
                  })),
                })),
                total: {
                  amount: table.totalPrice,
                  currency:
                    commonProp.getGlobalData?.CurrencySettings
                      ?.defaultCurrency || "TL",
                },
              };
              let sectionTitle = commonProp.getGlobalData.Sections.findSingle(
                "_id",
                GlobalStates.CurrentTable.section
              )?.title;
              printData = Object.assign(printData, {
                table: `${sectionTitle} - ${GlobalStates.CurrentTable.title}`,
              });

              Printer.ProductsInOrder.printProductsInOrder(
                printData,
                commonProp.getGlobalData?.Products,
                commonProp.router.t
              );
              Swal.fire({
                title: commonProp.router.t("print-product-success"),
                icon: "success",
              });
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: commonProp.router.t("edit-customer-count-warning"),
          });
        }
      }

      break;
    case "sendfirst":
      if (GlobalStates.CurrentTable?.isSafeSales) {
        Functions.inactiveButton(commonProp.router.t);
      } else {
        if (pageOrder.state.newOrders.length > 0) {
          pageOrderTable.setState((state: TableState) => {
            state.sendFirst = clone(pageOrderTable.state.selectedProducts);
            state.sendFirstIds = clone(pageOrderTable.state.selectedProductId);
            return state;
          });
        }
      }
      break;
    case "approve":
      if (pageOrderTable.props.or.unapproved.length == 0) {
        Swal.fire({
          title: commonProp.router.t("all-approved"),
          icon: "error",
        });
        return;
      }
      Functions.approveHomeDelivery(commonProp, pageOrder, pageOrderTable);
      break;
    case "cancel-all":
      if (
        pageOrderTable.props.or.unapproved.length == 0 &&
        pageOrderTable.props.or.products.length == 0 &&
        pageOrderTable.props.or.onWay.length == 0
      ) {
        Swal.fire({
          title: commonProp.router.t("no-product-to-cancel"),
          icon: "warning",
        });
        return;
      }
      Functions.cancelHomeDelivery(commonProp, pageOrder, pageOrderTable);
      break;
    case "cancel-selected":
      if (pageOrderTable.state.selectedProductId.length === 0) {
        Swal.fire({
          title: commonProp.router.t("select-cancel"),
          icon: "warning",
        });
        return;
      }
      Functions.cancelHomeDeliveryProduct(
        commonProp,
        pageOrder,
        pageOrderTable
      );
      break;
    case "deleteHomeDeliveryProduct":
      if (pageOrderTable.state.selectedProductId.length === 0) {
        Swal.fire({
          title: commonProp.router.t("select-delete"),
          icon: "warning",
        });
        return;
      }
      Functions.deleteHomeDeliveryProduct(
        commonProp,
        pageOrder,
        pageOrderTable
      );
      break;
    case "deleteHomeDelivery":
      Functions.deleteHomeDelivery(commonProp, pageOrder, pageOrderTable);
      break;
    case "onway-order":
      if (pageOrderTable.props.or.products.length == 0) {
        Swal.fire({
          title: commonProp.router.t("not-approved-products"),
          icon: "error",
        });
        return;
      }
      pageOrderTable.setState({
        courierDialog: true,
      });
      break;
    case "add-product-home-delivery":
      if (pageOrder.state.newOrders.length < 1) {
        Swal.fire({
          title: commonProp.router.t("please-add-product"),
          icon: "warning",
        });
        return;
      }
      let params = {
        orderNum: pageOrderTable.props.or.orderNum,
        products: pageOrder.state.newOrders.map((p: any) => {
          return {
            product: p.productId,
            price: p.priceId,
            quantity: p.quantity,
            options: p.options || [],
            title: p.productName,
            category: p.category,
          };
        }),
      };
      const result = await Services.Put.addProductToHomeDelivery(params);
      if (result.status) {
        let cancelled = result.data.data.products.filter(
          (p: any) => p.status == 0
        );
        let unapproved = result.data.data.products.filter(
          (p: any) => p.status == 1
        );
        let products = result.data.data.products.filter(
          (p: any) => p.status == 2
        );
        let onWay = result.data.data.products.filter((p: any) => p.status == 3);
        let paid = result.data.data.products.filter((p: any) => p.status == 4);
        let addingPrice = 0;
        pageOrder.state.newOrders.map(
          (p: any) => (addingPrice += Number(p.price))
        );
        let or = {
          cancelled: cancelled,
          unapproved: unapproved,
          products: products,
          onWay: onWay,
          paid: paid,
          totalPrice: (pageOrderTable.state.or.totalPrice += addingPrice),
          remainingPrice: (pageOrderTable.state.or.remainingPrice +=
            addingPrice),
        };
        pageOrderTable.props.or.cancelled = cancelled;
        pageOrderTable.props.or.unapproved = unapproved;
        pageOrderTable.props.or.products = products;
        pageOrderTable.props.or.onWay = onWay;
        pageOrderTable.props.or.paid = paid;

        pageOrderTable.setState({
          or: or,
        });
        pageOrder.setState({
          newOrders: [],
        });
        Swal.fire({
          title: commonProp.router.t("add-success"),
          icon: "success",
        });
        return;
      }
      Swal.fire({
        title: commonProp.router.t("error-try-again"),
        icon: "error",
      });
      break;
    case "closeTable":
      if (Functions.checkPerm("510")) {
        if (table.checkNo == 0) {
          Swal.fire({
            icon: "warning",
            title: commonProp.router.t("cannot-close-already-closed-table"),
          });
          return;
        }
        Swal.fire({
          icon: "question",
          title: commonProp.router.t("close-table-question"),
          html: commonProp.router.t("close-table-text"),
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: commonProp.router.t("cancel"),
          confirmButtonText: commonProp.router.t("yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            Services.Get.closeTable({ tableId: GlobalStates.SelectedTableId });
            closeTable();
            Swal.fire({
              icon: "success",
              title: commonProp.router.t("table-closed"),
            });
            return;
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("not-permission"),
        });
      }

      break;
    case "cancelCatering":
      if (!Functions.checkPerm("514")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProductId.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("select-catering"),
        });
        return;
      }
      if (
        pageOrderTable.state.selectedProducts.filter((p: any) => !p.isServe)
          .length > 0
      ) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("please-select-only-catering"),
        });
        return;
      }
      Swal.fire({
        icon: "question",
        title: commonProp.router.t("cancel-catering-question"),
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: commonProp.router.t("no"),
        confirmButtonText: commonProp.router.t("yes"),
      }).then(async (res) => {
        if (res.isConfirmed) {
          let cateringParams: cancelCatering = {
            products: pageOrderTable.state.selectedProductId,
            tableId: GlobalStates.SelectedTableId,
          };
          let response = await Services.Put.cancelCatering(cateringParams);
          if (response.status) {
            pageOrderTable.state.selectedProductId.map(
              (id: string, index: number) => {
                let product = pageOrder.state.newOrders.find(
                  (p: any) => p._id == id
                );
                if (!product) return;
                product.isServe = false;
                product.serveId = "";
                pageOrderTable.state.selectedProductId.remove(index);
                pageOrder.setState({
                  totalAmount: pageOrder.state.totalAmount + product.price,
                });
              }
            );
            toast.success(commonProp.router.t("cancel-catering-succes"));
            let tables = await Services.Get.allTablesSync().data.filter(
              (table: any) => table.order_type !== 8
            );
            commonProp.setGlobalData({
              AllTables: tables,
            });
            let table = tables.find(
              (t: any) => t._id == GlobalStates.SelectedTableId
            );
            if (!table) return;

            pageOrderTable.setState({
              or: table.orders,
              totalPrice: table.totalPrice,
              remainingPrice: table.remainingPrice,
              selectedProductId: [],
              selectedProducts: [],
            });
            return;
          }
          toast.error(commonProp.router.t("cancel-catering-error"));
        }
      });

      break;
    case "approveQr":
      let error = false;
      if (pageOrderTable.state.selectedProductId.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("please-select-product"),
        });
        return;
      }
      table.orders.map((o: any) => {
        o.products.map((p: any) => {
          if (
            pageOrderTable.state.selectedProductId.includes(p._id) &&
            !o.user
          ) {
            Swal.fire({
              icon: "error",
              title: commonProp.router.t("only-qr-code-approved"),
              timer: 3000,
            });
            error = true;
          }
          if (
            pageOrderTable.state.selectedProductId.includes(p._id) &&
            o.user &&
            p.status == 0
          ) {
            Swal.fire({
              icon: "error",
              title: commonProp.router.t("cannot-approve-cancelled-order"),
              timer: 3000,
            });
            error = true;
          }
        });
      });
      if (!error) {
        let approveParams = {
          tableId: GlobalStates.SelectedTableId,
          products: pageOrderTable.state.selectedProductId,
        };
        let approveResponse: any = await Services.Put.approveQrOrder(
          approveParams
        );
        if (approveResponse.status) {
          printers.KitchenByGroup.printKitchenByGroup(commonProp.router.t, {
            products: clone(pageOrderTable.state.selectedProducts).map(
              (p: any) => {
                p.category = pageOrderTable.props.getGlobalData.Products.find(
                  (product: any) => product._id == p.product
                ).category;
                p.priceName = pageOrderTable.props.getGlobalData.Products.find(
                  (product: any) => product._id == p.product
                ).prices.find((price: any) => price._id == p.priceId).priceName;
                return p;
              }
            ),
            section: pageOrderTable.props.getGlobalData.Sections.findSingle(
              "_id",
              table.section
            ).title,
          });
          toast.success(commonProp.router.t("approve-success-all"));
          pageOrderTable.state.selectedProducts.map((p: any) => {
            p.status = 2;
          });
          pageOrderTable.setState({
            selectedProductId: [],
            selectedProducts: [],
          });
          return;
        }
        toast.error(commonProp.router.t("error"));
      }
      break;
    case "cancelQr":
      let cancelError = false;
      if (pageOrderTable.state.selectedProductId.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("please-select-product"),
        });
        return;
      }
      table.orders.map((o: any) => {
        o.products.map((p: any) => {
          if (
            pageOrderTable.state.selectedProductId.includes(p._id) &&
            !o.user
          ) {
            Swal.fire({
              icon: "error",
              title: commonProp.router.t("only-qr-code-cancelled"),
              timer: 3000,
            });
            cancelError = true;
          }
          if (
            pageOrderTable.state.selectedProducts.filter(
              (p: any) => p.status == 0
            ).length > 0
          ) {
            Swal.fire({
              icon: "error",
              title: commonProp.router.t("cannot-cancel-cancelled-products"),
              timer: 3000,
            });
            cancelError = true;
          }
        });
      });
      if (!cancelError) {
        let cancelParams: cancelCatering = {
          tableId: GlobalStates.SelectedTableId,
          products: pageOrderTable.state.selectedProductId,
        };
        let cancelResponse: any = await Services.Put.cancelQrOrder(
          cancelParams
        );
        if (cancelResponse.status) {
          toast.success(commonProp.router.t("cancel-success-selected"));
          let totalAmount = 0;
          pageOrderTable.state.selectedProducts.map((p: any) => {
            p.status = 0;
            totalAmount += p.price;
          });
          pageOrderTable.setState({
            totalPrice: pageOrderTable.state.totalPrice - totalAmount,
            remainingPrice: pageOrderTable.state.remainingPrice - totalAmount,
            selectedProductId: [],
            selectedProducts: [],
          });
          return;
        }
        toast.error(commonProp.router.t("error"));
      }
      break;
    case "cancelSelectedProducts":
      if (!Functions.checkPerm("503")) {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("auth-err"),
        });
        return;
      }
      if (table.orders.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("close-table-cancel-warning"),
        });
        return;
      }
      if (pageOrderTable.state.selectedProductId.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("please-select-product"),
        });
        return;
      }
      if (
        pageOrderTable.state.selectedProducts.filter((p: any) =>
          pageOrder.state.newOrders.find((product: any) => p._id == product._id)
        ).length > 0
      ) {
        Swal.fire({
          icon: "warning",
          title: "Bu buton sadece oluşturulmuş siparişler içindir",
        });
        return;
      }
      pageOrderTable.setState({
        cancelDialog: true,
      });

      break;
    case "editCustomerCount":
      if (table.orders.length == 0) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("edit-customer-count-warning"),
        });
        return;
      }
      pageOrderTable.setState({
        editCustomerCountDialog: true,
      });
      break;
    case "service":
      if (Functions.checkPerm("524")) {
        if (pageOrderTable.state.totalPrice <= 0) {
          Swal.fire({
            icon: "warning",
            title: commonProp.router.t("edit-customer-count-warning"),
          });
          return;
        }
        pageOrderTable.setState({
          serviceDialog: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("not-permission"),
        });
      }
      break;
    case "exchangePrint":
      if (GlobalStates.CustomerId) {
        //paket servis oluşturma sayfasındaysa
        if (pageOrder.state.newOrders.length == 0) {
          Swal.fire({
            title: commonProp.router.t("please-add-product"),
            icon: "warning",
          });
          return;
        }
      }
      if (table.orders?.length == 0) {
        Swal.fire({
          title: commonProp.router.t("please-add-product"),
          icon: "warning",
        });
        return;
      }
      if (!commonProp.getGlobalData?.CurrencySettings?.defaultCurrency) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("currency-features-info"),
        });
        return;
      }
      pageOrderTable.setState({
        exchangePrintDialogOpen: true,
      });
      break;
    case "send":
      if (pageOrder.state.newOrders?.length == 0) {
        Swal.fire({
          title: commonProp.router.t("please-add-product"),
          icon: "warning",
        });
        return;
      }
      let products: any = [];
      pageOrder.state.newOrders.map((p: any) => {
        let find = commonProp.getGlobalData.Products.find(
          (product: any) => product._id == p.productId
        );
        if (find) {
          p.product = p.productId;
          p.category = find.category;
          products.push(p);
        }
      });
      let printData = {
        products: products,
        section: commonProp.getGlobalData.Sections.findSingle(
          "_id",
          table.section
        )?.title,
        user: getGlobalAuthData().user,
      };
      Printer.KitchenByGroup.printKitchenByGroup(
        commonProp.router.t,
        printData
      );
      break;
    case "getirVerify":
      const order = table.getirOrders?.find(
        (order: any) => order._id === GlobalStates.CurrentGetirOrder
      );
      if (!order) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("no-order-info"),
        });
        break;
      }

      if (order.status !== "unapproved" && order.status !== "scheduled") {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("verify-warning"),
        });
        break;
      }

      const getirPrintData = {
        orderNum: order.orderNum,
        confirmationId: order.confirmationId,
        products: order.products?.map((product: any) => ({
          productName: product.productName,
          price: product.price,
        })),
        discount: order.totalDiscountedPrice
          ? order.totalPrice - order.totalDiscountedPrice
          : undefined,
        note: order.note,
        customerName: order.client?.name,
        customerPhone: order.client?.clientMaskedPhoneNumber,
        totalPrice: order.totalDiscountedPrice
          ? order.totalDiscountedPrice
          : order.totalPrice,
      };

      if (order.status === "scheduled") {
        Swal.fire({
          icon: "question",
          title: commonProp.router.t("scheduled-verify-warning"),
          text: commonProp.router.t("scheduled-verify-description"),
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: commonProp.router.t("cancel"),
          confirmButtonText: commonProp.router.t("confirm"),
        }).then(async (response) => {
          if (response.isConfirmed) {
            Swal.fire({
              title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
              html: commonProp.router.t("uploading") + "...",
              showConfirmButton: false,
              showCancelButton: false,
              allowOutsideClick: false,
              willOpen(popup: HTMLElement) {
                Swal.showLoading();
              },
            });
            const response = await GetirService.verifyScheduledOrder(
              order.getirOrderId
            );
            if (response.status) {
              try {
                Printer.PrintGetirOrder.printGetirOrder(getirPrintData);
              } catch (e: any) {
                console.log("printer error");
              }
              Swal.fire({
                icon: "success",
                title: commonProp.router.t("verify-success"),
              });
              commonProp.router.navigate("/", { replace: true });
            } else {
              Swal.fire({
                icon: "error",
                title: commonProp.router.t("verify-error"),
              });
            }
          }
        });
      } else {
        Swal.fire({
          title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
          html: commonProp.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });
        const response = await GetirService.verifyOrder(order.getirOrderId);
        if (response.status) {
          try {
            Printer.PrintGetirOrder.printGetirOrder(getirPrintData);
          } catch (e: any) {
            console.log("printer error");
          }
          Swal.fire({
            icon: "success",
            title: commonProp.router.t("verify-success"),
          });
          commonProp.router.navigate("/", { replace: true });
        } else {
          Swal.fire({
            icon: "error",
            title: commonProp.router.t("verify-error"),
          });
        }
      }
      break;
    case "getirPrepare":
      let prepareOrder = table.getirOrders?.find(
        (order: any) => order._id === GlobalStates.CurrentGetirOrder
      );
      if (!prepareOrder) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("no-order-info"),
        });
        return;
      }
      if (!checkDateDiff(prepareOrder?.lastActionDate)) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("notification-status-change-cd"),
        });
        return;
      }
      if (
        prepareOrder.status !== "verified" &&
        prepareOrder.status !== "verified-scheduled"
      ) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("prepare-warning"),
        });
        return;
      }
      Swal.fire({
        title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
        html: commonProp.router.t("uploading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const prepareResponse = await GetirService.prepareOrder(
        prepareOrder.getirOrderId,
        { deliveryType: prepareOrder.deliveryType }
      );
      if (prepareResponse.status) {
        Swal.fire({
          icon: "success",
          title: commonProp.router.t("prepare-success"),
        });
        commonProp.router.navigate("/", { replace: true });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("status-update-error"),
        });
      }
      break;
    case "getirHandover":
      let handoverOrder = table.getirOrders?.find(
        (order: any) => order._id === GlobalStates.CurrentGetirOrder
      );
      if (!handoverOrder) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("no-order-info"),
        });
        return;
      }
      if (handoverOrder.deliveryType !== 1) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("handover-warning"),
        });
        return;
      }
      if (handoverOrder.status !== "prepared") {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("handover-status-warning"),
        });
        return;
      }
      Swal.fire({
        title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
        html: commonProp.router.t("uploading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const handoverResponse = await GetirService.handoverOrder(
        handoverOrder.getirOrderId
      );
      if (handoverResponse.status) {
        Swal.fire({
          icon: "success",
          title: commonProp.router.t("handover-success"),
        });
        commonProp.router.navigate("/", { replace: true });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("status-update-error"),
        });
      }
      break;
    case "getirDeliver":
      let deliverOrder = table.getirOrders?.find(
        (order: any) => order._id === GlobalStates.CurrentGetirOrder
      );
      if (!deliverOrder) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("no-order-info"),
        });
        return;
      }
      if (!checkDateDiff(deliverOrder?.lastActionDate)) {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("notification-status-change-cd"),
        });
        return;
      }
      if (deliverOrder.deliveryType === 1) {
        if (deliverOrder.status !== "prepared") {
          Swal.fire({
            icon: "warning",
            title: commonProp.router.t("deliver-status-warning"),
          });
          return;
        }
        Swal.fire({
          title: commonProp.router.t("deliver-manual-success"),
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: commonProp.router.t("yes"),
          cancelButtonText: commonProp.router.t("no"),
        }).then(async (res) => {
          Swal.fire({
            title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
            html: commonProp.router.t("uploading") + "...",
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            willOpen(popup: HTMLElement) {
              Swal.showLoading();
            },
          });
          const deliverResponse = await GetirService.manualDeliverOrder(
            deliverOrder.getirOrderId
          );
          if (deliverResponse.status) {
            Swal.fire({
              icon: "success",
              title: commonProp.router.t("deliver-success"),
            });
            commonProp.router.navigate("/", { replace: true });
          } else {
            Swal.fire({
              icon: "error",
              title: commonProp.router.t("status-update-error"),
            });
          }
          return;
        });
      }
      if (deliverOrder.status !== "on-way") {
        Swal.fire({
          icon: "warning",
          title: commonProp.router.t("deliver-status-warning"),
        });
        return;
      }
      Swal.fire({
        title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
        html: commonProp.router.t("uploading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const deliverResponse = await GetirService.deliverOrder(
        deliverOrder.getirOrderId
      );
      if (deliverResponse.status) {
        Swal.fire({
          icon: "success",
          title: commonProp.router.t("deliver-success"),
        });
        commonProp.router.navigate("/", { replace: true });
      } else {
        Swal.fire({
          icon: "error",
          title: commonProp.router.t("status-update-error"),
        });
      }
      break;
    case "getirCancel":
      pageOrderTable.setState({
        getirCancelDialog: true,
      });
      break;
    case "getirManualActions":
      pageOrderTable.setState({
        getirManualActionsDialog: true,
      });
      break;
    default:
      Functions.inactiveButton(commonProp.router.t);
      break;
  }
};

export default handleClick;
