import GlobalStates from "config/global/states";
import Swal from "sweetalert2";
import { PagePropCommonDocument } from "../../../../modules/views/pages/pageProps";
import Printer from "config/global/printers/index";
import { getGlobalAuthData } from "config/global/auth";


const SendMainOrder = (
  commonProp: PagePropCommonDocument,
) => {
  const table = commonProp.getGlobalData.AllTables.findSingle(
    "_id",
    GlobalStates.SelectedTableId
  );
 
    Swal.fire({
      title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
      html: commonProp.router.t("uploading") + "...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      willOpen(popup: HTMLElement) {
        Swal.showLoading();
      },
    });
    let user = getGlobalAuthData().user;
      let params = {
        orderNum: GlobalStates.SelectedOrderNum,
        tableId: GlobalStates.SelectedTableId,
        section: commonProp.getGlobalData.Sections?.findSingle(
          "_id",
          table.section
        )?.title,
      };
      Printer.KitchenByGroup.printKitchenByGroupMainOrder(commonProp.router.t, {
        ...params,
        user: user,
      });
      commonProp.setGlobalData({}, () => {
        Swal.fire({
          title: commonProp.router.t("print-product-success"),
          icon: "success",
          timer: 1500,
        });
        commonProp.router.navigate("/", { replace: true });
      });
};

export default SendMainOrder;
