import handleRightClick from "./click/handleRightClick";
import createUUID from "./uuid/createUUID";
import checkPerm from "./perm/checkPerm";
import splitProducts from "./product/splitProducts";
import moveProduct from "./product/moveProduct";
import mergeCheck from "./check/moveCheck";
import nameTable from "./table/nameTable";
import checkForOpenTable from "./table/checkForOpenTable";
import SendOrder from "./send/sendOrder";
import SendMainOrder from "./send/sendMainOrder";
import inactiveButton from "./button/inactiveButton";
import readbarcode from "./barcode/readBarcode";
import catering from "./catering/catering";
import handleClick from "./click/handleClick";
import deleteHomeDeliveryProduct from "./product/deleteHomeDeliveryProduct";
import cancelHomeDelivery from "./product/cancelHomeDelivery";
import cancelHomeDeliveryProduct from "./product/cancelHomeDeliveryProduct";
import deleteHomeDelivery from "./product/deleteHomeDelivery";
import approveHomeDelivery from "./product/approveHomeDelivery";
import onWayHomeDelivery from "./product/onWayHomeDelivery";

export default {
  handleRightClick,
  createUUID,
  checkPerm,
  moveProduct,
  splitProducts,
  mergeCheck,
  nameTable,
  checkForOpenTable,
  SendOrder,
  SendMainOrder,
  inactiveButton,
  readbarcode,
  catering,
  handleClick,
  deleteHomeDeliveryProduct,
  deleteHomeDelivery,
  cancelHomeDelivery,
  cancelHomeDeliveryProduct,
  approveHomeDelivery,
  onWayHomeDelivery
};
