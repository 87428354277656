import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from "@mui/material";
import { GlobalLocalStorages, GlobalStates } from 'config/global';

export const UnPairedNow = () => {

    const { t } = useTranslation();
    const [timer, setTimer] = useState(5);
    var timesRun = 0;
    useEffect(() => {
        const interval = setInterval(() => {
            timesRun++;
            setTimer(timer => timer - 1);
            if (timesRun == 5) {
                GlobalLocalStorages.Token.delete();
                GlobalLocalStorages.User.delete();
                GlobalStates.firstNavigate = true;
                clearInterval(interval)
                window.location.href = "/sign-in";
            }

        }, 1000);
        return () => clearInterval(interval);
    }, [])
    return (
        <Box>
            <Typography style={{ fontSize: "1.8vw", color: "red", fontWeight: "bold", padding: 2, margin: "auto" }} textAlign="center">
                {t('unpaired')}
            </Typography>
            <Typography style={{ fontSize: "1.8vw", color: "#fff", padding: 2, margin: "auto" }} textAlign="center">{t('unpaired-now')}</Typography>
            <Typography style={{ fontSize: "1.8vw", color: "#fff", padding: 2, margin: "auto" }} textAlign="center">{t('remaining-to-redirect')} {timer} {t('sec')}</Typography>
        </Box>
    )
}
