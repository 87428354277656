import "./createcost.css";
import Swal from "sweetalert2";
import { Component, useState } from "react";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Services from "../../../../../../services/index";
import { getGlobalAuthData } from "config/global";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Stack, TextField, FormHelperText, Box } from "@mui/material";
import { toast } from "react-toastify";
const ipcRenderer: any = window.require('electron').ipcRenderer;

interface ICost {
    title: string;
    expense_type: number;
    currency: string;
    expense_amount: number;
    description: string;
    expense_time: Date;
    payment_type: number;
    openAccount?: string;

}

type PageState = {
    cost: ICost;
    costs: any;
    createOpenAccountOpen: boolean;
};

type PageProps = {
    data: any
} & PagePropCommonDocument;

class CreateCost extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cost: {
                title: "",
                expense_type: 1,
                currency: this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
                expense_amount: 0,
                description: "",
                expense_time: new Date(),
                payment_type: 2,
            },
            costs: [],
            createOpenAccountOpen: false
        };
    }

    componentDidMount() {
        this.sets();
    }

    sets() {
        let getCosts = Services.Get.costs().data;

        this.setState({
            costs: getCosts
        })
    }

    handleChange = (value: string | number, type: string) => {
        let temp = JSON.parse(JSON.stringify(this.state.cost));
        let users = this.props.getGlobalData.OpenAccounts;
        switch (type) {
            case "title":
                temp.title = value;
                break;
            case "cost-amount":
                temp.expense_amount = value;
                break;
            case "cost-type":
                temp.expense_type = value;
                break;
            case "description":
                temp.description = value;
                break;
            case "payment":
                if (Number(value) == 0) {
                    if(users?.length > 0){
                        temp.openAccount = users[0]._id;
                    }
                    temp.expense_type = 2;
                }else{
                    temp.expense_type = 1;
                }
                temp.payment_type = Number(value);
                break;
            case "open_account":
                temp.openAccount = value;
                break;
            default:
                break;
        }
        this.setState({
            cost: temp
        })
    };

    handleSave = () => {
        let data: any = this.state.cost;
        if (data.title.replace(/\s/g, "").length < 0) {
            Swal.fire({
                icon: "error",
                title: this.props.router.t("error-cost-title"),
            });
        } else if (
            data.title.replace(/\s/g, "").length > 0
        ) {
            if(this.state.cost.expense_type == 2 && !this.state.cost.openAccount){
                toast.warning(this.props.router.t("please-select-open-account"));
                return;
            }
            if (localStorage.getItem('caseId')) data.caseId = localStorage.getItem('caseId');
            Services.Post.costs(data).then((result) => {
                if (result.status) {
                    this.setState((state) => {
                        state.costs.concat([
                            {
                                ...data,
                                branch: getGlobalAuthData().user.branchId,
                                createdAt: new Date(),
                                id: result.data.id
                            }
                        ])
                        return state;
                    }, () => {
                        this.props.closeModal();
                        Swal.fire({
                            icon: "success",
                            title: this.props.router.t("cost-success"),
                        });
                    })
                } else {
                    Swal.fire({
                        title: this.props.router.t('error-try-again'),
                        icon: "error"
                    })
                }
            });
        }
    };

    handleCancel = () => {
        Swal.fire({
            icon: "question",
            title: this.props.router.t("cancel-question"),
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.props.closeModal();
            }
        });
    };

    CreateOpenAccountDialog = () => {
        const [name, setName] = useState("");
        const [number, setNumber] = useState("");
        const [loading, setLoading] = useState(false);
        const handleSave = async () => {
            if (name?.length == 0) {
                toast.warning(this.props.router.t('name-required'));
                return;
            }
            setLoading(true);
            let info = toast.loading(this.props.router.t('pleaseWait'));
            let res = await Services.Post.createOpenAccount({ name: name, gsm_no: number ? number : undefined });
            if (res.status) {
                let users = await Services.Get.openAccounts().data;
                this.props.setGlobalData({ OpenAccounts: users });
                toast.update(info, { render: this.props.router.t('create-open-account-success'), type: "success", isLoading: false, autoClose: 3000 });
                setName("");
                setNumber("");
                this.setState({
                    createOpenAccountOpen: false
                });
                setLoading(false);
                this.props.closeModal();
                return;
            }
            setLoading(false);
            toast.update(info, { render: this.props.router.t('error'), type: "error", isLoading: false, autoClose: 2000 })
        }
        return (
            <Dialog open={this.state.createOpenAccountOpen} onClose={() => this.setState({ createOpenAccountOpen: false })} fullWidth>
                <DialogTitle>{this.props.router.t('create-open-account')}</DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={3} alignItems="start" sx={{ marginTop: "3vw" }}>
                        <TextField value={name} onChange={(e: any) => setName(e.target.value)} onClick={() => ipcRenderer.send("showKeyboard")} label={this.props.router.t('name')} />
                        <Box>
                            <TextField value={number} onChange={(e: any) => setNumber(e.target.value)} onClick={() => ipcRenderer.send("showKeyboard")} label={this.props.router.t('phone-number')} />
                            <FormHelperText>{this.props.router.t('optional')}</FormHelperText>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="contained" onClick={() => handleSave()} color="success">{this.props.router.t('save')}</Button>
                    <Button variant="outlined" color="error" onClick={() => this.setState({ createOpenAccountOpen: false })}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        return (

            <div className="create-credit-customer">
                <this.CreateOpenAccountDialog />
                <div
                    style={{ paddingLeft: "1vw", paddingRight: "1vw", paddingTop: "2vw" }}
                    className="row"
                >
                    <div style={{ width: "20%", alignItems: "center" }}>
                        {this.props.router.t("cost-name")}
                    </div>
                    <div style={{ width: "80%" }}>
                        <input
                            type={"text"}
                            onChange={(event) => {
                                this.handleChange(event?.target.value, "title")
                            }
                            }
                            onClick={() => ipcRenderer.send('showKeyboard')}
                        />
                    </div>
                </div>
                <div
                    style={{ paddingLeft: "1vw", paddingRight: "1vw", marginTop: "5px" }}
                    className="row"
                >
                    <div style={{ width: "20%" }}>
                        {this.props.router.t("cost-amount")}
                    </div>
                    <div style={{ width: "80%" }}>
                        <input
                            style={{ width: "10vw" }}
                            type={"number"}
                            onChange={(event) => {
                                this.handleChange(event?.target.value, "cost-amount")
                            }
                            }
                            onClick={() => ipcRenderer.send('showKeyboard')}
                        />
                        <div>
                            <div style={{ marginLeft: "19px", alignItems: "center" }}>
                                {this.props.router.t("payment-type")}
                            </div>

                            <select onChange={(event) => this.handleChange(event?.target.value, "payment")}>
                                <option value={2}>{this.props.router.t("cash")}</option>
                                <option value={0}>{this.props.router.t("open-account")}</option>
                            </select>
                        </div>
                    </div>
                </div>
                {this.state.cost.expense_type == 2 ? (
                    <div style={{ paddingLeft: "1vw", paddingRight: "1vw", marginTop: "5px" }} className="row">
                        <div>
                            <span style={{ marginRight: "3vw" }}>
                                {this.props.router.t("open-account")}
                            </span>
                            <select onChange={(event) => this.handleChange(event?.target.value, "open_account")}>
                                {this.props.getGlobalData.OpenAccounts?.map((acc: any) => (
                                    <option value={acc._id}>{acc.name}</option>
                                ))}
                            </select>
                            <Button sx={{ marginLeft: "3.5vw" }} onClick={() => this.setState({ createOpenAccountOpen: true })} variant="contained" >{this.props.router.t('create-open-account')}</Button>
                        </div>
                    </div>
                ) : null}
                <div
                    style={{ paddingLeft: "1vw", paddingRight: "1vw", marginTop: "5px" }}
                    className="row"
                >
                    <div style={{ width: "20%" }}>
                        {this.props.router.t("cost-description")}
                    </div>
                    <div style={{ width: "80%" }}>
                        <input
                            type={"text"}
                            onChange={(event) => {
                                this.handleChange(event?.target.value, "description")
                            }
                            }
                            onClick={() => ipcRenderer.send('showKeyboard')}
                        />
                    </div>

                </div>
                <div
                    style={{ paddingLeft: "1vw", paddingRight: "1vw", paddingTop: "1vw" }}
                    className="row"
                >
                    <div
                        onClick={() => this.handleSave()}
                        style={{ height: "6vh", fontSize: "large", paddingTop: "1.3vh" }}
                        className="btn btn-success"
                    >
                        {this.props.router.t("submit")}
                    </div>
                    <div
                        onClick={() => this.handleCancel()}
                        style={{ height: "6vh", fontSize: "large", paddingTop: "1.3vh" }}
                        className="btn btn-danger"
                    >
                        {this.props.router.t("cancel")}

                    </div>

                </div>
            </div>
        );
    }
}

export default CreateCost;
