import { Box, ButtonGroup, Button } from '@mui/material';
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'

export const LanguageSettings = () => {
    const { t } = useTranslation();
    const [lang, setLang] = useState(localStorage.getItem("language") || "tr");

    const changeLanguage = (value: string) => {
        setLang(value);
        localStorage.setItem("language", value);
        window.location.reload();
    }
    return (
        <Box sx={{position: "absolute", top: 20, right: 20}}>
            <ButtonGroup>
                <Button value="tr" color={lang == "tr" ? "success" : "error"} onClick={(e: any) => changeLanguage(e.target.value)}  variant="contained" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "10vw",
                    height: "3vw",
                }}>{t('turkish')}</Button>
                <Button value="en" color={lang == "en" ? "success" : "error"} onClick={(e: any) => changeLanguage(e.target.value)} variant="contained" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "10vw",
                    height: "3vw",
                }}>{t('english')}</Button>
            </ButtonGroup>
        </Box>
    )
}
