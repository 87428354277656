import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import { Component } from "react";
import { Navigate } from "react-router-dom";
import {
  getGlobalAuthData,
  GlobalLocalStorages,
  setGlobalAuthData,
} from "../config/global";
import V from "../library/variable";
import { JsonWebToken } from "utilities/jwt";
import NewCase from "./views/components/newCase";
import Services from "services/index";
import Thread from "../library/thread";
import functions from "config/global/functions";
import { PairedAnotherDevice } from "./views/pages/paired-another-device";
import { NoPaired } from "./views/pages/no-paired";
import { socket } from "./socket-provider";
import { Banned } from "./views/pages/banned";
import { BannedNow } from "./views/pages/banned-now";
import { UnPairedNow } from "./views/pages/unpaired-now";
import { getMacAddress } from "utilities/helpers";
import { createNewWindow } from "utilities/create-new-window";
import { PairOptionDocument, SocketPairDocument } from "modules/services/user";
import { toast } from "react-toastify";
import { NotificationDocument } from "modules/services/api/branch";
import Currencies from "constants/currencies";
import printers from "config/global/printers";
import clone from "clone";
import NoConnection from "./views/pages/no-connection";
// import dns from 'dns'

const DefaultPaymentMethods = [
  { type: 1, rank: 2 },
  { type: 2, rank: 1 },
  { type: 6, rank: 3 },
  { type: 7, rank: 4 },
  { type: 8, rank: 5 },
  { type: 10, rank: 6 },
  { type: 11, rank: 7 },
  { type: 12, rank: 8 },
  { type: 15, rank: 9 },
  { type: 16, rank: 10 },
  { type: 17, rank: 11 },
  { type: 18, rank: 12 },
  { type: 19, rank: 13 },
  { type: 20, rank: 14 },
];

type PageState = {
  isAuth: boolean;
  isPageLoading: boolean;
  pairOptions: { paired: boolean; sameDevice?: boolean; now?: boolean };
  banned: { status: boolean; now: boolean };
};

interface IStockChange {
  id: string;
  decreased: boolean;
  quantity: number;
}

type PageProps = {
  isSetGlobalData: boolean;
  setIsSetGlobalData: (isSet: boolean, callback?: () => void) => void;
} & PagePropCommonDocument;

class Providers extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isAuth: false,
      isPageLoading: true,
      pairOptions: { paired: false },
      banned: { status: false, now: false },
    };
  }

  componentDidMount() {
    window.addEventListener("online", (e) => {
      this.props.setGlobalData({
        isConnected: true,
      });
    });
    window.addEventListener("offline", (e) => {
      this.props.setGlobalData({
        isConnected: false,
      });
    });
    let macAddress = getMacAddress();
    socket.on("unpaired", (data: SocketPairDocument) => {
      if (data.macAddress != macAddress) return; //check mac address if logged in multiple devices
      this.setState({ pairOptions: { paired: false, now: true } });
    });
    socket.on("banned", (data: SocketPairDocument) => {
      if (data.macAddress != macAddress) return; //check mac address if logged in multiple devices
      this.setState({ banned: { status: true, now: true } });
    });
    socket.on("unban", (data: SocketPairDocument) => {
      if (data.macAddress != macAddress) return; //check mac address if logged in multiple devices
      this.setState({ banned: { status: false, now: false } });
    });
    socket.on("updateTable", (data: any) => {
      this.updateTable(data);
    });
    socket.on("deleteTable", (data: any) => {
      this.deleteTable(data);
    });
    socket.on("createTable", (data: any) => {
      this.createTable(data);
    });
    socket.on("updateTick", (data: any) => {
      this.updateTick(data);
    });
    socket.on("updateBarcode", (data: any) => {
      this.updateProduct(data);
    });
    socket.on("printTable", (data: any) => {
      if (localStorage.getItem("isMainComputer") === "true") {
        this.printTable(data);
      }
    });

    socket.on("stockChange", (data: IStockChange) => {
      this.updateStock(data);
    });
    socket.on("notification", (data: any) => {
      let notification = this.defineNotification(data);
      this.sendNotification(notification);
      let alert = new Audio();
      alert.src = "https://api.digigarson.com/sounds/alert.mp3";
      alert.load();
      alert.play();
      if (JSON.parse(localStorage.getItem("notifications")!)?.length > 0) {
        let oldNotifications = JSON.parse(
          localStorage.getItem("notifications")!
        );
        if (oldNotifications?.length == 10) {
          oldNotifications.pop();
        }
        oldNotifications = oldNotifications.concat([data]);
        oldNotifications.sort((a: any, b: any) => {
          return (
            new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime()
          );
        });
        localStorage.setItem("notifications", JSON.stringify(oldNotifications));
      } else {
        localStorage.setItem("notifications", JSON.stringify([data]));
      }
      Services.Delete.clearNotifications();
    });
    if (JSON.parse(localStorage.getItem("new-screen")!)) {
      const screenNew = JSON.parse(localStorage.getItem("new-screen")!);
      createNewWindow(
        `https://desktop.digigarson.org/`,
        screenNew.id,
        screenNew.bool
      );
    }
    this.onRouteChanged();
  }

  createTable = (table: any) => {
    let allTables = this.props.getGlobalData?.AllTables;
    allTables?.push(table);
    this.props.setGlobalData({
      AllTables: allTables,
    });
  };

  deleteTable = (tableId: string) => {
    let allTables = this.props.getGlobalData?.AllTables?.filter(
      (t: any) => t._id != tableId
    );
    this.props.setGlobalData({
      AllTables: allTables,
    });
  };

  printTable = (table: any) => {
    let serviceFee = 0;
    table.serviceFee?.map((fee: any) => (serviceFee += fee.amount));
    let printData = {
      discount: table.discount,
      checkNo: table.checkNo,
      cover: table.cover,
      serviceFee: serviceFee,
      tableInfo: table,
      orders: table.orders.map((order: any) => ({
        orderNum: order.orderNum,
        products: order.products.map((product: any) => ({
          productId: product.product,
          id: product._id,
          productName: product.productName,
          quantity: product.quantity,
          priceId: product.priceId,
          price: product.price,
          isFirst: product.isFirst,
          status: product.status,
          isServe: product.isServe,
        })),
      })),
      total: {
        amount: table.totalPrice,
        currency:
          this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      },
    };

    let sectionTitle = this.props.getGlobalData.Sections.findSingle(
      "_id",
      table.section
    )?.title;
    printData = Object.assign(printData, {
      table: `${sectionTitle} - ${table.title}`,
    });

    printers.ProductsInOrder.printProductsInOrder(
      printData,
      this.props.getGlobalData?.Products,
      this.props.router.t
    );
  };

  updateTable = (table: any) => {
    let allTables = this.props.getGlobalData?.AllTables?.map((t: any) => {
      if (table._id == t._id) {
        t = table;
        if (table.order_type == 3) {
          this.props.setGlobalData({
            AllTakeaway: table.orders,
          });
        }
      }
      return t;
    });
    this.props.setGlobalData({
      AllTables: allTables,
    });
  };

  updateStock = (data: IStockChange) => {
    let allStocks = this.props.getGlobalData?.stocks?.map((stock: any) => {
      if (data.id == stock._id) {
        stock.stock = data.quantity;
        stock.decreased = data.decreased;
      }
      return stock;
    });
    this.props.setGlobalData({
      stocks: allStocks,
    });
  };

  updateProduct = (product: any) => {
    let allProducts = this.props.getGlobalData?.Products.map((p: any) => {
      if (p._id == product._id) {
        p = product;
      }
      return p;
    });
    this.props.setGlobalData({
      Products: allProducts,
    });
  };

  updateTick = (tick: any) => {
    let allTicks = this.props.getGlobalData?.Ticks?.map((t: any) => {
      if (t._id == tick._id) {
        t = tick;
      }
      return t;
    });
    this.props.setGlobalData({
      Ticks: allTicks,
    });
  };

  defineNotification = (notification: NotificationDocument) => {
    let text = <></>;
    switch (notification.type) {
      case 0: //new app order
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("new-order")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("table")}:</strong>{" "}
              {notification.info.table}
            </p>
            <p>
              <strong>{this.props.router.t("section")}:</strong>{" "}
              {notification.info.section}
            </p>
            <p>
              <strong>{this.props.router.t("total-product-amount")}:</strong>{" "}
              {notification.info.quantity}
            </p>
            <p>
              <strong>{this.props.router.t("total-amount")}:</strong>{" "}
              {notification.info.amount}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </p>
          </div>
        );
        break;
      case 1: //new table service
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("new-service")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("table")}:</strong>{" "}
              {notification.info.table}
            </p>
            <p>
              <strong>{this.props.router.t("section")}:</strong>{" "}
              {notification.info.section}
            </p>
            <p>
              <strong>{this.props.router.t("customer")}:</strong>{" "}
              {notification.info.customer}
            </p>
            <p>
              <strong>{this.props.router.t("request")}:</strong>{" "}
              {notification.info.service}
            </p>
          </div>
        );
        break;
      case 2: //new home delivery order from app
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("new-home-delivery")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("customer")}:</strong>{" "}
              {notification.info.customer}
            </p>
            <p>
              <strong>{this.props.router.t("total-product-amount")}:</strong>{" "}
              {notification.info.quantity}
            </p>
            <p>
              <strong>{this.props.router.t("total-amount")}:</strong>{" "}
              {notification.info.amount}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </p>
          </div>
        );
        break;
      case 3: //new waiter order
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("new-waiter-order")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("table")}:</strong>{" "}
              {notification.info.table}
            </p>
            <p>
              <strong>{this.props.router.t("section")}:</strong>{" "}
              {notification.info.section}
            </p>
            <p>
              <strong>{this.props.router.t("total-product-amount")}:</strong>{" "}
              {notification.info.quantity}
            </p>
            <p>
              <strong>{this.props.router.t("total-amount")}:</strong>{" "}
              {notification.info.amount}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </p>
            <p>
              <strong>{this.props.router.t("waiter")}:</strong>{" "}
              {notification.info.waiter}
            </p>
          </div>
        );
        if (localStorage.getItem("isMainComputer") === "true") {
          let table = this.props.getGlobalData.AllTables.findSingle(
            "_id",
            notification.info.tableId
          );
          printers.KitchenByGroup.printKitchenByGroup(this.props.router.t, {
            products: clone(notification.info.products).map((p: any) => {
              p.category = this.props.getGlobalData.Products.find(
                (product: any) => product._id == p.product
              ).category;
              p.priceName = this.props.getGlobalData.Products.find(
                (product: any) => product._id == p.product
              ).prices.find((price: any) => price._id == p.priceId).priceName;
              return p;
            }),
            section: this.props.getGlobalData.Sections?.findSingle(
              "_id",
              table.section
            )?.title,
            tableTitle: table.title,
            waiter: notification.info?.waiter,
          });
        }
        break;
      case 4: // new getir order
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("new-getir-order")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("confirmation-id")}:</strong>{" "}
              {notification.info.confirmationId}
            </p>
            <p>
              <strong>{this.props.router.t("customer")}:</strong>{" "}
              {notification.info.customer}
            </p>
            <p>
              <strong>{this.props.router.t("total-amount")}:</strong>{" "}
              {notification.info.amount}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </p>
            <p>
              <strong>{this.props.router.t("delivery-type")}:</strong>{" "}
              {notification.info.deliveryType}
            </p>
          </div>
        );
        break;
      case 5: // getir order cancelled
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>{this.props.router.t("getir-order-cancellation")}</strong>
            </h2>
            <p>
              <strong>{this.props.router.t("confirmation-id")}:</strong>{" "}
              {notification.info.confirmationId}
            </p>
            <p>
              <strong>{this.props.router.t("customer")}:</strong>{" "}
              {notification.info.customer}
            </p>
            <p>
              <strong>{this.props.router.t("total-amount")}:</strong>{" "}
              {notification.info.amount}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </p>
            <p>
              <strong>{this.props.router.t("delivery-type")}:</strong>{" "}
              {notification.info.deliveryType}
            </p>
          </div>
        );
        break;
      default:
        text = (
          <div>
            <h2 style={{ textAlign: "center" }}>
              <strong>
                {this.props.router.t("unidentified-notification")}
              </strong>
            </h2>
          </div>
        );
    }
    return text;
  };

  sendNotification = (text: object) => {
    toast.info(text);
  };

  componentDidUpdate(prevProps: Readonly<PageProps>) {
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname
    ) {
      this.onRouteChanged();
    }
  }

  private onRouteChanged() {
    this.setState(
      {
        isPageLoading: true,
      },
      () => {
        this.setState(
          {
            isAuth:
              this.checkSession() &&
              getGlobalAuthData().user.branch_custom_id > 0,
          },
          () => {
            if (this.state.isAuth && !this.state.pairOptions.paired) {
              this.checkPairing();
            }
            if (this.state.isAuth && !this.props.isSetGlobalData) {
              this.setGlobalData().then((value) => {
                this.setState({
                  isPageLoading: false,
                });
              });
            } else {
              this.setState({
                isPageLoading: false,
              });
            }
          }
        );
      }
    );
  }

  private async checkPairing() {
    let pairOptions = Services.Get.getPairOptions().data;
    let macAddress = getMacAddress();
    if (!macAddress) {
      console.error("Pair error");
      return;
    }
    let user: any = Services.Get.profile().data;
    if (user.bannedDevices) {
      let find = user.bannedDevices?.find(
        (data: any) => data.macAddress == macAddress
      );
      if (find) {
        this.setState({
          banned: { status: true, now: false },
        });
      }
    }
    if (!pairOptions || pairOptions?.length == 0) {
      this.setState({ pairOptions: { paired: false } });
      return;
    }

    if (
      pairOptions?.find(
        (pairOption: PairOptionDocument) => pairOption.macAddress == macAddress
      )
    ) {
      this.setState({
        pairOptions: { paired: true, sameDevice: true },
      });
      return { paired: true, sameDevice: true };
    }
    this.setState({
      pairOptions: { paired: true, sameDevice: false },
    });
    return { paired: true, sameDevice: false };
  }

  setGlobalData() {
    return new Promise((resolve) => {
      Thread.start(() => {
        let homeDeliveryInfo = localStorage.getItem("isHomeDeliveryOpen");
        if (!functions.checkPerm("511")) {
          const branchAllData = Services.Get.myBranch().data;
          const products = Services.Get.allProduct().data;
          const categories = Services.Get.categories().data;
          let paymentMethods = Services.Get.getPaymentMethods().data;
          var caseResData = Services.Get.getCaseById(
            localStorage.getItem("caseId")!
          );
          paymentMethods =
            paymentMethods.length > 0 ? paymentMethods : DefaultPaymentMethods;
          paymentMethods.sort((a: any, b: any) => {
            return a.rank - b.rank;
          });
          products.sort((a: any, b: any) => {
            return a.rank - b.rank;
          });
          categories.sort((a: any, b: any) => {
            return a.rank - b.rank;
          });
          localStorage.setItem(
            "defaultCurrency",
            branchAllData.currencySettings?.defaultCurrency || "TL"
          );
          this.props.setGlobalData(
            {
              caseId: caseResData.data._id,
              Categories: categories,
              Products: products,
              ProductOptions: branchAllData.options,
              AllTables: Services.Get.allTablesSync().data.filter(
                (table: any) => table.order_type !== 8
              ),
              stocks: Services.Get.getStocks().data,
              recipes: Services.Get.getRecipes().data,
              semiIngredients: Services.Get.getSemiIngredients().data,
              Sections: branchAllData.sections,
              Ticks: Services.Get.tick({}).data,
              AllCourier: Services.Get.couriers().data,
              AllCustomers: Services.Get.customers().data,
              AllTakeaway:
                homeDeliveryInfo == "true"
                  ? Services.Get.takeawaySync().data
                  : [],
              Serves: Services.Get.getServes().data,
              Notes: Services.Get.notes().data,
              CancelReasons: Services.Get.cancelReasons().data,
              SpecialCustomers: Services.Get.specialCustomers().data,
              BranchImage: branchAllData.image,
              OpenAccounts: Services.Get.openAccounts().data,
              CurrencySettings: branchAllData.currencySettings,
              PaymentMethods:
                paymentMethods?.length > 0
                  ? paymentMethods
                  : DefaultPaymentMethods,
            },
            () => {
              this.props.setIsSetGlobalData(true, () => {
                resolve(1);
              });
            }
          );
        } else {
          var caseResData = Services.Get.case({});
          if (caseResData.status) {
            if (caseResData.data._id) {
              const branchAllData = Services.Get.myBranch().data;
              const products = Services.Get.allProduct().data;
              const categories = Services.Get.categories().data;
              let paymentMethods = Services.Get.getPaymentMethods().data;
              paymentMethods =
                paymentMethods.length > 0
                  ? paymentMethods
                  : DefaultPaymentMethods;
              paymentMethods.sort((a: any, b: any) => {
                return a.rank - b.rank;
              });
              products.sort((a: any, b: any) => {
                return a.rank - b.rank;
              });
              categories.sort((a: any, b: any) => {
                return a.rank - b.rank;
              });
              localStorage.setItem(
                "defaultCurrency",
                branchAllData.currencySettings?.defaultCurrency || "TL"
              );
              this.props.setGlobalData(
                {
                  caseId: caseResData.data._id,
                  Categories: categories,
                  Products: products,
                  ProductOptions: branchAllData.options,
                  AllTables: Services.Get.allTablesSync().data.filter(
                    (table: any) => table.order_type !== 8
                  ),
                  stocks: Services.Get.getStocks().data,
                  recipes: Services.Get.getRecipes().data,
                  semiIngredients: Services.Get.getSemiIngredients().data,
                  Sections: branchAllData.sections,
                  Ticks: Services.Get.tick({}).data,
                  AllCourier: Services.Get.couriers().data,
                  AllCustomers: Services.Get.customers().data,
                  AllTakeaway:
                    homeDeliveryInfo == "true"
                      ? Services.Get.takeawaySync().data
                      : [],
                  Serves: Services.Get.getServes().data,
                  Notes: Services.Get.notes().data,
                  CancelReasons: Services.Get.cancelReasons().data,
                  SpecialCustomers: Services.Get.specialCustomers().data,
                  BranchImage: branchAllData.image,
                  OpenAccounts: Services.Get.openAccounts().data,
                  CurrencySettings: branchAllData.currencySettings,
                  PaymentMethods:
                    paymentMethods?.length > 0
                      ? paymentMethods
                      : DefaultPaymentMethods,
                },
                () => {
                  this.props.setIsSetGlobalData(true, () => {
                    resolve(1);
                  });
                }
              );
            } else {
              resolve(1);
            }
          } else {
            resolve(1);
          }
        }
      });
    });
  }

  checkSession(): boolean {
    if (!V.isEmpty(GlobalLocalStorages.Token.get.access)) {
      setGlobalAuthData({
        tokens: {
          access: GlobalLocalStorages.Token.get.access,
          refresh: GlobalLocalStorages.Token.get.refresh,
        },
        user: JsonWebToken.parse(GlobalLocalStorages.Token.get.access),
      });
      return true;
    }
    GlobalLocalStorages.Token.delete();
    GlobalLocalStorages.User.delete();
    return false;
  }

  render() {
    if (!this.props.getGlobalData.isConnected) return <NoConnection />;
    if (this.state.isAuth && !this.state.isPageLoading) {
      if (this.state.banned.status && !this.state.banned.now) return <Banned />;
      if (this.state.banned.status && this.state.banned.now)
        return <BannedNow />;
      if (
        !this.state.pairOptions.paired &&
        this.state.pairOptions.now &&
        !this.state.banned.status
      )
        return <UnPairedNow />;
      if (
        !this.state.pairOptions.sameDevice &&
        !this.state.banned.status &&
        this.state.pairOptions.paired
      )
        return <PairedAnotherDevice />;
      if (
        !this.state.pairOptions.paired &&
        !this.state.banned.status &&
        !this.state.pairOptions.now
      )
        return <NoPaired />;
    }
    if (!Boolean(window.require))
      return <div className="text-center">dunno, where are you?</div>;
    if (this.state.isPageLoading)
      return <div className="caseClosed">{this.props.router.t("loading")}</div>;
    return !this.state.isAuth &&
      !this.props.router.location.pathname.endsWith("sign-in") ? (
      <Navigate to="/sign-in" />
    ) : this.state.isAuth &&
      this.props.router.location.pathname.endsWith("sign-in") ? (
      <Navigate to="/" />
    ) : (!this.state.isAuth &&
        this.props.router.location.pathname.endsWith("sign-in")) ||
      this.props.getGlobalData.caseId ? (
      this.props.children
    ) : (
      <NewCase {...this.props} />
    );
  }
}

export default Providers;
