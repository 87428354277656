import GlobalStates from "config/global/states";
import Settings from "../settings/settings";
import PrintKitchen from "./printKitchen";
export default class KitchenByGroup {
  static printKitchenByGroup(t: any, check: any) {
    const Groups = Settings.Groups.map((group) => ({
      ...group,
      products: check.products
        .filter((order: any) => !order.isFirst)
        .filter(
          (o: any) =>
            (group.categories.includes(o.category?.toString()) ||
              group.products.includes(o.product)) &&
            (group.tables
              ? group.tables.length > 0
                ? group.tables.includes(GlobalStates.SelectedTableId)
                : true
              : true)
        ),
      firstProducts: check.products
        ?.filter((order: any) => order.isFirst)
        .filter(
          (o: any) =>
            (group.categories.includes(o.category) ||
              group.products.includes(o.product)) &&
            (group.tables
              ? group.tables.length > 0
                ? group.tables.includes(GlobalStates.SelectedTableId)
                : true
              : true)
        ),
      table: check.tableTitle
        ? check.tableTitle
        : GlobalStates.CurrentTable.title,
      section: check.section,
    }));
    Groups.length
      ? Groups.forEach(
          (data) =>
            data.products.length &&
            PrintKitchen.printKitchen(
              t,
              {
                ...check,
                products: data.products,
                firstOrders: data.firstProducts,
                table: data.table,
                section: data.section,
              },
              data.printer
            )
        )
      : null;
  }

  static printKitchenByGroupMainOrder(t: any, check: any) {
    const Groups = Settings.Groups.map((group) => ({
      ...group,
      table: check.tableTitle
      ? check.tableTitle
      : GlobalStates.CurrentTable.title,
    section: check.section,
    }));
    Groups.length
      ? Groups.forEach(
          (data) =>
            PrintKitchen.printKitchenMainCheck(
              t,
              {
                ...check,
                table: data.table,
                section: data.section,
              },
              data.printer
            )
        )
      : null;
  }
}
