import React, { Component, useEffect, useState } from "react";
import "../check.css";
import Swal from "sweetalert2";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import { getGlobalAuthData, GlobalStates } from "config/global";
import Functions from "../../../../../../config/global/functions/index";
import ColorPicker from "./colorPicker";
import "./buttonRight.css";
import PageOrder, { PageOrderState } from "../../index";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Avatar,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogTitle,
  Dialog,
  Typography,
  TextField,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  Box,
  Stack,
  IconButton,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Table as MTable,
  useTheme,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import AddIcon from "@mui/icons-material/Add";
import Services from "services/index";
import { DeleteCoverParamDocument } from "modules/services/cover";
import { DiscountDeleteParamDocumet } from "modules/services/discount";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import clone from "clone";
import { markAsCatering } from "modules/services/orders";
import Printer from "config/global/printers/index";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import RemoveIcon from "@mui/icons-material/Remove";
import Currencies from "constants/currencies";
import { isEqual } from "lodash";
import GetirService from "services/getir-service";
const ipcRenderer: any = window.require("electron").ipcRenderer;

export type TableState = {
  isChecked: boolean;
  isAllChecked: boolean;
  selectedProductId: string[];
  sendFirst: any[];
  isColorPickerFired: boolean;
  or: any;
  selectedOrderNums: number[];
  selectedProducts: any[];
  sendFirstIds: string[];
  courierDialog: boolean;
  createCourierDialog: boolean;
  courier: {
    title: string;
    phone_number: string;
  };
  cateringDialog: boolean;
  cancelDialog: boolean;
  selectedServe: string;
  totalPrice: number;
  remainingPrice: number;
  covers: any[];
  discounts: any[];
  coverDialog: boolean;
  discountDialog: boolean;
  editCustomerCountDialog: boolean;
  deleteDialog: boolean;
  serviceDialog: boolean;
  seeServiceFeeDialog: boolean;
  exchangeVisibility: string;
  exchangePrintDialogOpen: boolean;
  defaultCurrency: string;
  activeTab: string;
  getirCancelDialog: boolean;
  getirManualActionsDialog: boolean;
};

type PageProps = {
  pageOrder: PageOrder;
  or: any;
  covers: any;
} & PagePropCommonDocument;

class Table extends Component<PageProps, TableState> {
  constructor(props: PageProps, or: any) {
    super(props);
    this.state = {
      sendFirst: [],
      isChecked: true,
      isAllChecked: false,
      selectedProductId: [],
      selectedOrderNums: [],
      isColorPickerFired: false,
      or: this.props.or,
      selectedProducts: [],
      sendFirstIds: [],
      courierDialog: false,
      createCourierDialog: false,
      courier: {
        title: "",
        phone_number: "",
      },
      cateringDialog: false,
      cancelDialog: false,
      selectedServe: "",
      totalPrice: 0,
      remainingPrice: 0,
      covers: [],
      discounts: [],
      coverDialog: false,
      discountDialog: false,
      editCustomerCountDialog: false,
      deleteDialog: false,
      serviceDialog: false,
      seeServiceFeeDialog: false,
      exchangeVisibility: "hidden",
      exchangePrintDialogOpen: false,
      defaultCurrency:
        this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      activeTab: "description",
      getirCancelDialog: false,
      getirManualActionsDialog: false,
    };
  }

  componentDidMount() {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    let isTakeaway = table.order_type == 3;
    if (!isTakeaway && table.order_type !== 6) {
      this.setState({
        totalPrice: table.totalPrice,
        remainingPrice: table.remainingPrice,
        covers: table.cover,
        discounts: table.discount,
      });
    }
    if (table.order_type === 6) {
      this.setState({
        or: this.props.or,
        totalPrice: this.props.or.totalPrice,
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<PageProps>,
    prevState: Readonly<TableState>,
    snapshot?: any
  ): void {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    if (table.order_type == 3) {
      let takeaway =
        this.props.getGlobalData.AllTakeaway[GlobalStates.CurrentTakeaway];
      let updatedOrder = {
        orderNum: takeaway?.orderNum,
        products: takeaway?.products?.filter((p: any) => p.status == 2) || [],
        unapproved: takeaway?.products?.filter((p: any) => p.status == 1) || [],
        cancelled: takeaway?.products?.filter((p: any) => p.status == 0) || [],
        onWay: takeaway?.products?.filter((p: any) => p.status == 3) || [],
        paid: takeaway?.products?.filter((p: any) => p.status == 4) || [],
        totalPrice: takeaway?.totalPrice || 0,
        remainingPrice: takeaway?.remainingPrice || 0,
        customer: takeaway?.customer || takeaway?.user, //qr kod siparişlerinde user - normal paket servislerde customer propertysi
        address: takeaway?.address,
      };
      if (!isEqual(this.state.or, updatedOrder)) {
        this.setState({
          or: updatedOrder,
        });
      }
      return;
    }
    if (table.order_type == 6) return;
    if (
      !isEqual(this.state.or, table?.orders) ||
      !isEqual(table?.cover, this.state.covers) ||
      !isEqual(table?.discount, this.state.discounts)
    ) {
      this.setState({
        or: table.orders,
        covers: table.cover,
        discounts: table.discount,
      });
    }
  }
  showToastify(type: string, message: string) {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "error":
        toast.error(message);
        break;
    }
  }
  getAddress(id: any) {
    if (GlobalStates.CustomerId) {
      let add = "",
        title = "",
        phone = "";

      this.props.getGlobalData.AllCustomers.map((customer: any) => {
        if (customer.id === id) {
          add = customer?.address[0].address;
          title = customer?.address[0].title;
          phone = customer.gsm_no;
        }
      });
      let totalAddress = `Adres Başlığı: ${title}` + "\n" + `Address: ${add}`;
      return totalAddress;
    }
  }

  getOrderOption(options: any, note?: string, getir?: boolean) {
    let optionNames = "";
    if (getir) {
      options.forEach((option: any) => {
        optionNames += `<b>${option?.name || "Getir Opsiyon"}</b><br>`;
        option.items.forEach((item: any) => {
          optionNames += `${item?.name}, `;
        });
        optionNames = optionNames.removeLastChar(2);
        optionNames += `<br><br>`;
      });
      if (note) {
        optionNames += `<b>Not</b><br>${note}<br><br>`;
      }
    } else {
      options.forEach((option: any) => {
        let findOption = this.props.getGlobalData.ProductOptions.findSingle(
          "_id",
          option.option_id
        );
        optionNames += `<b>${findOption?.name}</b><br>`;
        option.items.forEach((item: any) => {
          let findOptionItem = findOption?.items.findSingle(
            "_id",
            item.item_id
          );
          optionNames += `${findOptionItem?.item_name}, `;
        });
        optionNames = optionNames.removeLastChar(2);
        optionNames += `<br><br>`;
      });
      if (note) {
        optionNames += `<b>Not</b><br>${note}<br><br>`;
      }
    }
    return optionNames;
  }

  optionOrderPopup(pc: any): void {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    if (pc.options.length > 0 || pc.note) {
      Swal.fire({
        title: `<b>${this.props.router.t("options")}</b>`,
        html: this.getOrderOption(pc.options, pc.note, table.order_type === 6),
        confirmButtonText: this.props.router.t("close"),
      });
    } else {
      Swal.fire({
        icon: "error",
        title: this.props.router.t("no-option"),
      });
    }
  }

  discountNotePopup(note: string) {
    Swal.fire({
      title: `<b>${this.props.router.t("note")}</b>`,
      text: note,
      confirmButtonText: this.props.router.t("close"),
    });
  }

  onSelectedProduct(uid: string, product: any) {
    this.setState((state: TableState) => {
      let findIndex = state.selectedProductId.indexOfKey("", uid);
      let pIndex = state.selectedProducts.findIndex((p: any) => p._id == uid);
      if (findIndex > -1) {
        state.selectedProductId.remove(findIndex);
        state.selectedProducts.remove(pIndex);
        findIndex = state.sendFirst.indexOfKey("", uid);
        pIndex = state.selectedProducts.findIndex((p: any) => p._id == uid);
        if (findIndex > -1) {
          state.sendFirst.remove(findIndex);
          state.sendFirst.remove(pIndex);
        }
      } else {
        state.selectedProductId.push(uid);
        state.selectedProducts.push(product);
      }
      state.isAllChecked = false;
      return state;
    });
  }

  onSelectedOrder(orderNum: any, products: any[]) {
    this.setState((state: TableState) => {
      if (state.selectedOrderNums.includes(orderNum)) {
        let findIndex = state.selectedOrderNums.indexOfKey("", orderNum);
        state.selectedOrderNums.remove(findIndex);
        products.forEach((product: any) => {
          let index = state.selectedProductId.indexOfKey("", product._id);
          let i = state.selectedProducts.findIndex(
            (p: any) => p._id == product._id
          );
          if (index > -1) {
            state.selectedProductId.remove(index);
          }
          if (i > -1) {
            state.selectedProducts.remove(i);
          }
        });
      } else {
        state.selectedOrderNums.push(orderNum);
        products.map((p: any) => {
          let index = state.selectedProductId.indexOfKey("", p._id);
          let i = state.selectedProducts.findIndex(
            (product: any) => product._id == p._id
          );
          if (index == -1) {
            state.selectedProductId.push(p._id);
          }
          if (i == -1) {
            state.selectedProducts.push(p);
          }
        });
      }
      return state;
    });
  }

  handleCheckAll(event: any) {
    this.setState((state: TableState) => {
      state.isAllChecked = event.target.checked;
      state.selectedProductId = [];
      state.selectedOrderNums = [];
      state.selectedProducts = [];
      if (state.isAllChecked) {
        let table: any = this.props.getGlobalData.AllTables.findSingle(
          "_id",
          GlobalStates.SelectedTableId
        );
        table?.orders?.forEach((order: any) => {
          state.selectedOrderNums.push(order.orderNum);
          order.products.forEach((product: any) => {
            state.selectedProductId.push(product._id);
            state.selectedProducts.push(product);
          });
        });
        this.props.pageOrder.state.newOrders.forEach((p: any) =>
          state.selectedProductId.push(p._id)
        );
      }
      return state;
    });
  }

  onClick(type: string) {
    if (type === "sendOrder") {
      if (GlobalStates.CustomerId) {
        if (!GlobalStates.SelectedPaymentTypeId) {
          this.props.pageOrder.setState({
            isSelectPayment: true,
          });
          return;
        } else if (!GlobalStates.SelectedCourierId) {
          this.props.pageOrder.setState({
            isSelectCourier: true,
          });
          return;
        }
      }
    }

    Functions.handleClick(type, this.props, this.props.pageOrder, this);
  }

  Order = (product: any, color?: string) => {
    let productId = product.product || product.productId;
    const handleClick = (type: string, action: string) => {
      this.setState({
        //ürün tamamen silinirse diğer işlemlerde sorun yaratmaması için selected productları temizliyor
        selectedProducts: [],
        selectedProductId: [],
      });
      switch (type) {
        case "normal":
          this.props.pageOrder.setState((state: any) => {
            let findIndex = state.newOrders.findIndex(
              (p: any) => p._id == product._id
            );
            if (findIndex < 0) return state;
            let unitPrice =
              state.newOrders[findIndex].price /
              state.newOrders[findIndex].quantity;
            if (action == "increase") {
              this.props.pageOrder.setState({
                totalAmount: this.props.pageOrder.state.totalAmount + unitPrice,
              });
              state.newOrders[findIndex].quantity++;
              state.newOrders[findIndex].price += unitPrice;
              return state;
            }

            if (state.newOrders[findIndex].quantity <= 1) {
              this.props.pageOrder.setState({
                totalAmount:
                  this.props.pageOrder.state.totalAmount -
                  unitPrice * state.newOrders[findIndex].quantity,
              });
              state.newOrders.splice(findIndex, 1);
              return state;
            }
            this.props.pageOrder.setState({
              totalAmount: this.props.pageOrder.state.totalAmount - unitPrice,
            });
            state.newOrders[findIndex].quantity--;
            state.newOrders[findIndex].price -= unitPrice;
            return state;
          });
          break;
        case "sendFirst":
          this.setState((state: any) => {
            let findIndex = state.sendFirst.findIndex(
              (p: any) => p._id == product._id
            );
            if (findIndex < 0) return;
            let unitPrice =
              state.sendFirst[findIndex].price /
              state.sendFirst[findIndex].quantity;
            if (action == "increase") {
              this.props.pageOrder.setState({
                totalAmount: this.props.pageOrder.state.totalAmount + unitPrice,
              });
              state.sendFirst[findIndex].quantity++;
              state.sendFirst[findIndex].price += unitPrice;
              return state;
            }
            if (state.sendFirst[findIndex].quantity <= 1) {
              this.props.pageOrder.setState({
                totalAmount:
                  this.props.pageOrder.state.totalAmount -
                  unitPrice * state.newOrders[findIndex].quantity,
              });
              state.sendFirst.splice(findIndex, 1);
              return state;
            }
            this.props.pageOrder.setState({
              totalAmount: this.props.pageOrder.state.totalAmount - unitPrice,
            });
            state.sendFirst[findIndex].quantity--;
            state.sendFirst[findIndex].price -= unitPrice;
            return state;
          });
          break;
        default:
          break;
      }
    };
    if (this.state.sendFirst.find((p: any) => p._id == product._id)) {
      let currency = this.props.getGlobalData?.Products?.find(
        (_: any) => _._id == productId
      )?.prices?.find((price: any) => price._id == product.priceId)?.currency;
      return (
        <tr style={{ backgroundColor: color ? color : "#fff" }}>
          <td>
            <input
              type="checkbox"
              checked={this.state.selectedProductId.includes(product._id)}
              style={{ borderRadius: "3px" }}
              onChange={(event) => this.onSelectedProduct(product._id, product)}
            />
          </td>
          <td>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <button
                style={{ marginRight: "1vw" }}
                onClick={() => handleClick("sendFirst", "decrease")}
              >
                <RemoveIcon />
              </button>
              <span>{product.quantity}</span>
              <button
                style={{ marginLeft: "1vw" }}
                onClick={() => handleClick("sendFirst", "increase")}
              >
                <AddIcon />
              </button>
            </div>
          </td>
          <td>
            <strong>{product.productName}</strong>
          </td>
          <td>
            {product.isServe ? (
              this.props.router.t("catering")
            ) : (
              <>
                {product.price?.toFixed(2)}{" "}
                {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
              </>
            )}
            {this.state.defaultCurrency != currency ? (
              <>
                {" "}
                ({this.calculateExchange(product.price, currency).toFixed(
                  2
                )}{" "}
                {Currencies[currency]}){" "}
              </>
            ) : null}
          </td>
          <td>
            <article onClick={() => this.optionOrderPopup(product)}>
              {product.options?.length > 0 || product.note ? `...` : ``}
            </article>
          </td>
        </tr>
      );
    }
    let currency = this.props.getGlobalData?.Products?.find(
      (_: any) => _._id == productId
    )?.prices?.find((price: any) => price._id == product.priceId)?.currency;
    return (
      <tr style={{ backgroundColor: color ? color : "#fff", height: "2.5rem" }}>
        <td>
          <input
            type="checkbox"
            checked={this.state.selectedProductId.includes(product._id)}
            style={{ borderRadius: "3px" }}
            onChange={(event) => this.onSelectedProduct(product._id, product)}
          />
        </td>
        <td>
          {product.status ? (
            <>
              <span>{product.quantity?.toFixed(2)}</span>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
              }}
            >
              <button
                style={{ marginRight: "1vw" }}
                onClick={() => handleClick("normal", "decrease")}
              >
                <RemoveIcon />
              </button>
              <span>{product.quantity?.toFixed(2)}</span>
              <button
                style={{ marginLeft: "1vw" }}
                onClick={() => handleClick("normal", "increase")}
              >
                <AddIcon />
              </button>
            </div>
          )}
        </td>
        <td>{product.productName}</td>
        <td>
          {product.isServe ? (
            this.props.router.t("catering")
          ) : (
            <>
              {product.price?.toFixed(2)}{" "}
              {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
            </>
          )}
          {this.state.defaultCurrency != currency ? (
            <>
              {" "}
              ({this.calculateExchange(product.price, currency).toFixed(2)}{" "}
              {Currencies[currency]}){" "}
            </>
          ) : null}
        </td>
        <td>
          <article onClick={() => this.optionOrderPopup(product)}>
            {product.options?.length > 0 || product.note ? `...` : ``}
          </article>
        </td>
      </tr>
    );
  };

  GetirManualActionsDialog = () => {
    const theme = useTheme();

    const handleCancel = async () => {
      this.setState({
        getirManualActionsDialog: false,
      });
      Swal.fire({
        title: this.props.router.t("pleaseWait").toCapitalizeCase(),
        html: this.props.router.t("loading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const response = await GetirService.manualCancelOrder(
        this.props.or[0]?.getirOrderId
      );
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: this.props.router.t("getir-order-cancel-success"),
        });
        this.props.router.navigate("/");
        return;
      }
      Swal.fire({
        icon: "error",
        title: this.props.router.t("getir-order-cancel-error"),
      });
    };

    const handleDeliver = async () => {
      this.setState({
        getirManualActionsDialog: false,
      });
      Swal.fire({
        title: this.props.router.t("pleaseWait").toCapitalizeCase(),
        html: this.props.router.t("loading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const response = await GetirService.manualDeliverOrder(
        this.props.or[0]?.getirOrderId
      );
      if (response.status) {
        Swal.fire({
          icon: "success",
          title: this.props.router.t("deliver-success"),
        });
        this.props.router.navigate("/");
        return;
      }
      Swal.fire({
        icon: "error",
        title: this.props.router.t("deliver-error"),
      });
    };

    return (
      <Dialog
        open={this.state.getirManualActionsDialog}
        onClose={() => this.setState({ getirManualActionsDialog: false })}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
          },
        }}
      >
        <DialogTitle>{this.props.router.t("getirManualActions")}</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            <Box>
              <Typography>
                {this.props.router.t("getir-manual-actions-description")}
              </Typography>
            </Box>
            <Stack direction="column" spacing={2}>
              <Button
                variant="contained"
                color="success"
                sx={{
                  width: "12vw",
                  height: "2vw",
                }}
                onClick={handleDeliver}
              >
                {this.props.router.t("complete-order")}
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancel}
                sx={{
                  width: "12vw",
                  height: "2vw",
                }}
              >
                {this.props.router.t("cancel-order")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ getirManualActionsDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  GetirCancelDialog = () => {
    const [cancelOptions, setCancelOptions] = useState([]);
    const [cancelReasonId, setCancelReasonId] = useState("");
    const [cancelNote, setCancelNote] = useState("");
    const theme = useTheme();

    useEffect(() => {
      if (!this.state.getirCancelDialog) return;
      const fetchData = async () => {
        const response = await GetirService.getCancelOptions(
          this.props.or[0]?.getirOrderId
        );
        if (response.status) {
          setCancelOptions(response.data);
        } else {
          this.showToastify(
            "warning",
            this.props.router.t("getir-cancel-options-error")
          );
        }
      };

      fetchData();
    }, [this.state.getirCancelDialog]);

    const handleSubmit = async () => {
      if (!cancelReasonId) {
        this.showToastify(
          "warning",
          this.props.router.t("cancel-reason-required")
        );
        return;
      }
      this.setState({
        getirCancelDialog: false,
      });
      Swal.fire({
        title: this.props.router.t("pleaseWait").toCapitalizeCase(),
        html: this.props.router.t("loading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      const response = await GetirService.cancelOrder(
        this.props.or[0]?.getirOrderId,
        {
          cancelReason: cancelReasonId,
          cancelNote: cancelNote,
        }
      );
      if (!response.status) {
        Swal.fire({
          icon: "error",
          title: this.props.router.t("getir-order-cancel-error"),
        });
        return;
      }
      Swal.fire({
        icon: "success",
        title: this.props.router.t("getir-order-cancel-success"),
      });
      this.props.router.navigate("/");
    };

    const handleCancel = () => {
      this.setState({
        getirCancelDialog: false,
      });
      setCancelOptions([]);
    };
    return (
      <Dialog
        open={this.state.getirCancelDialog}
        onClose={handleCancel}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
          },
        }}
      >
        <DialogTitle
          sx={{
            margin: "auto",
            color: theme.palette.primary.main,
            textAlign: "center",
          }}
        >
          {this.props.router.t("getir-order-cancel")}
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={3}>
            <Select
              variant="filled"
              value={cancelReasonId}
              onChange={(e) => setCancelReasonId(e.target.value)}
              fullWidth
              sx={{
                minWidth: 300,
                backgroundColor: theme.palette.background.default,
              }}
            >
              {cancelOptions.map(
                (option: { id: string; message: string }, index: number) => (
                  <MenuItem value={option.id} key={index}>
                    {option.message}
                  </MenuItem>
                )
              )}
            </Select>
            <TextField
              label={this.props.router.t("note")}
              value={cancelNote}
              onChange={(e) => setCancelNote(e.target.value)}
              onClick={() => {
                ipcRenderer.send("showKeyboard");
              }}
              fullWidth
              variant="filled"
              sx={{ backgroundColor: theme.palette.background.default }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              sx={{ minWidth: 100 }}
            >
              {this.props.router.t("confirm")}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancel}
              sx={{ minWidth: 100 }}
            >
              {this.props.router.t("cancel")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  CateringDialog = () => {
    const createCatering = () => {
      setLoading(true);
      this.setState({
        cateringDialog: false,
      });
      Swal.fire({
        title: this.props.router.t("pleaseWait").toCapitalizeCase(),
        html: this.props.router.t("uploading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });
      let ordersAmount = 0;
      this.props.pageOrder.state.newOrders.map((p: any) => {
        if (this.state.selectedProductId.includes(p._id)) {
          p.isServe = true;
          p.serveId = this.state.selectedServe;
          ordersAmount += p.price;
        }
      });

      this.props.pageOrder.setState({
        totalAmount: this.props.pageOrder.state.totalAmount - ordersAmount,
      });

      if (!GlobalStates.CustomerId) {
        this.state.selectedProductId.map((id: string) => {
          if (
            !this.props.pageOrder.state.newOrders.find((o: any) => o._id == id)
          ) {
            this.state.or.map((o: any) =>
              o.products.map((p: any) => {
                if (p._id == id) {
                  let orderNum = o.orderNum;
                  let params: markAsCatering = {
                    tableId: GlobalStates.CurrentTable._id,
                    orderNum: orderNum,
                    orderId: id,
                    serveId: this.state.selectedServe,
                  };
                  Services.Put.markAsCatering(params).then(
                    async (response: any) => {
                      if (!response.status) {
                        this.showToastify(
                          "error",
                          this.props.router.t("updated-catering-error")
                        );
                        setLoading(false);
                      } else {
                        let tables =
                          await Services.Get.allTablesSync().data.filter(
                            (table: any) => table.order_type !== 8
                          );
                        this.props.setGlobalData({
                          AllTables: tables,
                        });

                        this.setState({
                          or: response.data.orders,
                          remainingPrice: response.data.remainingPrice,
                          totalPrice: response.data.totalPrice,
                        });
                        Swal.fire({
                          icon: "success",
                          title: this.props.router.t(
                            "selected-products-marked-catering"
                          ),
                        });
                        setLoading(false);
                        this.setState({ cateringDialog: false });
                        this.setState({
                          selectedProducts: [],
                          selectedProductId: [],
                        });
                        let table_ =
                          this.props.getGlobalData.AllTables.findSingle(
                            "_id",
                            GlobalStates.CurrentTable._id
                          );

                        table_.orders.length > 0
                          ? null
                          : this.props.router.navigate("/");
                        return;
                      }
                    }
                  );
                }
              })
            );
          }
        });
      }
      Swal.fire({
        icon: "success",
        title: this.props.router.t("selected-products-marked-catering"),
      });
      this.setState({ cateringDialog: false });
      this.setState({
        selectedProducts: [],
        selectedProductId: [],
      });
    };
    const [loading, setLoading] = useState(false);
    return (
      <Dialog
        open={this.state.cateringDialog}
        onClose={() => this.setState({ cateringDialog: false })}
        style={{ width: "100%" }}
        fullWidth
      >
        <DialogTitle sx={{ margin: "auto", color: "#fff" }}>
          {this.props.router.t("catering")}
        </DialogTitle>
        <DialogContent>
          <InputLabel id="catering-label">
            {this.props.router.t("catering")}
          </InputLabel>
          <Select
            variant="filled"
            value={this.state.selectedServe}
            onChange={(e: any) =>
              this.setState({ selectedServe: e.target.value })
            }
            label={this.props.router.t("catering")}
            labelId="catering-label"
            fullWidth
          >
            {this.props.getGlobalData.Serves?.map(
              (serve: any, index: number) => (
                <MenuItem value={serve.id} key={index}>
                  {serve.title}
                </MenuItem>
              )
            )}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={() => createCatering()}
          >
            {this.props.router.t("submit")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => this.setState({ cateringDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  CoverDialog = () => {
    const [title, setTitle] = useState(this.props.router.t("cover"));
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(1);
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    function getTwoDecimalPlaces(number: number): number {
      return Math.round(number * 100) / 100;
    }
    const saveCover = () => {
      if (title.length == 0 || !price || !quantity || quantity == 0) {
        this.showToastify("warning", this.props.router.t("fill-fields"));
        return;
      }
      setLoading(true);
      const data = {
        tableId: GlobalStates.SelectedTableId,
        type: type,
        perc:
          type == 2
            ? getTwoDecimalPlaces(price)
            : getTwoDecimalPlaces((price / table?.totalPrice) * 100 * quantity),
        quantity: Number(quantity),
        price:
          type == 2
            ? getTwoDecimalPlaces(
                ((table?.totalPrice * price) / 100) * quantity
              )
            : getTwoDecimalPlaces(quantity * price),
        title: title,
      };
      Services.Post.cover(data).then((resData) => {
        if (resData.status) {
          let tables = this.props.getGlobalData.AllTables?.map((table) => {
            if (table._id == GlobalStates.SelectedTableId) {
              table = resData.data;
            }
            return table;
          });
          this.setState({
            covers: resData.data.cover,
            coverDialog: false,
            totalPrice: resData.data.totalPrice,
            remainingPrice: resData.data.remainingPrice,
          });
          this.props.setGlobalData({
            AllTables: tables,
          });
          setLoading(false);
          this.showToastify("success", this.props.router.t("cover-added"));
        } else {
          setLoading(false);
          this.showToastify("error", this.props.router.t("error"));
        }
      });
    };
    return (
      <Dialog
        open={this.state.coverDialog}
        onClose={() => this.setState({ coverDialog: false })}
      >
        <div className="discount">
          <div className="descriptionTitle">
            {" "}
            {this.props.router.t("description")}:{" "}
          </div>
          <div className="discountDescription">
            <input
              style={{ color: "black" }}
              onChange={(event: any) => setTitle(event.target.value)}
              value={title}
              onClick={() => {
                ipcRenderer.send("showKeyboard");
              }}
              type="text"
            />
          </div>

          <div className="discountAmount">
            <div className="descriptionTitle1">
              {this.props.router.t("cover-amount")}:{" "}
            </div>
            <div className="percent">
              <div
                onClick={() => {
                  setType(type === 1 ? 2 : 1);
                }}
              >
                {type == 1 ? (
                  <>%</>
                ) : (
                  Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>
                )}
              </div>
            </div>
          </div>

          <div className="discountAmount">
            <input
              type="number"
              className="amount"
              onChange={(event: any) => setPrice(event.target.value)}
              onClick={() => {
                ipcRenderer.send("showKeyboard");
              }}
              value={price}
            />
            <div className="percent">
              <div className="percent">
                {type == 2 ? (
                  <>%</>
                ) : (
                  Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>
                )}
              </div>
            </div>
          </div>

          <div className="discountAmount">
            <input
              type="number"
              className="amount"
              onChange={(event: any) => setQuantity(event.target.value)}
              onClick={() => {
                ipcRenderer.send("showKeyboard");
              }}
              value={quantity}
            />
            <div className="percent">
              <div className="percent">
                {this.props.router.t("item-number")}
              </div>
            </div>
          </div>

          <div className="descriptionTitle">
            {this.props.router.t("total-coverage-amount")}:{" "}
            {type == 2
              ? Number(
                  Number((table?.totalPrice * price) / 100) * quantity
                )?.toFixed(2)
              : quantity * price}{" "}
            {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
          </div>

          <div
            onClick={() => {
              if (!loading) saveCover();
            }}
            className="submitDiscount"
          >
            {this.props.router.t("submit")}
          </div>
        </div>
      </Dialog>
    );
  };

  CancelDialog = () => {
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );

    const checkQuantity = (product: any) => {
      let find = this.state.selectedProducts.find(
        (p: any) => p._id == product._id
      );
      if (!find) return;
      return find.maxQuantity || find.quantity;
    };

    const handleClick = (product: any, action: string) => {
      this.setState((state: any) => {
        let findIndex = state.selectedProducts.findIndex(
          (pr: any) => pr._id == product._id
        );
        if (findIndex < 0) return;
        if (!state.selectedProducts[findIndex].maxQuantity) {
          state.selectedProducts[findIndex].maxQuantity =
            state.selectedProducts[findIndex].quantity;
        }
        switch (action) {
          case "decrease":
            state.selectedProducts[findIndex].quantity--;
            break;
          case "fullDecrease":
            state.selectedProducts[findIndex].quantity = 1;
            break;
          case "increase":
            state.selectedProducts[findIndex].quantity++;
            break;
          case "fullIncrease":
            let quantity = checkQuantity(product);
            state.selectedProducts[findIndex].quantity = quantity;
            break;
          default:
            break;
        }
        return state;
      });
    };

    const cancelProducts = async () => {
      if (
        !reason &&
        this.state.selectedProducts?.filter(
          (product: any) => product.status != 0
        )?.length > 0
      ) {
        toast.error(this.props.router.t("cancel-reason-required"));
        return;
      }
      let totalCancelAmount = 0;

      this.state.selectedProducts?.map((p: any) => {
        let quantity = checkQuantity(p);
        let unitPrice = p.price / quantity;
        totalCancelAmount += unitPrice * p.quantity;
      });
      if (totalCancelAmount > table.remainingPrice) {
        toast.warning(
          this.props.router.t("cancel-amount-more-than-remaining-amount")
        );
        return;
      }
      setLoading(true);
      this.setState({
        cancelDialog: false,
      });
      Swal.fire({
        title: this.props.router.t("pleaseWait").toCapitalizeCase(),
        html: this.props.router.t("uploading") + "...",
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        willOpen(popup: HTMLElement) {
          Swal.showLoading();
        },
      });

      await Services.Put.cancelProduct({
        cancelReason: reason,
        products: this.state.selectedProducts,
        tableId: GlobalStates.SelectedTableId,
      }).then(async (res) => {
        if (res.status) {
          await Services.Get.allTables().then((tables) => {
            this.props.setGlobalData({
              AllTables: tables.data,
            });
          });
          let products: any[] = [];
          let undoProducts: any[] = [];
          this.state.selectedProducts.map((p: any) => {
            this.props.getGlobalData.Products.map((product: any) => {
              if (product._id == p.product) {
                p.category = product.category;
                if (p.status == 0) {
                  undoProducts.push(p);
                  return;
                }
                products.push(p);
              }
            });
          });
          let printData = {
            products: products,
            section: this.props.getGlobalData.Sections.findSingle(
              "_id",
              table.section
            )?.title,
            user: getGlobalAuthData().user,
          };
          let undoPrintData = {
            products: undoProducts,
            section: this.props.getGlobalData.Sections.findSingle(
              "_id",
              table.section
            )?.title,
            user: getGlobalAuthData().user,
          };
          if (printData.products.length > 0) {
            Printer.KitchenCancelledByGroup.printKitchenCancelledByGroup(
              this.props.router.t,
              printData
            );
          }
          if (undoPrintData.products.length > 0) {
            Printer.KitchenByGroup.printKitchenByGroup(
              this.props.router.t,
              undoPrintData
            );
          }
          Swal.fire({
            icon: "success",
            title: this.props.router.t("cancel-success"),
          });
          this.setState({
            selectedProductId: [],
            selectedProducts: [],
          });
          setLoading(false);
          let table_ = this.props.getGlobalData.AllTables.findSingle(
            "_id",
            GlobalStates.SelectedTableId
          );

          table_.orders.length > 0 ? null : this.props.router.navigate("/");
        } else {
          Swal.fire({
            icon: "error",
            title: this.props.router.t("error"),
          });
          setLoading(false);
        }
      });
    };
    return (
      <Dialog
        open={this.state.cancelDialog}
        onClose={() => this.setState({ cancelDialog: false })}
        sx={{
          backdropFilter: "blur(5px)",
          backgroundColor: "rgb(240,230,0,0.4)",
        }}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Box>
                {this.state.selectedProducts?.filter(
                  (product: any) => product.status != 0
                )?.length != 0 ? (
                  <>
                    <Typography>
                      {this.props.router.t("products-to-be-cancelled")}
                    </Typography>
                    <Stack direction="column" spacing={2}>
                      {this.state.selectedProducts
                        ?.filter((product: any) => product.status != 0)
                        ?.map((product: any) => (
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <Typography>{product.productName}</Typography>
                            <IconButton
                              disabled={product.quantity == 1 ? true : false}
                              onClick={() =>
                                handleClick(product, "fullDecrease")
                              }
                            >
                              <KeyboardDoubleArrowLeftIcon />
                            </IconButton>
                            <IconButton
                              disabled={product.quantity == 1 ? true : false}
                              onClick={() => handleClick(product, "decrease")}
                            >
                              <KeyboardArrowLeftIcon />
                            </IconButton>
                            <Typography>{product.quantity}</Typography>
                            <IconButton
                              disabled={
                                checkQuantity(product) - product.quantity == 0
                                  ? true
                                  : false
                              }
                              onClick={() => handleClick(product, "increase")}
                            >
                              <KeyboardArrowRightIcon />
                            </IconButton>
                            <IconButton
                              disabled={
                                checkQuantity(product) - product.quantity == 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                handleClick(product, "fullIncrease")
                              }
                            >
                              <KeyboardDoubleArrowRightIcon />
                            </IconButton>
                          </Stack>
                        ))}
                    </Stack>
                  </>
                ) : null}
              </Box>
              <Box>
                {this.state.selectedProducts?.filter(
                  (product: any) => product.status == 0
                )?.length != 0 ? (
                  <>
                    <Typography>
                      {this.props.router.t("products-to-be-return")}
                    </Typography>
                    <Stack direction="column" spacing={2}>
                      {this.state.selectedProducts
                        ?.filter((product: any) => product.status == 0)
                        ?.map((product: any) => (
                          <Stack
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <Typography>{product.productName}</Typography>
                            <IconButton
                              disabled={product.quantity == 1 ? true : false}
                              onClick={() =>
                                handleClick(product, "fullDecrease")
                              }
                            >
                              <KeyboardDoubleArrowLeftIcon />
                            </IconButton>
                            <IconButton
                              disabled={product.quantity == 1 ? true : false}
                              onClick={() => handleClick(product, "decrease")}
                            >
                              <KeyboardArrowLeftIcon />
                            </IconButton>
                            <Typography>{product.quantity}</Typography>
                            <IconButton
                              disabled={
                                checkQuantity(product) - product.quantity == 0
                                  ? true
                                  : false
                              }
                              onClick={() => handleClick(product, "increase")}
                            >
                              <KeyboardArrowRightIcon />
                            </IconButton>
                            <IconButton
                              disabled={
                                checkQuantity(product) - product.quantity == 0
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                handleClick(product, "fullIncrease")
                              }
                            >
                              <KeyboardDoubleArrowRightIcon />
                            </IconButton>
                          </Stack>
                        ))}
                    </Stack>
                  </>
                ) : null}
              </Box>
            </Box>
            <Box>
              {this.state.selectedProducts.filter(
                (product: any) => product.status != 0
              )?.length != 0 ? (
                <Stack spacing={4}>
                  <Box>
                    <InputLabel id="reason">
                      {this.props.router.t("cancel-reason")}
                    </InputLabel>
                    <Select
                      labelId="reason"
                      value={reason}
                      sx={{ minWidth: "8vw" }}
                      onChange={(e: any) => setReason(e.target.value)}
                    >
                      {this.props.getGlobalData.CancelReasons.map(
                        (reason: any) => (
                          <MenuItem value={reason._id}>{reason.title}</MenuItem>
                        )
                      )}
                    </Select>
                  </Box>
                </Stack>
              ) : null}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            disabled={loading}
            onClick={() => cancelProducts()}
          >
            {this.props.router.t("confirm")}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ cancelDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  ExchangePrintDialog = () => {
    const print = (currency: string) => {
      let table =
        this.props.getGlobalData.AllTables.findSingle(
          "_id",
          GlobalStates.SelectedTableId
        ) || GlobalStates.CurrentTable;
      let exchange = 1;
      let allTotal: any = null;
      switch (currency) {
        case "TL":
          exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.TL;
          break;
        case "USD":
          exchange =
            this.props.getGlobalData?.CurrencySettings?.currencies?.USD;
          break;
        case "EUR":
          exchange =
            this.props.getGlobalData?.CurrencySettings?.currencies?.EUR;
          break;
        case "GBP":
          exchange =
            this.props.getGlobalData?.CurrencySettings?.currencies?.GBP;
          break;
        case "ALL":
          if (this.props.getGlobalData?.CurrencySettings) {
            let total = GlobalStates.CustomerId
              ? this.props.pageOrder.state.totalAmount
              : table.order_type == 3
              ? this.state.or.totalPrice
              : table.totalPrice;
            exchange = 1;
            currency = this.state.defaultCurrency;
            allTotal = [
              {
                currency: "TL",
                amount:
                  total /
                  this.props.getGlobalData?.CurrencySettings?.currencies?.TL,
              },
              {
                currency: "USD",
                amount:
                  total /
                  this.props.getGlobalData?.CurrencySettings?.currencies?.USD,
              },
              {
                currency: "EUR",
                amount:
                  total /
                  this.props.getGlobalData?.CurrencySettings?.currencies?.EUR,
              },
              {
                currency: "GBP",
                amount:
                  total /
                  this.props.getGlobalData?.CurrencySettings?.currencies?.GBP,
              },
            ];
          }
          break;
        default:
          break;
      }
      if (GlobalStates.CustomerId) {
        if (this.props.pageOrder.state.newOrders?.length == 0) {
          Swal.fire({
            icon: "warning",
            title: this.props.router.t("please-add-product"),
          });
          return;
        }
        this.setState({ exchangePrintDialogOpen: false });
        Swal.fire({
          title: this.props.router.t("pleaseWait").toCapitalizeCase(),
          html: this.props.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });
        let printData = {
          checkNo: this.props.router.t("take-away"),
          discounts: this.props.pageOrder.state.newDiscounts,
          covers: [],
          orders: [
            {
              orderNum: 1,
              products: this.props.pageOrder.state.newOrders.map(
                (product: any) => ({
                  productId: product.product,
                  id: product._id,
                  productName: product.productName,
                  quantity: product.quantity,
                  priceId: product.priceId,
                  price: product.price,
                  isFirst: product.isFirst,
                  status: product.status,
                  isServe: product.isServe,
                })
              ),
            },
          ],
          total: {
            amount: this.props.pageOrder.state.totalAmount,
            currency: currency,
          },
          tableInfo: table,
          allTotal: allTotal,
          table: this.props.router.t("take-away"),
          section: this.props.router.t("take-away"),
          homeDelivery: true,
          currency: currency,
          currencyValue: exchange,
        };
        try {
          Printer.ProductsInOrder.printProductsInOrder(
            printData,
            this.props.getGlobalData?.Products,
            this.props.router.t
          );
        } catch (e: any) {
          Swal.fire({
            title: e.message,
            icon: "error",
          });
        }
        Swal.fire({
          title: this.props.router.t("print-product-success"),
          icon: "success",
        });
        return;
      }
      if (table.orders.length > 0) {
        if (table.order_type == 3) {
          //paket servis masasıysa müşteri adı ve adresi bilgileride yazdırılacak
          Swal.fire({
            icon: "question",
            title: this.props.router.t("not-print-question"),
            showCancelButton: true,
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.setState({ exchangePrintDialogOpen: false });
              let customer = this.state.or?.customer;
              let printData = {
                discount: table.discount,
                checkNo: this.props.router.t("take-away"),
                cover: table.cover,
                tableInfo: table,
                orders: [
                  {
                    orderNum: this.state.or.orderNum,
                    products: this.state.or.products
                      .concat(this.state.or.onWay)
                      .concat(this.state.or.unapproved),
                  },
                ],
                total: {
                  amount: this.state.or.totalPrice,
                  currency: currency,
                },
                allTotal: allTotal,
                homeDelivery: true,
                address: this.state.or.address || customer?.address[0].address,
                phone: customer?.gsm_no,
                name:
                  customer?.full_name ||
                  customer?.name + " " + customer?.lastname,
                currency: currency,
                currencyValue: exchange,
              };

              let sectionTitle = this.props.getGlobalData.Sections.findSingle(
                "_id",
                GlobalStates.CurrentTable.section
              )?.title;
              printData = Object.assign(printData, {
                table: `${sectionTitle} - ${GlobalStates.CurrentTable.title}`,
              });
              Printer.ProductsInOrder.printProductsInOrder(
                printData,
                this.props.getGlobalData?.Products,
                this.props.router.t
              );
              Swal.fire({
                title: this.props.router.t("print-product-success"),
                icon: "success",
              });
            }
          });
          return;
        }
        this.setState({ exchangePrintDialogOpen: false });
        Swal.fire({
          icon: "question",
          title: this.props.router.t("not-print-question"),
          showCancelButton: true,
        }).then((result: any) => {
          if (result.isConfirmed) {
            Services.Put.table({
              tableId: GlobalStates.SelectedTableId,
              print: true,
            }).then((resData) => {
              let printData = {
                discount: table.discount,
                checkNo: table.checkNo,
                cover: table.cover,
                tableInfo: table,
                orders: table.orders.map((order: any) => ({
                  orderNum: order.orderNum,
                  products: order.products.map((product: any) => ({
                    productId: product.product,
                    id: product._id,
                    productName: product.productName,
                    quantity: product.quantity,
                    priceId: product.priceId,
                    price: product.price,
                    isFirst: product.isFirst,
                    status: product.status,
                    isServe: product.isServe,
                  })),
                })),
                total: {
                  amount: table.totalPrice,
                  currency: currency,
                },
                allTotal: allTotal,
                currency: currency,
                currencyValue: exchange,
              };

              let sectionTitle = this.props.getGlobalData.Sections.findSingle(
                "_id",
                GlobalStates.CurrentTable.section
              )?.title;
              printData = Object.assign(printData, {
                table: `${sectionTitle} - ${GlobalStates.CurrentTable.title}`,
              });

              Printer.ProductsInOrder.printProductsInOrder(
                printData,
                this.props.getGlobalData?.Products,
                this.props.router.t
              );
              Swal.fire({
                title: this.props.router.t("print-product-success"),
                icon: "success",
              });
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: this.props.router.t("edit-customer-count-warning"),
        });
      }
    };
    return (
      <Dialog
        open={this.state.exchangePrintDialogOpen}
        onClose={() => this.setState({ exchangePrintDialogOpen: false })}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(239, 37, 37, 0.68)",
        }}
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              backgroundColor: "red",
              padding: 2,
              width: "60%",
              borderRadius: 3,
              margin: "auto",
            }}
          >
            <Typography variant="h6" textAlign="center" sx={{ color: "#fff" }}>
              {this.props.router.t("exchangePrint")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 6, md: 7 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {this.state.defaultCurrency == "TL" ? null : (
              <Grid item key={Math.floor(Math.random() * 1000)}>
                <Button
                  onClick={() => print("TL")}
                  sx={{
                    borderRadius: 2,
                    width: "8vw",
                    height: "3vw",
                    backgroundColor: "#FF8D03",
                  }}
                  variant="contained"
                >
                  TL
                </Button>
              </Grid>
            )}
            {this.state.defaultCurrency == "USD" ? null : (
              <Grid item key={Math.floor(Math.random() * 1000)}>
                <Button
                  onClick={() => print("USD")}
                  sx={{
                    borderRadius: 2,
                    width: "8vw",
                    height: "3vw",
                    backgroundColor: "#FF8D03",
                  }}
                  variant="contained"
                >
                  USD
                </Button>
              </Grid>
            )}
            {this.state.defaultCurrency == "EUR" ? null : (
              <Grid item key={Math.floor(Math.random() * 1000)}>
                <Button
                  onClick={() => print("EUR")}
                  sx={{
                    borderRadius: 2,
                    width: "8vw",
                    height: "3vw",
                    backgroundColor: "#FF8D03",
                  }}
                  variant="contained"
                >
                  EUR
                </Button>
              </Grid>
            )}
            {this.state.defaultCurrency == "GBP" ? null : (
              <Grid item key={Math.floor(Math.random() * 1000)}>
                <Button
                  onClick={() => print("GBP")}
                  sx={{
                    borderRadius: 2,
                    width: "8vw",
                    height: "3vw",
                    backgroundColor: "#FF8D03",
                  }}
                  variant="contained"
                >
                  GBP
                </Button>
              </Grid>
            )}
            <Grid item key={Math.floor(Math.random() * 1000)}>
              <Button
                onClick={() => print("ALL")}
                sx={{
                  borderRadius: 2,
                  width: "8vw",
                  height: "3vw",
                  backgroundColor: "#FF8D03",
                }}
                variant="contained"
              >
                {this.props.router.t("all")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ exchangePrintDialogOpen: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  ServiceDialog = () => {
    const [serviceFee, setServiceFee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(1);

    const addServiceFee = async (amount?: number) => {
      if (!amount && serviceFee <= 0) {
        toast.warning(this.props.router.t("percentile-must-be-greater"));
        return;
      }
      this.setState({ serviceDialog: false });
      setLoading(true);
      const info = toast.loading(this.props.router.t("pleaseWait"));
      let per = amount || serviceFee;
      let res = await Services.Post.addServiceFee({
        amount: per,
        tableId: GlobalStates.SelectedTableId,
        type: type,
      });
      if (res.status) {
        toast.update(info, {
          render: this.props.router.t("add-service-fee-success"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        this.setState({
          totalPrice: res.data.totalPrice,
          remainingPrice: res.data.remainingPrice,
        });
        let tables = await Services.Get.allTablesSync().data.filter(
          (table: any) => table.order_type !== 8
        );
        this.props.setGlobalData({ AllTables: tables });
        setLoading(false);
        return;
      }
      toast.update(info, {
        render: this.props.router.t("error"),
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      setLoading(false);
    };

    return (
      <Dialog
        open={this.state.serviceDialog}
        onClose={() => this.setState({ serviceDialog: false })}
        fullWidth
      >
        <DialogTitle>{this.props.router.t("set-service-fee")}</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={5}>
            {type == 2 ? null : (
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                justifyContent="center"
              >
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(2.5)}
                    variant="contained"
                    color="success"
                  >
                    %2.5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(5)}
                    variant="contained"
                    color="success"
                  >
                    %5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(7.5)}
                    variant="contained"
                    color="success"
                  >
                    %7.5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(10)}
                    variant="contained"
                    color="success"
                  >
                    %10
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(20)}
                    variant="contained"
                    color="success"
                  >
                    %20
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container justifyContent="center" sx={{ marginTop: "0.8vw" }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  onClick={() => ipcRenderer.send("showKeyboard")}
                  value={serviceFee}
                  onChange={(e: any) => setServiceFee(e.target.value)}
                  label={`${this.props.router.t("special-price")} ${
                    type == 1 ? "(%)" : ""
                  }`}
                  type="number"
                  sx={{ minWidth: "10vw" }}
                />
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color={type == 1 ? "success" : "info"}
                    sx={{ width: "10vw", height: "3vh", fontSize: "17px" }}
                    onClick={() => setType(1)}
                  >
                    {this.props.router.t("percentile")}
                  </Button>
                  <Button
                    variant="contained"
                    color={type == 2 ? "success" : "info"}
                    sx={{ width: "10vw", height: "3vh", fontSize: "17px" }}
                    onClick={() => setType(2)}
                  >
                    {this.props.router.t("_amount")}
                  </Button>
                </ButtonGroup>
              </Stack>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={loading}
            onClick={() => addServiceFee()}
            color="success"
          >
            {this.props.router.t("save")}
          </Button>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => this.setState({ seeServiceFeeDialog: true })}
          >
            {this.props.router.t("see-service-fees")}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ serviceDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  ShowServiceFeeDialog = () => {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    const [loading, setLoading] = useState(false);
    const deleteServiceFee = async (id: string) => {
      let info = toast.loading(this.props.router.t("pleaseWait"));
      setLoading(true);
      let res = await Services.Delete.deleteServiceFee({
        tableId: table._id,
        serviceId: id,
      });
      if (res.status) {
        toast.update(info, {
          render: this.props.router.t("service-fee-deleted-success"),
          isLoading: false,
          type: "success",
          autoClose: 3000,
        });
        let tables = await Services.Get.allTablesSync().data;
        this.props.setGlobalData({ AllTables: tables });
        this.setState({
          totalPrice: res.data.totalPrice,
          remainingPrice: res.data.remainingPrice,
        });
        setLoading(false);
        return;
      }
      toast.update(info, {
        render: this.props.router.t("error"),
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      setLoading(false);
    };

    return (
      <Dialog
        open={this.state.seeServiceFeeDialog}
        onClose={() => this.setState({ seeServiceFeeDialog: false })}
        fullWidth
      >
        <DialogTitle>{this.props.router.t("see-service-fees")}</DialogTitle>
        <DialogContent>
          {table.serviceFee?.length == 0 ? (
            <>
              <Typography>
                {this.props.router.t("no-service-fee-added")}
              </Typography>
            </>
          ) : (
            <TableContainer>
              <MTable>
                <TableHead>
                  <TableRow>
                    <TableCell>{this.props.router.t("percentile")}</TableCell>
                    <TableCell>{this.props.router.t("amount")}</TableCell>
                    <TableCell>
                      {this.props.router.t("creation-time")}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {table.serviceFee?.map((service: any) => (
                    <TableRow>
                      <TableCell>%{service.percentile?.toFixed(1)}</TableCell>
                      <TableCell>
                        {service.amount?.toFixed(2)}{" "}
                        {Currencies[
                          localStorage.getItem("defaultCurrency")!
                        ] || <>₺</>}
                      </TableCell>
                      <TableCell>
                        {new Date(service.createdAt)?.toLocaleTimeString()}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          disabled={loading}
                          onClick={() => deleteServiceFee(service._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MTable>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ seeServiceFeeDialog: false })}
          ></Button>
        </DialogActions>
      </Dialog>
    );
  };
  DiscountDialog = () => {
    const [note, setNote] = useState("");
    const [discountAmount, setDiscountAmount] = useState(0);
    const [type, setType] = useState("amount");
    const [loading, setLoading] = useState(false);

    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;

    const addDiscount = () => {
      let noteS = note;
      if (this.state.activeTab === "saved-notes" && note) {
        noteS = this.props.getGlobalData?.Notes?.find(
          (_) => _._id === note
        ).title;
      }
      if (noteS.length == 0 || discountAmount == 0 || !type) {
        this.showToastify("warning", this.props.router.t("fill-fields"));
        return;
      }
      setLoading(true);
      const data = {
        tableId: GlobalStates.SelectedTableId,
        type: type === "percent" ? 0 : 1,
        amount: discountAmount,
        note: noteS,
      };
      if (table.isSafeSales) {
        if (type === "percent") {
          data.type = 1;
          data.amount =
            this.props.pageOrder.state.totalAmount * (discountAmount / 100);
        }
      }
      Services.Post.discount(data).then((resData) => {
        if (resData.status) {
          this.setState({
            discounts: resData.data.discount,
            totalPrice: resData.data.totalPrice,
            remainingPrice: GlobalStates.CurrentTable.isSafeSales
              ? 0
              : resData.data.remainingPrice,
          });
          let tables = this.props.getGlobalData.AllTables?.map((table) => {
            if (table._id == GlobalStates.SelectedTableId) {
              table = resData.data;
            }
            return table;
          });
          this.props.setGlobalData({
            AllTables: tables,
          });
          setLoading(false);
          this.setState({ discountDialog: false });
          this.showToastify("success", this.props.router.t("discount-success"));
        }
      });
    };
    const handleTabClick = (tabName: any) => {
      setNote("");
      this.setState({ activeTab: tabName });
    };
    return (
      <Dialog
        open={this.state.discountDialog}
        onClose={() => this.setState({ discountDialog: false })}
      >
        <div className="discount">
          <div className="description">
            <div
              className="descriptionTitle"
              style={{
                color:
                  this.state.activeTab === "description"
                    ? "black"
                    : "darkmagenta",
              }}
              onClick={() => handleTabClick("description")}
            >
              {this.props.router.t("description")}
            </div>
            <div
              className="descriptionTitle"
              style={{
                color:
                  this.state.activeTab === "saved-notes"
                    ? "black"
                    : "darkmagenta",
              }}
              onClick={() => handleTabClick("saved-notes")}
            >
              {this.props.router.t("saved-notes")}
            </div>
          </div>
          {this.state.activeTab === "description" ? (
            <div className="discountDescription">
              <input
                style={{ color: "black" }}
                onChange={(event: any) => setNote(event.target.value)}
                onClick={() => {
                  ipcRenderer.send("showKeyboard");
                }}
                type="text"
              />
            </div>
          ) : (
            <div>
              <Select
                sx={{ minWidth: "100%" }}
                labelId="noteLabel"
                value={note}
                onChange={(e: any) => {
                  setNote(e.target.value);
                }}
              >
                {this.props.getGlobalData?.Notes?.map((note: any) => (
                  <MenuItem value={note._id}>{note.title}</MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div className="descriptionTitle">
            {" "}
            {this.props.router.t("discount-rate")}:{" "}
          </div>
          <div className="discountAmount">
            <input
              onChange={(e: any) => setDiscountAmount(e.target.value)}
              onClick={() => {
                ipcRenderer.send("showKeyboard");
              }}
              type="number"
              className="amount"
              defaultValue={discountAmount}
            />
            <div className="percent">
              <div
                style={{
                  backgroundColor: type === "percent" ? "red" : "rgb(70, 0, 0)",
                }}
                onClick={() => setType("percent")}
                className="percent"
              >
                %
              </div>
              <div
                style={{
                  fontSize: "15px",
                  backgroundColor: type === "amount" ? "red" : "rgb(70, 0, 0)",
                  wordWrap: "break-word",
                  whiteSpace: "nowrap",
                  wordBreak: "break-word",
                  width: "170px",
                }}
                onClick={() => setType("amount")}
                className="amount"
              >
                {this.props.router.t("quantity")}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              if (!loading) addDiscount();
            }}
            className="submitDiscount"
          >
            {this.props.router.t("submit")}
          </div>
        </div>
      </Dialog>
    );
  };

  async createCourier() {
    if (
      !this.props.pageOrder.state.courier.title ||
      !this.props.pageOrder.state.courier.phone_number
    ) {
      this.props.pageOrder.setState({
        snackbar: true,
        snackbar_title: "fill-required-fields",
        snackbar_severity: "error",
      });
      return;
    }
    Swal.fire({
      title: this.props.router.t("pleaseWait").toCapitalizeCase(),
      html: this.props.router.t("uploading") + "...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      willOpen(popup: HTMLElement) {
        Swal.showLoading();
      },
    });
    let params = {
      title: this.props.pageOrder.state.courier.title,
      number: this.props.pageOrder.state.courier.phone_number,
    };
    const res = await Services.Post.couriers(params);
    if (res.status) {
      this.props.setGlobalData({
        AllCourier: this.props.getGlobalData.AllCourier.concat([res.data]),
      });
      this.props.pageOrder.setState({
        snackbar: true,
        snackbar_title: "create-courier-success",
        snackbar_severity: "success",
        courier: {
          title: "",
          phone_number: "",
        },
      });
      this.setState({ createCourierDialog: false });
      return;
    }
    this.props.pageOrder.setState({
      snackbar: true,
      snackbar_title: "error",
      snackbar_severity: "error",
    });
  }

  SelectCourier = () => {
    const selectOrder = (courier: string) => {
      GlobalStates.SelectedCourierId = courier;
      this.setState({
        courierDialog: false,
      });
      Functions.onWayHomeDelivery(this.props, this.props.pageOrder, this);
    };

    return (
      <Dialog
        open={this.state.courierDialog}
        style={{ width: "100%" }}
        onClose={() => this.setState({ courierDialog: false })}
        fullWidth={true}
        PaperProps={{
          style: {
            backgroundColor: "#616161",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle sx={{ margin: "auto", color: "#fff" }}>
          {this.props.router.t("please-select-courier")}
        </DialogTitle>
        <List sx={{ pt: 0 }}>
          {this.props.getGlobalData.AllCourier.filter(
            (courier: any) => !courier.isBusy && courier.isActive
          ).map((courier: any) => (
            <ListItem
              button
              onClick={() => selectOrder(courier)}
              key={courier}
              sx={{
                margin: "auto",
                cursor: "pointer",
                "&:hover": { backgroundColor: "green" },
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ color: "#fff" }} primary={courier.title} />
              <ListItemText
                sx={{
                  color: courier.isBusy ? "#FA3939" : "#09DE02",
                  textAlign: "right",
                }}
                primary={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {this.props.router.t("available")}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          {this.props.getGlobalData.AllCourier.filter(
            (courier: any) => courier.isBusy && courier.isActive
          ).map((courier: any) => (
            <ListItem
              button
              key={courier}
              sx={{
                margin: "auto",
                cursor: "not-allowed",
                "&:hover": { backgroundColor: "#F20000" },
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ color: "#fff" }} primary={courier.title} />
              <ListItemText
                sx={{
                  color: courier.isBusy ? "#FA3939" : "#09DE02",
                  textAlign: "right",
                }}
                primary={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {
                      <Typography sx={{ fontWeight: "bold" }}>
                        {this.props.router.t("busy")}
                      </Typography>
                    }
                  </Typography>
                }
              />
            </ListItem>
          ))}
          {this.props.getGlobalData.AllCourier.filter(
            (courier: any) => !courier.isActive
          ).map((courier: any) => (
            <ListItem
              button
              key={courier}
              sx={{
                margin: "auto",
                cursor: "not-allowed",
                "&:hover": { backgroundColor: "black" },
              }}
              disabled
            >
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText sx={{ color: "#fff" }} primary={courier.title} />
              <ListItemText
                sx={{ color: "#201C1C", textAlign: "right" }}
                primary={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {this.props.router.t("offline")}
                  </Typography>
                }
              />
            </ListItem>
          ))}
          <ListItem
            autoFocus
            button
            onClick={() => this.setState({ createCourierDialog: true })}
          >
            <ListItemAvatar>
              <Avatar>
                <AddIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ color: "#220615" }}
              primary={
                <Typography sx={{ fontWeight: "bold" }}>
                  {this.props.router.t("add-courier")}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Dialog>
    );
  };

  CreateCourier = () => {
    return (
      <Dialog
        open={this.state.createCourierDialog}
        style={{ width: "100%" }}
        fullWidth={true}
        onClose={() => this.setState({ createCourierDialog: false })}
        PaperProps={{
          style: {
            backgroundColor: "#616161",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle sx={{ margin: "auto" }}>
          <Typography sx={{ fontWeight: "bold" }}>
            {this.props.router.t("createcourier")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="space-around"
          >
            <TextField
              autoFocus
              id="title"
              label={this.props.router.t("name")}
              InputProps={{
                inputProps: {
                  style: { color: "#fff" },
                },
              }}
              onClick={() => ipcRenderer.send("showKeyboard")}
              variant="outlined"
              sx={{
                color: "#fff",
                margin: "6px auto 3px auto",
                marginTop: "4px",
                display: "block",
                width: { sm: 300, md: 300 },
                label: { color: "#fff" },
              }}
              fullWidth
              onChange={(e: any) =>
                this.props.pageOrder.setState({
                  courier: {
                    title: e.target.value,
                    phone_number:
                      this.props.pageOrder.state.courier.phone_number,
                  },
                })
              }
              required
            />
            <TextField
              autoFocus
              id="title"
              label={this.props.router.t("phone-number")}
              InputProps={{
                inputProps: {
                  style: { color: "#fff" },
                },
              }}
              onClick={() => ipcRenderer.send("showKeyboard")}
              variant="outlined"
              sx={{
                color: "#fff",
                margin: "6px auto 3px auto",
                marginTop: "4px",
                display: "block",
                width: { sm: 300, md: 300 },
                label: { color: "#fff" },
              }}
              fullWidth
              onChange={(e: any) =>
                this.props.pageOrder.setState({
                  courier: {
                    title: this.props.pageOrder.state.courier.title,
                    phone_number: e.target.value,
                  },
                })
              }
              required
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => this.createCourier()}
          >
            {this.props.router.t("add-courier")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => this.setState({ createCourierDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  checkGetirProductPair = (productId: string) => {
    let product = this.props.getGlobalData?.Products?.find(
      (product: any) => product.getirId === productId
    );
    return product !== undefined;
  };

  EditCustomerCountDialog = () => {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    const [customerCount, setCustomerCount]: any = useState(
      table.customerCount || null
    );
    const handleSave = async (type: string) => {
      switch (type) {
        case "reset":
          let resetResponse = await Services.Put.updateCustomerCount({
            tableId: table._id,
            type: "reset",
          });
          if (resetResponse.status) {
            toast.success(this.props.router.t("reset-customer-count-success"));
            table.customerCount = null;
            this.setState({
              editCustomerCountDialog: false,
            });
            setCustomerCount(null);
            return;
          }
          toast.error(this.props.router.t("error"));
          break;
        case "save":
          if (!customerCount || customerCount == 0) {
            toast.warning(this.props.router.t("fill-fields"));
            return;
          }
          let response = await Services.Put.updateCustomerCount({
            tableId: table._id,
            type: "save",
            customerCount: customerCount,
          });
          if (response.status) {
            toast.success(this.props.router.t("update-customer-count-success"));
            table.customerCount = customerCount;
            this.setState({
              editCustomerCountDialog: false,
            });
            setCustomerCount(null);
            return;
          }
          toast.error(this.props.router.t("error"));
          break;
        default:
          break;
      }
    };
    return (
      <Dialog
        open={this.state.editCustomerCountDialog}
        onClose={() =>
          this.setState({
            editCustomerCountDialog: false,
          })
        }
      >
        <DialogTitle>{this.props.router.t("editCustomerCount")}</DialogTitle>
        <DialogContent>
          <TextField
            label={this.props.router.t("customer-count")}
            value={customerCount}
            onChange={(e: any) => setCustomerCount(Number(e.target.value))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSave("save")}
          >
            {this.props.router.t("save")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => this.setState({ editCustomerCountDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleSave("reset")}
          >
            {this.props.router.t("remove-customer-count")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  calculateExchange = (price: number, currency: string) => {
    let exchange = 1;
    switch (currency) {
      case "TL":
        exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.TL;
        break;
      case "USD":
        exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.USD;
        break;
      case "EUR":
        exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.EUR;
        break;
      case "GBP":
        exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.GBP;
        break;
      default:
        break;
    }
    return price / exchange;
  };

  Orders = (order: any) => {
    if (order.user) {
      return (
        <>
          <div
            className="checkboxOrder"
            style={{
              backgroundColor:
                order.products.filter((p: any) => p.status == 2).length > 0
                  ? "#fff"
                  : order.products.filter((p: any) => p.status == 1).length > 0
                  ? "#ffcc00"
                  : "#cc3300",
            }}
          >
            <input
              type="checkbox"
              style={{ borderRadius: "3px", margin: "5px" }}
              checked={this.state.selectedOrderNums.includes(order.orderNum)}
              onChange={(event) =>
                this.onSelectedOrder(order.orderNum, order.products)
              }
            />
            <div className="caption">
              {this.props.router.t("order-no")}: {order.orderNum}
            </div>
          </div>

          {order.products
            ?.filter((p: any) => p.status == 2)
            .map((product: any) => {
              let currency = this.props.getGlobalData?.Products?.find(
                (_: any) => _._id == product.product
              )?.prices?.find(
                (price: any) => price._id == product.priceId
              )?.currency;
              return (
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedProductId.includes(
                        product._id
                      )}
                      style={{ borderRadius: "3px" }}
                      onChange={(event) =>
                        this.onSelectedProduct(product._id, product)
                      }
                    />
                  </td>
                  <td>{product.quantity}</td>
                  <td>{product.productName}</td>
                  <td style={{ minWidth: "80px" }}>
                    {product.isServe ? (
                      this.props.router.t("catering")
                    ) : (
                      <>
                        {product.price?.toFixed(2)}{" "}
                        {Currencies[
                          localStorage.getItem("defaultCurrency")!
                        ] || <>₺</>}
                      </>
                    )}
                    {this.state.defaultCurrency != currency ? (
                      <>
                        {" "}
                        (
                        {this.calculateExchange(
                          product.price,
                          currency
                        ).toFixed(2)}{" "}
                        {Currencies[currency]}){" "}
                      </>
                    ) : null}
                  </td>

                  <td>
                    <article onClick={() => this.optionOrderPopup(product)}>
                      {product.options?.length > 0 || product.note ? `...` : ``}
                    </article>
                  </td>
                </tr>
              );
            })}
          {order.products
            ?.filter((p: any) => p.status == 1)
            .map((product: any) => {
              let currency = this.props.getGlobalData?.Products?.find(
                (_: any) => _._id == product.product
              )?.prices?.find(
                (price: any) => price._id == product.priceId
              )?.currency;

              return (
                <tr style={{ backgroundColor: "#ffcc00" }}>
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedProductId.includes(
                        product._id
                      )}
                      style={{ borderRadius: "3px" }}
                      onChange={(event) =>
                        this.onSelectedProduct(product._id, product)
                      }
                    />
                  </td>
                  <td>{product.quantity}</td>
                  <td>{product.productName}</td>
                  <td style={{ minWidth: "80px" }}>
                    {product.isServe ? (
                      this.props.router.t("catering")
                    ) : (
                      <>
                        {product.price?.toFixed(2)}{" "}
                        {Currencies[
                          localStorage.getItem("defaultCurrency")!
                        ] || <>₺</>}
                      </>
                    )}
                    {this.state.defaultCurrency != currency ? (
                      <>
                        {" "}
                        (
                        {this.calculateExchange(
                          product.price,
                          currency
                        ).toFixed(2)}{" "}
                        {Currencies[currency]}){" "}
                      </>
                    ) : null}
                  </td>

                  <td>
                    <article onClick={() => this.optionOrderPopup(product)}>
                      {product.options?.length > 0 || product.note ? `...` : ``}
                    </article>
                  </td>
                </tr>
              );
            })}
          {order.products
            ?.filter((p: any) => p.status == 0)
            .map((product: any) => {
              let currency = this.props.getGlobalData?.Products?.find(
                (_: any) => _._id == product.product
              )?.prices?.find(
                (price: any) => price._id == product.priceId
              )?.currency;

              return (
                <tr style={{ backgroundColor: "#cc3300" }}>
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.selectedProductId.includes(
                        product._id
                      )}
                      style={{ borderRadius: "3px" }}
                      onChange={(event) =>
                        this.onSelectedProduct(product._id, product)
                      }
                    />
                  </td>
                  <td>{product.quantity}</td>
                  <td>{product.productName}</td>
                  <td style={{ minWidth: "80px" }}>
                    {product.isServe ? (
                      this.props.router.t("catering")
                    ) : (
                      <>
                        {product.price}{" "}
                        {Currencies[
                          localStorage.getItem("defaultCurrency")!
                        ] || <>₺</>}
                      </>
                    )}
                    {this.state.defaultCurrency != currency ? (
                      <>
                        {" "}
                        (
                        {this.calculateExchange(
                          product.price,
                          currency
                        ).toFixed(2)}{" "}
                        {Currencies[currency]}){" "}
                      </>
                    ) : null}
                  </td>

                  <td>
                    <article onClick={() => this.optionOrderPopup(product)}>
                      {product.options?.length > 0 || product.note ? `...` : ``}
                    </article>
                  </td>
                </tr>
              );
            })}
          <hr />
        </>
      );
    }
    if (order.getirOrderId) {
      return (
        <>
          <div className="checkboxOrder">
            <input
              type="checkbox"
              style={{ borderRadius: "3px", margin: "5px" }}
              checked={this.state.selectedOrderNums.includes(order.orderNum)}
              onChange={(event) =>
                this.onSelectedOrder(order.orderNum, order.products)
              }
            />
            <div className="caption">
              {this.props.router.t("order-no")}: {order.orderNum}
            </div>
          </div>

          {order.products.map((product: any) => {
            return (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={this.state.selectedProductId.includes(product._id)}
                    style={{ borderRadius: "3px" }}
                    onChange={(event) =>
                      this.onSelectedProduct(product._id, product)
                    }
                  />
                </td>
                <td>
                  <span>{product.quantity}</span>
                </td>
                <td
                  style={
                    !this.checkGetirProductPair(product.getirProductId)
                      ? {
                          color: "#FF1616",
                          fontWeight: "bold",
                        }
                      : {}
                  }
                >
                  {product.productName}
                </td>
                <td style={{ minWidth: "80px" }}>
                  {product.price?.toFixed(2)} <>₺</>
                </td>
                <td>
                  <article onClick={() => this.optionOrderPopup(product)}>
                    {product.options?.length > 0 || product.note ? `...` : ``}
                  </article>
                </td>
              </tr>
            );
          })}
          <hr />
        </>
      );
    }
    return (
      <>
        <div className="checkboxOrder">
          <input
            type="checkbox"
            style={{ borderRadius: "3px", margin: "5px" }}
            checked={this.state.selectedOrderNums.includes(order.orderNum)}
            onChange={(event) =>
              this.onSelectedOrder(order.orderNum, order.products)
            }
          />
          <div className="caption">
            {this.props.router.t("order-no")}: {order.orderNum}
          </div>
        </div>

        {order.products
          ?.filter((p: any) => p.status != 0)
          .map((product: any) => {
            let currency = this.props.getGlobalData?.Products?.find(
              (_: any) => _._id == product.product
            )?.prices?.find(
              (price: any) => price._id == product.priceId
            )?.currency;

            return (
              <tr>
                <td>
                  <input
                    type="checkbox"
                    checked={this.state.selectedProductId.includes(product._id)}
                    style={{ borderRadius: "3px" }}
                    onChange={(event) =>
                      this.onSelectedProduct(product._id, product)
                    }
                  />
                </td>
                <td>
                  <span>{product.quantity}</span>
                </td>
                <td>{product.productName}</td>
                <td style={{ minWidth: "80px" }}>
                  {product.isServe ? (
                    this.props.router.t("catering")
                  ) : (
                    <>
                      {product.price?.toFixed(2)}{" "}
                      {Currencies[localStorage.getItem("defaultCurrency")!] || (
                        <>₺</>
                      )}
                    </>
                  )}
                  {this.state.defaultCurrency != currency ? (
                    <>
                      {" "}
                      (
                      {this.calculateExchange(product.price, currency).toFixed(
                        2
                      )}{" "}
                      {Currencies[currency]}){" "}
                    </>
                  ) : null}
                </td>
                <td>
                  <article onClick={() => this.optionOrderPopup(product)}>
                    {product.options?.length > 0 || product.note ? `...` : ``}
                  </article>
                </td>
              </tr>
            );
          })}
        {order.products
          ?.filter((p: any) => p.status == 0)
          .map((product: any) => {
            return (
              <tr style={{ backgroundColor: "#cc3300" }}>
                <td>
                  <input
                    type="checkbox"
                    checked={this.state.selectedProductId.includes(product._id)}
                    style={{ borderRadius: "3px" }}
                    onChange={(event) =>
                      this.onSelectedProduct(product._id, product)
                    }
                  />
                </td>
                <td>{product.quantity}</td>
                <td>{product.productName}</td>
                {product.isServe ? (
                  this.props.router.t("catering")
                ) : (
                  <td>
                    {product.price?.toFixed(2)}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </td>
                )}

                <td>
                  <article onClick={() => this.optionOrderPopup(product)}>
                    {product.options?.length > 0 || product.note ? `...` : ``}
                  </article>
                </td>
              </tr>
            );
          })}
        <hr />
      </>
    );
  };
  deleteCover = async (cover: any) => {
    let params: DeleteCoverParamDocument = {
      tableId: GlobalStates.SelectedTableId,
      coverId: cover._id,
      coverPrice: cover.price,
    };
    const result = await Services.Delete.cover(params);
    if (result.status) {
      let tables = this.props.getGlobalData.AllTables?.map((table) => {
        if (table._id == GlobalStates.SelectedTableId) {
          table = result.data;
        }
        return table;
      });
      this.props.setGlobalData({
        AllTables: tables,
      });
      this.setState({
        covers: result.data.cover,
        totalPrice: result.data.totalPrice,
        remainingPrice: result.data.remainingPrice,
      });
      this.showToastify("success", this.props.router.t("cover-deleted"));
    } else {
      this.showToastify("error", this.props.router.t("cover-delete-error"));
    }
  };

  deleteDiscount = async (discount: any) => {
    let params: DiscountDeleteParamDocumet = {
      tableId: GlobalStates.SelectedTableId,
      discountId: discount._id,
      amount: discount.amount,
    };
    const result = await Services.Delete.discount(params);
    if (result.status) {
      this.setState({
        totalPrice: result.data.totalPrice,
        remainingPrice: result.data.remainingPrice,
        discounts: result.data.discount,
      });
      let tables = this.props.getGlobalData.AllTables?.map((table) => {
        if (table._id == GlobalStates.SelectedTableId) {
          table = result.data;
        }
        return table;
      });
      this.props.setGlobalData({
        AllTables: tables,
      });
      this.showToastify("success", this.props.router.t("discount-deleted"));
    } else {
      this.showToastify("error", this.props.router.t("discount-delete-error"));
    }
  };

  Covers = (cover: any) => {
    return (
      <tr>
        <td>
          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() => this.deleteCover(cover)}
          />
        </td>
        <td>{cover.quantity}</td>
        <td>{cover.title}</td>
        <td>
          {Number(cover.price).toFixed(2)}{" "}
          {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
        </td>
        <td>{this.props.router.t("cover")}</td>
      </tr>
    );
  };

  Discounts = (discount: any) => {
    return (
      <tr>
        <td>
          <DeleteIcon
            sx={{ cursor: "pointer" }}
            onClick={() => this.deleteDiscount(discount)}
          />
        </td>
        <td>1</td>
        <td>{this.props.router.t("discount-table")}</td>
        <td>
          -{Number(discount.amount).toFixed(2)}{" "}
          {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
        </td>
        <td>
          <article onClick={() => this.discountNotePopup(discount.note)}>
            ...
          </article>
        </td>
      </tr>
    );
  };

  ButtonBottom = () => {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    if (table.order_type === 6) return <></>;
    return (
      <div className="buttonBottom">
        {GlobalStates.BottomButtons &&
          GlobalStates.BottomButtons.map((button: any, i: number) => (
            <button
              key={i}
              style={{
                backgroundColor: button?.color,
                fontSize: "0.3",
                minHeight: "50%",
              }}
              onClick={(event) => this.onClick(button?.name)}
              onContextMenu={(event) =>
                Functions.handleRightClick(
                  this,
                  event,
                  i,
                  button,
                  "bottom",
                  this.props
                )
              }
              data-toggle="modal"
              data-target="#globalModal"
            >
              {this.props.router.t(button.name)}
            </button>
          ))}
      </div>
    );
  };
  ButtonRight = () => {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    let buttons = GlobalStates.CustomerId
      ? GlobalStates.TakeawayButtons
      : GlobalStates.CurrentTable.isSafeSales
      ? GlobalStates.CaseSaleButtons
      : table.order_type == 3
      ? GlobalStates.homeDeliveryButtons
      : table.order_type == 6
      ? GlobalStates.GetirButtons
      : GlobalStates.RightButtons;

    return (
      <div className="rightButtons">
        {this.state.isColorPickerFired ? (
          <ColorPicker {...this.props} pageOrderTable={this} />
        ) : null}
        {buttons &&
          buttons?.map((button: any, i: number) => (
            <button
              key={i}
              style={{ backgroundColor: button.color }}
              onClick={(event) => this.onClick(button?.name)}
              onContextMenu={(event) =>
                Functions.handleRightClick(
                  this,
                  event,
                  i,
                  button,
                  "right",
                  this.props
                )
              }
              data-toggle="modal"
              data-target="#globalModal"
            >
              {this.props.router.t(button.name)}
            </button>
          ))}
      </div>
    );
  };

  AmountContainer = () => {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;

    const calculateAmount = (currencyValue: number, amount: number) => {
      return (amount / currencyValue).toFixed(2);
    };
    return (
      <Box
        sx={{
          zIndex: 3,
          visibility: this.state.exchangeVisibility,
          position: "absolute",
          maxWidth: "19vw",
          width: "19vw",
          height: "10vh",
          left: "25.5vw",
          top: "9.5vw",
          borderRadius: 4,
        }}
      >
        <TableContainer
          sx={{
            backgroundColor: "#fff",
            borderRadius: 4,
            border: "2px solid black",
            boxShadow: "2px 2px 2px 2px",
          }}
        >
          <MTable>
            <TableHead>
              <TableRow>
                <TableCell>{this.props.router.t("currency")}</TableCell>
                <TableCell>{this.props.router.t("total")}</TableCell>
                <TableCell>{this.props.router.t("remaining")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.defaultCurrency == "TL" ? null : (
                <TableRow>
                  <TableCell>{this.props.router.t("turkish-lira")}</TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.TL,
                      table.totalPrice
                    )}{" "}
                    {Currencies["TL"]}
                  </TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.TL,
                      table.remainingPrice
                    )}{" "}
                    {Currencies["TL"]}
                  </TableCell>
                </TableRow>
              )}
              {this.state.defaultCurrency == "USD" ? null : (
                <TableRow>
                  <TableCell>{this.props.router.t("dollar")}</TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.USD,
                      table.totalPrice
                    )}{" "}
                    {Currencies["USD"]}
                  </TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.USD,
                      table.remainingPrice
                    )}{" "}
                    {Currencies["USD"]}
                  </TableCell>
                </TableRow>
              )}
              {this.state.defaultCurrency == "EUR" ? null : (
                <TableRow>
                  <TableCell>{this.props.router.t("euro")}</TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.EUR,
                      table.totalPrice
                    )}{" "}
                    {Currencies["EUR"]}
                  </TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.EUR,
                      table.remainingPrice
                    )}{" "}
                    {Currencies["EUR"]}
                  </TableCell>
                </TableRow>
              )}
              {this.state.defaultCurrency == "GBP" ? null : (
                <TableRow>
                  <TableCell>{this.props.router.t("pound")}</TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.GBP,
                      table.totalPrice
                    )}{" "}
                    {Currencies["GBP"]}
                  </TableCell>
                  <TableCell>
                    {calculateAmount(
                      this.props.getGlobalData?.CurrencySettings?.currencies
                        ?.GBP,
                      table.remainingPrice
                    )}{" "}
                    {Currencies["GBP"]}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MTable>
        </TableContainer>
      </Box>
    );
  };

  render() {
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    let isTakeaway = table.order_type == 3;
    let getirTotal = 0;
    if (table.order_type === 6) {
      getirTotal = this.props.or[0]?.totalDiscountedPrice
        ? this.props.or[0]?.totalDiscountedPrice
        : this.props.or[0]?.totalPrice;
    }
    return (
      <>
        <this.EditCustomerCountDialog />
        <this.SelectCourier />
        <this.CreateCourier />
        <this.CateringDialog />
        <this.GetirCancelDialog />
        <this.GetirManualActionsDialog />
        <this.CoverDialog />
        <this.DiscountDialog />
        <this.CancelDialog />
        <this.ServiceDialog />
        <this.ShowServiceFeeDialog />
        <this.ExchangePrintDialog />
        <div
          className="tableContainer"
          style={{ overflowY: "scroll", height: "99%" }}
        >
          <div
            className="titleinfo"
            onClick={() => {
              if (!this.props.getGlobalData?.CurrencySettings) {
                toast.warning(this.props.router.t("currency-features-info"));
                return;
              }
              this.setState({
                exchangeVisibility:
                  this.state.exchangeVisibility == "hidden"
                    ? "visible"
                    : "hidden",
              });
            }}
          >
            <div
              className={
                isTakeaway ||
                this.state.or?.remainingPrice ||
                table?.remainingPrice
                  ? "totalTable"
                  : "singleTotal"
              }
            >
              <div>
                {" "}
                <strong> {this.props.router.t("total")} </strong>
              </div>
              <div>
                {" "}
                {isTakeaway
                  ? this.state.or?.totalPrice?.toFixed(2)
                  : table.order_type === 6
                  ? getirTotal?.toFixed(2)
                  : Number(
                      Number(table?.totalPrice) +
                        Number(this.props.pageOrder.state.totalAmount)
                    )?.toFixed(2)}{" "}
                {this.state.defaultCurrency ? (
                  Currencies[this.state.defaultCurrency]
                ) : (
                  <>₺</>
                )}
              </div>
            </div>

            {isTakeaway ||
            this.state.or?.remainingPrice ||
            table?.remainingPrice ? (
              <div className="remainingTable">
                {isTakeaway ? (
                  this.state.or?.remainingPrice ? (
                    <>
                      <div>
                        <strong>{this.props.router.t("remaining")}:</strong>
                      </div>
                      <div>
                        {Number(this.state.or?.remainingPrice)?.toFixed(2)}{" "}
                        {this.state.defaultCurrency ? (
                          Currencies[this.state.defaultCurrency]
                        ) : (
                          <>₺</>
                        )}
                      </div>
                    </>
                  ) : null
                ) : table?.remainingPrice &&
                  !GlobalStates.CurrentTable.isSafeSales ? (
                  <>
                    <div>
                      <strong>{this.props.router.t("remaining")}</strong>
                    </div>
                    <div>
                      {Number(table?.remainingPrice)?.toFixed(2)}{" "}
                      {this.state.defaultCurrency ? (
                        Currencies[this.state.defaultCurrency]
                      ) : (
                        <>₺</>
                      )}
                    </div>{" "}
                  </>
                ) : GlobalStates.CurrentTable.isSafeSales ? (
                  <>
                    <div>
                      <strong>{this.props.router.t("remaining")}</strong>
                    </div>
                    <div>
                      {Number(
                        Number(table?.totalPrice) +
                          Number(this.props.pageOrder.state.totalAmount)
                      )?.toFixed(2)}{" "}
                      {this.state.defaultCurrency ? (
                        Currencies[this.state.defaultCurrency]
                      ) : (
                        <>₺</>
                      )}
                    </div>{" "}
                  </>
                ) : null}
              </div>
            ) : null}
            <this.AmountContainer />
          </div>
          <div className="Tablebox">
            <table className="adisyonTable">
              <thead className="adisyonThead">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      style={{ borderRadius: "3px" }}
                      checked={this.state.isAllChecked}
                      onChange={(event) => this.handleCheckAll(event)}
                    />
                  </th>
                  <th className="tableProductCount" style={{}}>
                    {this.props.router.t("quantity")}
                  </th>
                  <th>{this.props.router.t("name")}</th>
                  <th>{this.props.router.t("price")}</th>
                  <th>{this.props.router.t("options")}</th>
                </tr>
              </thead>

              <tbody>
                {isTakeaway
                  ? null
                  : this.state?.covers?.map((cover: any, index: number) => (
                      <this.Covers {...cover} key={index} />
                    ))}
                {isTakeaway && this.state?.or?.paid?.length > 0 ? (
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: "#339900",
                      border: "2px solid black",
                      textAlign: "center",
                      alignSelf: "center",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    {this.props.router.t("delivered")}
                  </td>
                ) : null}

                {isTakeaway
                  ? this.state?.or?.paid?.map((order: any, index: number) =>
                      this.Order(order, "#339900")
                    )
                  : null}

                {isTakeaway && this.state?.or?.onWay?.length > 0 ? (
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: "#42ba96",
                      border: "2px solid black",
                      textAlign: "center",
                      alignSelf: "center",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    {this.props.router.t("on-way")}
                  </td>
                ) : null}

                {isTakeaway
                  ? this.state?.or?.onWay?.map((order: any, index: number) =>
                      this.Order(order, "#42ba96")
                    )
                  : null}

                {isTakeaway && this.state?.or?.products?.length > 0 ? (
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: "#99cc33",
                      border: "2px solid black",
                      textAlign: "center",
                      alignSelf: "center",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    {this.props.router.t("approved")}
                  </td>
                ) : null}

                {isTakeaway
                  ? this.state?.or?.products?.map((order: any, index: number) =>
                      this.Order(order, "#99cc33")
                    )
                  : null}
                {isTakeaway && this.state?.or?.unapproved?.length > 0 ? (
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: "#ffcc00",
                      border: "2px solid black",
                      textAlign: "center",
                      alignSelf: "center",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    {this.props.router.t("unapproved")}
                  </td>
                ) : null}

                {isTakeaway
                  ? this.state?.or?.unapproved?.map(
                      (order: any, index: number) =>
                        this.Order(order, "	#ffcc00")
                    )
                  : this.state?.or?.map((order: any, index: number) => (
                      <this.Orders {...order} key={index} />
                    ))}
                {isTakeaway && this.state.or?.cancelled?.length > 0 ? (
                  <td
                    colSpan={5}
                    style={{
                      backgroundColor: "#cc3300",
                      border: "2px solid black",
                      textAlign: "center",
                      alignSelf: "center",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    {this.props.router.t("cancelled")}
                  </td>
                ) : null}

                {isTakeaway && this?.state.or?.cancelled?.length > 0
                  ? this.state?.or?.cancelled.map((order: any, index: number) =>
                      this.Order(order, "#cc3300")
                    )
                  : null}

                {isTakeaway ? null : this.state?.sendFirst?.length > 0 ? (
                  <>
                    {this.state?.sendFirst?.map((p: any) => (
                      <this.Order {...p} />
                    ))}
                    <hr
                      style={{
                        borderTopWidth: "3px",
                        borderColor: "black",
                        borderStyle: "dotted",
                      }}
                    />
                  </>
                ) : null}

                {this.props.pageOrder?.state?.newOrders
                  .filter(
                    (order) => !this.state.sendFirstIds.includes(order._id)
                  )
                  .map((pc: any, index) => (
                    <this.Order {...pc} key={index} />
                  ))}

                {isTakeaway
                  ? this.state?.discounts?.map((discount: any, index: any) => (
                      <this.Discounts {...discount} />
                    ))
                  : table?.discount?.map((discount: any, index: any) => (
                      <this.Discounts {...discount} />
                    ))}

                {this.props?.pageOrder?.state?.newDiscounts?.map(
                  (discount: any) => (
                    <this.Discounts {...discount} />
                  )
                )}
              </tbody>
            </table>
          </div>

          <span>
            {this.state.or?.customer ? (
              <>
                {this.props.router.t("address")}:{" "}
                {this.state.or?.address
                  ? this.state.or?.address
                  : this.state.or?.customer?.address
                  ? this.state.or?.customer?.address[0]?.address
                  : "Unknown Address"}
              </>
            ) : null}
          </span>
          <span> {this.getAddress(GlobalStates.CustomerId)}</span>
          {!GlobalStates.CustomerId &&
            !GlobalStates.CurrentTable.isSafeSales &&
            !isTakeaway && <this.ButtonBottom />}
        </div>
        <this.ButtonRight />
      </>
    );
  }
}

export default Table;
