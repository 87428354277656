import i18n from "utilities/i18n";

const buttonNameList = {
  moveTable: i18n.t("moveTable"),
  moveProduct: i18n.t("moveProduct"),
  sendOrder: i18n.t("sendOrder"),
  checkout: i18n.t("checkout"),
  changePrice: i18n.t("changePrice"),
  discount: i18n.t("discount"),
  cover: i18n.t("cover"),
  sendfirst: i18n.t("sendfirst"),
  fastcheckout: i18n.t("fastcheckout"),
  print: i18n.t("print"),
  catering: i18n.t("catering"),
  cancelCatering: i18n.t("cancelCatering"),
  cancelSelectedProducts: i18n.t("cancelSelectedProducts"),
  editCustomerCount: i18n.t("editCustomerCount"),
  closeTable: i18n.t("closeTable"),
  approveQr: i18n.t("approveQr"),
  cancelQr: i18n.t("cancelQr"),
  service: i18n.t("service"),
  changePersonPrice: i18n.t("changePersonPrice"),
  cashCaseOpen: i18n.t("cashCaseOpen"),
  sendMainOrder: i18n.t("sendMainOrder"),
};
const takeAwayButtonNameList = {
  sendOrder: i18n.t("sendOrder"),
  sendfirst: i18n.t("sendfirst"),
  catering: i18n.t("catering"),
  cancelCatering: i18n.t("cancelCatering"),
  print: i18n.t("print"),
};
const caseSaleButtonNameList = {
  checkout: i18n.t("checkout"),
  discount: i18n.t("discount"),
};
const takeawayButtons = [
  {
    name: "sendOrder",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendfirst",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "checkout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "catering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelCatering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "print",
    color: "hsl(3, 81%, 46%)",
  },
];

const homeDeliveryButtons = [
  {
    name: "add-product-home-delivery",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "approve",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "onway-order",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "checkout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancel-selected",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancel-all",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "deleteHomeDeliveryProduct",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "deleteHomeDelivery",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "print",
    color: "hsl(3, 81%, 46%)",
  },
];
const caseSaleButtons = [
  {
    name: "checkout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "discount",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "send",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "print",
    color: "hsl(3, 81%, 46%)",
  },
];

const buttons = [
  {
    name: "moveProduct",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "checkout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "moveTable",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendOrder",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "changePrice",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "discount",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cover",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendfirst",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "fastcheckout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "print",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "catering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelCatering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelSelectedProducts",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "editCustomerCount",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "closeTable",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "approveQr",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelQr",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "service",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "exchangePrint",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "changePersonPrice",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cashCaseOpen",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendMainOrder",
    color: "hsl(3, 81%, 46%)",
  },
];

const featurebuttons = [
  {
    name: "mergeCheck",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "nameTable",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "catering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "nopayment",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "readbarcode",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "splitProducts",
    color: "hsl(3, 81%, 46%)",
  },
];

const rightButtons: any[] = [
  {
    name: "cancelSelectedProducts",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "moveProduct",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "catering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelCatering",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "approveQr",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cancelQr",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "editCustomerCount",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendfirst",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "service",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "exchangePrint",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "closeTable",
    color: "hsl(3, 81%, 46%)",
  },
];

const getirButtons: Array<{ name: string; color: string }> = [
  {
    name: "getirVerify",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "getirPrepare",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "getirHandover",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "getirDeliver",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "getirCancel",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "getirManualActions",
    color: "hsl(3, 81%, 46%)",
  },
];

const bottomButtons: any[] = [
  {
    name: "moveTable",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "sendOrder",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "checkout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "print",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "fastcheckout",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "discount",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "cover",
    color: "hsl(3, 81%, 46%)",
  },
  {
    name: "changePrice",
    color: "hsl(3, 81%, 46%)",
  },
];

export {
  buttons,
  rightButtons,
  bottomButtons,
  getirButtons,
  buttonNameList,
  takeawayButtons,
  takeAwayButtonNameList,
  caseSaleButtons,
  caseSaleButtonNameList,
  homeDeliveryButtons,
};
