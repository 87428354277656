import Api from "./api";
import { ErrorCodes, Result, ServicePages } from "../public/ajax";
import { CaseGetParamDocument } from "../modules/services/case";
import V from "../library/variable";
import { CheckGetParamDocument } from "../modules/services/checks";
import { ProductGetParamDocument } from "../modules/services/product";
import { SectionGetParamDocument } from "../modules/services/sections";
import { TableGetParamDocument } from "../modules/services/tables";
import { TickGetParamDocument } from "../modules/services/tick";
import { OrderGetParamDocument } from "../modules/services/orders";
import { getServers } from "dns";
import functions from "config/global/functions";

const language: any = localStorage.getItem("language") || "tr";

const Get = {
  case(params: CaseGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.case],
      data: params,
    });
  },
  categories() {
    return Api.getSync({
      url: [ServicePages.categories, language],
    });
  },
  checks(params: CheckGetParamDocument) {
    let url = params.checkId ? ["old", params.checkId] : [];
    return Api.getSync({
      url: [ServicePages.checks].concat(url),
      data: params,
    });
  },
  oldChecks() {
    return Api.getSync({
      url: [ServicePages.check, "old"],
    });
  },
  costs() {
    return Api.getSync({
      url: [
        functions.checkPerm("511") ? ServicePages.costs : "expense",
        localStorage.getItem("caseId")!,
      ],
    });
  },
  couriers() {
    return Api.getSync({
      url: [ServicePages.couriers],
    });
  },
  customers() {
    return Api.getSync({
      url: [ServicePages.customers],
    });
  },
  order(params: OrderGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.orders, params.tableId],
      data: params,
    });
  },
  allProduct() {
    return Api.getSync({
      url: [ServicePages.products, language],
    });
  },
  productById(params: ProductGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.products, params.productId],
      data: params,
    });
  },
  allSection() {
    return Api.getSync({
      url: [ServicePages.section],
    });
  },
  allTables() {
    return Api.get({
      url: [ServicePages.tables, language],
    });
  },
  allTablesSync() {
    return Api.getSync({
      url: [ServicePages.tables, language],
    });
  },
  tableById(params: TableGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.table, params.tableId],
      data: params,
    });
  },
  takeaway() {
    return Api.get({
      url: [ServicePages.takeaway],
    });
  },
  takeawaySync() {
    return Api.getSync({
      url: [ServicePages.takeaway],
    });
  },
  tick(params: TickGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.tick, params.tickCustomerId],
      data: params,
    });
  },
  myBranch() {
    return Api.getSync({
      url: [ServicePages.myBranch, language],
    });
  },
  closeTable(params: TableGetParamDocument) {
    return Api.getSync({
      url: [ServicePages.tables, "close", params.tableId],
    });
  },
  getServes() {
    return Api.getSync({
      url: ["serves"],
    });
  },
  getNotifications() {
    return Api.getSync({
      url: ["notifications"],
    });
  },
  zReport() {
    return Api.getSync({
      url: ["report", "z-report"],
    });
  },
  allCases() {
    return Api.getSync({
      url: ["cases", "all"],
    });
  },
  getCaseById(id: string) {
    return Api.getSync({
      url: ["cases", id],
    });
  },
  notes() {
    return Api.getSync({
      url: ["notes"],
    });
  },
  cancelReasons() {
    return Api.getSync({
      url: ["cancel-reasons"],
    });
  },
  specialCustomers() {
    return Api.getSync({
      url: ["special-customer"],
    });
  },
  openAccounts() {
    return Api.getSync({
      url: ["open-account"],
    });
  },
  openAccountById(id: string) {
    return Api.getSync({
      url: ["open-account", id],
    });
  },
  getPairOptions() {
    return Api.getSync({
      url: ["pairing"],
    });
  },
  profile() {
    return Api.getSync({
      url: ["profile"],
    });
  },
  getProductByBarcode(barcode: string) {
    return Api.getSync({
      url: ["products", "barcode", barcode],
    });
  },
  getStocks() {
    return Api.getSync({
      url: ["stocks"],
    });
  },
  getRecipes() {
    return Api.getSync({
      url: ["recipes"],
    });
  },
  getSemiIngredients() {
    return Api.getSync({
      url: ["semi-ingredients"],
    });
  },
  getPaymentMethods() {
    return Api.getSync({
      url: ["payment-methods"],
    });
  },
};

export default Get;
