import Currencies from "constants/currencies";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import { Component } from "react";

type PageState = {
  time: any;
  min: number;
  sec: number;
};

type PageProps = {
  tableTitle: string;
  sectionTitle: string;
  createdAt: string;
  updatedAt: string;
  onClick: any;
  isSelected: boolean;
  isBusy: boolean;
  isPrinted: boolean;
  router: PagePropCommonDocument["router"];
  order_type?: any;
  status?: string;
  totalPrice: number;
};

class Table extends Component<PageProps, PageState> {
  interval: any;
  constructor(props: any) {
    super(props);
    this.state = {
      time: new Date(),
      min: 0,
      sec: 0,
    };
  }

  componentDidMount() {
    this.getTime();
    this.interval = setInterval(() => {
      this.getTime();
    }, 1000);
  }

  getBgColor = () => {
    if (this.props.order_type == 3) {
      return "#1250D5";
    }
    if (this.props.isSelected) {
      return "brown";
    } else if (this.props.isBusy) {
      if (this.props.order_type === 6) {
        switch (this.props.status) {
          case "unapproved":
          case "scheduled":
            return "#FF9F33";
          case "preparing":
            return "orange";
          case "on-way":
          case "handovered":
            return "green";
        }
      }
      if (this.props.isPrinted) {
        return "blue";
      }
      return "green";
    } else {
      return "#485563";
    }
  };

  componentWillUnmount(): void {
    clearInterval(this.interval);
  }

  getTime = () => {
    let ms: any = new Date(
      new Date().getTime() - new Date(this.props?.createdAt).getTime()
    );
    let min = Math.floor(ms / 60000);
    let sec = Math.floor((ms % 60000) / 1000);
    this.setState({
      min: min,
      sec: sec,
    });
  };

  render() {
    const tableTimer =
      JSON.parse(localStorage.getItem("tableTimer")!) == null
        ? true
        : JSON.parse(localStorage.getItem("tableTimer")!);

    return (
      <article
        className="tableArticle"
        style={{
          backgroundColor: this.getBgColor(),
          left: 5,
          top: 5,
        }}
        onClick={this.props.onClick}
      >
        <div className="in">
          <div className="tableTitle">
            <span>
              {this.props.isSelected
                ? this.props.router.t("cancel")
                : this.props.tableTitle}
            </span>
          </div>
          <div>
            <span>{this.props.isSelected ? "_" : this.props.sectionTitle}</span>
          </div>
          <div className="tableDate">
            <span>
              {this.props.totalPrice}
              {Currencies[localStorage.getItem("defaultCurrency")!] || (
                <>₺</>
              )}{" "}
            </span>
            {this.props.totalPrice > 0 && tableTimer ? (
              <span>
                {this.state.min}:{this.state.sec < 10 ? <>0</> : null}
                {this.state.sec}
              </span>
            ) : null}
          </div>
        </div>
      </article>
    );
  }
}

export default Table;
