import { Component } from "react";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Printer from "../../../../../../config/global/printers/index";
import Services from "services/index";
// import Table from '@mui/material/Table';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Box, Tabs, Tab, Stack, AppBar } from '@mui/material'
import { Helmet } from 'react-helmet';
import Swal from "sweetalert2";
import Currencies from "constants/currencies";
import "./xreportdetail.css"
import { bill_types } from "config/global/printers/report/z-report";
import PrintIcon from '@mui/icons-material/Print';

type PageState = {
    response: any;
    tab: number;
};

type PageProps = {
    data: any;
} & PagePropCommonDocument;

const PaymentTypes = {
    99: "opening-amount",
    98: "total-amount",
    1: "pos_bank",
    2: "pos_cash",
    3: "app_payment",
    6: "tick",
    7: "sodexo",
    8: "smart",
    9: "winwin",
    10: "multinet",
    11: "setcard",
    12: "metropol",
    13: "edended",
    14: "tips",
    15: "jetKurye",
    16: "not-payable",
    17: "migros-hemen",
    18: "getir-online",
    19: "trendyol",
    20: "yemek-sepeti"
}

class XReportDetail extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            response: Services.Get.zReport().data,
            tab: 0
        }
    }


    getXReport() {
        if (this.state.response) {
            Swal.fire({
                title: this.props.router.t("pleaseWait").toCapitalizeCase(),
                html: this.props.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            Printer.ZReport.printZReport(this.state.response,this.props.router.t);
            setTimeout(() => {
                Swal.fire({
                    icon: "success",
                    title: this.props.router.t("x-report"),
                    html: this.props.router.t("printedXReport"),
                });
            }, 1000)
            return;
        }
        Swal.fire({
            icon: "info",
            title: this.props.router.t("not-report"),
        });
    }

    calculateExpenseButOpenAccount() {
        let expenseAmount = 0;
        this.state.response?.expenses?.map((expense: any) => {
            if(expense.expense_type != 2) expenseAmount += expense.amount;
        });
        return expenseAmount;
    }


    render() {
        function createPayment(
            type: number,
            amount: number,
            currency: string,
        ) {
            return { type, amount, currency };
        }

        function createProduct(
            name: string,
            quantity: number,
            price: number,
            isServe: boolean
        ) {
            return { name, quantity, price, isServe };
        }
        const paymentRows: any[] = [];
        let total: any = {
            type: 98,
            amount: 0,
            currency: ''
        }
        this.state.response.start_balance?.map((bal: any) => {
            bal.type = 99;
            total.amount += bal.amount;
            paymentRows.push(bal);
        })
        this.state.response.payments?.map((payment: any) => {

            paymentRows.push(createPayment(payment.type, payment.amount, payment.currency));
            if (payment.type == 16 || payment.type == 14) return;
            total.amount += payment.amount

        });

        const productRows: any[] = [];
        this.state.response.products?.map((p: any) => {
            if (p.status == 0) return;
            productRows.push(createProduct(p.productName, p.quantity, p.price, p.isServe));
        })



        paymentRows.push(total);
        let productTotal: any = {
            name: this.props.router.t('total'),
            quantity: 0,
            price: 0
        }
        let cateringAmount = 0;
        this.state.response.products?.map((p: any) => {
            if (p.status == 0) return;
            if (p.isServe) {
                cateringAmount += p.price;
                return;
            }
            productTotal.quantity += p.quantity;
            productTotal.price += p.price;

        });
        productRows.push(productTotal);
        let expensesTotal: any = {
            title: this.props.router.t('total'),
            expense_amount: 0,
            currency: '',
        }
        let expenseRows: any[] = [];
        if (cateringAmount > 0) expenseRows.push({ title: this.props.router.t('catering'), expense_amount: cateringAmount, currency: this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL" })

        this.state.response.expenses?.map((ex: any) => expenseRows.push(ex));

        this.state.response.expenses?.map((ex: any) => {
            expensesTotal.expense_amount += ex.expense_amount;
        })
        expenseRows.push(expensesTotal);
        let expense_total_amount = 0;
        this.state.response.expenses?.map((ex: any) => {
            if (ex.payment_type == 2) expense_total_amount += ex.expense_amount;
        })

        let allTotal: any[] = [];
        allTotal.push({ name: this.props.router.t('total-revenue'), amount: total.amount }, { name: `${this.props.router.t('total-expense')} (${this.props.router.t('cash')})`, amount: expense_total_amount }, { name: this.props.router.t('catering'), amount: cateringAmount }, { name: this.props.router.t('turnover'), amount: (total.amount - expense_total_amount) })
        
        return (
            <Box sx={{ width: "100%", overflowY: "scroll", height: "99%" }}>
                <AppBar sx={{ display: "flex", flexDirection: "row", justifyContent: "center", backgroundColor: "#fff" }}>
                    <Tabs value={this.state.tab} onChange={(e, newValue) => this.setState({ tab: newValue })} sx={{ width: "100%", marginLeft: "10vw" }} centered>
                        <Tab value={0} label={this.props.router.t('revenues')} />
                        <Tab value={1} label={this.props.router.t('expense')} />
                        <Tab value={2} label={this.props.router.t('total')} />
                    </Tabs>
                    <Button startIcon={<PrintIcon />} sx={{margin: "auto", marginRight: "2vw", height: "2.5vw", width: "8vw", fontSize: "0.9vw"}} variant="contained" onClick={() => this.getXReport()}>{this.props.router.t('print')}</Button>
                </AppBar>
                <div hidden={this.state.tab != 0}>
                    <Stack direction="row" justifyContent="space-around" sx={{ marginTop: "1.5vw" }}>
                        <Stack direction="column" spacing={5}>
                            <Box sx={{ width: "35vw", backgroundColor: "#27A52D", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#138619, #17701B)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('sold-products')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "35vw", backgroundColor: "#1BC623" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}</TableCell>
                                                <TableCell sx={{ width: "10%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('quantity')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.products?.map((group: any) => (
                                                <>
                                                    <TableRow sx={{ backgroundColor: "#138619" }}>
                                                        <TableCell sx={{ color: "#fff", fontSize: "1vw", fontWeight: "bold" }}>{group.categoryTitle}</TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    {group.products?.map((product: any) => (
                                                        <TableRow sx={{ backgroundColor: "#1BC623" }}>
                                                            <TableCell sx={{ width: "50%", fontSize: "1vw" }}>{product.name}</TableCell>
                                                            <TableCell sx={{ width: "10%", fontSize: "1vw" }}>{product.quantity?.toFixed(1)}</TableCell>
                                                            <TableCell sx={{ width: "40%", fontSize: "1vw" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{ width: "35vw", backgroundColor: "#27A52D", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#138619, #17701B)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('order-types')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "20vw", backgroundColor: "#1BC623" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}</TableCell>
                                                <TableCell sx={{ width: "10%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('quantity')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.billType?.map((bill: any) => (

                                                <TableRow sx={{ backgroundColor: "#1BC623" }}>
                                                    <TableCell sx={{ width: "50%", fontSize: "1vw" }}>{this.props.router.t(bill_types[(bill.type) as keyof typeof bill_types])}</TableCell>
                                                    <TableCell sx={{ width: "40%", fontSize: "1vw" }}>{bill.percent}%</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={5}>
                            <Box sx={{ width: "35vw", backgroundColor: "#27A52D", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#138619, #17701B)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('added-payments')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "35vw", backgroundColor: "#1BC623" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('payment-type')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.payments?.map((payment: any) => (
                                                <>

                                                    <TableRow sx={{ backgroundColor: "#1BC623" }}>
                                                        <TableCell sx={{ width: "50%", fontSize: "1vw" }}>{this.props.router.t(PaymentTypes[(payment.type) as keyof typeof PaymentTypes])}</TableCell>
                                                        <TableCell sx={{ width: "40%", fontSize: "1vw" }}>{payment.amount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{ width: "35vw", backgroundColor: "#27A52D", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#138619, #17701B)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('tips')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "20vw", backgroundColor: "#1BC623" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('currency')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#17701B", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >
                                            {this.state.response?.tips?.map((tip: any) => (
                                                <>

                                                    <TableRow sx={{ backgroundColor: "#1BC623" }}>
                                                        <TableCell sx={{ width: "50%", fontSize: "1vw" }}>{tip.currency}</TableCell>
                                                        <TableCell sx={{ width: "40%", fontSize: "1vw" }}>{tip.amount}</TableCell>
                                                    </TableRow>
                                                </>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Stack>
                    </Stack>
                </div>
                <div hidden={this.state.tab != 1}>
                    <Stack direction="row" justifyContent="space-around" sx={{ marginTop: "1.5vw" }}>
                        <Stack direction="column" spacing={5}>
                            <Box sx={{ width: "38vw", backgroundColor: "#DEA12F", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#CB8E1C, #A97516)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('cancelled-products')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "35vw", backgroundColor: "#C29E3B" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#A97516", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}</TableCell>
                                                <TableCell sx={{ width: "10%", backgroundColor: "#A97516", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('quantity')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#A97516", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.cancelledProducts?.map((product: any) => (

                                                <TableRow sx={{ backgroundColor: "#C29E3B" }}>
                                                    <TableCell sx={{ width: "50%", fontSize: "1vw" }}>{product.name}</TableCell>
                                                    <TableCell sx={{ width: "10%", fontSize: "1vw" }}>{product.quantity?.toFixed(1)}</TableCell>
                                                    <TableCell sx={{ width: "40%", fontSize: "1vw" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{ width: "38vw", backgroundColor: "#E42222", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#CB3535, #AD0000)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('expenses')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "20vw", backgroundColor: "#CA2525" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "10%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_title')}</TableCell>
                                                <TableCell sx={{ width: "60%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('type')}</TableCell>
                                                <TableCell sx={{ width: "10%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('customer')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.expenses?.map((expense: any) => (

                                                <TableRow sx={{ backgroundColor: "#CA2525" }}>
                                                    <TableCell sx={{ width: "10%", fontSize: "1vw", color: "#fff" }}>{expense.title}</TableCell>
                                                    <TableCell sx={{ width: "60%", fontSize: "1vw", color: "#fff" }}>{expense.amount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                    <TableCell sx={{ width: "20%", fontSize: "1vw", color: "#fff" }}>{expense.expense_type == 2 ? (<>{this.props.router.t('open-account')}</>) : (<>{this.props.router.t(PaymentTypes[(expense.payment_type) as keyof typeof PaymentTypes])}</>)}</TableCell>
                                                    <TableCell sx={{ width: "10%", fontSize: "1vw", color: "#fff" }} align="center">{expense.expense_type == 2 ? <>{expense.openAccount?.name}</> : <>-</>}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Stack>
                        <Stack direction="column" spacing={5}>
                            <Box sx={{ width: "35vw", backgroundColor: "#E42222", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#CB3535, #AD0000)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('applied-discounts')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "20vw", backgroundColor: "#CA2525" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('note')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.discounts?.map((discount: any) => (

                                                <TableRow sx={{ backgroundColor: "#CA2525" }}>
                                                    <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t(discount.note)}</TableCell>
                                                    <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{discount.amount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Box sx={{ width: "35vw", backgroundColor: "#E42222", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                                <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#CB3535, #AD0000)", padding: "1vw" }}>
                                    <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('served-products')}</Typography>
                                </Box>
                                <TableContainer sx={{ marginTop: "1.5vw", overflowY: "scroll", height: "35vw", backgroundColor: "#CA2525" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: "50%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}</TableCell>
                                                <TableCell sx={{ width: "30%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('quantity')}</TableCell>
                                                <TableCell sx={{ width: "20%", backgroundColor: "#AD0000", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.response?.caterings?.map((product: any) => (

                                                <TableRow sx={{ backgroundColor: "#CA2525" }}>
                                                    <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{product.name}</TableCell>
                                                    <TableCell sx={{ width: "30%", fontSize: "1vw", color: "#fff" }}>{product.quantity?.toFixed(1)}</TableCell>
                                                    <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Stack>
                    </Stack>
                </div>
                <div hidden={this.state.tab != 2}>
                    <Stack direction="row" justifyContent="space-around">
                        <Box sx={{ width: "50vw", backgroundColor: "#4C97DA", padding: "2vw", borderRadius: "1vw", border: "1px solid black", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px;" }}>
                            <Box sx={{ width: "80%", margin: "auto", borderRadius: 4, backgroundImage: "linear-gradient(#2384DA, #125D9F)", padding: "1vw" }}>
                                <Typography variant="h4" textAlign="center" sx={{ fontSize: "1vw", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('total')}</Typography>
                            </Box>
                            <TableContainer sx={{ marginTop: "1.5vw", backgroundColor: "#2384DA" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: "60%", backgroundColor: "#125D9F", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('name')}</TableCell>
                                            <TableCell sx={{ width: "40%", backgroundColor: "#125D9F", color: "#fff", fontWeight: "bold" }}>{this.props.router.t('_amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ backgroundColor: "#098C03" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('total-revenue')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.totalRevenue?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#098C03" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('total-service-fee-amount')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.totalServiceFeeAmount.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#8C0303" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('total-catering-amount')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.totalCateringAmount.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#8C0303" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('total-expense-amount')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.totalExpenseAmount.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>} ({this.calculateExpenseButOpenAccount()?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>})</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#8C0303" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('total-discount-amount')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.totalDiscountAmount.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ backgroundColor: "#098C03" }}>
                                            <TableCell sx={{ width: "40%", fontSize: "1vw", color: "#fff" }}>{this.props.router.t('turnover')}</TableCell>
                                            <TableCell sx={{ width: "50%", fontSize: "1vw", color: "#fff" }}>{this.state.response?.general?.endorsement.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                    </Stack>
                </div>
            </Box>
        )
    }
}

export default XReportDetail;
