import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import English from "../resources/en.json";
import Turkish from "../resources/tr.json";
import Arabic from "../resources/ar.json";

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: English },
        tr: { translation: Turkish },
        ar: { translation: Arabic },
    },
    lng: localStorage.getItem("language") || "tr",
    fallbackLng: "tr",
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;