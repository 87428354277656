export default class Screen {
  static ipcRenderer: any = window.require("electron").ipcRenderer;
  static Screens: any = [];
  static async fetchScreens() {
    try {
      const result = await Screen.ipcRenderer.invoke("screens");
      return result;
    } catch (error) {
      console.error("Error fetching screens:", error);
      return [];
    }
  }

  static async initialize() {
    this.Screens = await this.fetchScreens();
  }
}
async function main() {
  await Screen.initialize();
}

main();
