import { EditOutlined } from "@ant-design/icons";
import "./products.css";
import { Component, useContext } from "react";

type PageState = {};

type PageProps = {
  title: string,
  price: any,
  currency: string,
  onClick: any
  onClickNote: any,
  image: string,
  showImages:boolean,
  textColor:string
};
class Product extends Component<PageProps, PageState> {
  render() {
    return (
      <div className="productBox" style={{ backgroundImage: this.props.showImages ? `url(${this.props.image})` : "none", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} >
        <div onClick={this.props.onClick} className="productMiddle">
          <div className="productName" style={{color: this.props.textColor}}>
            <span>{this.props.title}</span>
          </div>
          <div className="productPrice">
            <span>{this.props.price} {this.props.currency}</span>
          </div>
        </div>
        <div onClick={this.props.onClickNote} className="productSpecial">
          <div className="editDiv">
                <p>.</p>
                <p>.</p>
                <p>.</p>
          </div>
        </div>
      </div >
    );
  }
}

export default Product;
