import { getGlobalAuthData } from 'config/global/auth';
import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import printers from "config/global/printers";
import clone from "clone";

const approveHomeDelivery = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: commonProp.router.t("approve-all-question"),
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            let params = {
                orderNum: pageOrderTable.props.or.orderNum
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            let user = getGlobalAuthData().user
            const res = await Services.Put.approveHomeDelivery(params);
            let products = pageOrderTable.state.or.products.concat(pageOrderTable.state.or.unapproved);
            if(res.status) {
                printers.KitchenByGroup.printKitchenByGroup(commonProp.router.t,{
                    products: clone(products).map((p: any) => {          
                        p.category = pageOrderTable.props.getGlobalData.Products.find((product: any) => product._id == p.product).category;
                        p.priceName = pageOrderTable.props.getGlobalData.Products.find((product: any) => product._id == p.product).prices.find((price: any) => price._id == p.priceId).priceName
                        return p;
                    }),
                    section: 'Paket Servis',
                    user: user
                });
                pageOrderTable.props.or.products = products;
                pageOrderTable.props.or.unapproved = [];
                pageOrderTable.setState({
                    or: {
                        products: products,
                        unapproved: [],
                        cancelled: pageOrderTable.props.or.cancelled,
                        onWay: pageOrderTable.props.or.onWay,
                        paid: pageOrderTable.props.or.paid
                    }
                });
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('approve-success-all')
                })
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default approveHomeDelivery;
