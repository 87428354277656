import { Component } from "react";
import IntlTelInput from "react-intl-tel-input";
import Swal from "sweetalert2";
import { payment_types } from "constants/paymentTypes";
import { PagePropCommonDocument } from "../../../modules/views/pages/pageProps";
import Services from "../../../services/index";
import { TakeawayPayParamDocument } from "../../../modules/services/takeaway";
import { MODAL } from "constants/modalTypes";
import "../../../assets/app/styles/takeaway.css";
const ipcRenderer: any = window.require("electron").ipcRenderer;

interface ISearchParameters {
  phone: string;
  name: string;
  paidStatus: number;
  dates: {
    start: any;
    end: any;
  };
}

type PageProps = {} & PagePropCommonDocument;

type PageState = {
  searchParams: ISearchParameters;
  searchTakeaway: any;
  takeAway: any[];
  oldTakeAway: any[];
  totalCount: number;
};

class PageTakeAway extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      totalCount: 0,
      searchParams: {
        phone: "",
        name: "",
        paidStatus: -1,
        dates: {
          start: new Date().toLocaleDateString("en-CA"),
          end: new Date().toLocaleDateString("en-CA"),
        },
      },
      searchTakeaway: [],
      takeAway: [],
      oldTakeAway: [],
    };
  }

  componentDidMount(): void {
    if (
      JSON.stringify(this.state.oldTakeAway) !==
      JSON.stringify(this.props.getGlobalData.AllTakeaway)
    ) {
      this.sets();
    }
  }
  componentDidUpdate(prevProps: Readonly<PageProps>) {
    if (
      JSON.stringify(this.state.oldTakeAway) !==
      JSON.stringify(this.props.getGlobalData.AllTakeaway)
    ) {
      this.sets();
    }
  }

  sets() {
    this.setState(
      (state: PageState) => {
        state.takeAway = [];

        this.props.getGlobalData.AllTakeaway?.forEach((takeaway) => {
          let customer = this.props.getGlobalData.AllCustomers.findSingle(
            "id",
            takeaway.customer?._id
          );
          if (!customer) {
            if (!takeaway.user) return;
            takeaway.user.full_name =
              takeaway.user?.name + " " + takeaway.user?.lastname;
            takeaway.customer = takeaway.user;
          }
          state.takeAway.push({ ...takeaway, gsm_no: customer?.gsm_no });
        });
        state.searchTakeaway = state.takeAway;
        state.totalCount = this.state.searchTakeaway.length;
        state.oldTakeAway = this.props.getGlobalData.AllTakeaway;
        return state;
      },
      () => {
        this.filter();
      }
    );
  }

  filter() {
    this.setState((state: PageState) => {
      state.searchTakeaway = state.takeAway
        .filter(
          (takeAway) =>
            !takeAway.gsm_no ||
            takeAway.gsm_no.toString().search(state.searchParams.phone) > -1
        )
        .filter(
          (takeAway) =>
            takeAway.customer?.full_name
              .toLowerCase()
              .search(state.searchParams.name) > -1
        )
        .filter(
          (takeAway) =>
            state.searchParams.paidStatus == -1 ||
            takeAway.status == state.searchParams.paidStatus
        )
        .filter((takeAway) => {
          const date = new Date(takeAway.createdAt).toLocaleDateString("en-CA");
          return (
            date >= state.searchParams.dates.start &&
            date <= state.searchParams.dates.end
          );
        });
      state.totalCount = this.state.searchTakeaway.length;
      return state;
    });
  }

  searchParameters(data: any) {
    this.setState(
      (state: PageState) => {
        state.searchParams = Object.assign(state.searchParams, data);
        return state;
      },
      () => {
        this.filter();
      }
    );
  }

  selectCourier = (id: any) => {
    let selectedCourier = this.props.getGlobalData.AllCourier.findSingle(
      "_id",
      id
    );

    if (selectedCourier?.title == undefined) {
      return "Kurye silinmiş";
    } else if (selectedCourier?.title == null) {
      return "a";
    } else {
      return selectedCourier?.title;
    }
  };

  getOrderStatus(status: number) {
    let bgColor = "";
    let text = "";

    switch (status) {
      case 0:
        bgColor = "#cc3300";
        text = this.props.router.t("cancelled");
        break;
      case 1:
        bgColor = "#ffcc00";
        text = this.props.router.t("unapproved");
        break;
      case 2:
        bgColor = "#99cc33";
        text = this.props.router.t("approved");
        break;
      case 3:
        bgColor = "#42ba96";
        text = this.props.router.t("on-way");
        break;
      case 4:
        bgColor = "#339900";
        text = this.props.router.t("delivered");
        break;
      default:
        bgColor = "red";
        text = "error";
    }

    return (
      <th
        style={{
          backgroundColor: bgColor,
          cursor: "pointer",
        }}
      >
        {text}
      </th>
    );
  }

  render() {
    let today = new Date().toLocaleDateString();
    return (
      <>
        <div className="takeaway-header">
          <div className="left">
            <div className="operations">
              <button
                style={{ width: "12vw" }}
                onClick={() =>
                  this.props.router.navigate(`/takeaway/all-takeaway`, {
                    replace: true,
                  })
                }
              >
                {this.props.router.t("all-orders")}
              </button>
            </div>
            <div className="search">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>{this.props.router.t("phone-number")}</th>
                    <th>{this.props.router.t("name")}</th>
                    <th>{this.props.router.t("payment-status")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={this.state.searchParams?.phone}
                        onChange={(e: any) =>
                          this.searchParameters({ phone: e.target.value })
                        }
                        style={{ color: "black", width: "100%" }}
                      />
                    </td>
                    <td>
                      <input
                        onChange={(event: any) =>
                          this.searchParameters({
                            ...this.state.searchParams,
                            name: event.target.value.toLowerCase(),
                          })
                        }
                        style={{ color: "black" }}
                        onClick={() => {
                          ipcRenderer.send("showKeyboard");
                        }}
                      />
                    </td>
                    <td>
                      <select
                        defaultValue={-1}
                        style={{ color: "black", width: "100%", height: "3vw" }}
                        onChange={(event: any) =>
                          this.searchParameters({
                            ...this.state.searchParams,
                            paidStatus: Number(event.target.value),
                          })
                        }
                      >
                        <option value={-1}>{this.props.router.t("all")}</option>
                        <option value={4}>
                          {this.props.router.t("delivered")}
                        </option>
                        <option value={3}>
                          {this.props.router.t("on-way")}
                        </option>
                        <option value={2}>
                          {this.props.router.t("approved")}
                        </option>
                        <option value={1}>
                          {this.props.router.t("unapproved")}
                        </option>
                        <option value={0}>
                          {this.props.router.t("cancelled")}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="right">
            <button
              className="col1-2"
              onClick={() => this.props.router.navigate("/", { replace: true })}
            >
              {this.props.router.t("close")}
            </button>
            <button
              onClick={() =>
                this.props.router.navigate("/takeaway/customers", {
                  replace: true,
                })
              }
            >
              {this.props.router.t("new-order")}
            </button>
            <button
              onClick={() => this.props.router.navigate("/", { replace: true })}
            >
              {this.props.router.t("customer-free-ticket")}
            </button>
          </div>
        </div>

        <div className="takeaway-body">
          <div style={{ textAlign: "center" }}>
            {this.props.router.t("total-takeaway-quantity")}
            {this.state.totalCount}
          </div>
          <table>
            <thead>
              <tr>
                <th>{this.props.router.t("phone-number")}</th>
                <th>{this.props.router.t("name")}</th>
                <th>{this.props.router.t("courier-name")}</th>
                <th>{this.props.router.t("creation-date")}</th>
                <th>{this.props.router.t("order-status")}</th>
                <th>{this.props.router.t("payment-type")}</th>
                <th>{this.props.router.t("check")}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.searchTakeaway?.map((data: any) => {
                return today ===
                  new Date(data?.createdAt).toLocaleDateString() ? (
                  <tr>
                    <th>{data.gsm_no}</th>
                    <th>{data?.customer?.full_name}</th>
                    <th>
                      {data.courier ? this.selectCourier(data.courier) : ""}
                    </th>
                    <th>
                      {new Date(data.createdAt).toLocaleDateString("en-CA")}{" "}
                      {new Date(data.createdAt).toLocaleTimeString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </th>
                    {this.getOrderStatus(data.status)}
                    <th>
                      {data.payments.length > 0
                        ? this.props.router.t(
                            payment_types[Number(data.payments[0].type) - 1]
                          )
                        : this.props.router.t(
                            payment_types[Number(data?.defaultPaymentType) - 1]
                          )}
                    </th>
                    <th>
                      <button
                        onClick={() =>
                          this.props.openModal(MODAL.TAKEAWAY_DETAIL, data)
                        }
                      >
                        {this.props.router.t("detail")}
                      </button>
                    </th>
                  </tr>
                ) : (
                  <tr></tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default PageTakeAway;
