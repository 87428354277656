import { Box, Button, Typography } from '@mui/material';
import { GlobalLocalStorages, GlobalStates, getGlobalAuthData } from 'config/global';
import React from 'react'
import { isMacOs, isWindows } from 'react-device-detect';
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import Services from 'services/index';
import { Informations } from '../components/informations';
import { LanguageSettings } from '../components/language-settings';
import { getMacAddress } from 'utilities/helpers';
const ipcRenderer: any = window.require('electron').ipcRenderer;


export const NoPaired = () => {
    const { t } = useTranslation();

    const pairDevice = async () => {
        let mac = getMacAddress();
        if(!mac){
            console.error("Pair error");
            return;
        }
        let deviceName = ipcRenderer.sendSync("getDeviceName");
        let response = await Services.Post.pairDevice({ macAddress: mac, deviceName});
        if (response.status) {
            window.location.reload();
            return;
        }
        toast.error(t("error"));
        return;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <LanguageSettings />
            <Box>
                <Informations />
                <Typography style={{ fontSize: "1.8vw", color: "#fff", padding: 2, margin: "auto" }} textAlign="center">
                    {t('no-pair')}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "1.5vw" }}>
                <Button variant="contained" color="success" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "10vw",
                    height: "3vw",
                    marginRight: 2
                }} onClick={() => pairDevice()}>{t('yes')}</Button>
                <Button variant="contained" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "10vw",
                    height: "3vw",
                    marginLeft: 2
                }} onClick={() => {
                    GlobalLocalStorages.Token.delete();
                    GlobalLocalStorages.User.delete();
                    GlobalStates.firstNavigate = true;
                    window.location.href = '/sign-in'

                }} color="error">{t('sign-out')}</Button>
            </Box>
        </Box>
    )
}
