import { Component, useState } from "react";
import { MODAL } from "constants/modalTypes";
import "./getcreditlist.css";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Services from "services/index";
import Currencies from "constants/currencies";
import { Button, Dialog, Grid, DialogTitle, DialogContent, DialogActions, TextField, Box, Typography, Stack, FormHelperText } from "@mui/material";
import { toast } from "react-toastify";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    ticks: any[];
    showingTicks: any
    showFilterTicks: any
    checkId: string;
    editDialog: boolean;
    filterDialog: boolean;
    startDate: Date;
    endDate: Date;
};

type PageProps = {
    data: {
        tickCustomerId: string
        startDate: Date
        endDate:Date
    }
} & PagePropCommonDocument;

class Getcreditlist extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        const ticks = Services.Get.tick({
            tickCustomerId: this.props.data.tickCustomerId
        }).data;
        this.state = {
            ticks: ticks,
            showingTicks: ticks,
            showFilterTicks:ticks,
            checkId: "",
            editDialog: false,
            filterDialog: false,
            startDate: props.data.startDate === undefined ? null : props.data.startDate,
            endDate: props.data.endDate === undefined ? null : props.data.endDate
        };
    }
    componentDidMount() {
        if (this.state.startDate && this.state.endDate) {
            const filteredTicks = this.state.showingTicks.ticks.filter((tick:any) => {  
                const tickDate = new Date(tick.createdAt);
              
                return tickDate >= new Date(this.state.startDate) && tickDate <= new Date(this.state.endDate);
              });
              const showingTicksState = { ...this.state.showingTicks };
              showingTicksState.ticks= filteredTicks || []
              this.setState({
                showFilterTicks: showingTicksState
            })
        }
    }

    handleCheck(checkID: any) {
        this.setState({
            checkId: checkID
        }, () => {
            this.props.openModal(MODAL.OLD_CREDIT_CHECK, {
                checkId: this.state.checkId,
                tickCustomerId: this.props.data.tickCustomerId,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
            });
        })


    }

    Ticks = (props: any) => {
        return (
            <tr className="checkInList" style={{ "backgroundColor": props.isActive ? "red" : "green" }}>
                <th style={{ width: "10vw" }}>{new Date(props.createdAt).toLocaleString()}</th>
                <th style={{ width: "15vw" }}>{props.debt.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>
                <th style={{ width: "15vw" }}>{props.on_payment.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>
                <th style={{ width: "15vw" }}>{(props.debt - props.on_payment).toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</th>

                <th style={{ width: "15vw" }}>
                    <button
                        style={{ background: "blue" }}
                        onClick={() => this.handleCheck(props.checkId)}
                    >
                        {this.props.router.t("detail")}
                    </button>
                </th>
            </tr>
        );
    };

    EditCustomerDialog = () => {
        const tickCustomer = this.props.getGlobalData.Ticks?.find((customer: any) => customer._id == this.props.data.tickCustomerId);
        const [name, setName] = useState(tickCustomer?.name);
        const [phoneNumber, setPhoneNumber] = useState(tickCustomer?.phoneNum);
        const [discountAmount, setDiscountAmount] = useState(tickCustomer?.discountAmount || 0);
        const [loading, setLoading] = useState(false);
        const handleClose = () => {
            setName(tickCustomer?.name);
            setDiscountAmount(tickCustomer?.discountAmount || 0);
            this.setState({
                editDialog: false
            });
        }

        const handleSave = async () => {
            if (!name || !phoneNumber) {
                toast.warning(this.props.router.t('please-provide-name'));
                return;
            }
            if (discountAmount < 0 || discountAmount > 100) {
                toast.warning(this.props.router.t('discount-amount-error'));
                return;
            }
            setLoading(true);
            let params = {
                discount: discountAmount || 0,
                name: name,
                phoneNumber: phoneNumber,
                tickId: this.props.data.tickCustomerId
            }
            let response = await Services.Put.updateTickCustomer(params);
            if (response.status) {
                this.setState({
                    editDialog: false
                });
                let tickCustomers = this.props.getGlobalData.Ticks?.map((customer: any) => {
                    if (customer._id == this.props.data.tickCustomerId) {
                        customer.name = name;
                        customer.discountAmount = discountAmount;
                        customer.phoneNum = phoneNumber;
                    }
                    return customer;
                })
                this.props.setGlobalData({
                    Ticks: tickCustomers
                });
                toast.success(this.props.router.t('edit-customer-success'));
                setLoading(false);
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }
        return (
            <Dialog open={this.state.editDialog} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{ background: "red", margin: "auto", width: "70%", borderRadius: "2vw" }}>
                        <Typography sx={{ padding: "1vw", color: "#fff", fontWeight: "bold", fontSize: "1.2vw" }} textAlign="center">{this.props.router.t('edit-customer')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={3} sx={{ width: "50%", margin: "auto", marginTop: 2 }}>
                        <TextField label={this.props.router.t('name')} onClick={() => ipcRenderer.send("showKeyboard")} value={name} onChange={(e: any) => setName(e.target.value)} />
                        <TextField label={this.props.router.t('phone-number')} onClick={() => ipcRenderer.send("showKeyboard")} value={phoneNumber} onChange={(e: any) => setPhoneNumber(e.target.value)} />
                        <Stack>
                            <TextField label={`${this.props.router.t('discount')} (%)`} onClick={() => ipcRenderer.send("showKeyboard")} type="number" value={discountAmount} onChange={(e: any) => setDiscountAmount(e.target.value)} />
                            <FormHelperText>
                                {this.props.router.t('edit-discount-amount-helper')}
                            </FormHelperText>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="success" disabled={loading} onClick={handleSave}>{this.props.router.t('save')}</Button>
                    <Button variant="outlined" color="error" disabled={loading} onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    FilterDialog = () => {
        const [startDate, setStart] = useState(this.state.startDate || new Date());
        const [endDate, setEnd] = useState(this.state.endDate || new Date());
        const [loading, setLoading] = useState(false);

        const handleClose = () => {
            this.setState({
                filterDialog: false
            });
        }
        const handleFilter = () => {
            
            const filteredTicks = this.state.showingTicks.ticks.filter((tick:any) => {  
                const tickDate = new Date(tick.createdAt);
              
                return tickDate >= new Date(startDate) && tickDate <= new Date(endDate);
              });
              const showingTicksState = { ...this.state.showingTicks };
              showingTicksState.ticks= filteredTicks || []
              this.setState({
                showFilterTicks: showingTicksState,
                startDate: startDate,
                endDate: endDate
            })
            handleClose()
          }

        return (
        <Dialog open={this.state.filterDialog} onClose={handleClose} fullWidth>
            <DialogTitle>
            {this.props.router.t('filter')}
            </DialogTitle>
            <DialogContent>
            <Stack direction="column" spacing={3} sx={{ width: "50%", margin: "auto", marginTop: 2 }}>
              <TextField label= {this.props.router.t('start-date')} type="date" value={startDate} onChange={(e: any) => setStart(e.target.value)} />
              <TextField label= {this.props.router.t('end-date')} type="date" value={endDate} onChange={(e: any) => setEnd(e.target.value)} />
            </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" disabled={loading} onClick={handleFilter}>{this.props.router.t('filter')}</Button>
                <Button variant="outlined" color="error" disabled={loading} onClick={handleClose}>{this.props.router.t('cancel')}</Button>
            </DialogActions>
        </Dialog>

        )
    }

    render() {
        return (
            <>
                <this.FilterDialog />
                <this.EditCustomerDialog />
                <Box component="span" m={1} display="flex" justifyContent="space-between" alignItems="center">
                    <Button variant="contained" startIcon={<KeyboardReturnIcon />} color="error" sx={{ marginRight: 2 }} onClick={() => {
                        this.props.closeModal();
                        this.props.openModal(MODAL.GET_CREDIT_CUSTOMER);
                    }}>{this.props.router.t('back-to-list')}</Button>
                    <div>
                    <Button variant="contained" color="inherit" sx={{ marginRight: 2 }} onClick={() => this.setState({ filterDialog: true })}>{this.props.router.t('filter')}</Button>
                    <Button variant="contained" color="inherit" sx={{ marginRight: 2 }} onClick={() => this.setState({ editDialog: true })}>{this.props.router.t('edit-customer')}</Button>
                    </div>
                   
                </Box>
                <div style={{ overflowY: "auto", height: "90%" }}>
                    <table cellSpacing="0" cellPadding="0">
                        <thead>
                            <table
                                style={{ width: "70vw" }}
                                cellSpacing="0"
                                cellPadding="1">
                                <tr style={{ color: "white", background: "grey" }}>
                                    <th style={{ width: "10vw" }}>
                                        {this.props.router.t("date")}
                                    </th>
                                    <th style={{ width: "15vw" }}>
                                        {this.props.router.t("total-ticks")}
                                    </th>
                                    <th style={{ width: "15vw" }}>
                                        {this.props.router.t("paid-amount")}
                                    </th>
                                    <th style={{ width: "15vw" }}>
                                        {this.props.router.t("remaining-amount")}
                                    </th>
                                    <th style={{ width: "15vw" }}>
                                        {this.props.router.t("actions")}
                                    </th>
                                </tr>
                            </table>
                        </thead>
                        <tbody>
                            <div style={{ height: "100%", overflow: "auto" }}>
                                <table style={{ width: "70vw" }} cellSpacing="0" cellPadding="1">
                                    {this.state.showFilterTicks.ticks?
                                        this.state.showFilterTicks.ticks.map((tick: any, index: number) => (
                                            <this.Ticks {...tick} key={index} />
                                        )
                                        ):<></>
                                    }
                                </table>
                            </div>
                        </tbody>
                    </table>
                </div>

            </>
        );
    }
}

export default Getcreditlist;
