import React, { Component, useEffect, useState } from 'react'
import { PagePropCommonDocument } from 'modules/views/pages/pageProps';
import { Box, Button, Grid, Typography, Stack, AppBar, Toolbar, Avatar, Collapse, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, TextField, InputLabel, ButtonGroup, TableContainer, Table, TableRow, TableCell, TableHead, TableBody, FormHelperText } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { KeyboardReturn } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Currencies from 'constants/currencies';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import functions from 'config/global/functions';
import clone from 'clone';
import { isEqual } from 'lodash';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Swal from 'sweetalert2';
import { getGlobalAuthData } from 'config/global';
import Services from 'services/index';
import { payment_types } from 'constants/paymentTypes';
import printers from 'config/global/printers';
const ipcRenderer: any = window.require('electron').ipcRenderer;

const PaymentTypes = [
    {
        type: 1,
        name: "credit-card"
    },
    {
        type: 2,
        name: "cash"
    },
    {
        type: 6,
        name: "ticks"
    },
    {
        type: 11,
        name: "setcard"
    },
    {
        type: 7,
        name: "sodexo"
    },
    {
        type: 10,
        name: "multinet"
    },
    {
        type: 8,
        name: "smart"
    },
    {
        type: 12,
        name: "metropol"
    },
    {
        type: 20,
        name: "yemeksepeti"
    },
    {
        type: 19,
        name: "trendyol"
    },
    {
        type: 15,
        name: "jetkurye"
    },
    {
        type: 16,
        name: "not-payable"
    },
    {
        type: 17,
        name: "migros-hemen"
    },
    {
        type: 18,
        name: "getir-online"
    },
]


type PageProps = {} & PagePropCommonDocument;

type PageState = {
    products: any[];
    categories: any[];
    orders: any[];
    paidProducts: any[],
    discounts: any[];
    payments: any[];
    loading: boolean;
    selectedCategory: any;
    selectedCategories: string[]; //all categories including subcategories of the selected main category
    selectedCategoriesObjects: any[];
    currenctCategoryId: string;
    totalPrice: number;
    remainingPrice: number;
    optionDialogOpen: boolean;
    selectedProduct: any;
    productDetailOpen: boolean;
    detailedProduct: any,
    multiplier: number,
    discountDialogOpen: boolean,
    appliedDiscountsDialogOpen: boolean,
    paymentState: boolean,
    searchText: string
}



export default class CaseSale extends Component<PageProps, PageState> {
    _ismounted = false;
    interval: any;
    constructor(props: any) {
        super(props);
        this.state = {
            products: this.props.getGlobalData?.Products?.filter((p: any) => p.active_list.includes(5)),
            categories: this.props.getGlobalData?.Categories,
            orders: [],
            paidProducts: [],
            discounts: [],
            payments: [],
            loading: false,
            selectedCategory: null,
            selectedCategories: [],
            selectedCategoriesObjects: [],
            currenctCategoryId: "all",
            totalPrice: 0,
            remainingPrice: 0,
            optionDialogOpen: false,
            selectedProduct: null,
            productDetailOpen: false,
            detailedProduct: null,
            multiplier: 1,
            discountDialogOpen: false,
            appliedDiscountsDialogOpen: false,
            paymentState: false,
            searchText: ""
        }
    }

    scanProduct(barcode: string) {
        if (!barcode) return;
        let product = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == barcode);
        if (!product) {
            let formattedBarcode = barcode.split(" ")[0];
            if (!formattedBarcode) {
                toast.warning(this.props.router.t('product-not-found'));
                return;
            }
            product = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == formattedBarcode);
            if (!product) {
                formattedBarcode = barcode.slice(1, 7);
                product = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == formattedBarcode);
                if (!product) {
                    formattedBarcode = barcode.slice(0, 6);
                    product = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == formattedBarcode);
                    if (!product) {
                        if (!product) {
                            formattedBarcode = barcode.slice(0, 7);
                            product = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == formattedBarcode);
                            if (!product) {
                                toast.warning(this.props.router.t('product-not-found'));
                                return;
                            }
                            let forced = false;
                            product.options?.forEach((option: any) => {
                                if (option?.is_forced_choice) {
                                    forced = true;
                                    return;
                                }
                            });
                            if (product.sale_type == 2) {
                                let weight = barcode.split(" ")[1];
                                if (!weight) {
                                    weight = barcode.slice(7,-1);
                                    if (!weight) {
                                        toast.warning(this.props.router.t('weight-not-found'));
                                        return;
                                    }
                                }
                                this.productOnClick(product, forced, Number(weight) / 1000, true);
                                return;
                            }
                            return;
                        }
                        return;
                    }
                    let forced = false;
                    product.options?.forEach((option: any) => {
                        if (option?.is_forced_choice) {
                            forced = true;
                            return;
                        }
                    });
                    if (product.sale_type == 2) {
                        let weight = barcode.split(" ")[1];
                        if (!weight) {
                            weight = barcode.slice(7,-1);
                            if (!weight) {
                                toast.warning(this.props.router.t('weight-not-found'));
                                return;
                            }
                        }
                        this.productOnClick(product, forced, Number(weight) / 1000, true);
                        return;
                    }
                    return;
                }
            }
            let forced = false;
            product.options?.forEach((option: any) => {
                if (option?.is_forced_choice) {
                    forced = true;
                    return;
                }
            });
            if (product.sale_type == 2) {
                let weight = barcode.split(" ")[1];
                if (!weight) {
                    weight = barcode.slice(7,-1);
                    if (!weight) {
                        toast.warning(this.props.router.t('weight-not-found'));
                        return;
                    }
                }
                this.productOnClick(product, forced, Number(weight) / 1000, true);
                return;
            }
        }
        let forced = false;
        product.options?.forEach((option: any) => {
            if (option?.is_forced_choice) {
                forced = true;
                return;
            }
        });
        this.productOnClick(product, forced);
    }

    componentDidMount() {
        var code = "";
        this._ismounted = true;
        window.addEventListener("keydown", (e: any) => {
            if (!this._ismounted) return;
            if (this.interval) clearInterval(this.interval);
            if (e.code == "Enter" || e.code == "NumpadEnter") {
                if (code) {
                    this.scanProduct(code);
                }
                code = ""
                return;
            }
            if (e.code != "Shift") {
                code = code + String(e.key);
            }
            this.interval = setInterval(() => code = "", 20);
        })
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    categoryOnClick = async (category: any) => {
        if (category == "all") {
            this.setState({
                currenctCategoryId: "all",
                selectedCategories: [],
                selectedCategoriesObjects: []
            });
            return;
        }
        let check = this.state.selectedCategories?.findIndex((c: any) => c == category._id);
        if (check > -1) {
            let sCategories = this.state.selectedCategories;
            let oCategories = this.state.selectedCategoriesObjects;
            sCategories.splice(check, 1);
            oCategories.splice(check, 1);

            this.setState({
                selectedCategories: category.is_sub_category ? sCategories : [],
                selectedCategoriesObjects: oCategories
            });
            return;
        }
        let index = this.state.selectedCategoriesObjects?.findIndex((c: any) => c.parent_category == category.parent_category)
        let sCategories = this.state.selectedCategories;
        if (index > -1) {
            sCategories.remove(index);
            await this.setState({
                selectedCategories: sCategories
            });
        }
        this.setState({
            selectedCategories: category.is_sub_category ? this.state.selectedCategories?.concat(category._id) : [category._id],
            selectedCategoriesObjects: category.is_sub_category ? this.state.selectedCategoriesObjects?.concat(category) : [category],
            currenctCategoryId: category._id
        });
    }

    addNewOrder = (product: any, priceId: string, note?: string) => {
        product = clone(product);
        let price = product.prices?.find((p: any) => p._id == priceId);
        let defaultCurrency = this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL";
        if (defaultCurrency != price.currency) {
            if (!this.props.getGlobalData?.CurrencySettings?.defaultCurrency) {
                toast.warning(this.props.router.t('currency-features-info'));
                return;
            }
            let exchange = 1;
            switch (price.currency) {
                case "TL":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies.TL;
                    break;
                case "USD":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies.USD;
                    break;
                case "EUR":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies.EUR;
                    break;
                case "GBP":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies.GBP;
                    break;
                default:
                    break;

            }
            price.price = price.price * exchange;
        }
        product.options.map((opt: any) => {
            opt.linked?.items?.map((item: any) => {
                price.price += item.price
            });
        });
        let orderId = functions.createUUID();
        product.quantity = product.quantity * this.state.multiplier;
        let order = {
            _id: orderId,
            image: product.image,
            productName: product.title,
            productId: product._id,
            category: product.category,
            price: price.price * product.quantity,
            priceId: priceId,
            priceName: price.priceName,
            quantity: product.quantity,
            options: product.options,
            note: note || ""
        }
        let index = this.state.orders?.findIndex((o: any) => isEqual({ ...o, _id: null, quantity: null, price: null }, { ...order, _id: null, quantity: null, price: null }));
        this.setState((state: PageState) => {
            state.totalPrice += price.price * product.quantity;
            state.remainingPrice += price.price * product.quantity;
            if (index > -1) {
                state.orders[index].quantity += Number(product.quantity);
                state.orders[index].price += price.price * product.quantity;
                return state;
            }
            state.orders.push(order);
            return state;
        })
    }

    productOnClick = (product: any, isForced?: boolean, weight?: number, barcode?: boolean) => {
        if (!barcode && !isForced) {
            let forced = isForced || false;
            product.options?.map((opt: any) => {
                if (opt.is_forced_choice) forced = true;
                opt.linked = this.props.getGlobalData?.ProductOptions?.find((o: any) => o._id == opt.option_id);
            });
            if (product.sale_type == 2) forced = true;
            if (product.prices?.length > 1) forced = true;
            if (forced) {
                this.setState({
                    optionDialogOpen: true,
                    selectedProduct: product
                });
                return;
            }
        }
        this.addNewOrder({ ...product, options: [], quantity: weight || product.prices[0].amount }, product.prices[0]._id);
    }

    hasSubCategories = (category: any) => {
        return this.state.categories?.filter((c: any) => c.parent_category == category?._id)?.length > 0
    }

    SubCategories = (category: any) => {
        return (
            <>
                {this.state.categories?.filter((c: any) => c.parent_category == category?._id)?.map((c: any) => (
                    <Stack direction="column" spacing={1} alignItems="center">
                        <Button sx={{ backgroundColor: this.state.selectedCategories?.includes(c._id) ? "#A9A9A9" : "#DEDEDE", "&:hover": { backgroundColor: "#BBBBBB" }, width: "8vw", height: "3vw", fontSize: "0.7vw", fontWeight: "bold", textAlign: "center" }} onClick={() => this.categoryOnClick(c)} variant='contained' color='inherit' endIcon={this.hasSubCategories(c) ? this.state.selectedCategories?.includes(c._id) ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}>{c.title}</Button>
                        <Collapse in={this.state.selectedCategories?.includes(c._id)}>
                            {this.SubCategories(c)}
                        </Collapse>
                    </Stack>
                ))}
            </>
        )
    }

    getSubCategories = (category: string) => {
        let subCategories = this.state.categories.filter((c: any) => c.parent_category == category)

        subCategories.forEach((c: any) => {
            subCategories = [...subCategories, ...this.getSubCategories(c._id)]
        })

        return subCategories;
    }

    Categories = () => {

        return (
            <>
                {this.state.categories?.filter((category: any) => !category.is_sub_category)?.map((category) => (
                    <Stack direction="column" spacing={1} alignItems="center">
                        <Button sx={{ backgroundColor: this.state.selectedCategories?.includes(category._id) ? "#A9A9A9" : "#DEDEDE", "&:hover": { backgroundColor: "#BBBBBB" }, width: "8vw", height: "3vw", fontSize: "0.7vw", fontWeight: "bold", textAlign: "center" }} onClick={() => this.categoryOnClick(category)} variant='contained' color='inherit' endIcon={this.hasSubCategories(category) ? this.state.selectedCategories?.includes(category._id) ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null}>{category.title}</Button>
                        <Collapse in={this.state.selectedCategories?.includes(category._id)}>
                            {this.SubCategories(category)}
                        </Collapse>
                    </Stack>
                ))}
            </>
        )
    }

    Products = () => {
        return (
            <Box className="products" sx={{ overflowY: "scroll", height: "83vh", width: "40vw", padding: 2 }}>
                <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {this.state.products?.filter((product: any) => this.state.currenctCategoryId == "all" || this.getSubCategories(this.state.currenctCategoryId)?.map((c: any) => c._id)?.concat([this.state.currenctCategoryId]).includes(product.category)).filter(item => item.title?.toString()?.toLowerCase()?.search(this.state.searchText.toLowerCase()) > -1).map((product: any, i: number) => (
                        <Grid item xs={2} sm={3} md={4} key={i}>
                            <Box sx={{ borderRadius: 6, borderTopRightRadius: 0, borderTopLeftRadius: 0, border: "1px solid white", boxShadow: "5px 5px 5px black", backgroundImage: "linear-gradient(#fff, #C8C7C7)" }} onClick={() => this.productOnClick(product)}>
                                <Stack direction="column" spacing={2}>
                                    <Avatar variant='square' src={product.image} sx={{ width: "100%", height: "10vh" }} />
                                    <Box className="text" sx={{ pading: 1 }}>
                                        <Typography textAlign="center" sx={{ fontSize: "1vw", fontWeight: "bold" }}>{product.title}</Typography>
                                        <Typography textAlign="center" sx={{ fontSize: "0.9vw" }}>{product.prices[0]?.price} {Currencies[product.prices[0]?.currency] || <>₺</>}</Typography>
                                    </Box>
                                    <Button variant='contained' onClick={(e: any) => {
                                        e.stopPropagation();
                                        this.productOnClick(product, true);
                                    }} color="inherit" sx={{ backgroundColor: "transparent", borderRadius: 6, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}><EditIcon /></Button>
                                </Stack>

                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }

    OptionDialog = () => {
        const [priceId, setPriceId] = useState(this.state.selectedProduct?.prices[0].priceId);
        const [options, setOptions]: any = useState([]);
        const [note, setNote] = useState("");
        const [selectedNote, setSelectedNote] = useState("");
        const [weight, setWeight] = useState(1);

        const handleClose = () => {
            this.setState({
                optionDialogOpen: false,
                selectedProduct: null
            });
        }

        const getWeight = () => {
            let weight = ipcRenderer.sendSync('getWeight');
            setWeight(Number(weight));
        }


        const getSelectedCount = (option_id: string) => {
            let index = options.findIndex((opt: any) => opt.option_id == option_id);
            if (index > -1) {
                return options[index]?.linked?.items?.length
            }
            return 0;
        }

        const handleOptionSelect = (option: any, item: any) => {
            let tempOptions = clone(options);
            let index = tempOptions.findIndex((opt: any) => opt.option_id == option.option_id);
            if (index > -1) {
                let itemIndex = tempOptions[index]?.linked?.items?.findIndex((i: any) => i._id == item._id);
                if (itemIndex > -1) {
                    tempOptions[index]?.linked?.items.remove(itemIndex);
                    if (tempOptions[index]?.linked?.items?.length == 0) {
                        tempOptions.remove(index);
                    }
                    setOptions(tempOptions);
                    return;
                }
                if (tempOptions[index]?.linked?.items?.length == option?.linked?.choose_limit && !option?.linked?.unlimitedChoice) {
                    toast.error(this.props.router.t('max-product'));
                    return;
                }
                tempOptions[index]?.linked?.items?.push(item);
                setOptions(tempOptions);
                return;
            }
            let tempOption = clone(option);
            tempOption.linked.items = [item];
            tempOptions.push(tempOption);
            setOptions(tempOptions);
        }

        const checkOptionSelected = (option: any, item: any) => {
            let index = options.findIndex((opt: any) => opt.option_id == option.option_id);
            if (index > -1) {
                let itemIndex = options[index]?.linked?.items?.findIndex((i: any) => i._id == item._id);
                if (itemIndex > -1) return true;
                return false;
            }
            return false;
        }

        useEffect(() => {
            setPriceId(this.state.selectedProduct?.prices[0]._id);
        }, [])

        const handleSubmit = () => {
            this.addNewOrder({ ...this.state.selectedProduct, options: options, quantity: weight ? weight : 1 }, priceId, note);
            this.setState({
                selectedProduct: null,
                optionDialogOpen: false
            });
        }

        return (
            <Dialog open={this.state.optionDialogOpen} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <Box sx={{ padding: "1vw", margin: "auto", width: "60%", backgroundImage: "linear-gradient(#9A9999, #C06E6E)", borderRadius: 4 }}>
                        <Typography variant="h6" fontWeight="bold" textAlign="center">{this.props.router.t('product-options')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ overflowY: "scroll" }}>
                    <Stack direction="row" spacing={2} justifyContent="space-around">
                        <Stack direction="column" spacing={5}>
                            <Box className="prices">
                                <Typography variant='h5' fontWeight="bold" sx={{ marginBottom: "0.8vw" }}>{this.props.router.t('prices')}</Typography>
                                <Stack direction="row" spacing={1}  >
                                    {this.state.selectedProduct?.prices?.map((price: any, i: number) => (
                                        <Stack key={i} direction="column" alignItems="center">
                                            <Typography>{price.priceName}</Typography>
                                            <Button onClick={() => setPriceId(price._id)} variant="contained" color={priceId == price._id ? "success" : "info"} sx={{ borderRadius: 5 }}>{price.price} {price.currency}</Button>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Box>
                            <Box className="note">
                                <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "0.8vw" }}>{this.props.router.t('note')}</Typography>
                                <Stack direction="row" alignItems="center" spacing={3}>
                                    <Box>
                                        <InputLabel id="noteId">{this.props.router.t('saved-notes')}</InputLabel>
                                        <Select value={selectedNote} onChange={(e: any) => {
                                            let note = this.props.getGlobalData?.Notes?.find((note: any) => note._id == e.target.value);
                                            setNote(note.note);
                                            setSelectedNote(e.target.value);
                                        }} sx={{ width: "10vw" }} labelId='noteId'>
                                            {this.props.getGlobalData.Notes?.map((note: any) => (
                                                <MenuItem value={note._id}>{note.title}</MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                    <Box>
                                        <InputLabel>{this.props.router.t('note')}</InputLabel>
                                        <TextField value={note} onChange={(e: any) => setNote(e.target.value)} onClick={() => ipcRenderer.send('showKeyboard')} sx={{ width: "12vw" }} />
                                    </Box>
                                </Stack>
                            </Box>
                            {this.state.selectedProduct?.sale_type == 2 ? (
                                <Box className="weight">
                                    <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: "0.8vw" }}>{this.props.router.t('weight')}</Typography>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <TextField type="number" value={weight} onChange={(e: any) => setWeight(Number(e.target.value))} onClick={() => ipcRenderer.send('showKeyboard')} label={this.props.router.t('weight')} />
                                        <Button sx={{ fontSize: "0.8vw" }} variant="contained" onClick={getWeight} color="success">{this.props.router.t('get-weight-from-scales')}</Button>
                                    </Stack>
                                </Box>
                            ) : null}

                        </Stack>
                        {this.state.selectedProduct?.options?.length > 0 ? (
                            <Box className="options">
                                <Typography variant='h5' fontWeight="bold" sx={{ marginBottom: "0.8vw" }}>{this.props.router.t('options')}</Typography>
                                <Stack direction="column" spacing={2}>
                                    {this.state.selectedProduct?.options?.map((option: any) => (
                                        <Box>
                                            <Typography fontWeight="bold" variant="h6">{option.linked?.name}{option.is_forced_choice ? (<sup style={{ color: "red", fontWeight: "bold", fontSize: "1.1vw" }}>*</sup>) : null} <span>({getSelectedCount(option.option_id)}/{option?.linked?.unlimitedChoice ? option?.linked?.items?.length : option?.linked?.choose_limit})</span></Typography>
                                            <Grid container sx={{ width: "40vw" }} spacing={{ md: 2 }} columns={{ xs: 4, sm: 4, md: 12 }}>
                                                {option.linked?.items?.map((item: any, i: number) => (
                                                    <>
                                                        <Grid item xs={1} sm={1} md={4} key={i}>
                                                            <Button onClick={(() => handleOptionSelect(option, item))} color={checkOptionSelected(option, item) ? "success" : "error"} sx={{ fontSize: "0.9vw", borderRadius: 3, width: "8vw", height: "3vw" }} variant="contained">{item.item_name} (+{item.price})</Button>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="success" variant="contained" onClick={handleSubmit}>{this.props.router.t('add')}</Button>
                    <Button color="error" variant="outlined" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    Orders = () => {
        let defaultCurrency = localStorage.getItem('defaultCurrency') || "TL";

        const handleQuantity = (index: any, action: string) => {
            this.setState((state: PageState) => {
                if (index <= -1) {
                    return state;
                }
                let unitPrice = state.orders[index].price / state.orders[index].quantity;
                switch (action) {
                    case "increase":
                        state.orders[index].price += unitPrice;
                        state.totalPrice += unitPrice;
                        state.remainingPrice += unitPrice;
                        state.orders[index].quantity++;

                        break;
                    case "decrease":
                        if (state.orders[index].quantity <= 1) {
                            handleQuantity(index, 'remove');
                            break;
                        }
                        state.orders[index].price -= unitPrice;
                        state.totalPrice -= unitPrice;
                        state.remainingPrice -= unitPrice;
                        state.orders[index].quantity--;
                        break;
                    case "remove":
                        state.totalPrice -= state.orders[index].price;
                        state.remainingPrice -= state.orders[index].price;
                        state.orders.remove(index);
                        break;
                    default:
                        break;
                }
                if (state.totalPrice < 0) state.totalPrice = 0;
                if (state.remainingPrice < 0) state.remainingPrice = 0;
                return state;
            })
        }


        const calculateExchange = (price: number, currency: string) => {
            let exchange = 1;
            switch (currency) {
                case "TL":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.TL;
                    break;
                case "USD":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.USD;
                    break;
                case "EUR":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.EUR;
                    break;
                case "GBP":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.GBP;
                    break;
                default:
                    break;
            }
            return `${price / exchange} ${Currencies[currency]}`
        }

        return (
            <Box className="orders" sx={{ backgroundImage: "linear-gradient(#fff, #C8C7C7)", width: "44vw", height: "65vh", borderRadius: 6, padding: 2 }}>
                <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3 }}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant='h6' fontWeight="bold">{this.props.router.t('orders')}</Typography>
                        <Typography variant='h6' fontWeight="bold">{this.props.router.t('total-amount')}: {this.state.totalPrice?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                    </Stack>
                </Box>
                <Box className="content" sx={{ marginTop: "1vw", overflowY: "scroll", height: "50vh", padding: 2 }}>
                    <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {this.state.orders?.map((product: any, i: number) => {
                            let currency = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.productId)?.prices?.find((price: any) => price._id == product.priceId)?.currency;

                            return (
                                <Grid item xs={2} sm={3} md={3} key={i}>
                                    <Box onClick={() => {
                                        this.setState({
                                            detailedProduct: product,
                                            productDetailOpen: true
                                        });
                                    }} sx={{ position: "relative", borderRadius: 6, borderTopRightRadius: 0, borderTopLeftRadius: 0, border: "1px solid white", boxShadow: "5px 5px 5px black", backgroundImage: "linear-gradient(#fff, #C8C7C7)" }}>
                                        <DeleteIcon onClick={(e: any) => {
                                            e.stopPropagation();
                                            handleQuantity(i, 'remove');
                                        }} sx={{
                                            position: "absolute", right: 0, zIndex: 2, textShadow:
                                                "0.07em 0 black", cursor: "pointer"
                                        }} />
                                        <Stack direction="column" spacing={2}>
                                            <Avatar variant='square' src={product.image} sx={{ width: "100%", height: "10vh" }} />
                                            <Box className="text" sx={{ pading: 1 }}>
                                                <Typography textAlign="center" sx={{ fontSize: "1vw", fontWeight: "bold" }}>{product.productName}</Typography>
                                                <Typography textAlign="center" sx={{ fontSize: "0.9vw" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>} {currency != defaultCurrency ? (<>
                                                    ({calculateExchange(product.price, currency)})
                                                </>) : null}</Typography>
                                                {product.options?.length > 0 || product?.note ? (
                                                    <Box>
                                                        <Typography sx={{ fontSize: "1.2vw" }} textAlign="center">...</Typography>
                                                    </Box>
                                                ) : null}
                                            </Box>

                                            <Stack direction="row" justifyContent="center" alignItems="center" spacing="0.2vw">
                                                <IconButton onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    handleQuantity(i, 'decrease');
                                                }}>{<RemoveIcon sx={{ fontSize: "1.5vw" }} />}</IconButton>
                                                <Typography sx={{ fontSize: "1vw" }}>{product.quantity?.toFixed(2)}</Typography>
                                                <IconButton onClick={(e: any) => {
                                                    e.stopPropagation();
                                                    handleQuantity(i, 'increase');
                                                }}>{<AddIcon sx={{ fontSize: "1.5vw" }} />}</IconButton>
                                            </Stack>
                                        </Stack>

                                    </Box>
                                </Grid>
                            )
                        }
                        )}
                    </Grid>
                </Box>
            </Box>
        )
    }
    ProductDetailDialog = () => {

        const handleClose = () => {
            this.setState({
                productDetailOpen: false,
                detailedProduct: null
            })
        }

        return (
            <Dialog open={this.state.productDetailOpen} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", width: "60%", margin: "auto", padding: 2, borderRadius: 6 }}>
                        <Typography textAlign="center" variant="h5" fontWeight="bold">{this.state.detailedProduct?.productName}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" justifyContent="space-around" spacing={2}>
                        <Stack direction="column" spacing={3}>
                            <span><span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>{this.props.router.t('price-name')}:</span> {this.state.detailedProduct?.priceName}</span>
                            <span><span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>{this.props.router.t('price')}:</span> {this.state.detailedProduct?.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</span>
                            <span><span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>{this.props.router.t('quantity')}:</span> {this.state.detailedProduct?.quantity?.toFixed(2)}</span>
                            {this.state.detailedProduct?.note ? (
                                <>
                                    <span><span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>{this.props.router.t('note')}:</span> {this.state.detailedProduct?.note}</span>
                                </>
                            ) : null}
                        </Stack>
                        {this.state.detailedProduct?.options?.length > 0 ? (
                            <Box className="options">
                                <Typography variant='h5' fontWeight="bold" sx={{ marginBottom: "0.8vw" }}>{this.props.router.t('options')}</Typography>
                                <Stack direction="column" spacing={2}>
                                    {this.state.detailedProduct?.options?.map((option: any) => (
                                        <Box>
                                            <Typography fontWeight="bold" variant="h6">{option.linked?.name} </Typography>
                                            <Grid container sx={{ width: "30vw" }} spacing={{ md: 3 }} columns={{ xs: 4, sm: 4, md: 8 }}>
                                                {option.linked?.items?.map((item: any, i: number) => (
                                                    <>
                                                        <Grid item xs={1} sm={1} md={4} key={i}>
                                                            <Button color="success" sx={{ fontSize: "0.9vw", borderRadius: 3, width: "8vw", height: "3vw" }} variant="contained">{item.item_name} (+{item.price})</Button>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        ) : null}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose}>{this.props.router.t('close')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    handlePrint = () => {
        if (this.state.orders?.length == 0) {
            toast.warning(this.props.router.t('please-add-product'));
            return;
        }
        let printData = {
            table: `${this.props.router.t('case-sale')}-${this.props.router.t('case-sale')}`,
            checkNo: this.props.router.t('case-sale'),
            discount: this.state.discounts,
            tableInfo: this.state,
            cover: [],
            orders: [
                {
                    orderNum: this.props.router.t('case-sale'),
                    products: this.state.orders
                }
            ],
            total: {
                amount: this.state.totalPrice,
                currency: this.props.getGlobalData?.CurrencySettings
                    ?.defaultCurrency || "TL",
            }
        }
        printers.ProductsInOrder.printProductsInOrder(printData,this.props.getGlobalData?.Products,this.props.router.t);
        toast.success(this.props.router.t('print-product-success'));

    }

    DiscountDialog = () => {
        const [type, setType] = useState(0);
        const [amount, setAmount]: any = useState(null);
        const [title, setTitle] = useState("");
        const [activeTab, setActiveTab] = useState("description");
        const handleTabClick = (tabName:any) => {
            setTitle("")
            setActiveTab(tabName);
          }
          
        const handleClose = () => {
            setType(0);
            setAmount(null);
            setTitle("");
            this.setState({
                discountDialogOpen: false
            });
        }

        const handleSubmit = () => {
 
            if (!amount || !title) {
                toast.warning(this.props.router.t('please-specify-details'));
                return;
            }
            let formattedAmount = type == 0 ? (this.state.totalPrice * amount) / 100 : amount;
            if (this.state.remainingPrice < formattedAmount) {
                toast.warning(this.props.router.t('discount-price-error'));
                return;
            }
            let noteS = title
            if(activeTab === "saved-notes" && title){
             noteS = this.props.getGlobalData?.Notes?.find((_)=> _._id === title).title
            }
            if (noteS.length == 0) {
                toast.warning(this.props.router.t('please-specify-details'));
                return;
            }
            let tempDiscounts = clone(this.state.discounts);
            tempDiscounts.push({
                type: type,
                note: noteS,
                amount: formattedAmount,
                user: getGlobalAuthData().user._id
            });
            this.setState({
                discounts: tempDiscounts,
                totalPrice: this.state.totalPrice - formattedAmount,
                remainingPrice: this.state.remainingPrice - formattedAmount
            });
            toast.success(this.props.router.t('discount-success'));
            handleClose();
        }
        return (
            <Dialog open={this.state.discountDialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{ padding: "1vw", margin: "auto", width: "60%", backgroundImage: "linear-gradient(#9A9999, #C06E6E)", borderRadius: 4 }}>
                        <Typography variant='h4' textAlign="center">{this.props.router.t('apply-discount')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Stack direction="column" spacing={3} alignItems="center">
                            <Box className="description">
                            <Box className="descriptionTitle" style={{ color: activeTab === "description"? "black":"darkmagenta"}} onClick={() => handleTabClick("description")}>
                            {this.props.router.t("description")}</Box>
                            <Box className="descriptionTitle" style={{ color: activeTab === "saved-notes"? "black":"darkmagenta"}} onClick={() => handleTabClick("saved-notes")}>
                            {this.props.router.t("saved-notes")}</Box>
                            </Box>
                            { activeTab === "description" ?
                            <TextField value={title} onChange={(e: any) => setTitle(e.target.value)} label={this.props.router.t('_title')} onClick={() => ipcRenderer.send('showKeyboard')} /> :
                            <Select sx={{ minWidth: "100%" }} labelId="noteLabel" value={title} onChange={(e: any) => {
                                setTitle(e.target.value)
                               }}>
                                   {this.props.getGlobalData?.Notes?.map((note: any) => (
                                       <MenuItem value={note._id}>
                                           {note.title}
                                       </MenuItem>
                                   ))}
                               </Select>}
                            <Stack direction="row" spacing={2} alignItems="center">
                                <TextField type="number" value={amount} onChange={(e: any) => setAmount(Number(e.target.value))} label={this.props.router.t('_amount')} onClick={() => ipcRenderer.send('showKeyboard')} />
                                <Box>
                                    <ButtonGroup>
                                        <Button sx={{ width: "6vw", height: "3vw" }} variant='contained' color={type == 0 ? "success" : "info"} onClick={() => setType(0)}>(%)</Button>
                                        <Button sx={{ width: "6vw", height: "3vw" }} variant='contained' color={type == 1 ? "success" : "info"} onClick={() => setType(1)}>{this.props.router.t('quantity')}</Button>
                                    </ButtonGroup>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="success" onClick={handleSubmit}>{this.props.router.t('save')}</Button>
                    <Button variant='outlined' color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    AppliedDiscountsDialog = () => {
        const handleDelete = (index: number) => {
            let tempDiscounts = clone(this.state.discounts);
            let discount = clone(tempDiscounts[index]);
            tempDiscounts.remove(index);
            this.setState({
                totalPrice: this.state.orders?.length != 0 ? this.state.totalPrice + discount.amount : 0,
                remainingPrice: this.state.orders?.length != 0 ? this.state.remainingPrice + discount.amount : 0,
                discounts: tempDiscounts,
                appliedDiscountsDialogOpen: false
            });
            toast.success(this.props.router.t('discount-deleted'));
        }

        return (
            <Dialog open={this.state.appliedDiscountsDialogOpen} onClose={() => this.setState({ appliedDiscountsDialogOpen: false })} fullWidth>
                <DialogTitle>
                    <Box sx={{ padding: "1vw", margin: "auto", width: "60%", backgroundImage: "linear-gradient(#9A9999, #C06E6E)", borderRadius: 4 }}>
                        <Typography variant='h4' textAlign="center">{this.props.router.t('applied-discounts')}</Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ display: "flex", justifyContent: "center" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.router.t('_title')}</TableCell>
                                    <TableCell>{this.props.router.t('_amount')}</TableCell>
                                    <TableCell>{this.props.router.t('type')}</TableCell>
                                    <TableCell>{this.props.router.t('actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.discounts?.map((discount: any, i: number) => (
                                    <TableRow key={i}>
                                        <TableCell>{discount.note}</TableCell>
                                        <TableCell>{discount.amount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        <TableCell>{discount.type == 0 ? this.props.router.t('percent') : this.props.router.t('quantity')}</TableCell>
                                        <TableCell><IconButton onClick={() => handleDelete(i)}><DeleteIcon /></IconButton></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color="error" onClick={() => this.setState({ appliedDiscountsDialogOpen: false })}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    Navbar = () => {
        return (
            <AppBar sx={{ backgroundColor: "#2F353E", padding: 2 }}>
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "60%" }}>
                        <Button variant='contained' sx={{ height: "3vw", width: "7vw", fontSize: "1vw" }} onClick={() => {
                            if (this.state.orders?.length == 0) {
                                this.props.router.navigate("/", { replace: true })
                                return;
                            }
                            Swal.fire({
                                icon: "question",
                                title: this.props.router.t('sure-cancel'),
                                showConfirmButton: true,
                                showCancelButton: true,
                                cancelButtonText: this.props.router.t('no'),
                                confirmButtonText: this.props.router.t('yes')
                            }).then(res => {
                                if (res.isConfirmed) {
                                    this.props.router.navigate("/", { replace: true })
                                }
                            })
                        }} startIcon={<KeyboardReturn />} color="error">{this.props.router.t('back')}</Button>
                        <Box sx={{ width: "25vw", margin: "auto", marginLeft: "2vw", marginRight: "2vw", backgroundColor: "#3F4E6D", padding: "0.7vw", borderRadius: "8px" }}>
                            <Typography textAlign="center" variant="h4" sx={{ fontWeight: "bold", fontFamily: "serif", fontSize: "1.8vw" }}>{this.props.router.t('case-sale')} {this.state.paymentState ? ` - ${this.props.router.t('payTable')}` : null}</Typography>
                        </Box>
                        {this.state.paymentState ? null : (
                            <Box>
                                <TextField onClick={() => ipcRenderer.send('showKeyboard')} variant='outlined' placeholder={this.props.router.t('search')} value={this.state.searchText} onChange={(e: any) => this.setState({ searchText: e.target.value })} sx={{ width: "20vw", borderRadius: 4, fontSize: "0.9vw", "& .MuiOutlinedInput-root": { "& input": { borderRadius: 4 }, "& fieldset": { borderRadius: 4 }, backgroundColor: "#fff", borderRadius: 4, "&:hover": { backgroundColor: "#fff" } } }} />
                            </Box>
                        )}
                        <Box>
                            {this.state.paymentState ? (
                                <Box sx={{ width: "25vw", margin: "auto", marginLeft: "2vw", marginRight: "2vw", backgroundColor: "#3F4E6D", padding: "0.7vw", borderRadius: "8px" }}>
                                    <Typography textAlign="center" variant="h4" sx={{ fontWeight: "bold", fontFamily: "serif", fontSize: "1.8vw" }}>
                                        {this.props.router.t('total-amount')}: {this.state.totalPrice?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
                                    </Typography>
                                </Box>

                            ) : (

                                <Stack direction="row" spacing={1}>
                                    <Button variant='contained' color={this.state.multiplier == 1 ? "success" : "info"} onClick={() => this.setState({ multiplier: 1 })} sx={{ width: "4vw", height: "2.5vw", textTransform: "lowercase" }}>&#215;1</Button>
                                    <Button variant='contained' color={this.state.multiplier == 2 ? "success" : "info"} onClick={() => this.setState({ multiplier: 2 })} sx={{ width: "4vw", height: "2.5vw" }}>&#215;2</Button>
                                    <Button variant='contained' color={this.state.multiplier == 3 ? "success" : "info"} onClick={() => this.setState({ multiplier: 3 })} sx={{ width: "4vw", height: "2.5vw" }}>&#215;3</Button>
                                    <Button variant='contained' color={this.state.multiplier == 4 ? "success" : "info"} onClick={() => this.setState({ multiplier: 4 })} sx={{ width: "4vw", height: "2.5vw" }}>&#215;4</Button>
                                    <Button variant='contained' color={this.state.multiplier == 5 ? "success" : "info"} onClick={() => this.setState({ multiplier: 5 })} sx={{ width: "4vw", height: "2.5vw" }}>&#215;5</Button>
                                </Stack>
                            )}

                        </Box>
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }

    Payment = () => {
        const [orders, setOrders]: any = useState([]); //orders
        const [paymentType, setPaymentType] = useState(1); //selected payment type
        const [onPayment, setOnPayment]: any = useState([]); //to be paid products
        const [priceBePaid, setPrice] = useState(0);  //will pay total price
        const [manualAmount, setManualAmount] = useState(0);
        const [showKeyboard, setKeyboard] = useState(false);
        const [paymentDialogOpen, setPaymentDialog] = useState(false);
        const [tipDialogOpen, setTipDialog] = useState(false);
        const [tickCustomerOpen, setTickCustomerDialog] = useState(false);
        const [specialCustomerOpen, setSpecialCustomerDialog] = useState(false);

        let defaultCurrency = localStorage.getItem('defaultCurrency') || "TL";

        const filterProducts = () => {
            let products = clone(this.state.orders);
            products.map((p: any, i: number) => {
                let index = this.state.paidProducts?.findIndex((o: any) => o._id == p._id);
                if (index > -1) {
                    let paidProduct = this.state.paidProducts[index];
                    if (p.quantity == paidProduct.quantity) {
                        delete products[i];
                    } else {
                        p.quantity -= paidProduct.quantity;
                        p.price -= paidProduct.price;
                    }
                }
            });
            setOrders(products.filter((p: any) => p != null));
            return products.filter((p: any) => p != null);
        }

        useEffect(() => {
            filterProducts();
        }, [])

        const handleQuantity = async (index: any, action: string, all: boolean) => {
            setManualAmount(0);
            switch (action) {
                case "order":
                    let products = clone(orders);
                    let product = clone(products[index]);
                    let tempOnPayment = clone(onPayment);

                    if (all) {

                        //orders edit
                        products.remove(index);
                        setPrice(priceBePaid + product.price);
                        setOrders(products);

                        //to be paid products edit
                        let pIndex = tempOnPayment.findIndex((p: any) => p._id == product._id);
                        if (pIndex > -1) {
                            tempOnPayment[pIndex].quantity += product.quantity;
                            tempOnPayment[pIndex].price += product.price;
                            setOnPayment(tempOnPayment);
                            return;
                        }
                        tempOnPayment.push(product);
                        setOnPayment(tempOnPayment);
                        return;
                    }
                    let unitPrice = product.price / product.quantity;

                    //orders edit
                    if (products[index].quantity <= 1) {
                        setPrice(priceBePaid + product.price);
                        products.remove(index);
                    } else {
                        products[index].price -= unitPrice;
                        products[index].quantity--;
                        setPrice(priceBePaid + unitPrice);
                    }
                    setOrders(products);

                    //to be paid products edit
                    let findIndex = tempOnPayment?.findIndex((p: any) => p._id == product._id);
                    if (findIndex > -1) {
                        tempOnPayment[findIndex].quantity++;
                        tempOnPayment[findIndex].price += unitPrice;
                        setOnPayment(tempOnPayment);
                        return;
                    }
                    tempOnPayment.push({
                        ...product,
                        quantity: 1,
                        price: unitPrice
                    });
                    setOnPayment(tempOnPayment)
                    break;
                case "onPayment":
                    let onPaymentProducts = clone(onPayment);
                    let onPaymentProduct = clone(onPaymentProducts[index]);
                    let tempOrders = clone(orders);

                    if (all) {

                        //to be paid products edit
                        onPaymentProducts.remove(index);
                        setPrice(priceBePaid - onPaymentProduct.price);
                        setOnPayment(onPaymentProducts);

                        let tOrderIndex = tempOrders.findIndex((p: any) => p._id == onPaymentProduct._id);
                        if (tOrderIndex > -1) {
                            tempOrders[tOrderIndex].quantity += onPaymentProduct.quantity;
                            tempOrders[tOrderIndex].price += onPaymentProduct.price;
                            setOrders(tempOrders);
                            return
                        };
                        tempOrders.push(onPaymentProduct);
                        setOrders(tempOrders);

                        return;
                    }
                    let oUnitPrice = onPaymentProduct.price / onPaymentProduct.quantity;

                    //to be paid products edit
                    if (onPaymentProducts[index].quantity <= 1) {
                        setPrice(priceBePaid - onPaymentProduct.price);
                        onPaymentProducts.remove(index);
                    } else {
                        onPaymentProducts[index].price -= oUnitPrice;
                        onPaymentProducts[index].quantity--;
                        setPrice(priceBePaid - oUnitPrice);
                    }
                    setOnPayment(onPaymentProducts);

                    //orders edit
                    let oIndex = orders?.findIndex((p: any) => p._id == onPaymentProduct._id);
                    if (oIndex > -1) {
                        tempOrders[oIndex].quantity++;
                        tempOrders[oIndex].price += oUnitPrice;
                        setOrders(tempOrders);
                        return;
                    }
                    tempOrders.push({
                        ...onPaymentProduct,
                        quantity: 1,
                        price: oUnitPrice
                    });
                    setOrders(tempOrders);
                    break;
            }
        }

        const TipDialog = () => {
            const handleSubmit = async (answer: boolean) => {
                let tempPayments = clone(this.state.payments);
                if (answer) {
                    tempPayments[tempPayments.length - 1].amount -= getPaidAmount() - this.state.totalPrice;
                } else {
                    tempPayments.push({
                        type: 14,
                        amount: getPaidAmount() - this.state.totalPrice,
                        currency: localStorage.getItem('defaultCurrency') || "TL",
                        paidProducts: []
                    });
                }
                await this.setState({
                    payments: tempPayments
                });
                completeOrder(true);
                setTipDialog(false);
            }
            return (
                <Dialog open={tipDialogOpen} onClose={() => setTipDialog(false)} PaperProps={{
                    style: {
                        backgroundColor: "#313131"
                    }
                }}>
                    <DialogTitle>
                        <Typography variant='h5' sx={{ color: "#fff", fontWeight: "bold" }}>
                            {this.props.router.t('tip-title')}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography sx={{ fontSize: "1vw", color: "#fff" }}>
                            {this.props.router.t('tip-question')}
                        </Typography>
                        <FormHelperText sx={{ color: "#fff", fontSize: "0.8vw" }}>{this.props.router.t('money-back-title')}: {(getPaidAmount() - this.state.totalPrice).toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>} </FormHelperText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='success' sx={{ width: "6vw", height: "2.5vw", backgroundColor: "#0D8012" }} onClick={() => handleSubmit(true)}>{this.props.router.t('yes')}</Button>
                        <Button variant='contained' color='error' sx={{ width: "6vw", height: "2.5vw", backgroundColor: "#BD0D0D" }} onClick={() => handleSubmit(false)}>{this.props.router.t('no')}</Button>
                    </DialogActions>
                </Dialog>
            )
        }

        const TickCustomerDialog = () => {
            const [selectedTickCustomer, setSelectedTickCustomer] = useState("");
            const handleClose = () => {
                setSelectedTickCustomer("");
                setTickCustomerDialog(false);

            }

            const handleSubmit = () => {
                if (!selectedTickCustomer) {
                    toast.warning(this.props.router.t('please-select-customer'));
                    return;
                }
                addPayment(selectedTickCustomer);
                setTickCustomerDialog(false);
            }
            return (
                <Dialog open={tickCustomerOpen} onClose={handleClose} fullWidth PaperProps={{
                    style: {
                        backgroundColor: "#B4B4B4"
                    }
                }}>
                    <DialogTitle>
                        <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3, marginBottom: "2vw" }}>
                            <Typography sx={{ fontSize: "2vw" }} variant='h5' textAlign="center" margin="auto" fontWeight="bold">{this.props.router.t('ticks')}</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer sx={{ overflowY: "scroll" }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: "#878787" }}>
                                    <TableRow sx={{ fontWeight: "bold" }}>
                                        <TableCell>{this.props.router.t('name')}</TableCell>
                                        <TableCell>{this.props.router.t('phone-number')}</TableCell>
                                        <TableCell>{this.props.router.t('total-debt')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.getGlobalData.Ticks?.map((tick: any) => (
                                        <TableRow sx={{ backgroundColor: selectedTickCustomer == tick._id ? "#30B100" : "#A9A9A9" }} onClick={() => setSelectedTickCustomer(tick._id)}>
                                            <TableCell>{tick.name}</TableCell>
                                            <TableCell>{tick.phoneNum}</TableCell>
                                            <TableCell>{tick.totalAmount} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color="success" disabled={!selectedTickCustomer} onClick={handleSubmit}>{this.props.router.t('select')}</Button>
                        <Button variant='outlined' color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                    </DialogActions>
                </Dialog>
            )
        }

        const SpecialCustomerDialog = () => {
            const [selectedSpecialCustomer, setSelectedSpecialCustomer] = useState("");
            const handleClose = () => {
                setSelectedSpecialCustomer("");
                setSpecialCustomerDialog(false);
            }

            const handleSubmit = () => {
                if (!selectedSpecialCustomer) {
                    toast.warning(this.props.router.t('please-select-customer'));
                    return;
                }
                addPayment(undefined, selectedSpecialCustomer);
                setSpecialCustomerDialog(false);
            }
            return (
                <Dialog open={specialCustomerOpen} onClose={handleClose} fullWidth PaperProps={{
                    style: {
                        backgroundColor: "#B4B4B4"
                    }
                }}>
                    <DialogTitle>
                        <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3, marginBottom: "2vw" }}>
                            <Typography variant='h5' sx={{ fontSize: "2vw" }} textAlign="center" margin="auto" fontWeight="bold">{this.props.router.t('not-payable')}</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer sx={{ overflowY: "scroll" }}>
                            <Table>
                                <TableHead sx={{ backgroundColor: "#878787" }}>
                                    <TableRow>
                                        <TableCell>{this.props.router.t('name')}</TableCell>
                                        <TableCell>{this.props.router.t('phone-number')}</TableCell>
                                        <TableCell>{this.props.router.t('total-amount')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.getGlobalData.SpecialCustomers?.map((customer: any) => (
                                        <TableRow sx={{ backgroundColor: selectedSpecialCustomer == customer._id ? "#30B100" : "#A9A9A9" }} onClick={() => setSelectedSpecialCustomer(customer._id)}>
                                            <TableCell>{customer.name}</TableCell>
                                            <TableCell>{customer.gsm_no}</TableCell>
                                            <TableCell>{customer.totalAmount} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color="success" disabled={!selectedSpecialCustomer} onClick={handleSubmit}>{this.props.router.t('select')}</Button>
                        <Button variant='outlined' color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                    </DialogActions>
                </Dialog>
            )
        }

        const addPayment = (tickId?: string, specialCustomer?: string) => {
            let amount = manualAmount || priceBePaid;
            if (amount == 0 && onPayment?.length == 0) {
                toast.warning(this.props.router.t('invalid-amount'));
                return;
            }

            if (paymentType == 6 && !tickId) {
                setTickCustomerDialog(true);
                return;
            }

            if (paymentType == 16 && !specialCustomer) {
                setSpecialCustomerDialog(true);
                return
            }

            if (onPayment?.length > 0) {
                let tempPaidProducts = clone(this.state.paidProducts);
                onPayment.map((product: any) => {
                    let index = tempPaidProducts.findIndex((o: any) => o._id == product._id);
                    if (index > -1) {
                        tempPaidProducts[index].quantity += product.quantity;
                        tempPaidProducts[index].price += product.price;
                    } else {
                        tempPaidProducts.push(product);
                    }
                    this.setState({
                        paidProducts: tempPaidProducts
                    })
                })
            }
            let tempPayments = clone(this.state.payments);
            let findIndex = tempPayments.findIndex((p: any) => p.type == paymentType && p.tickId == tickId && p.specialCustomer == specialCustomer);
            if (findIndex > -1) {
                tempPayments[findIndex].amount += amount;
                if (onPayment.length > 0) {
                    tempPayments[findIndex].paidProducts = tempPayments[findIndex].paidProducts?.concat(clone(onPayment));
                }
            } else {
                tempPayments.push({
                    type: paymentType,
                    amount: manualAmount || priceBePaid,
                    currency: localStorage.getItem('defaultCurrency') || "TL",
                    paidProducts: onPayment,
                    tickId: tickId,
                    specialCustomer: specialCustomer
                });
            }

            setOnPayment([]);
            setPrice(0);
            setManualAmount(0);
            this.setState({
                payments: tempPayments,
                remainingPrice: this.state.remainingPrice - amount < 0 ? 0 : this.state.remainingPrice - amount
            });
            toast.success(this.props.router.t('payment-successful'))
        }

        const completeOrder = async (forced?: boolean) => {
            if ((getPaidAmount() - this.state.totalPrice) > 0 && !forced) {
                setTipDialog(true);
                return;
            }
            let data = {
                products: this.state.paidProducts,
                payments: this.state.payments,
                discounts: this.state.discounts,
                caseId: this.props.getGlobalData.caseId
            }
            Swal.fire({
                title: this.props.router.t("pleaseWait").toCapitalizeCase(),
                html: this.props.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            let response = await Services.Post.createCaseSale(data);
            if (response.status) {
                Swal.fire({
                    icon: "success",
                    title: this.props.router.t('order-success-done')
                });
                let user = getGlobalAuthData().user;
                let orders = clone(this.state.orders)?.map((product) => ({
                    product: product.productId,
                    productName: product.productName,
                    isFirst: false,
                    quantity: product.quantity,
                    category: product.category,
                    price: product.priceId,
                    options: product.options ?? [],
                    note: product.note,
                    priceName: product.priceName,
                    isServe: product.isServe ? true : false,
                    serveId: product.serveId ? product.serveId : null
                }));
                let printData = {
                    products: orders,
                    firstProducts: [],
                    tableTitle: this.props.router.t('case-sale'),
                    orderNum: this.props.router.t('case-sale'),
                    section: this.props.router.t('case-sale'),
                    user: user,
                }

                try {
                    printers.KitchenByGroup.printKitchenByGroup(this.props.router.t,printData);
                } catch (e: any) {
                    Swal.fire({
                        title: e.message,
                        icon: "error"
                    })
                }
                this.setState({
                    orders: [],
                    paymentState: false,
                    paidProducts: [],
                    payments: [],
                    discounts: [],
                    totalPrice: 0,
                    remainingPrice: 0
                })
                setOnPayment([]);
                setPrice(0);
                setManualAmount(0);
                setPaymentType(1);
            } else {
                Swal.fire({
                    icon: "error",
                    title: this.props.router.t('error')
                });
            }
        }

        const payAll = async () => {
            let products = filterProducts();
            setOnPayment(products);
            setPrice(this.state.remainingPrice);
            setOrders([]);
        }

        const getPaidAmount = () => {
            let amount = 0;
            this.state.payments?.map((payment: any) => {
                if (payment.type == 14) return;
                amount += payment.amount;
            });
            return amount;
        }

        const Numpad = () => {

            const handleClick = (key: string) => {
                let temp = manualAmount.toString();
                switch (key) {
                    case "clear":
                        setManualAmount(0);
                        break;
                    case "remove":
                        temp = temp.slice(0, -1)
                        setManualAmount(Number(temp));
                        break;
                    default:
                        temp = temp + key;
                        setManualAmount(Number(temp));

                }
            }

            return (
                <Box sx={{ margin: "auto" }}>
                    <Stack direction="column" spacing={4} alignItems="center">
                        <Box sx={{
                            padding: "3vw",
                            borderRadius: 6,
                            backgroundImage: "linear-gradient(#fff, #C8C7C7)",
                            width: "30vw",
                            height: "35vw"
                        }}>
                            <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3, marginBottom: "2vw" }}>
                                <Stack direction="column" alignItems="center" spacing={2}>
                                    <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" fontWeight="bold">{this.props.router.t('remaining-amount')}: {(this.state.remainingPrice - (manualAmount ? manualAmount : priceBePaid)) < 0 ? "0.00" : (this.state.remainingPrice - (manualAmount ? manualAmount : priceBePaid))?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                    {(getPaidAmount() - this.state.totalPrice) > 0 ? (
                                        <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" fontWeight="bold">{this.props.router.t('money-back-title')}: {(getPaidAmount() - this.state.totalPrice)?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                    ) : null}
                                </Stack>
                            </Box>
                            <Stack direction="column" spacing={3} alignItems="center">
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("1")}>1</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("2")}>2</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("3")}>3</Button>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("4")}>4</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("5")}>5</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("6")}>6</Button>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("7")}>7</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("8")}>8</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("9")}>9</Button>
                                </Stack>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "0.8vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("clear")}>{this.props.router.t('clear')}</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "1vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("0")}>0</Button>
                                    <Button disableRipple sx={{ width: "4vw", height: "4vw", fontSize: "0.8vw", "&:active": { backgroundColor: "#239C06" } }} variant='contained' color="error" onClick={() => handleClick("remove")}>{this.props.router.t('delete')}</Button>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box>
                            <Stack direction="column" spacing={3}>
                                <Button startIcon={<KeyboardReturn />} sx={{ width: "13vw", height: "3vw", fontSize: "0.9vw" }} variant='contained' color="error" onClick={() => {
                                    setPaymentType(1);
                                    setOnPayment([]);
                                    setPrice(0);
                                    this.setState({
                                        paymentState: false
                                    });
                                }}>{this.props.router.t('return-orders')}</Button>
                                <Button variant='contained' color='info' onClick={payAll} sx={{ width: "13vw", height: "3vw", fontSize: "0.9vw" }}>{this.props.router.t('pay-all')}</Button>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            )
        }

        const PaymentsDialog = () => {
            const getProductCount = (products: any) => {
                let count = 0;
                if (!products || products?.length == 0) return 0;
                products?.map((p: any) => {
                    count += p.quantity;
                });
                return count;
            }

            const deletePayment = async (index: number) => {
                let tempPayments = clone(this.state.payments);
                let payment = clone(tempPayments[index]);
                let tempPaidProducts = clone(this.state.paidProducts);
                tempPayments.remove(index);
                if (payment.paidProducts?.length > 0) {
                    payment.paidProducts?.map((product: any) => {
                        let findIndex = tempPaidProducts.findIndex((p: any) => p._id == product._id);
                        if (findIndex > -1) {
                            if (tempPaidProducts[findIndex].quantity == product.quantity) {
                                tempPaidProducts.remove(findIndex);
                            } else {
                                tempPaidProducts[findIndex].quantity -= product.quantity;
                                tempPaidProducts[findIndex].price -= product.price;
                            }
                        }
                    })
                }
                await this.setState({
                    remainingPrice: this.state.remainingPrice + payment.amount,
                    payments: tempPayments,
                    paidProducts: tempPaidProducts
                });
                filterProducts();
                toast.success(this.props.router.t('payment-delete-success'));
                if (this.state.payments?.length == 0) {
                    setPaymentDialog(false);
                }
            }

            const getCustomer = (payment: any) => {
                if (payment.type == 6) {
                    return this.props.getGlobalData.Ticks?.find((t: any) => t._id == payment.tickId);
                }
                return this.props.getGlobalData.SpecialCustomers?.find((s: any) => s._id == payment.specialCustomer);
            }

            return (
                <Dialog open={paymentDialogOpen} onClose={() => setPaymentDialog(false)} maxWidth="md" fullWidth>
                    <DialogTitle>
                        <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3, marginBottom: "2vw" }}>
                            <Typography variant='h6' sx={{ fontSize: "1.1vw" }} textAlign="center" margin="auto" marginRight="0.3vw" fontWeight="bold">{this.props.router.t('payments')}</Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TableContainer sx={{ margin: "auto", width: "80%", height: "90%", overflowY: "scroll" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{this.props.router.t('payment-type')}</TableCell>
                                        <TableCell>{this.props.router.t('_amount')}</TableCell>
                                        <TableCell>{this.props.router.t('product-count')}</TableCell>
                                        <TableCell>{this.props.router.t('customer')}</TableCell>
                                        <TableCell>{this.props.router.t('actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state?.payments?.map((payment: any, index: number) => (
                                        <TableRow>
                                            <TableCell>{this.props.router.t(payment_types[payment.type - 1])}</TableCell>
                                            <TableCell>{payment.amount} {payment.curency}</TableCell>
                                            <TableCell>{getProductCount(payment.paidProducts)}</TableCell>
                                            <TableCell>{payment.type == 6 || payment.type == 16 ? (<>{getCustomer(payment)?.name}</>) : <>-</>}</TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => deletePayment(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button color="error" variant='outlined' onClick={() => setPaymentDialog(false)}>{this.props.router.t('cancel')}</Button>
                    </DialogActions>
                </Dialog>
            )
        }

        const PaymentOrders = () => {
            return (
                <Box>
                    <Box className="orders" sx={{ backgroundImage: "linear-gradient(#BA3E3E, #B80000)", width: "35vw", height: "60vh", borderRadius: 6, padding: 2 }}>
                        <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3 }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant='h6' textAlign="center" margin="auto" marginLeft="0.2vw" fontWeight="bold">{this.props.router.t('orders')}</Typography>
                                <Stack direction="column" spacing={1}>
                                    <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" margin="auto" marginRight="0.3vw" fontWeight="bold">{this.props.router.t('remaining-amount')}: {(this.state.remainingPrice - (manualAmount ? manualAmount : priceBePaid)) < 0 ? "0.00" : (this.state.remainingPrice - (manualAmount ? manualAmount : priceBePaid))?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                    {(getPaidAmount() - this.state.totalPrice) > 0 ? (
                                        <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" margin="auto" marginRight="0.3vw" fontWeight="bold">{this.props.router.t('money-back-title')}: {(getPaidAmount() - this.state.totalPrice)?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                    ) : null}
                                </Stack>
                            </Stack>
                        </Box>
                        <Box className="content" sx={{ marginTop: "1vw", overflowY: "scroll", height: "45vh", padding: 2 }}>
                            <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {orders?.map((product: any, i: number) => {
                                    let currency = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.productId)?.prices?.find((price: any) => price._id == product.priceId)?.currency;

                                    return (
                                        <Grid item xs={2} sm={3} md={4} key={i}>
                                            <Box onClick={() => {
                                                handleQuantity(i, 'order', true)
                                            }} sx={{ position: "relative", borderRadius: 6, borderTopRightRadius: 0, borderTopLeftRadius: 0, border: "1px solid white", boxShadow: "5px 5px 5px black", backgroundImage: "linear-gradient(#fff, #C8C7C7)" }}>
                                                <Stack direction="column" spacing={2}>
                                                    <Avatar variant='square' src={product.image} sx={{ width: "100%", height: "10vh" }} />
                                                    <Box className="text" sx={{ pading: 1 }}>
                                                        <Typography textAlign="center" sx={{ fontSize: "1vw", fontWeight: "bold" }}>{product.productName}</Typography>
                                                        <Typography textAlign="center" sx={{ fontSize: "0.9vw" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>} {currency != defaultCurrency ? (<>
                                                            ({calculateExchange(product.price, currency)})
                                                        </>) : null}</Typography>
                                                        {product.options?.length > 0 || product?.note ? (
                                                            <Box>
                                                                <Typography sx={{ fontSize: "1.2vw" }} textAlign="center">...</Typography>
                                                            </Box>
                                                        ) : null}
                                                    </Box>

                                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                        <Typography sx={{ fontSize: "1vw" }}>{product.quantity?.toFixed(2)}</Typography>
                                                        <IconButton onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            handleQuantity(i, 'order', false);
                                                        }}>{<KeyboardArrowRightIcon sx={{ fontSize: "1.5vw" }} />}</IconButton>
                                                    </Stack>
                                                </Stack>

                                            </Box>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        </Box>

                    </Box>
                    <Stack direction="column" spacing={2} margin="auto" justifyContent="center" alignContent="center" marginTop="1.5vw" alignItems="center">
                        <Button startIcon={<KeyboardReturn />} sx={{ width: "13vw", height: "3vw", fontSize: "0.9vw" }} variant='contained' color="error" onClick={() => {
                            setPaymentType(1);
                            setOnPayment([]);
                            setPrice(0);
                            this.setState({
                                paymentState: false
                            });
                        }}>{this.props.router.t('return-orders')}</Button>
                        <Button variant='contained' color='info' onClick={payAll} sx={{ width: "13vw", height: "3vw", fontSize: "0.9vw" }}>{this.props.router.t('pay-all')}</Button>
                    </Stack>
                </Box>
            )
        }

        const OnPaymentOrders = () => {

            return (
                <Box>
                    <Box className="orders" sx={{ backgroundImage: "linear-gradient(#56BA3E, #239C06)", width: "35vw", height: "60vh", borderRadius: 6, padding: 2 }}>
                        <Box className="header" sx={{ backgroundImage: "linear-gradient(#9A9999, #C06E6E)", padding: "1vw", width: "90%", margin: "auto", borderRadius: 3 }}>
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant='h6' textAlign="center" margin="auto" marginLeft="0.2vw" fontWeight="bold">{this.props.router.t('orders-onpayment')}</Typography>
                                <Stack direction="column" spacing={1} alignContent="center" alignItems="center">
                                    <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" margin="auto" marginRight="0.3vw" fontWeight="bold">{this.props.router.t('amount-paid')}: {manualAmount ? manualAmount?.toFixed(2) : priceBePaid?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                    <Typography sx={{ fontSize: "1.1vw" }} textAlign="center" margin="auto" marginRight="0.3vw" fontWeight="bold">{this.props.router.t('paid-amount')}: {getPaidAmount()?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                        <Box className="content" sx={{ marginTop: "1vw", overflowY: "scroll", height: "45vh", padding: 2 }}>
                            <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {onPayment?.map((product: any, i: number) => {
                                    let currency = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.productId)?.prices?.find((price: any) => price._id == product.priceId)?.currency;

                                    return (
                                        <Grid item xs={2} sm={3} md={4} key={i}>
                                            <Box onClick={() => {
                                                handleQuantity(i, 'onPayment', true)

                                            }} sx={{ position: "relative", borderRadius: 6, borderTopRightRadius: 0, borderTopLeftRadius: 0, border: "1px solid white", boxShadow: "5px 5px 5px black", backgroundImage: "linear-gradient(#fff, #C8C7C7)" }}>
                                                <Stack direction="column" spacing={2}>
                                                    <Avatar variant='square' src={product.image} sx={{ width: "100%", height: "10vh" }} />
                                                    <Box className="text" sx={{ pading: 1 }}>
                                                        <Typography textAlign="center" sx={{ fontSize: "1vw", fontWeight: "bold" }}>{product.productName}</Typography>
                                                        <Typography textAlign="center" sx={{ fontSize: "0.9vw" }}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>} {currency != defaultCurrency ? (<>
                                                            ({calculateExchange(product.price, currency)})
                                                        </>) : null}</Typography>
                                                        {product.options?.length > 0 || product?.note ? (
                                                            <Box>
                                                                <Typography sx={{ fontSize: "1.2vw" }} textAlign="center">...</Typography>
                                                            </Box>
                                                        ) : null}
                                                    </Box>

                                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                        <IconButton onClick={(e: any) => {
                                                            e.stopPropagation();
                                                            handleQuantity(i, 'onPayment', false);
                                                        }}>{<KeyboardArrowLeftIcon sx={{ fontSize: "1.5vw" }} />}</IconButton>
                                                        <Typography sx={{ fontSize: "1vw" }}>{product.quantity?.toFixed(2)}</Typography>
                                                    </Stack>
                                                </Stack>

                                            </Box>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        </Box>
                    </Box>
                    <Stack direction="row" spacing={6} alignContent="center" justifyContent="center" marginTop="1.5vw">
                        <Stack direction="column" spacing={4} alignContent="center">
                            <TextField disabled={onPayment?.length > 0} variant='filled' onClick={() => ipcRenderer.send('showKeyboard')} type='number' sx={{ width: "12vw", height: "3vw", fontSize: "1vw", "& .MuiFilledInput-root": { backgroundColor: "#fff", "&:hover": { backgroundColor: "#fff" } } }} label={this.props.router.t('_amount')} value={manualAmount} onChange={(e: any) => {
                                setManualAmount(Number(e.target.value));
                            }} />
                            <Button variant='contained' color="warning" sx={{ width: "12vw", height: "3vw", fontSize: "1vw" }} onClick={() => setKeyboard(!showKeyboard)}>{showKeyboard ? this.props.router.t('hide-numpad') : this.props.router.t('show-numpad')}</Button>
                        </Stack>
                        <Stack direction="column" spacing={4}>
                            <Button variant='contained' color="success" sx={{ width: "12vw", height: "3vw", fontSize: "1vw" }} onClick={() => addPayment()}>{this.props.router.t('add-payment')}</Button>
                            {this.state.payments?.length > 0 ? (
                                <Button variant='contained' color="info" sx={{ width: "12vw", height: "3vw", fontSize: "0.8vw" }} onClick={() => setPaymentDialog(true)}>{this.props.router.t('added-payments')}</Button>
                            ) : null}
                            {this.state.remainingPrice == 0 ? (
                                <Button variant='contained' color="success" sx={{ width: "12vw", height: "3vw", fontSize: "1vw" }} onClick={() => completeOrder()}>{this.props.router.t('complete-order')}</Button>
                            ) : null}
                        </Stack>
                    </Stack>
                </Box>
            )
        }

        const calculateExchange = (price: number, currency: string) => {
            let exchange = 1;
            switch (currency) {
                case "TL":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.TL;
                    break;
                case "USD":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.USD;
                    break;
                case "EUR":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.EUR;
                    break;
                case "GBP":
                    exchange = this.props.getGlobalData?.CurrencySettings?.currencies?.GBP;
                    break;
                default:
                    break;
            }
            return `${price / exchange} ${Currencies[currency]}`
        }

        return (
            <Box>
                {PaymentsDialog()}
                {TipDialog()}
                {TickCustomerDialog()}
                {SpecialCustomerDialog()}
                {this.Navbar()}
                <ToastContainer />
                <Box sx={{ height: "80vh", padding: 5 }}>
                    <Stack direction="row" spacing={3} justifyContent="space-around">
                        {showKeyboard ? (
                            <>
                                {Numpad()}
                            </>
                        ) : (
                            <>
                                {PaymentOrders()}
                            </>
                        )}
                        {OnPaymentOrders()}
                        <Box className="paymentTypes">
                            <Stack direction="column" spacing={1}>
                                {PaymentTypes?.map((pType: any) => (
                                    <Button sx={{ width: "12vw", height: "3vw", fontSize: "1vw", borderRadius: 3 }} variant="contained" color={pType.type == paymentType ? "success" : "error"} onClick={() => setPaymentType(pType.type)}>
                                        {this.props.router.t(pType.name)}
                                    </Button>
                                ))}
                            </Stack>
                        </Box>
                    </Stack>

                </Box>
            </Box >
        )
    }

    render() {
        if (this.state.paymentState) {
            return (
                <Box>
                    <this.Payment />
                </Box>
            )
        }
        return (
            <Box>
                <ToastContainer />
                <this.ProductDetailDialog />
                <this.DiscountDialog />
                <this.AppliedDiscountsDialog />
                {this.state.optionDialogOpen &&
                    (
                        <this.OptionDialog />
                    )
                }
                <this.Navbar />
                <Box sx={{ height: "80vh", padding: 5 }}>
                    <Stack direction="row" spacing={3}>
                        <Box className="orders-buttons" sx={{ width: "140vw" }}>
                            <Stack direction="column" spacing={4}>
                                <this.Orders />
                                <Box className="buttons">
                                    <Stack direction="column" spacing={2}>
                                        <Stack direction="row" spacing={4} justifyContent="center">
                                            <Button variant="contained" color="warning" sx={{ width: "11.5vw", fontSize: "1vw", height: "4vw" }} onClick={this.handlePrint}>{this.props.router.t('print')}</Button>
                                            <Button variant="contained" color="info" sx={{ width: "11.5vw", fontSize: "1vw", height: "4vw" }} onClick={() => {
                                                if (this.state.remainingPrice == 0) {
                                                    toast.warning(this.props.router.t('discount-price-error'));
                                                    return;
                                                }
                                                this.setState({ discountDialogOpen: true });
                                            }}>{this.props.router.t('apply-discount')}</Button>
                                            <Button variant="contained" color="success" sx={{ width: "11.5vw", fontSize: "1vw", height: "4vw" }} onClick={() => {
                                                if (this.state.orders?.length == 0) {
                                                    toast.warning(this.props.router.t('please-add-product'));
                                                    return;
                                                }
                                                this.setState({
                                                    paymentState: true
                                                });
                                            }}>{this.props.router.t('go-checkout')}</Button>
                                        </Stack>
                                        {this.state.discounts?.length > 0 ? (
                                            <Stack direction="row" alignContent="center" justifyContent="center">
                                                <Button sx={{ width: "11.5vw", fontSize: "1vw", height: "4vw" }} variant="contained" color="info" onClick={() => this.setState({ appliedDiscountsDialogOpen: true })}>{this.props.router.t('applied-discounts')}</Button>
                                            </Stack>
                                        ) : null}
                                    </Stack>

                                </Box>

                            </Stack>
                        </Box>
                        <Box className="products-categories">
                            <Stack direction="row" spacing={2}>
                                <this.Products />
                                <Box className="categories" sx={{ width: "10vw", overflowY: "scroll", height: "83vh", padding: 2 }}>
                                    <Stack direction="column" spacing={1} alignItems="center">
                                        <Button sx={{ backgroundColor: this.state.selectedCategories?.length == 0 ? "#A9A9A9" : "#DEDEDE", "&:hover": { backgroundColor: "#BBBBBB" }, width: "8vw", height: "3vw", fontSize: "0.7vw", fontWeight: "bold", textAlign: "center" }} onClick={() => this.categoryOnClick("all")} variant='contained' color='inherit'>{this.props.router.t('all')}</Button>
                                        <this.Categories />
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Box>
            </Box>
        )
    }
}
