import React, { Component } from "react";
import "./printer.css";
import Printer from "../../../../../config/global/printers/index";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import CloseIcon from '@mui/icons-material/Close';
import Keyboard from 'react-simple-keyboard';
import Swal from "sweetalert2";
import {  Tabs, Tab } from '@mui/material'
interface IGroupPrinter {
    printer: string;
    name: string;
    categories: any;
    products: any;
    tables: any;
}

type PageState = {
    tab: number;
    editPrinter: any;
    selectedPrinter: any;
    selectedCategory: string;
    selectedSection: string;
    barcodePrinter: any;
    printerName: string;
    groupPrinter: IGroupPrinter;
    keyboardOn: boolean;
    selectedItem: string;
    layoutName: string;
};

type PageProps = {} & PagePropCommonDocument;

class PrinterSettings extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            tab:0,
            editPrinter: {},
            selectedPrinter: "",
            selectedCategory: "",
            selectedSection:  "",
            barcodePrinter: "",
            keyboardOn: false,
            layoutName: "",
            selectedItem: "",
            printerName: Printer.Settings.CurrentSafePrinterName || "",
            groupPrinter: {
                printer: "",
                name: "",
                categories: [],
                products: [],
                tables: []
            },
        };
    }

    componentDidMount(): void {
        this.setState({
            barcodePrinter: localStorage.getItem('barcodePrinter') || ""
        });
    }

    handleGroupPrinterInput(e: any) {
        Printer.Settings.CurrentPrinter = e.target.value;
        Printer.Settings.saveSettings(0);
    }
    handleCategoryInput(e: any) {
        this.setState({
            selectedCategory: e.target.value || ""
        });
    }

    handleSectionInput(e: any) {
        this.setState({
            selectedSection: e.target.value || ""
        });
    }

    handlePrinterNameInput = (e: any) => {
        this.setState({
            printerName: e.target.value,
        });
        Printer.Settings.CurrentSafePrinterName = e.target.value;
        Printer.Settings.saveSettings(0);
    }

    PrinterList({ selected }: { selected: string }) {
        return Printer?.Print.Printers?.map((x: any) => (
            <option selected={x.name === selected} value={x.name}>
                {x.displayName}
            </option>
        ));
    }
    busyCategoryList = () => {
        if(!this.state.selectedPrinter)return [];
        const filteredCategories = this.props.getGlobalData.Categories?.filter(item => {
            const selectedPrinterCategories = this.findPrinterInGroup(this.state.selectedPrinter).categories;
            return !selectedPrinterCategories.includes(item._id);
        });
        return filteredCategories??[];
    }
    CategoryList(selected:string) {     
        const filteredCategories = this.busyCategoryList();
        const options = filteredCategories.map((x: any) => (
            <option selected={x._id === selected} value={x._id}>
                {x.title}
            </option>
        ));
        return options;
    }
    SectionList(selected:string) {
        const options =this.props.getGlobalData.Sections?.map((x: any) => (
            <option selected={x._id === selected} value={x._id}>
                {x.title}
            </option>
        ));
        return options;
    }
   
    onKeyPress(button: any) {
        if (button == "{shift}" || button == "{lock}") {
            if (this.state.layoutName == "default") {
                this.setState({
                    layoutName: "shift"
                });
                return;
            }
            this.setState({
                layoutName: "default"
            })
            return;
        }
        let element = document.querySelector(this.state.selectedItem) as HTMLInputElement;
        if (button == "{bksp}") {
            element.value = element.value.slice(0, -1);
            return;
        }
        if (button == "{enter}") return;
        else if (button == "{space}") element.value += " ";
        else if (button == "{tab}") element.value += "    ";
        else
            element.value += button;
    }

    GroupPrinterList = () => {
        return Printer?.Print.Printers?.filter(
            (x: any) => !Printer.Settings.Groups.some((a) => a.printer == x.name)
        ).map((x: any) => (
            <option
                selected={x.name === this.state.groupPrinter.printer}
                value={x.name}
            >
                {x.displayName}
            </option>
        ));
    }
    printerTest = () => {
        Swal.fire({
            icon: "question",
            title: this.props.router.t('printers-test-question'),
            text: this.props.router.t('printers-test-text'),
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: this.props.router.t('no'),
            confirmButtonText: this.props.router.t('yes')
        })
            .then(response => {
                if (response.isConfirmed) {
                    let printers: any[] = [];
                    if (localStorage.getItem('general-printer')) {
                        let generalPrinter = JSON.parse(localStorage.getItem('general-printer')!);
                        printers.push(generalPrinter);
                    }
                    if (localStorage.getItem('printer-groups')) {
                        let printerGroups = JSON.parse(localStorage.getItem('printer-groups')!);
                        printers = printers.concat(printerGroups);

                    }
                    if (printers.length == 0) {
                        Swal.fire({
                            icon: "error",
                            title: this.props.router.t('test-printers-error')
                        });
                        return;
                    }
                    Printer.PrinterTest.printPrinterTest(printers);
                    return;
                }
            })
    }
    findPrinterInGroup = (printerName: any) =>
        Printer.Settings.Groups.filter((_: { name: any }) => _.name === printerName)[0];
        
    getSubCategories(category: string) {
        let subCategories = this.props.getGlobalData.Categories.filter((c: any) => c.parent_category == category)
        subCategories.forEach((c: any) => {
            subCategories = [...subCategories, ...this.getSubCategories(c._id)]
        })

        return subCategories;
    }
    render(): React.ReactNode {
        return (
            <main className="printerSettings">
                <section>
                    <article>
                        <div></div>
                    </article>
                </section>
                <section>
                    <article>
                        <div>
                            <div>
                                <h3>{this.props.router.t("general-printer-setup")}</h3>
                            </div>
                            <div>
                                <label htmlFor="globalPrinter">
                                    {this.props.router.t("general-printer")}
                                </label>
                                <select
                                    onChange={(e) => this.handleGroupPrinterInput(e)}
                                    id="globalPrinter"
                                >
                                    <this.PrinterList selected={Printer.Settings.CurrentPrinter!} />
                                </select>
                            </div>
                            <div>
                                <label htmlFor="printerNames">
                                    {this.props.router.t("receipt-name")}
                                </label>
                                <input
                                    type="text"
                                    id="safePrinterName"
                                    onChange={this.handlePrinterNameInput}
                                    value={this.state.printerName}
                                    onClick={() => window.require('electron').ipcRenderer.send('showKeyboard')}

                                />
                                <button
                                    id="btnOlustur"
                                    style={{ marginTop: 8 }}
                                    onClick={this.printerTest}
                                >
                                    {this.props.router.t('test-printers')}
                                </button>
                                <div style={{ marginTop: 8 }}>
                                    <span>{this.props.router.t('barcode-printer')}</span>
                                    <select id="barcodePrinter" value={this.state.barcodePrinter} onChange={(e: any) => {
                                        this.setState({
                                            barcodePrinter: e.target.value
                                        });
                                        localStorage.setItem('barcodePrinter', e.target.value);
                                    }}>
                                        <option value="" selected={!this.state.barcodePrinter}>
                                           {this.props.router.t("selectedPrinter")}
                                        </option>
                                        {Printer?.Print.Printers?.map((printer: any) => (
                                            <option value={printer.name} selected={printer.name === this.state.barcodePrinter}>
                                                {printer.displayName}
                                            </option>
                                        ))}

                                    </select>
                                </div>

                            </div>
                        </div>
                    </article>
                    <article>
                        <div>
                            <div>
                                <h3>{this.props.router.t("group-printer-setting")}</h3>
                            </div>
                            <div>
                                <span>{this.props.router.t("group-printers")}</span>
                                <select
                                    name="printer_name"
                                    id="grupPrinters"
                                    onChange={(event) =>
                                        this.setState({
                                            groupPrinter: {
                                                printer: event.target.value,
                                                name: this.state.groupPrinter.name,
                                                categories: this.state.groupPrinter.categories,
                                                products:this.state.groupPrinter.products,
                                                tables:this.state.groupPrinter.tables
                                            },
                                        })
                                    }
                                >
                                    <option>{this.props.router.t("make-choice")}</option>
                                    <this.GroupPrinterList />
                                </select>
                            </div>
                            <div>
                                <label htmlFor="printerNames">
                                    {this.props.router.t("name-the-printer")}
                                </label>
                                <div className="createPrinter">
                                    <input
                                        onChange={(e) =>
                                            this.setState({
                                                groupPrinter: {
                                                    printer: this.state.groupPrinter.printer,
                                                    name: e.target.value,
                                                    categories: this.state.groupPrinter.categories,
                                                    products: this.state.groupPrinter.products,
                                                    tables:this.state.groupPrinter.tables
                                                },
                                            })
                                        }
                                        type="text"
                                        name="name"
                                        id="groupPrinterName"
                                        onClick={() => window.require('electron').ipcRenderer.send('showKeyboard')}
                                    />
                                </div>
                                <button
                                    id="btnOlustur"
                                    onClick={() =>
                                        this.state.groupPrinter.printer &&
                                        (Printer.Settings.Groups.push({
                                            id: Printer.Settings.Groups.length + 1,
                                            ...this.state.groupPrinter,
                                        }),
                                            Printer.Settings.saveSettings(1),
                                            this.setState({
                                                groupPrinter: {
                                                    printer: String(),
                                                    name: String(),
                                                    categories: [],
                                                    products: [],
                                                    tables:[]
                                                },
                                            }))
                                    }
                                >
                                    {this.props.router.t("create")}
                                </button>
                                <div className="createdPrinter">
                                    <div>
                                        <h3>{this.props.router.t("choose-group")}</h3>
                                    </div>
                                    <table className="PrinterTable">
                                        <thead>
                                            <tr>
                                                <th>{this.props.router.t("printer-groups")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Printer.Settings.Groups.map((group: any) => (
                                                <tr
                                                    style={{
                                                        backgroundColor:
                                                            group.name === this.state.selectedPrinter
                                                                ? "red"
                                                                : "white",
                                                    }}
                                                    onClick={() =>
                                                        this.setState({
                                                            selectedPrinter: group.name,
                                                        })
                                                    }
                                                >
                                                    <td>
                                                        <span className="flex flex-row px-1">
                                                            <div className="truncate">
                                                                {group.name}-{group.printer}
                                                            </div>
                                                        </span>
                                                        <button
                                                            onClick={(event) => (
                                                                event.stopPropagation(),
                                                                (Printer.Settings.Groups = Printer.Settings.Groups.filter(
                                                                    (x) => x.printer !== group.printer
                                                                )),
                                                                Printer.Settings.saveSettings(1),
                                                                window.location.reload()
                                                            )}
                                                            className="bg-red-500 px-2 ml-5"
                                                        >
                                                            x
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article>
                    <div className="printTabbar">
                    <div className="printTabbarApbar">      
                <Tabs
                    value={this.state.tab}
                    onChange={(e, newValue) => this.setState({ tab: newValue })}
                    sx={{ width: "100%", marginLeft: "10vw" }}
                    centered
                >
                    <Tab value={0} label={this.props.router.t("category")} />
                    <Tab value={1} label={this.props.router.t("product")} />
                    <Tab value={2} label={this.props.router.t("table")} />
                </Tabs>
             </div>
            { 
            !this.state.selectedPrinter?null:
               this.state.tab === 0 && (
                       <table className="PrinterTable">
                       <thead>
                           <tr>
                               <th>{this.props.router.t("category-name")}</th>
                           </tr>
                       </thead>
                       <tbody>
                           {this.props.getGlobalData.Categories.map((_: any) => {
                               if (!this.state.selectedPrinter) return;
                               const categorySelected =
                                   this.findPrinterInGroup(
                                       this.state.selectedPrinter
                                   ).categories.findIndex((e: any) => e === _._id) === -1
                                       ? false
                                       : true;
                               if (categorySelected) {
                                   return (
                                       <tr
                                           onClick={() => {
                                               const find =
                                                   Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                                   ];
                                               Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                               ].categories = find.categories.filter(
                                                   (x: any) => x !== _._id
                                               );
                                               this.setState({
                                                   editPrinter: {
                                                       ...this.state.editPrinter,
                                                       groups: Printer.Settings.Groups,
                                                   },
                                               });
                                               Printer.Settings.saveSettings(1);
                                           }}
                                           style={{
                                               backgroundColor: categorySelected
                                                   ? "green"
                                                   : "transparent",
                                               color: "white",
                                           }}
                                       >
                                           {_.title}
                                       </tr>
                                   );
                               } else {
                                   return (
                                       <tr
                                           onClick={() => {
                                               Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                               ].categories.push(_._id);
                                               this.setState({
                                                   editPrinter: {
                                                       ...this.state.editPrinter,
                                                       groups: Printer.Settings.Groups,
                                                   },
                                               });
                                               Printer.Settings.saveSettings(1);
                                           }}
                                       >
                                           {_.title}
                                       </tr>
                                   );
                               }
                           })}
                       </tbody>
                   </table>
                )}
            {!this.state.selectedPrinter?null: this.state.tab === 1 && this.busyCategoryList().length>0 &&(
                    <div>
                   <select
                       onChange={(e) => this.handleCategoryInput(e)}
                       id="globalPrinter"
                   >
                       {this.CategoryList(this.busyCategoryList().find((category: any)=> category._id === this.state.selectedCategory)?this.state.selectedCategory: this.busyCategoryList()[0]._id??"")}
                   </select>
                   <table className="PrinterTable">
                       <thead>
                           <tr>
                               <th>{this.props.router.t("product-name")}</th>
                           </tr>
                       </thead>
                       <tbody>
                           {this.props.getGlobalData.Products.filter(item => this.getSubCategories(this.busyCategoryList().find((category: any)=> category._id === this.state.selectedCategory)?this.state.selectedCategory:this.busyCategoryList()[0]._id).map((c: any) => c._id).concat([this.busyCategoryList().find((category: any)=> category._id === this.state.selectedCategory)?this.state.selectedCategory:this.busyCategoryList()[0]._id]).includes(item.category)).map((_: any) => {
                               
                               const productSelected =
                               this.findPrinterInGroup(
                                this.state.selectedPrinter
                            ).products?
                                   this.findPrinterInGroup(
                                       this.state.selectedPrinter
                                   ).products?.findIndex((e: any) => e === _._id) === -1
                                       ? false
                                       : true
                                    :false;
                               if (productSelected) {
                                   return (
                                       <tr
                                           onClick={() => {
                                               const find =
                                                   Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                                   ];
                                                   if(!Printer.Settings.Groups[
                                                    Printer.Settings.Groups.findIndex(
                                                        (e: { name: any }) =>
                                                            e.name === this.state.selectedPrinter
                                                    )
                                                ].products){
                                                    Printer.Settings.Groups[
                                                        Printer.Settings.Groups.findIndex(
                                                            (e: { name: any }) =>
                                                                e.name === this.state.selectedPrinter
                                                        )
                                                    ].products=[] 
                                                }
                                               Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                               ].products = find.products.filter(
                                                   (x: any) => x !== _._id
                                               );
                                               this.setState({
                                                   editPrinter: {
                                                       ...this.state.editPrinter,
                                                       groups: Printer.Settings.Groups,
                                                   },
                                               });
                                               Printer.Settings.saveSettings(1);
                                           }}
                                           style={{
                                               backgroundColor: productSelected
                                                   ? "green"
                                                   : "transparent",
                                               color: "white",
                                           }}
                                       >
                                           {_.title}
                                       </tr>
                                   );
                               } else {
                                   return (
                                       <tr
                                           onClick={() => {
                                            if(!Printer.Settings.Groups[
                                                Printer.Settings.Groups.findIndex(
                                                    (e: { name: any }) =>
                                                        e.name === this.state.selectedPrinter
                                                )
                                            ].products){
                                                Printer.Settings.Groups[
                                                    Printer.Settings.Groups.findIndex(
                                                        (e: { name: any }) =>
                                                            e.name === this.state.selectedPrinter
                                                    )
                                                ].products = [] 
                                            }
                                               Printer.Settings.Groups[
                                                   Printer.Settings.Groups.findIndex(
                                                       (e: { name: any }) =>
                                                           e.name === this.state.selectedPrinter
                                                   )
                                               ].products.push(_._id);
                                               this.setState({
                                                   editPrinter: {
                                                       ...this.state.editPrinter,
                                                       groups: Printer.Settings.Groups,
                                                   },
                                               });
                                               Printer.Settings.saveSettings(1);
                                           }}
                                       >
                                           {_.title}
                                       </tr>
                                   );
                               }
                           })}
                       </tbody>
                   </table>
                    </div>
                )}
            {!this.state.selectedPrinter?null: this.state.tab === 2 && (
                    <div>
                          <select
                              onChange={(e) => this.handleSectionInput(e)}
                              id="globalPrinter"
                          >
                              {this.SectionList(this.state.selectedSection?this.props.getGlobalData.Sections.find((Section: any)=> Section._id === this.state.selectedSection)._id :this.props.getGlobalData.Sections?this.props.getGlobalData.Sections[0]._id:"")}
                          </select>
                          <table className="PrinterTable">
                              <thead>
                                  <tr>
                                      <th>{this.props.router.t("table-name")}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {this.props.getGlobalData.AllTables.filter(item => this.state.selectedSection?this.state.selectedSection===item.section:this.props.getGlobalData.Sections?this.props.getGlobalData.Sections[0]._id===item.section:null).map((_: any) => {
                                      if (!this.state.selectedPrinter) return;
                                      const tableSelected= 
                                                this.findPrinterInGroup(
                                        this.state.selectedPrinter).tables? this.findPrinterInGroup(this.state.selectedPrinter
                                        ).tables.findIndex((e: any) => e === _._id) === -1
                                                ? false
                                                : true
                                              :false;
                                      if (tableSelected) {
                                          return (
                                              <tr
                                                  onClick={() => { 
                                                      const find =
                                                          Printer.Settings.Groups[
                                                          Printer.Settings.Groups.findIndex(
                                                              (e: { name: any }) =>
                                                                  e.name === this.state.selectedPrinter
                                                          )
                                                          ];
                                                          if(!Printer.Settings.Groups[
                                                            Printer.Settings.Groups.findIndex(
                                                                (e: { name: any }) =>
                                                                    e.name === this.state.selectedPrinter
                                                            )
                                                        ].tables){
                                                            Printer.Settings.Groups[
                                                                Printer.Settings.Groups.findIndex(
                                                                    (e: { name: any }) =>
                                                                        e.name === this.state.selectedPrinter
                                                                )
                                                            ].tables=[]
                                                        }
                                                      Printer.Settings.Groups[
                                                          Printer.Settings.Groups.findIndex(
                                                              (e: { name: any }) =>
                                                                  e.name === this.state.selectedPrinter
                                                          )
                                                      ].tables = find.tables.filter(
                                                          (x: any) => x !== _._id
                                                      );
                                                      this.setState({
                                                          editPrinter: {
                                                              ...this.state.editPrinter,
                                                              groups: Printer.Settings.Groups,
                                                          },
                                                      });
                                                      Printer.Settings.saveSettings(1);
                                                  }}
                                                  style={{
                                                      backgroundColor: tableSelected
                                                          ? "green"
                                                          : "transparent",
                                                      color: "white",
                                                  }}
                                              >
                                                  {_.title}
                                              </tr>
                                          );
                                      } else {
                                          return (
                                              <tr
                                                  onClick={() => {
                                                   let tables = Printer.Settings.Groups[ Printer.Settings.Groups.findIndex(
                                                        (e: { name: any }) =>
                                                            e.name === this.state.selectedPrinter
                                                    )].tables
                                                    if(!tables){
                                                        Printer.Settings.Groups[
                                                            Printer.Settings.Groups.findIndex(
                                                                (e: { name: any }) =>
                                                                    e.name === this.state.selectedPrinter
                                                            )
                                                        ].tables = []
                                                    }    
                                                      Printer.Settings.Groups[
                                                          Printer.Settings.Groups.findIndex(
                                                              (e: { name: any }) =>
                                                                  e.name === this.state.selectedPrinter
                                                          )
                                                      ].tables.push(_._id);
                                                      this.setState({
                                                          editPrinter: {
                                                              ...this.state.editPrinter,
                                                              groups: Printer.Settings.Groups,
                                                          },
                                                      });
                                                      Printer.Settings.saveSettings(1);
                                                  }}
                                              >
                                                  {_.title}
                                              </tr>
                                          );
                                      }
                                  })}
                              </tbody>
                          </table>
                    </div>
                )}
             </div>
                    </article>
                    {this.state.keyboardOn ? (<>
                        <Keyboard onKeyPress={(e: any) => this.onKeyPress(e)} layoutName={this.state.layoutName} />
                        <CloseIcon sx={{ cursor: "pointer" }} onClick={() => this.setState({ keyboardOn: false })} />

                    </>) : null}

                </section>
            </main>
        );
    }
}

export default PrinterSettings;
