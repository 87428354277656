import All from "./all/printAll";
import Check from "./check/printCheck";
import Customer from "./customer/customer";
import Kitchen from "./kitchen/printKitchen";
import KitchenByGroup from "./kitchen/printKitchenByGroup";
import ProductsInOrder from "./order/printProductsInOrder";
import Print from "./print/print";
import Report from "./report/report";
import Settings from "./settings/settings";
import PrinterTest from "./test/test-printers";
import KitchenCancelled from "./kitchen/printKitchenCancelled";
import KitchenCancelledByGroup from "./kitchen/printKitchenCancelledByGroup";
import ZReport from "./report/z-report";
import PrintBarcode from "./barcode/barcode";
import PrintGetirOrder from "./order/printGetirOrder";

export default {
  All,
  Check,
  Customer,
  Print,
  Kitchen,
  ProductsInOrder,
  PrintGetirOrder,
  KitchenByGroup,
  Report,
  Settings,
  PrinterTest,
  KitchenCancelled,
  KitchenCancelledByGroup,
  ZReport,
  PrintBarcode,
};
