import ReactDOMServer from "react-dom/server";
import DateFormat from "../date/date";
import Print from "../print/print";
import Settings, { PrinterDefaultColumnHeight } from "../settings/settings";
import { payment_types } from "../../../../constants/paymentTypes";
import Currencies from "constants/currencies";
import { getGlobalAuthData } from "config/global/auth";

export default class ProductsInOrder {
  static printProductsInOrder(check: any, producList: any = [], t: any) {
    let user = getGlobalAuthData().user;
    if (
      !check.orders.length &&
      !check.paymentReceived &&
      !check.homeDelivery &&
      !check.caseSale
    )
      return;
    let perDefaultHeight = PrinterDefaultColumnHeight;
    let currency = check.currency
      ? check.currency
      : localStorage.getItem("defaultCurrency")
      ? localStorage.getItem("defaultCurrency")
      : "TL";
    let exchange = check?.currencyValue;
    let products: any[] = [];
    let productInfo: any;
    check.orders?.map((order: any) => {
      order.products?.map((product: any) => {
        productInfo = producList.find(
          (item: any) => item._id === (product.productId || product.product)
        );
        let index = products.findIndex(
          (p: any) =>
            p.productName == product.productName && !p.isServe &&
            product.priceId == p.priceId &&
            product.status == p.status &&
            product.options?.toString() == p.options?.toString()
        );
        if (index > -1) {
          products[index].quantity += product.quantity;
          products[index].price += product.price;
          return;
        }
        products.push(product);
      });
    });
    check.orders = [
      {
        orderNum: 0,
        products: products,
      },
    ];
    const getPriceName = (product: any) => {
      let index_ = 0;
      const productItem = producList.find(
        (item: any) => item._id === (product.productId || product.product)
      );
      if (productItem) {
        const priceId = productItem.prices.find(
          (item: any) => item._id === (product.priceId || product.priceId)
        );
        if (priceId) {
          productItem.prices.forEach((prices: any) => {
            if (prices._id !== product.priceId) {
              prices.order_type.forEach((order_type: any) => {
                priceId.order_type.includes(order_type) ? index_++ : null;
              });
            }
          });
        }

        if (productItem && productItem.prices.length > 1 && index_ > 0) {
          const price = productItem.prices.find(
            (price: any) => price._id === product.priceId
          );
          return " / " + price?.priceName ?? "";
        }
      }
      return "";
    };
    const getSaleType = (product: any) => {
      const productItem = producList.find(
        (item: any) => item._id === (product.productId || product.product)
      );
      if (productItem && productItem.sale_type === 2) {
        return " Kg" ?? "";
      }
      return "";
    };

    const getPaymentProduct = (product: any) => {
      let foundProduct = false;
      if (check && check.tableInfo && check.tableInfo.paid_orders) {
        check.tableInfo.paid_orders.forEach((orderPaid: any) => {
          let productPaid = orderPaid.products.find(
            (paidProduct: any) =>
              paidProduct._id === (product.id || product.product)
          );
          if (productPaid) {
            foundProduct = true;
            return "*";
          }
        });

        if (foundProduct) {
          return "*";
        }
      }

      return "";
    };

    const getWidth = (product: any, firstWidth: any, secondWidth: any) => {
      const productItem = producList.find(
        (item: any) => item._id === (product.productId || product.product)
      );
      return productItem && productItem.sale_type === 2
        ? firstWidth
        : secondWidth;
    };

    const getFontWidth = (bo: any) => {
      return bo ? "normal" : "bold";
    };

    let Info = (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <strong>{t("date") ?? "Tarih"}: </strong>
            {new Date().toLocaleString()}
          </div>
          <div style={{ marginBottom: "5px" }}>
            <strong>{t("check-no") ?? "Adisyon No"}: </strong>
            {check.checkNo ?? check.tableInfo.checkNo}
          </div>
          <div style={{ marginBottom: "5px" }}>
            <strong>{t("waiter") ?? "Garson"}: </strong>
            {user.name} {user.lastname}
          </div>
          {check.checkNo === t("case-sale") ? null : check.table ? (
            <div style={{ marginBottom: "5px" }}>
              <strong>{t("table") ?? "Masa"}: </strong>
              {check.table}
            </div>
          ) : null}
          {check.name ? (
            <div style={{ marginBottom: "5px" }}>
              <strong>{t("customer") ?? "Müşteri"}: </strong>
              {check.name}
            </div>
          ) : null}
          {check.address ? (
            <div style={{ marginBottom: "5px" }}>
              <strong>{t("address") ?? "Adres"}: </strong>
              {check.address}
            </div>
          ) : null}
          {check.phone ? (
            <div style={{ marginBottom: "5px" }}>
              <strong>{t("phone") ?? "Telefon"}: </strong>
              {check.phone}
            </div>
          ) : null}
        </div>
      </div>
    );
    let height = 10;
    let Products = (
      <div>
        <hr
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            border: "1px solid black none none none",
            borderStyle: "dashed",
          }}
        />
        <div style={{ marginLeft: "0px", marginBottom: "3px" }}>
          <table style={{ display: "block" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    width: "28px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textAlign: "start",
                  }}
                >
                  <span style={{ marginRight: "1px", fontSize: "13px" }}>
                    {t("quantity")}
                  </span>
                </td>
                <td style={{ width: "210px", textAlign: "start" }}>
                  <span
                    style={{
                      marginRight: "1px",
                      fontSize: "13px",
                      marginLeft: "6px",
                    }}
                  >
                    {t("product")}
                  </span>
                </td>
                <td
                  style={{
                    width: "55px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textAlign: "end",
                  }}
                >
                  <span style={{ marginRight: "0px", fontSize: "13px" }}>
                    {t("price")}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <div style={{ marginLeft: "5px" }}>
            <table style={{ display: "block" }}>
              <tbody>
                {check?.cover?.length > 0
                  ? check?.cover?.map((cover: any) => (
                      <tr>
                        <td
                          style={{
                            width: "28px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "start",
                          }}
                        >
                          <span style={{ marginRight: "1px" }}>
                            {cover.quantity}{" "}
                          </span>
                        </td>
                        <td style={{ width: "210px", textAlign: "start" }}>
                          <span>{cover.title}</span>
                        </td>
                        <td
                          style={{
                            width: "55px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "end",
                          }}
                        >
                          <span>
                            {(exchange
                              ? cover.price / exchange
                              : cover.price * Number(cover.quantity)
                            ).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    ))
                  : null}
                {check?.orders[0]?.products?.length > 0
                  ? check?.orders?.map((order: any) => {
                      height += perDefaultHeight;
                      return order.products
                        ?.filter((product: any) => product.status != 0)
                        ?.map((product: any) => (
                          <tr>
                            <td
                              style={{
                                width: getWidth(product, "28px", "23px"),
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textAlign: "start",
                              }}
                            >
                              <span style={{ marginRight: "1px" }}>
                                {(product.quantity % 1 === 0
                                  ? product.quantity
                                  : product.quantity
                                      .toFixed(2)
                                      .replace(/\.?0+$/, "")) +
                                  getSaleType(product)}{" "}
                              </span>
                            </td>
                            <td
                              style={{
                                width: getWidth(product, "200px", "210px"),
                                textAlign: "start",
                              }}
                            >
                              <span style={{ marginRight: "1px" }}>
                                {product.productName + getPriceName(product)}{" "}
                              </span>
                            </td>
                            <td
                              style={{
                                width: "55px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textAlign: "end",
                              }}
                            >
                              <span style={{ marginRight: "0px" }}>
                                {product.isServe
                                  ? t("catering")
                                  : (exchange
                                      ? product.price / exchange
                                      : product.price
                                    )?.toFixed(2)}{" "}
                                {getPaymentProduct(product)}
                              </span>
                            </td>
                          </tr>
                        ));
                    })
                  : null}
              </tbody>
            </table>
          </div>
          <hr
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid black none none none",
              borderStyle: "dashed",
            }}
          />
        </div>
      </div>
    );
    const discounts = (
      <div>
        <div style={{ marginLeft: "20px" }}>
          <tbody>
            {check?.discount?.map((discount: any) => {
              height += perDefaultHeight;
              return (
                <table>
                  <td>
                    <span style={{ marginRight: "10px" }}>{t("discount")}</span>
                  </td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {Number(discount.amount).toFixed(2)}{" "}
                      {Currencies[currency!] || <>₺</>}
                    </span>
                  </td>
                </table>
              );
            })}
          </tbody>
        </div>
        {check?.discount?.length > 0 ? (
          <hr
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid black none none none",
              borderStyle: "dashed",
            }}
          />
        ) : null}
      </div>
    );

    const getTotalDiscount = () => {
      let total = 0;
      if (check?.discount?.length > 0) {
        check?.discount?.map((discount: any) => {
          total += discount.amount;
        });
      } else if (check?.tableInfo?.discount?.length > 0) {
        check?.tableInfo?.discount?.map((discount: any) => {
          total += discount.amount;
        });
      }
      return total;
    };
    const getTotalCover = () => {
      let total = 0;
      if (check?.cover?.length > 0) {
        check?.cover?.map((cover: any) => {
          total += cover.price;
        });
      } else if (check?.tableInfo?.cover?.length > 0) {
        check?.tableInfo?.cover?.map((cover: any) => {
          total += cover.price;
        });
      }
      return total;
    };

    const getTotalService = () => {
      let total = 0;
      if (check?.service) {
        check?.service?.map((service: any) => {
          total += service.amount;
        });
      } else if (check?.serviceFee) {
        total += check?.serviceFee;
      } else if (check?.tableInfo) {
        check?.tableInfo?.serviceFee?.map((service: any) => {
          total += service.amount;
        });
      }
      return total;
    };

    const Total =
      check.homeDelivery === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "500" }}>
              {t("subTotal") ?? "Ara Toplam"}:{" "}
            </span>
            {Number(
              exchange
                ? check.total.amount / exchange
                : check.total.amount -
                    getTotalService() +
                    getTotalDiscount() -
                    getTotalCover()
            )?.toFixed(2)}{" "}
            {check.total.currency}
          </div>
          {Number(getTotalDiscount())?.toFixed(2) === "0.00" ? (
            <div></div>
          ) : (
            <div style={{ marginBottom: "10px" }}>
              <span style={{ fontWeight: "500" }}>
                {t("total-discount") ?? "Toplam İndirim"}:{" "}
              </span>
              {Number(getTotalDiscount())?.toFixed(2)} {check.total.currency}
            </div>
          )}
          <div style={{ marginBottom: "10px" }}>
            <span style={{ fontWeight: "600" }}>
              {t("total") ?? "Toplam"}:{" "}
            </span>{" "}
            <span style={{ fontWeight: "900" }}>
              {Number(
                exchange ? check.total.amount / exchange : check.total.amount
              ).toFixed(2)}
              {check.total.currency}
            </span>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ marginLeft: "10px" }}>
            <table style={{ display: "block" }}>
              <tbody>
                <tr style={{ marginBottom: "5px" }}>
                  <td style={{ width: "180px", textAlign: "start" }}>
                    <span>{t("subTotal") ?? "Ara Toplam"}</span>
                  </td>
                  <td style={{ width: "28px", textAlign: "start" }}>
                    <span></span>
                  </td>
                  <td
                    style={{
                      width: "55px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "end",
                    }}
                  >
                    <span>
                      {Number(
                        exchange
                          ? (check.total.amount -
                              getTotalService() -
                              getTotalCover() +
                              getTotalDiscount()) /
                              exchange
                          : check.total.amount -
                              getTotalService() -
                              getTotalCover() +
                              getTotalDiscount()
                      )?.toFixed(2)}
                    </span>
                  </td>
                </tr>
                {check?.service ? (
                  check?.service.map((service: any) => {
                    height += perDefaultHeight;
                    return (
                      <tr style={{ marginBottom: "5px" }}>
                        <td
                          style={{
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "start",
                          }}
                        >
                          <span style={{ marginRight: "1px" }}>
                            {t("service") ?? "Servis"} {"%"}
                            {service.percentile
                              .toFixed(2)
                              .replace(/\.?0+$/, "")}
                          </span>
                        </td>
                        <td
                          style={{
                            width: "28px",
                            textAlign: "start",
                            paddingRight: "30px",
                          }}
                        >
                          <span></span>
                        </td>
                        <td
                          style={{
                            width: "55px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "end",
                          }}
                        >
                          <span>
                            {exchange
                              ? service.amount / exchange
                              : Number(service.amount).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : check.checkNo !== t("case-sale") && check.tableInfo ? (
                  check.tableInfo.serviceFee.map((service: any) => {
                    height += perDefaultHeight;
                    return (
                      <tr style={{ marginBottom: "5px" }}>
                        <td
                          style={{
                            width: "180px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "start",
                          }}
                        >
                          <span style={{ marginRight: "1px" }}>
                            {t("service") ?? "Servis"} {"%"}
                            {service.percentile
                              .toFixed(2)
                              .replace(/\.?0+$/, "")}
                          </span>
                        </td>
                        <td
                          style={{
                            width: "28px",
                            textAlign: "start",
                            paddingRight: "30px",
                          }}
                        >
                          <span></span>
                        </td>
                        <td
                          style={{
                            width: "55px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textAlign: "end",
                          }}
                        >
                          <span>
                            {exchange
                              ? (Number(service.amount) / exchange).toFixed(2)
                              : Number(service.amount).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <td></td>
                )}

                {Number(getTotalDiscount())?.toFixed(2) === "0.00" ? (
                  <tr></tr>
                ) : (
                  <tr style={{ marginBottom: "5px" }}>
                    <td style={{ width: "180px", textAlign: "start" }}>
                      <span>{t("total-discount") ?? "Toplam İndirim"}</span>
                    </td>
                    <td style={{ width: "28px", textAlign: "start" }}>
                      <span></span>
                    </td>
                    <td
                      style={{
                        width: "55px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textAlign: "end",
                      }}
                    >
                      <span>
                        {Number(
                          exchange
                            ? Number(getTotalDiscount()) / exchange
                            : Number(getTotalDiscount())
                        )?.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                )}
                <tr style={{ marginBottom: "5px", marginTop: "5px" }}>
                  <td
                    style={{
                      width: "180px",
                      textAlign: "start",
                      fontWeight: getFontWidth(
                        check.payments
                          ? check.paymentslength > 0
                          : check.tableInfo.payments.length > 0
                      ),
                      fontSize: (
                        check.payments
                          ? check.paymentslength > 0
                          : check.tableInfo.payments.length > 0
                      )
                        ? 16
                        : 24,
                    }}
                  >
                    {" "}
                    <span>{t("total") ?? "Toplam"}</span>
                  </td>
                  <td style={{ width: "28px", textAlign: "start" }}>
                    <span></span>
                  </td>
                  <td
                    style={{
                      width: "55px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textAlign: "end",
                      fontWeight: getFontWidth(
                        check.payments
                          ? check.paymentslength > 0
                          : check.tableInfo.payments.length > 0
                      ),
                      fontSize: (
                        check.payments
                          ? check.paymentslength > 0
                          : check.tableInfo.payments.length > 0
                      )
                        ? 16
                        : 24,
                    }}
                  >
                    <span>
                      {Number(
                        exchange
                          ? check.total.amount / exchange
                          : check.total.amount
                      ).toFixed(2)}
                    </span>{" "}
                  </td>
                </tr>

                {(
                  check.payments
                    ? check.paymentslength > 0
                    : check.tableInfo.payments.length > 0
                ) ? (
                  <tr style={{ marginBottom: "5px" }}>
                    <td style={{ width: "180px", textAlign: "start" }}>
                      <span>{t("total-paid") ?? "Ödenen Toplam"}</span>
                    </td>
                    <td style={{ width: "28px", textAlign: "start" }}>
                      <span></span>
                    </td>
                    <td
                      style={{
                        width: "55px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textAlign: "end",
                      }}
                    >
                      <span>
                        {Number(
                          exchange
                            ? check.tableInfo.totalPrice -
                                check.tableInfo.remainingPrice / exchange
                            : check.tableInfo.totalPrice -
                                check.tableInfo.remainingPrice
                        )?.toFixed(2)}{" "}
                      </span>
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
                {(
                  check.payments
                    ? check.paymentslength > 0
                    : check.tableInfo.payments.length > 0
                ) ? (
                  <tr style={{ marginBottom: "5px" }}>
                    <td
                      style={{
                        width: "180px",
                        textAlign: "start",
                        fontWeight: "bold",
                        fontSize: 24,
                      }}
                    >
                      <span>{t("total-remaining") ?? "Kalan Toplam"}</span>
                    </td>
                    <td style={{ width: "28px", textAlign: "start" }}>
                      <span></span>
                    </td>
                    <td
                      style={{
                        width: "55px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textAlign: "end",
                        fontWeight: "bold",
                        fontSize: 24,
                      }}
                    >
                      <span>
                        {Number(
                          exchange
                            ? check.tableInfo.remainingPrice / exchange
                            : check.tableInfo.remainingPrice
                        )?.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      );

    const footer = (
      <div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "1px",
            fontSize: "13px",
            textAlign: "center",
          }}
        >
          {t("digigarsonSubTittle")}
        </div>
        <div style={{ fontSize: "12px", textAlign: "center" }}>
          digigarson.com
        </div>
      </div>
    );
    const allTotal = (
      <>
        <hr
          style={{
            width: "100%",
            marginTop: "10px",
            marginBottom: "10px",
            border: "1px solid black none none none",
            borderStyle: "dashed",
          }}
        />

        <div style={{ marginLeft: "20px" }}>
          <strong>{t("allCurrency") ?? "Tüm Para Birimleri"}</strong>
          {check?.allTotal?.map((total: any) => {
            height += perDefaultHeight;
            return (
              <div
                style={{
                  marginBottom: 3,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {total.amount?.toFixed(2)}{" "}
                  {Currencies[total.currency!] || <>₺</>}
                </span>
              </div>
            );
          })}
        </div>
      </>
    );
    const data: any[] = [
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${
          Settings.CurrentSafePrinterName === (undefined || "")
            ? "DIGIGARSON"
            : Settings.CurrentSafePrinterName
        }</div>`,
      },
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(Info),
      },
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(Products),
      },

      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(Total),
      },
      {
        type: "text",
        value: check?.allTotal
          ? ReactDOMServer.renderToStaticMarkup(allTotal)
          : "",
      },
    ];
    if (check.paymentReceived) {
      data.push(
        {
          type: "text",
          value: `<div style="width: 100%; padding: 3px 0;">
                              <div style="display: flex; align-items: center;">
                                ${
                                  check.tableInfo.payments.length > 0
                                    ? `<div style="margin-bottom: 10px; font-weight: 700; font-size: 16px; margin-left: 20px;">
                                      <span style="font-weight: 700;">${
                                        t("paid-amount") ?? "Ödenen Tutar"
                                      }:</span>
                                      ${Number(
                                        check.paymentReceived.amount
                                      ).toFixed(2)} ${
                                        Currencies[currency!] || "₺"
                                      }
                                    </div>`
                                    : `<div></div>`
                                }
                              </div>
                            </div>`,
        },
        {
          type: "text",
          value: `<div style="width: 100%; padding: 3px 0;">
                    <div style="display: flex; align-items: center;">
                      <span style="font-weight: 700; font-size: 16px; margin-left: 20px;">${
                        t("paidRemaining") ?? "Ödemeden Sonra Kalan Tutar"
                      }</span>
                    </div>
                    <div style="display: flex;justify-content: center; padding-left: 10px;">
                      <span style="font-weight: 700; font-size: 16px; margin-left: 20px;">${(
                        Number(check.remaining.amount) -
                        Number(check.paymentReceived.amount)
                      ).toFixed(2)} ${Currencies[currency!] || "₺"}</span>
                    </div>
                  </div>`,
        }
      );
    }
    data.push({
      type: "text",
      value: ReactDOMServer.renderToStaticMarkup(footer),
    });

    Print.print(Settings.payload_with_settings(data), height);
  }
}
