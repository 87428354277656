import ReactDOMServer from "react-dom/server";
import Print from "../print/print";
import Settings from "../settings/settings";

interface IGetirPrint {
  orderNum: number;
  confirmationId: string;
  products: Array<{
    productName: string;
    price: number;
  }>;
  discount?: number;
  note?: string;
  customerName: string;
  customerPhone: string;
  totalPrice: number;
}

export default class PrintGetirOrder {
  static printGetirOrder(order: IGetirPrint) {
    let defaultHeight = 10;
    let height = order.products?.length * 2 + defaultHeight;
    const styles = {
      body: {
        fontFamily: "Arial, sans-serif",
        margin: 0,
        padding: 0,
        backgroundColor: "#f9f9f9",
      },
      container: {
        width: "80mm",
        margin: "0 auto",
        backgroundColor: "#fff",
        padding: "10px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        fontSize: "12px",
      },
      header: {
        textAlign: "center" as "center",
        marginBottom: "10px",
      },
      headerH1: {
        margin: 0,
        fontSize: "16px",
      },
      logo: {
        maxWidth: "50mm",
        margin: "5px auto",
      },
      details: {
        marginBottom: "10px",
      },
      detailItem: {
        margin: "3px 0",
      },
      detailList: {
        paddingLeft: "20px",
        margin: "5px 0",
      },
      total: {
        margin: "3px 0",
        display: "flex",
        justifyContent: "space-between",
      },
      discount: {
        margin: "3px 0",
        display: "flex",
        justifyContent: "space-between",
      },
      totalStrong: {
        marginRight: "5px",
      },
      totalSpan: {
        marginLeft: "auto",
      },
      note: {
        marginTop: "10px",
        fontStyle: "italic",
      },
    };

    let allData = (
      <div style={styles.container}>
        <div style={styles.header}>
          <img
            src="https://api.digigarson.com/Images/61c9c255d4ee0706afa14598/logo/getir-logo.png"
            style={{
              width: "60px",
              height: "60px",
            }}
          />
        </div>
        <div style={styles.details}>
          <p style={styles.detailItem}>
            <strong>Sipariş Numarası:</strong> {order.orderNum}
          </p>
          <p style={styles.detailItem}>
            <strong>Onay Kodu:</strong> {order.confirmationId}
          </p>
          <p style={styles.detailItem}>
            <strong>Müşteri Numarası:</strong> {order.customerPhone}
          </p>
          <p style={styles.detailItem}>
            <strong>Müşteri Adı:</strong> {order.customerName}
          </p>
          <p style={styles.detailItem}>
            <strong>Sipariş Detayları:</strong>
          </p>
          <ul style={styles.detailList}>
            {order.products.map((product: any, index: number) => (
              <li key={index}>
                {product.productName} - {product.price} TL
              </li>
            ))}
          </ul>
          {order.discount && (
            <div style={styles.discount}>
              <strong style={styles.totalStrong}>Ortak Kampanya:</strong>
              <span style={styles.totalSpan}>{order.discount} TL</span>
            </div>
          )}
          <div style={styles.total}>
            <strong style={styles.totalStrong}>Toplam Tutar:</strong>
            <span style={styles.totalSpan}>{order.totalPrice} TL</span>
          </div>
        </div>
        <div style={styles.note}>
          <p style={styles.detailItem}>
            <strong>Sipariş Notu:</strong> {order.note}
          </p>
        </div>
      </div>
    );

    const data: any[] = [
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(allData),
      },
    ];

    Print.print(Settings.payload_with_settings(data), height);
  }
}
