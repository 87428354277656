const moveProduct = (router: any, products:any[], selectedProductId:string[], tableid: any) => {
  let productsToBeMoved = products.filter(
    (product) => selectedProductId?.includes(product.uid)
  );
  const data = productsToBeMoved.map((product: any) => product.product);
  
  window.localStorage.setItem("productsToBeMoved", JSON.stringify(data));
  router.navigate("/moveproduct/" + tableid, {
    replace: true,
  });
};

export default moveProduct;
