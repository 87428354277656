import React, { Component } from 'react'
import { Box, Typography, Stack, CircularProgress } from '@mui/material'
import i18n from 'utilities/i18n'
const sound = require('assets/sounds/alert.mp3')

type IProps = {}
type IState = {
    timer: number
}

export default class NoConnection extends Component<IProps, IState> {
    interval: any = null;

    componentDidMount() {
        let alert = new Audio(sound);
        alert.load();
        alert.play();
        this.interval = setInterval(() => {
            alert.play();
        }, 10000)
    }

    componentWillUnmount(): void {
        clearInterval(this.interval)
    }


    render() {
        return (
            <Box sx={{ margin: "auto", width: "80%" }}>
                <Stack direction="column" spacing={4} alignItems="center">
                    <CircularProgress color='info' size={60} sx={{ fontSize: "4vw" }} disableShrink />
                    <Typography variant='h4' textAlign="center" fontWeight="bold" sx={{ color: "#D00101" }}>
                        {i18n.t('check-internet-connection')}
                    </Typography>
                </Stack>
            </Box>
        )
    }
}
