import ReactDOMServer from "react-dom/server";
import Print from "../print/print";
import Settings from "../settings/settings";
import { toast } from "react-toastify";
const ipcRenderer: any = window.require('electron').ipcRenderer;

interface IPrintData {
    productName: string;
    price: number;
    currency: string;
    value: string;
    user: string;
    branch: string;
    weight: number;
    pairCode: boolean;
    unitPrie: number;
    sale_type: number;
}

function numberWithCommas(x: any) {
    return x?.toString()?.replace(/\./g, ",");
}


export default class PrintBarcode {
    static printBarcode(value: any) {
        if(!localStorage.getItem('barcodePrinter')){
            toast.error('Barkod yazıcısı seçilmemiş!');
            return false;
        }
        const information = (
            <div>
                <span style={{ fontSize: "8px", position: "absolute", left: 4 }}>www.digigarson.com</span>
                <span style={{ fontSize: "8px", position: "absolute", right: 4 }}>{new Date().toLocaleString()}</span>
                <br />
                <div className="header">
                    <span style={{ fontWeight: "bold" }}>{value.productName}</span>
                    <br />
                    <span>{value.branch}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 3 }}>
                    <div style={{ marginTop: 6, border: "2px solid black", padding: 3, width: "35%" }}>
                        <span className="price" style={{ fontWeight: "bold" }}>{value.price} {value.currency}</span>
                        <br />
                        {value.sale_type == 2 ? (
                            <span className="weight">{((value.weight / 1000)?.toFixed(2))} KG</span>
                        ) : ""}
                    </div>
                    <div style={{ fontSize: "10px", marginTop: 5 }}>

                        {value.sale_type == 2 ? (
                            <span>
                                Birim fiyatı 1 kg için {value.unitPrice} {value.currency}
                            </span>
                        ) : <span>Birim fiyatı {value.unitPrice} {value.currency}</span>}

                        <br />
                        <span>
                            Fiyatlara KDV dahildir.
                        </span>
                    </div>
                </div>
            </div>
        )
        const data: any[] = [
            {
                type: "text",
                value: ReactDOMServer.renderToStaticMarkup(information),
                style: { textAlign: "center", fontFamily: "serif" }
            },
            {
                type: 'barCode',
                value: value.value,
                height: 30,                     // height of barcode
                width: 1,                       // width of barcode
                displayValue: true,             // Display value below barcode
                fontsize: 15,
                style: { align: "center", textAlign: "center", margin: "auto" }
            }
        ]

        ipcRenderer.postMessage("printBarcode", { message: data, printer: localStorage.getItem('barcodePrinter') });
        return true;
    }
}
