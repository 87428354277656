import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import clone from "clone";
import Thread from "../../../../library/thread";
import { FaRegSadTear } from "react-icons/fa";
import { info } from "console";
import reasons from "public/reasons";

const deleteHomeDelivery = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: commonProp.router.t("delete-question-all"),
        text: commonProp.router.t("delete-question-all-info"),
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            let params = {
                orderNum: pageOrderTable.props.or.orderNum
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            const res = await Services.Delete.deleteHomeDelivery(params);
            if(res.status) {
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('delete-success-homedelivery')
                })

                let Tables = pageOrderTable.props.getGlobalData.AllTables.map((table: any) => {
                    if(table.order_type == 3) {
                        table.orders = table.orders.filter((order: any) => order.orderNum != params.orderNum);
                    }
                    return table;
                })
                pageOrderTable.props.setGlobalData({
                    AllTables: Tables
                })
                commonProp.router.navigate('/');
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default deleteHomeDelivery;
