import {Component} from "react";
import {MODAL} from "constants/modalTypes";
import "./getcreditcustomer.css";
import FuzzySearch from "fuzzy-search";
import {PagePropCommonDocument} from "modules/views/pages/pageProps";
import Currencies from "constants/currencies";
import { Button } from "@mui/material";
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    showingCreditList: any;
    customerId: string;
};

type PageProps = {
    data: any;
} & PagePropCommonDocument;

class Getcreditcustomer extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            showingCreditList: this.props.getGlobalData.Ticks,
            customerId: "",
        };
    }

    handleCheck(id: any) {
        this.setState(
            {
                customerId: id,
            },
            () => {
                this.props.openModal(MODAL.GET_CREDIT_LIST, {
                    tickCustomerId: id
                });
            }
        );
    }

    getPaid(tick: any) {
        let paid = 0;
        tick?.ticks?.map((t: any) => {
            paid += t.on_payment;
        });
        return paid;

    }

    getRemaining(tick: any) {
        let paid = this.getPaid(tick);
        let remaining = 0;
        remaining = tick.totalAmount - paid;
        return remaining;
    }
    Ticks = () => {
        return this.state.showingCreditList?.map((item: any) => (
            <tr className="checkInList">
                <th style={{width: "17vw"}}>{item.name}</th>
                <th style={{width: "13vw"}}>{item.phoneNum}</th>
                <th style={{width: "10vw"}}>{item.totalAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</th>
                <th style={{width: "10vw"}}>{this.getPaid(item)?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</th>
                <th style={{width: "10vw"}}>{this.getRemaining(item)?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</th>
                <th style={{width: "10vw"}}>
                    <button
                        style={{background: "blue"}}
                        onClick={() => this.handleCheck(item._id)}
                    >
                        {this.props.router.t("detail")}
                    </button>
                </th>
            </tr>
        ));
    };

    searchTick(event: any, type: string) {
        let tick: any = this.props.getGlobalData.Ticks;
        const tickSearcher = new FuzzySearch(tick, [type], {
            caseSensitive: false,
        });
        const result = tickSearcher.search(event.target.value.trim());

        this.setState({
            showingCreditList: result,
        });
    }
    createcreditCustomer() {
        this.props.openModal(MODAL.CREATE_CREDIT_CUSTOMER)
    }
    render() {
        return (
            <>
                <div className="searchcost">
                    <table>
                        <thead>
                        <Button className="createcustomerbutton" variant="contained" color="error" sx={{marginTop: 0, fontSize: "0.6rem", color: "#fff", fontWeight: "bold"}} onClick={() => this.createcreditCustomer()}>{this.props.router.t('new-ticks-customer')}</Button>
                        <tr style={{paddingTop:  "10px !important"}}>
                            <th>{this.props.router.t("phone-number")}</th>
                            <th>{this.props.router.t("name")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {["phoneNum", "name"].map((type: string) => (
                                <td>
                                    <input
                                        style={{color: "black", borderRadius: "16px", margin:"0px"}}
                                        onChange={(event) => this.searchTick(event, type)}
                                        onClick={() => ipcRenderer.send('showKeyboard')}
                                    />
                                </td>
                            ))}
                        </tr>
                        </tbody>
                    </table>
                </div>
                <table cellSpacing="0" cellPadding="0">
                    <thead>
                    <tr>
                        <td>
                            <table
                                style={{width: "70vw"}}
                                cellSpacing="0"
                                cellPadding="1"
                            >
                                <tr style={{color: "white", background: "grey"}}>
                                    <th style={{width: "17vw"}}>
                                        {this.props.router.t("user")}
                                    </th>
                                    <th style={{width: "13vw"}}>
                                        {this.props.router.t("phone-number")}
                                    </th>
                                    <th style={{width: "10vw"}}>
                                        {this.props.router.t("total")}
                                    </th>
                                    <th style={{width: "10vw"}}>
                                        {this.props.router.t("paid-amount")}
                                    </th>
                                    <th style={{width: "10vw"}}>
                                        {this.props.router.t("remaining-amount")}
                                    </th>
                                    <th style={{width: "10vw"}}>
                                        {this.props.router.t("actions")}
                                    </th>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <div style={{height: "34vh", overflow: "auto"}}>
                        <table style={{width: "70vw"}} cellSpacing="0" cellPadding="1">
                            {<this.Ticks/>}
                        </table>
                    </div>
                    </tbody>
                </table>
            </>
        );
    }
}

export default Getcreditcustomer;
