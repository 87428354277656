import {useEffect, useState} from "react";



function DateTime(props: any) {
    const [clockState, setClockState] = useState(new Date());
        useEffect(() => {
            const interval = setInterval(() => {
                const date = new Date();
                setClockState(date)
            }, 1000);
            return () => clearInterval(interval)
        }, [])
    

        return (
            <div>
                <p>
                    {" "}
                    {props.router.t('hour')} : {clockState.toLocaleTimeString()}
                </p>
                <p>
                    {" "}
                    {props.router.t('date')} : {clockState.toLocaleDateString()}
                </p>
            </div>
        );
    }


export default DateTime;
