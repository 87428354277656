import { getGlobalAuthData, getGlobalBranchData } from 'config/global';
import React from 'react'
import io from "socket.io-client";


export const socket: any = io(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`, {
    withCredentials: true,
    transports: ["websocket"],
    path: "/socket.io",
    secure: true
});
socket.on("connect", () => {
    let data = { userId: getGlobalAuthData().user._id, socketId: socket.id, role: "pos", branchId: getGlobalAuthData()?.user?.branchId};
    socket.emit("pair", data);
    socket.emit("userConnect", data.userId);
})

export const SocketContext = React.createContext(null);

