import { Component } from "react";
import { MODAL } from "constants/modalTypes";
import { payment_types } from "constants/paymentTypes";
import Swal from "sweetalert2";
import Services from "services/index";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Functions from "../../../../../../config/global/functions/index";
import Callcheck from "./callcheck";
import { CheckPutParamDocument } from "../../../../../../modules/services/checks";
const ipcRenderer: any = window.require("electron").ipcRenderer;

type PageState = {
  fetchComplete: boolean;
  payments: any[];
  orders: any[];
  discounts: any[];
  getOldCheck: any;
};

type PageProps = {
  data: Callcheck;
} & PagePropCommonDocument;

const PaymentTypes = [
  "pos_bank",
  "pos_cash",
  "app_payment",
  "serve",
  "discount",
  "tick",
  "sodexo",
  "smart",
  "winwin",
  "multinet",
  "setcard",
  "metropol",
  "edended",
  "tips",
  "jetKurye",
  "not-payable",
  "migros-hemen",
  "getir-online",
  "trendyol",
  "yemek-sepeti",
];
class Updatecheck extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      fetchComplete: false,
      payments: [],
      orders: [],
      discounts: [],
      getOldCheck: null,
    };
  }

  componentDidMount() {
    this.sets();
  }

  formatPaymentsData(payments: any[]) {
    let data = Array();
    payments?.forEach((payment: any) =>
      data.push({ payment: payment, uid: Functions.createUUID() })
    );
    return data;
  }

  sets() {
    const getOldCheck_ = Services.Get.checks({
      checkId: this.props.data.state.checkID,
    }).data;
    const payments: any[] = [];
    getOldCheck_.payments.map((payment: any) => {
      payments.push({ payment, uid: Functions.createUUID() });
    });
    const orders = getOldCheck_.orders;
    this.setState({
      payments: payments,
      orders: orders,
      discounts: getOldCheck_.discounts,
      getOldCheck: getOldCheck_,
    });
  }

  getTotal() {
    let sum = 0;
    if (this.state.getOldCheck) {
      this.state.getOldCheck.serviceFee?.map((o: any) => (sum += o.amount));
      this.state.getOldCheck.covers?.map(
        (o: any) => (sum += o.price * o.quantity)
      );
    }
    this.state.orders?.map((o: any) =>
      o.products.map((p: any) =>
        p.status === 0 || p.isServe ? null : (sum += p.price)
      )
    );
    this.state.discounts?.map((d: any) => (sum -= d.amount));
    return sum;
  }

  getRemaining() {
    let total = this.getTotal();
    let totalPayment = 0;
    this.state.payments.forEach((payment: any) => {
      if (payment.payment.type == 14) return;
      totalPayment += Number(payment.payment.amount);
    });
    return total - totalPayment;
  }

  getTotalPayment(payment: any) {
    let sum = 0;
    payment.forEach((payment: any) =>
      payment.type == 14 ? undefined : (sum += payment.amount)
    );
    return sum;
  }

  save() {
    let data: CheckPutParamDocument = {
      checkId: this.props.data.state.checkID,
      payments: this.state.payments.map((row: any) => ({
        type: Number(row.payment.type),
        amount: Number(row.payment.amount),
        currency: row.payment.currency,
        tickId: row.payment.tickId || undefined,
        createdAt: new Date().toISOString(),
      })),
    };
    if (this.getRemaining() < 0) {
      Swal.fire({
        title: this.props.router.t("update-check"),
        text:
          this.props.router.t("update-check-more-error") +
          this.getRemaining() * -1,
        icon: "error",
      });
    } else if (this.getRemaining() > 0) {
      Swal.fire({
        title: this.props.router.t("update-check-less"),
        icon: "error",
        text:
          this.props.router.t("update-check-less-error") + this.getRemaining(),
      });
    } else {
      Services.Put.checks(data).then((resData) => {
        if (resData.status) {
          Swal.fire({
            title: this.props.router.t("updated-ticket"),
            icon: "success",
            timer: 2000,
          }).then(() => {
            this.cancel();
          });
        } else {
          Swal.fire({
            title: this.props.router.t("error"),
            icon: "error",
          });
        }
      });
    }
  }

  cancel() {
    this.props.openModal(MODAL.CALL_CHECK);
  }

  deleteRow(uid: any) {
    this.setState({
      payments: this.state.payments.filter((row: any) => row.uid != uid),
    });
  }

  addRow() {
    this.setState({
      payments: [
        ...this.state.payments,
        {
          payment: {
            type: 1,
            amount: 0,
            currency:
              this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
              "TL",
          },
          uid: Functions.createUUID(),
        },
      ],
    });
  }

  handlePriceChange(value: any, uid: any) {
    let payment = this.state.payments.findSingle("uid", uid);
    payment.payment.amount = Number(value);
    this.setState({
      payments: this.state.payments,
    });
  }

  handleTypeChange(value: any, uid: any) {
    let payment = this.state.payments.findSingle("uid", uid);
    payment.payment.type = Number(value);
    this.setState({
      payments: this.state.payments,
    });
  }

  Payment = (props: any) => {
    return (
      <tr style={{ width: "100%" }}>
        <td style={{ width: "20vw", textAlign: "center" }}>
          <select
            onChange={(event) =>
              this.handleTypeChange(event.target.value, props.uid)
            }
            defaultValue={props.payment.type}
          >
            {PaymentTypes.map((payment_type: any, i: number) => (
              <option value={i + 1}>
                {" "}
                {this.props.router.t(payment_type)}{" "}
              </option>
            ))}
          </select>
        </td>
        <td style={{ width: "20vw", textAlign: "center" }}>
          <input
            min={0}
            type={"number"}
            defaultValue={props.payment.amount}
            onChange={(event) =>
              this.handlePriceChange(event.target.value, props.uid)
            }
            onClick={() => {
              ipcRenderer.send("showKeyboard");
            }}
          />
        </td>
        <td style={{ width: "10vw", textAlign: "center" }}>
          {props.payment.currency}
        </td>
        <td style={{ width: "9vw", textAlign: "center" }}>
          <button
            onClick={() => this.deleteRow(props.uid)}
            className="btn btn-danger"
          >
            {this.props.router.t("delete")}
          </button>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <>
        <div className="oldCheckContainer no-overflow">
          <table cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <td>
                  <table
                    style={{ width: "59vw" }}
                    cellSpacing="0"
                    cellPadding="1"
                  >
                    <tr style={{ color: "white", background: "grey" }}>
                      <th style={{ width: "20vw", textAlign: "center" }}>
                        {this.props.router.t("type")}
                      </th>
                      <th style={{ width: "20vw", textAlign: "center" }}>
                        {this.props.router.t("amount")}
                      </th>
                      <th style={{ width: "10vw", textAlign: "center" }}>
                        {this.props.router.t("genus")}
                      </th>
                      <th style={{ width: "9vw", textAlign: "center" }}>
                        {this.props.router.t("action")}
                      </th>
                    </tr>
                  </table>
                </td>
              </tr>
            </thead>
            <tbody>
              <div style={{ height: "32vh", overflow: "auto" }}>
                <table
                  style={{ width: "59vw" }}
                  cellSpacing="0"
                  cellPadding="1"
                >
                  {this.state.payments.map((payment: any) => (
                    <this.Payment {...payment} />
                  ))}
                  <tr>
                    <th style={{ width: "20vw", textAlign: "center" }}></th>
                    <th style={{ width: "20vw", textAlign: "center" }}>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.addRow()}
                      >
                        {this.props.router.t("add")}
                      </button>
                    </th>
                    <th style={{ width: "10vw", textAlign: "center" }}></th>
                    <th style={{ width: "9vw", textAlign: "center" }}></th>
                  </tr>
                </table>
              </div>
            </tbody>
          </table>
        </div>
        <div>
          <span style={{ paddingLeft: "7vw", fontWeight: 600 }}>
            {this.props.router.t("total-ticket-amount")}:{" "}
            {this.getTotal()
              .toFixed(2)
              .replace(/\.?0+$/, "")}
          </span>
          <span style={{ paddingLeft: "5vw", fontWeight: 600 }}>
            {this.props.router.t("remaining-ticket-amount")}:{" "}
            {this.getRemaining()
              .toFixed(2)
              .replace(/\.?0+$/, "")}
          </span>
        </div>
        <div className="changePriceButtons">
          <button style={{ background: "green" }} onClick={() => this.save()}>
            {" "}
            {this.props.router.t("submit")}
          </button>
          <button onClick={() => this.cancel()}>
            {this.props.router.t("cancel")}
          </button>
        </div>
      </>
    );
  }
}

export default Updatecheck;
