import { Component } from "react";
import Swal from "sweetalert2";
import "./optionmenu.css";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import clone from "clone";
import PageOrder from "../../../orders";
import { Select, MenuItem, InputLabel } from '@mui/material';
import Currencies from "constants/currencies";
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    selectedOptions: Array<any>;
    priceType: string;
    note: string;
    addCount: number,
    quantity: number,
    selectedNote: string
};

type PageProps = {
    data: PageOrder
} & PagePropCommonDocument;

class OptionMenu extends Component<PageProps, PageState> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            selectedOptions: [],
            priceType: this.props.data.state.selectedProduct.prices[0]._id,
            note: "",
            addCount: this.props.data.state.multiplier,
            quantity: 0,
            selectedNote: ""
        };
    }

    handleSubmit(isAll?: boolean): void {
        let remainForcedChoice = false;
        this.props.data.state.selectedProduct.options.forEach((item: any) => {
            if (item.is_forced_choice) {
                let x = this.state.selectedOptions.find((opt: any) => opt.option_id == item.option_id);
                if (x == undefined) {
                    remainForcedChoice = true;
                    return;
                }
            }
        });
        if (this.props.data.state.selectedProduct.sale_type == 2 && (!this.state.quantity || this.state.quantity <= 0)) {
            Swal.fire({
                icon: "error",
                title: "Hata"
            });
            return;
        }
        if (remainForcedChoice) {
            Swal.fire({
                title: this.props.router.t("option-warning"),
                icon: "warning",
            });
        } else {
            let addCount = isAll ? this.state.addCount : this.props.data.state.selectedProduct.sale_type == 2 ? this.state.quantity : 1;
            this.props.data.setNewOrderProduct(
                Object.assign(clone(this.props.data.state.selectedProduct), { options: this.state.selectedOptions }),
                addCount,
                true,
                this.state.note,
                this.state.priceType
            );
            if (this.props.data.state.selectedProduct.sale_type == 2) {
                this.props.closeModal();
            }
            if (
                isAll ||
                this.state.addCount === 1
            ) {
                this.props.closeModal();
            } else {
                this.setState((state: PageState) => {
                    state.addCount -= addCount;
                    return state;
                })
            }
        }
    }

    handleOptionSelect(option: any, item: any, isMulti: boolean): void {
        this.setState((state: PageState) => {
            let findIndex = state.selectedOptions.indexOfKey("option_id", option.option_id);
            if (findIndex > -1) {
                if (isMulti) {
                    let selectedOption = state.selectedOptions[findIndex];
                    let findIndexItem = selectedOption.linked.items.indexOfKey("_id", item._id);
                    if (findIndexItem > -1) {
                        selectedOption.linked.items.remove(findIndexItem);
                    } else {
                        if (selectedOption.linked.choose_limit > selectedOption.linked.items.length) {
                            selectedOption.linked.items.push(item);
                        } else {
                            Swal.fire({
                                title: this.props.router.t("max-product"),
                                icon: "error",
                            });
                        }
                    }
                } else {
                    state.selectedOptions[findIndex].linked.items = [item];
                }
            } else {
                option.linked.items = [item];
                state.selectedOptions.push(option);
            }
            return state;
        })
    }

    SelectedCount = (props: any) => {
        let findIndex = this.state.selectedOptions.indexOfKey("option_id", props.option_id);
        let count = findIndex > -1
            ? this.state.selectedOptions[findIndex].linked.items.length
            : 0
        return (count.toString());
    }
    selectedCount = (option_id: any) => {
        let findIndex = this.state.selectedOptions.indexOfKey("option_id", option_id.option_id);
        let count = findIndex > -1
            ? this.state.selectedOptions[findIndex].linked.items.length
            : 0
        return (count.toString())
    }

    OptionItem = (props: any) => {
        return (
            <div
                className="option"
                style={{
                    minWidth: "5vw",
                    backgroundColor: props.bgColor,
                    textAlign: "center"
                }}
                onClick={props.onClick}
            >{props.itemName} {props.price != 0 ? `(${props.price < 0 ? `-` : `+`}${props.price})` : ``}</div>
        );
    }

    Option = (props: any) => {
        props.option?.linked?.unlimitedChoice ? props.option.linked.choose_limit = props.option?.linked?.items.length : null;
        let findIndex = this.state.selectedOptions.indexOfKey("option_id", props.option.option_id);     
        let count = findIndex > -1
            ? this.state.selectedOptions.length>0? this.state.selectedOptions[findIndex].linked.items.length:0
            : 0
        return (props.option.linked === undefined? (
            <div></div>
          ) : (
            <div className="optionContainer">
                <div className="optionName">
                    {props.option.linked.name}
                    {props.option.is_forced_choice ? (
                        <span style={{ color: "red" }}>*</span>
                    ) : null}
                    <span style={{ color: "blue" }}>
                        {count}/{props.option.linked.choose_limit}
                    </span>
                </div>
                {
                    props.option.linked.items.map((item: any) => {
                        return (
                            <div
                                className="option"
                                style={{
                                    minWidth: "5vw",
                                    backgroundColor: this.getColor(props.option, item),
                                    textAlign: "center"
                                }}
                                onClick={() => this.handleOptionSelect(props.option, item, props.option.linked.type == 2)}
                            >{item.item_name} {item.price != 0 ? `(${item.price < 0 ? `-` : `+`}${item.price})` : ``}</div>
                        );
                    }

                    )}
            </div>
        ))
    }

    getColor = (option: any, item: any) => {
        let color = "red";
        let find = this.state.selectedOptions.findSingle("option_id", option.option_id);
        if (find) {
            if (find.linked.items.findSingle("_id", item._id)) {
                color = "green";
            }
        }
        return color;
    };

    getWeight = () => {
        let weight = ipcRenderer.sendSync('getWeight');
        this.setState({
            quantity: weight,
            addCount: weight
        })
    }
    
    render() {
        return (
            <div className="optionmenu" style={{ overflow: "scroll" }}>
                <div className="optionNote">

                    <>
                        <span>{this.props.router.t("price")}</span>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            {
                                this.props.data.state.selectedProduct.prices.map((price: any) => (
                                    <>
                                        <div>
                                            {price.priceName}
                                            <button
                                                className="option"
                                                style={{
                                                    minWidth: "5vw",
                                                    backgroundColor: this.state.priceType == price._id ? "green" : "blue",
                                                    textAlign: "center",
                                                    display: "block"
                                                }}
                                                onClick={(event: any) => this.setState({ priceType: event?.target.value })}
                                                value={price._id}
                                            > {price.price_name} {price.price} {Currencies[price.currency]} </button>
                                        </div>

                                    </>

                                ))
                            }
                        </div>
                    </>

                    {this.props.data.state.selectedProduct.sale_type == 2 || this.props.data.state.selectedProduct.sale_type == 3? (
                        <>
                            <span>{this.props.router.t('quantity')}</span>
                            <input type="number" value={this.state.quantity} onChange={(e: any) => this.setState({ quantity: e?.target.value, addCount: e?.target.value })} onClick={() => ipcRenderer.send('showKeyboard')} />
                            <button onClick={this.getWeight} style={{backgroundColor: "#F33030", color: "#fff", marginTop: 2, marginBottom: 1, margin: "auto", padding: 3, width: "50%", borderRadius: 8}}>{this.props.router.t('get-weight-from-scales')}</button>
                        </>
                    ) : null}
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center"}}>
                        <div>
                            <InputLabel id="noteLabel">{this.props.router.t('saved-notes')}</InputLabel>
                            <Select sx={{ minWidth: "8vw" }} labelId="noteLabel" value={this.state.selectedNote} onChange={(e: any) => {
                                this.setState({
                                    selectedNote: e.target.value,
                                    note: this.props.getGlobalData?.Notes?.find((note: any) => note._id == e.target.value).note
                                });
                            }}>
                                {this.props.getGlobalData?.Notes?.map((note: any) => (
                                    <MenuItem value={note._id}>
                                        {note.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div>
                            <span>{this.props.router.t("note")}</span>
                            <input
                                value={this.state.note}
                                onChange={(event: any) => this.setState({ note: event?.target.value })}
                                onClick={() => ipcRenderer.send('showKeyboard')}
                            />
                        </div>
                    </div>
                    <div
                        onClick={() => this.setState({ note: "" })}
                        className="clearButton"
                    >
                        {this.props.router.t("clear")}
                    </div>
                    <div onClick={() => this.handleSubmit(true)} className="submitOption">
                        {this.props.router.t("all")}
                    </div>
                    <div onClick={() => this.handleSubmit()} className="submitOption">
                        {this.props.router.t("add")} ({this.state.addCount})
                    </div>
                </div>
                <div>
                    <span className="optionTitle">{this.props.router.t("option")}</span>
                    <div className="optionList">
                        {
                            clone(this.props.data.state.selectedProduct.options).map((option: any) =>
                                <this.Option
                                    option={option}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default OptionMenu;
