import { CurrenciesParamDocument } from './../modules/services/api/branch.d';
import { AddServiceFee } from './../modules/services/tables.d';
import Api from "./api";
import {ServicePages} from "../public/ajax";
import {CustomerPostParamDocument, SpecialCustomerPost} from "../modules/services/customers";
import {CreateSpecialTable, TransferTableGetParamDocument} from "../modules/services/tables";
import {
    TakeawayPayParamDocument,
    TakeawayPostParamDocument,
} from "../modules/services/takeaway";
import {SignInPostParamDocument} from "../modules/services/signIn";
import {CasePostParamDocument} from "../modules/services/case";
import {CheckPostParamDocument} from "../modules/services/checks";
import {CostPostParamDocument} from "../modules/services/cost";
import {CourierPostParamDocument} from "../modules/services/couriers";
import {OrderPostParamDocument} from "../modules/services/orders";
import {CoverPostParamDocument} from "../modules/services/cover";
import {DiscountPostParamDocument} from "../modules/services/discount";
import {TickPostParamDocument} from "../modules/services/tick";

const Post = {
    case(params: CasePostParamDocument) {
        return Api.post({
            url: [ServicePages.case],
            data: params
        });
    },
    checks(params: any) {
        return Api.post({
            url: [ServicePages.checks, params.tableId, "pay"],
            data: params
        });
    },
    costs(params: CostPostParamDocument) {
        return Api.post({
            url: [ServicePages.costs],
            data: params
        });
    },
    couriers(params: CourierPostParamDocument) {
        return Api.post({
            url: [ServicePages.couriers],
            data: params
        });
    },
    customers(params: CustomerPostParamDocument) {
        return Api.post({
            url: [ServicePages.customers],
            data: params
        });
    },
    order(params: OrderPostParamDocument) {
        return Api.post({
            url: [ServicePages.orders, params.tableId],
            data: params
        });
    },
    cover(params: CoverPostParamDocument) {
        return Api.post({
            url: [ServicePages.covers, params.tableId],
            data: params
        });
    },
    discount(params: DiscountPostParamDocument) {
        return Api.post({
            url: [ServicePages.discount, params.tableId],
            data: params
        });
    },
    transferTable(params: TransferTableGetParamDocument) {
        return Api.post({
            url: [ServicePages.orders, "transfer"],
            data: params
        });
    },
    takeaway(params: TakeawayPostParamDocument) {
        return Api.post({
            url: [ServicePages.takeaway],
            data: params
        });
    },
    payTakeaway(params: TakeawayPayParamDocument) {
        return Api.post({
            url: [ServicePages.takeaway, params.id, "pay"],
            data: params
        });
    },
    tick(params: TickPostParamDocument) {
        return Api.post({
            url: [ServicePages.tick],
            data: params
        });
    },
    signIn(params: SignInPostParamDocument) {
        return Api.post({
            url: [ServicePages.signIn],
            data: params
        });
    },
    createSpecialTable(params: CreateSpecialTable){
        return Api.post({
            url: ["special-table"],
            data: params
        });
    },
    createSpecialCustomer(params: SpecialCustomerPost){
        return Api.post({
            url: ["special-customer"],
            data: params
        })
    },
    addServiceFee(params: AddServiceFee) {
        return Api.post({
            url: ["table", "service", params.tableId],
            data: params
        })
    },
    createOpenAccount(params: {name: string, gsm_no?: string}){
        return Api.post({
            url: ['open-account'],
            data: params
        })
    },
    currencies(params: CurrenciesParamDocument){
        return Api.post({
            url: ['currencies'],
            data: {currencySettings: params}
        })
    },
    pairDevice(params: {macAddress: string, deviceName: string}){
        return Api.post({
            url: ["pairing"],
            data: params
        })
    },
    pairWithCode(params: {pairCode: number, macAddress: string, deviceName: string}){
        return Api.post({
            url: ["pair-with-code"],
            data: params
        })
    },
    removeDiscountFromTick(params: {customerId: string, tickId: string, discountId: string, checkDiscount: string}){
        return Api.post({
            url: ['ticks', 'discount', params.customerId, params.tickId],
            data: params
        })
    },
    pairBarcode(params: {productId: string, barcode: string, plu?: string}){
        return Api.post({
            url: ['products', 'barcode', params.productId],
            data: params
        })
    },
    createCaseSale(params: {products: any[], payments: any[], discounts: any[], caseId: string}){
        return Api.post({
            url: ['case-sale'],
            data: params
        })
    },
    createCaseSaleTable(){
        return Api.post({
            url: ['case-sale', 'create'],
            data: {}
        })
    }
};

export default Post;
