import ReactDOMServer from "react-dom/server";
import DateFormat from "../date/date";
import Print from "../print/print";
import Settings, { PrinterDefaultColumnHeight } from "../settings/settings";

export default class Kitchen {
  static printKitchen(t: any, check: any, printer?: string) {
    if (!check.products?.length) return;
    let perDefaultHeight = PrinterDefaultColumnHeight;
    let height = 0;
    const products = check.products.map((order: any) => {
      height += perDefaultHeight;
      if (order.isFirst) return null;
      return (
        <>
          <div
            style={{
              marginBottom: 3,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ width: "fit-content" }}>
              <span style={{ fontSize: 18, fontWeight: 600 }}>
                {order.quantity}x{" "}
              </span>
              <span style={{ fontSize: 18, fontWeight: 600 }}>
                {" "}
                {order.productName} / {order.priceName}
              </span>
              <br />
              {order.note?.length > 0 ? (
                <span style={{ fontSize: 18, fontWeight: 600 }}>
                  {t("note")}: {order.note}
                </span>
              ) : null}
            </div>
          </div>
          {order.options &&
            order.options.map((option: any) => {
              height += perDefaultHeight;
              return (
                <div
                  style={{
                    marginBottom: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingLeft: 15,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  <span style={{ fontSize: 24, fontWeight: "bold" }}>.</span>
                  <strong>{option.name}:</strong>
                  <span style={{ overflow: "hidden", wordBreak: "break-all" }}>
                    {option.items?.map((sub: any) => {
                      height += perDefaultHeight;
                      return <strong>{sub.name},</strong>;
                    })}
                  </span>
                </div>
              );
            })}
        </>
      );
    });
    const firstProducts = check?.firstOrders?.map((order: any) => {
      height += perDefaultHeight;
      return (
        <>
          <div
            style={{
              marginBottom: 3,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <div style={{ width: "fit-content" }}>
              <span style={{ fontSize: 18, fontWeight: 600 }}>
                {order.quantity}x{" "}
              </span>
              <span style={{ fontSize: 18, fontWeight: 600 }}>
                {" "}
                {order.productName}{" "}
              </span>
            </div>
          </div>
          {order.options &&
            order.options.map((option: any) => {
              height += perDefaultHeight;
              return (
                <div
                  style={{
                    marginBottom: 3,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingLeft: 15,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  <span style={{ fontSize: 24, fontWeight: "bold" }}>.</span>{" "}
                  <strong>{option.name}:</strong>
                  <span style={{ overflow: "hidden", wordBreak: "break-all" }}>
                    {option.items?.map((sub: any) => {
                      height += perDefaultHeight;
                      return <strong>{sub.name},</strong>;
                    })}
                  </span>
                </div>
              );
            })}
        </>
      );
    });

    const dataTop = [
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${
          Settings.CurrentSafePrinterName === (undefined || "")
            ? "DIGIGARSON"
            : Settings.CurrentSafePrinterName
        }</div>`,
      },
      {
        type: "text",
        value: `<div style=" display: flex; flex-direction: column; justify-content: center; width: 100%; text-align: center">
                            ${
                              check.invoiceName
                                ? `<span>${check.invoiceName}</span><br>`
                                : ""
                            }
                            <span>${t(
                              "date"
                            )}: ${new Date().toLocaleString()}</span><br>
                            <span>${t("waiter")} / ${t("authorised")}: ${
          check.waiter
            ? check.waiter
            : check.user?.name + " " + check.user?.lastname
        }</span><br/>
                            <span>${
                              check.customer
                                ? `${t("address")}: ${check.address}`
                                : `${t("section")}: ${check.section} ${t(
                                    "table"
                                  )}: ${check.table}`
                            }</span><br>
                            <span>${
                              check.customer
                                ? `${t("person")}: ${check.customer.full_name}`
                                : ``
                            }</span><br>
                        </div>
                        <hr />
                        <div style="display: flex; flex-direction: column, justify-content: center, width: 100%, ">
                            <span style="font-weight: 700; font-size:18px; text-align:center;">${t(
                              "products"
                            )}</span>
                        </div>`,
      },
    ];
    const dataFirstOrders = [
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(firstProducts),
      },
      {
        type: "text",
        value: `<div style="display: flex; flex-direction: column; justify-content: center; width: 100%; text-align:center; font-weight: 700; font-size:22px"">
                --------------------------
            </div>`,
      },
    ];

    const dataOrders = [
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(products),
      },
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(
          <>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span style={{ left: 0 }}>..</span>
              <span style={{ right: 0 }}></span>
            </div>
            <hr />
          </>
        ),
      },
    ];
    let data: any[] = [];
    if (check?.firstOrders?.length > 0) {
      data = dataTop.concat(dataFirstOrders.concat(dataOrders));
    } else {
      data = dataTop.concat(dataOrders);
    }

    Print.print(Settings.payload_with_settings(data), height, printer);
  }
  static printKitchenMainCheck(t: any, check: any, printer?: string) {
    const dataTop = [
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${
          Settings.CurrentSafePrinterName === (undefined || "")
            ? "DIGIGARSON"
            : Settings.CurrentSafePrinterName
        }</div>`,
      },
      {
        type: "text",
        value: `<div style=" font-size:17px; display: flex; flex-direction: column; justify-content: center; width: 100%; text-align: center">
                            ${
                              check.invoiceName
                                ? `<span>${check.invoiceName}</span><br>`
                                : ""
                            }
                            <span>${t(
                              "date"
                            )}: ${new Date().toLocaleString()}</span><br>
                            <span>${t("waiter")} / ${t("authorised")}: ${
          check.waiter
            ? check.waiter
            : check.user?.name + " " + check.user?.lastname
        }</span><br/>
                            <span>${
                              check.customer
                                ? `${t("address")}: ${check.address}`
                                : `${t("section")}: ${check.section} ${t(
                                    "table"
                                  )}: ${check.table}`
                            }</span><br>
                            <span>${
                              check.customer
                                ? `${t("person")}: ${check.customer.full_name}`
                                : ``
                            }</span><br>
                        </div>
                        <hr />`,
      },
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:18px; text-align:center;">${t(
          "sendMainOrder"
        )}</div>`,
      },
    ];

    const dataOrders = [
      {
        type: "text",
        value: ReactDOMServer.renderToStaticMarkup(
          <>
            <hr />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span style={{ left: 0 }}>..</span>
              <span style={{ right: 0 }}></span>
            </div>
            <hr />
          </>
        ),
      },
    ];
    let data: any[] = [];
    data = dataTop.concat(dataOrders);
    Print.print(Settings.payload_with_settings(data), 0, printer);
  }
}
