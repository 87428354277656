import React, { Component, useState, useEffect } from 'react'
import { PagePropCommonDocument } from 'modules/views/pages/pageProps';
import { Button, Grid, Typography, Box, AppBar, Toolbar, TextField, Avatar, Stack, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText } from "@mui/material"
import { KeyboardReturn } from '@mui/icons-material';
import Currencies from 'constants/currencies';
import { getGlobalAuthData } from 'config/global';
import { ToastContainer, toast } from 'react-toastify';
import printers from 'config/global/printers';

const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageProps = {} & PagePropCommonDocument;

type PageState = {
    products: any[];
    searchText: string;
    printBarcodeDialog: boolean;
    selectedProduct: any
}

export default class Scales extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            products: [],
            searchText: "",
            printBarcodeDialog: false,
            selectedProduct: null
        }
    }

    componentDidMount(): void {
        this.setState({
            products: this.props.getGlobalData?.Products?.filter((p: any) => p.barcode && p.sale_type == 2)
        });
    }

    getCategory = (product: any) => {
        return this.props.getGlobalData.Categories?.find((c: any) => c._id == product.category).title
    }

    PrintBarcode = () => {
        const [weight, setWeight] = useState(0);
        const [interval, setInt] = useState(false);
        const [scalesWeight, setScalesWeight] = useState(0);
        const handleClose = () => {
            setWeight(0);
            this.setState({
                printBarcodeDialog: false
            });
        }

        const formatWeight = (weight: number) => {
            let length = weight.toString()?.length;
            let dif = 5 - length;
            let str = weight?.toString();
            if (dif > 0) {
                for (let i = 0; i < dif; i++) {
                    str = "0" + str
                }
            }
            return str;
        }

        const handleSubmit = () => {
            if (!this.state.selectedProduct) return;
            let resWeight = weight && weight > 0 ? weight : scalesWeight * 1000;
            if (!resWeight || resWeight <= 0) {
                toast.warning(this.props.router.t('print-barcode-warning'));
                return;
            }
            let formattedWeight = formatWeight(resWeight);
            let data = {
                productName: this.state.selectedProduct?.title,
                price: this.state.selectedProduct?.prices[0].price * (resWeight / 1000),
                currency: this.state.selectedProduct?.prices[0].currency,
                value: `${this.state.selectedProduct?.barcode} ${formattedWeight}`,
                user: `${getGlobalAuthData()?.user?.name} ${getGlobalAuthData()?.user?.lastname}`,
                branch: getGlobalAuthData()?.user?.branchTitle,
                weight: resWeight,
                unitPrice: this.state.selectedProduct?.prices[0].price,
                pairCode: false,
                sale_type: this.state.selectedProduct?.sale_type
            }
            let res = printers.PrintBarcode.printBarcode(data);
            if (!res) return;
            toast.success(this.props.router.t('barcode-printed-success'));
            this.setState({
                printBarcodeDialog: false
            });
        }

        useEffect(() => {
            if (interval) return;
            setInt(true);
            let intervalId = setInterval(() => {
                if (this.state.printBarcodeDialog) {
                    let weight = ipcRenderer.sendSync('getWeight');
                    if (weight >= 0) {
                        setScalesWeight(weight);
                    }
                }
            }, 500);
            return () => clearInterval(intervalId);
        }, [])

        return (
            <Dialog open={this.state.printBarcodeDialog} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{
                        backgroundColor: "#C12E2E",
                        padding: "0.5vw",
                        borderRadius: 8,
                        width: "60%",
                        margin: "auto",
                        color: "#fff"
                    }}>
                        <Typography variant='h4' textAlign="center">
                            {this.props.router.t('print-barcode')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" alignContent="center">
                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center" alignContent="center">
                            <Typography variant='h6'>{this.state.selectedProduct?.title}</Typography>
                            <Typography variant='h6'>{this.state.selectedProduct?.prices[0]?.price} {this.state.selectedProduct?.prices[0]?.currency}</Typography>
                        </Stack>
                        <Box>
                            <Typography fontSize="1.4vw" fontWeight="bold" textAlign="center">{scalesWeight} Kg</Typography>
                        </Box>
                        <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
                            <TextField type="number" label={this.props.router.t('weight-gr')} value={weight} onChange={(e: any) => setWeight(e.target.value)} onClick={() => ipcRenderer.send('showKeyboard')} />
                            <FormHelperText>
                                {this.props.router.t('print-barcode-helper')}
                            </FormHelperText>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color="success" onClick={handleSubmit}>{this.props.router.t('print')}</Button>
                    <Button variant='outlined' color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog >
        )
    }

    render() {
        return (
            <Box>
                <ToastContainer />
                <this.PrintBarcode />
                <AppBar sx={{ backgroundColor: "#2F353E", padding: 2 }}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" sx={{ height: "50%" }}>
                            <Button variant='contained' sx={{ height: "auto" }} onClick={() => this.props.router.navigate("/", { replace: true })} startIcon={<KeyboardReturn />} color="error">{this.props.router.t('back')}</Button>
                            <Box sx={{ width: "25vw", margin: "auto", marginLeft: "2vw", marginRight: "2vw", backgroundColor: "#3F4E6D", padding: "0.7vw", borderRadius: "8px" }}>
                                <Typography textAlign="center" variant="h4" sx={{ fontWeight: "bold", fontFamily: "serif", fontSize: "1.8vw" }}>{this.props.router.t('scales')}</Typography>
                            </Box>
                            <Box sx={{ height: "auto" }}>
                                <TextField placeholder={this.props.router.t('search')} onClick={() => ipcRenderer.send('showKeyboard')} onChange={(e: any) => this.setState({ searchText: e.target.value })} sx={{
                                    width: "20vw", border: "none",
                                    '.MuiInputBase-input': { backgroundColor: "#fff", borderRadius: "20px", border: "none" },
                                    '.MuiOutlinedInput-root': { borderRadius: "20px" }
                                }} />
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box className="content" sx={{ height: "85vh", overflowY: "scroll", padding: 5 }}>
                    {this.state.products?.length == 0 ? (
                        <Typography variant="h3" textAlign="center" sx={{ color: "#fff", fontFamily: "serif" }}>{this.props.router.t('has-no-paired-product')}</Typography>
                    ) : (
                        <Box sx={{ display: "flex", margin: "auto", marginTop: 0, width: "95%" }}>
                            <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
                                alignItems="center" sx={{ margin: "auto", width: "50%" }}
                            >
                                {this.state.products?.filter((product: any) => product.title?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()))?.map((product: any, i: number) => (
                                    <Grid item xs={2} sm={3} md={3} key={i}>
                                        <Box onClick={() => {
                                            this.setState({
                                                selectedProduct: product,
                                                printBarcodeDialog: true
                                            })
                                        }} sx={{ backgroundColor: "#C14C4C", width: "20vw", padding: "1vw", borderRadius: "5px", "&:hover": { transform: "scale(1.1)", boxShadow: "3px 5px #fff" }, "&:active": { backgroundColor: "#B96666" } }}>
                                            <Stack alignItems="center" spacing={1} sx={{ color: "#fff" }}>
                                                <Avatar src={product.image} variant='rounded' sx={{ width: "10vw", height: "7vw" }} />
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}:</span> {product.title}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('category')}:</span> {this.getCategory(product)}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('price')}:</span> {product.prices[0].price} {Currencies[product.prices[0]?.currency] || "₺"}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('barcode')}:</span> {product.barcode}</Typography>
                                                {product.plu ? (
                                                    <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('plu-text')}:</span> {product.plu}</Typography>
                                                ) : null}
                                            </Stack>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                    )}
                </Box>
            </Box>
        )
    }
}
