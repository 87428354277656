import GlobalStates from "config/global/states";
import Swal from "sweetalert2";
import Services from "../../../../services/index";
import PageOrder from "../../../../app/views/pages/orders";
import { PagePropCommonDocument } from "../../../../modules/views/pages/pageProps";
import Table from "../../../../app/views/pages/orders/check/components/table";
import Printer from "config/global/printers/index";
import { getGlobalAuthData } from "config/global/auth";

const checkSemiIngredient = (
  semiIngredient: any,
  commonProp: PagePropCommonDocument,
  quantity: number
): any[] => {
  let errors: any[] = [];
  semiIngredient?.detail?.map((product: any) => {
    if (product.productType == "SemiIngredient") {
      let semi = commonProp.getGlobalData?.semiIngredients?.find(
        (s: any) => s._id == product.productId
      );
      if (!semi) {
        errors.push({
          product: product.product,
          error: "stock-not-found",
        });
        return;
      }
      let errs: any[] = checkSemiIngredient(
        semi,
        commonProp,
        quantity * product.quantity
      );
      errors = errors.concat(errs);
    } else {
      let ingredient = commonProp.getGlobalData?.stocks?.find(
        (stock: any) => stock._id == product.productId
      );
      if (!ingredient) {
        errors.push({
          product: product.product,
          error: "stock-not-found",
        });
        return;
      }
      if (ingredient.stock < quantity * product.quantity) {
        errors.push({
          product: product.product,
          error: "stock-insufficient",
        });
        return;
      }
    }
  });
  return errors;
};

const checkRecipe = (
  recipe: any,
  commonProp: PagePropCommonDocument,
  quantity: number
) => {
  let errors: any[] = [];
  recipe.detail?.map((product: any) => {
    if (product.productType == "SemiIngredient") {
      let semi = commonProp.getGlobalData?.semiIngredients?.find(
        (s: any) => s._id == product.productId
      );
      if (!semi) {
        errors.push({
          product: product.product,
          error: "stock-not-found",
        });
        return;
      }
      let errs: any[] = checkSemiIngredient(
        semi,
        commonProp,
        quantity * product.quantity
      );
      errors = errors.concat(errs);
    } else {
      let ingredient = commonProp.getGlobalData?.stocks?.find(
        (stock: any) => stock._id == product.productId
      );
      if (!ingredient) {
        errors.push({
          product: product.product,
          error: "stock-not-found",
        });
        return;
      }
      if (ingredient.stock < quantity * product.quantity) {
        errors.push({
          product: product.product,
          error: "stock-insufficient",
        });
        return;
      }
    }
  });
  return errors;
};

const checkStocks = (orders: any[], commonProp: PagePropCommonDocument) => {
  let errors: any[] = [];
  orders.map((order: any) => {
    let find = commonProp.getGlobalData?.recipes?.find(
      (p: any) => p.product == order.product
    );
    if (find && find.isActive) {
      let error = checkRecipe(find, commonProp, order.quantity);
      errors = errors.concat(error);
    }
  });
  return errors;
};

const SendOrder = (
  commonProp: PagePropCommonDocument,
  pageOrder: PageOrder,
  pageOrderTable?: Table
) => {
  if (pageOrder.state.newOrders.length === 0) {
    return;
  }
  const table = commonProp.getGlobalData.AllTables.findSingle(
    "_id",
    GlobalStates.SelectedTableId
  );
  let orders = pageOrder.state.newOrders.map((product) => ({
    product: product.productId,
    productName: product.productName,
    isFirst: pageOrderTable?.state.sendFirstIds.includes(product._id) ?? false,
    quantity: product.quantity,
    category: product.category,
    price: product.priceId,
    options: product.options ?? [],
    note: product.note,
    priceName: product.priceName,
    isServe: product.isServe ? true : false,
    serveId: product.serveId ? product.serveId : null,
  }));

  const errors = checkStocks(orders, commonProp);
  if (errors?.length > 0) {
    let str = "";
    errors.map((error: any) => {
      str = str + `${error.product}: ${commonProp.router.t(error.error)}\n`;
    });
    Swal.fire({
      title: commonProp.router.t("stock-error"),
      text: str,
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText: commonProp.router.t("cancel"),
      confirmButtonText: commonProp.router.t("yes"),
    }).then((response) => {
      if (response.isConfirmed) {
        Swal.fire({
          title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
          html: commonProp.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });
        let user = getGlobalAuthData().user;

        if (GlobalStates.CustomerId) {
          let success = true;
          const ordersMap = {
            products: orders,
            customer: GlobalStates.CustomerId,
            address: 0,
            courier: GlobalStates.SelectedCourierId
              ? GlobalStates.SelectedCourierId
              : "",
            defaultPaymentType: GlobalStates.SelectedPaymentTypeId,
          };

          Services.Post.takeaway(ordersMap).then((resData) => {
            if (resData.status) {
              Swal.fire({
                title: commonProp.router.t("order-created"),
                icon: "success",
                timer: 2000,
              }).then(() => {
                commonProp.router.navigate("/", { replace: true });
              });
            } else {
              Swal.fire({
                icon: "error",
                title: commonProp.router.t("error-try-again"),
              });
              success = false;
            }
          });
          if (!success) return;
        } else {
          const ordersMap = orders;
          let params = {
            orderNum: GlobalStates.SelectedOrderNum,
            tableId: GlobalStates.SelectedTableId,
            products: ordersMap,
            section: commonProp.getGlobalData.Sections?.findSingle(
              "_id",
              table.section
            )?.title,
          };
          Services.Post.order(params).then((resData) => {
            if (resData.status && Array.isArray(resData.data.orders)) {
              pageOrderTable?.setState({
                or: resData.data.orders,
                totalPrice: resData.data.totalPrice,
                remainingPrice: resData.data.remainingPrice,
              });
              pageOrder.setState({
                totalAmount: 0,
              });
              pageOrder.setState({
                newOrders: [],
              });
              let tables = commonProp.getGlobalData.AllTables.map((table) => {
                if (table._id == params.tableId) {
                  table.updatedAt = new Date();
                  table.orders = resData.data.orders;
                  table.totalPrice = resData.data.totalPrice;
                  table.remainingPrice = resData.data.remainingPrice;
                }
                return table;
              });
              try {
                Printer.KitchenByGroup.printKitchenByGroup(
                  commonProp.router.t,
                  {
                    ...params,
                    user: user,
                  }
                );
              } catch (e: any) {
                Swal.fire({
                  title: e.message,
                  icon: "error",
                });
              }
              commonProp.setGlobalData({}, () => {
                Swal.fire({
                  title: commonProp.router.t("order-created"),
                  icon: "success",
                  timer: 2000,
                });
                commonProp.router.navigate("/", { replace: true });
              });
            }
          });
        }
      }
    });
  } else {
    Swal.fire({
      title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
      html: commonProp.router.t("uploading") + "...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      willOpen(popup: HTMLElement) {
        Swal.showLoading();
      },
    });
    let user = getGlobalAuthData().user;

    if (GlobalStates.CustomerId) {
      let success = true;
      const ordersMap = {
        products: orders,
        customer: GlobalStates.CustomerId,
        address: 0,
        courier: GlobalStates.SelectedCourierId
          ? GlobalStates.SelectedCourierId
          : "",
        defaultPaymentType: GlobalStates.SelectedPaymentTypeId,
      };

      Services.Post.takeaway(ordersMap).then((resData) => {
        if (resData.status) {
          Swal.fire({
            title: commonProp.router.t("order-created"),
            icon: "success",
            timer: 2000,
          }).then(() => {
            commonProp.router.navigate("/", { replace: true });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: commonProp.router.t("error-try-again"),
          });
          success = false;
        }
      });
      if (!success) return;
    } else {
      const ordersMap = orders;
      let params = {
        orderNum: GlobalStates.SelectedOrderNum,
        tableId: GlobalStates.SelectedTableId,
        products: ordersMap,
        section: commonProp.getGlobalData.Sections?.findSingle(
          "_id",
          table.section
        )?.title,
      };
      Services.Post.order(params).then((resData) => {
        if (resData.status && Array.isArray(resData.data.orders)) {
          pageOrderTable?.setState({
            or: resData.data.orders,
            totalPrice: resData.data.totalPrice,
            remainingPrice: resData.data.remainingPrice,
          });
          pageOrder.setState({
            totalAmount: 0,
          });
          pageOrder.setState({
            newOrders: [],
          });
          let tables = commonProp.getGlobalData.AllTables.map((table) => {
            if (table._id == params.tableId) {
              table.updatedAt = new Date();
              table.orders = resData.data.orders;
              table.totalPrice = resData.data.totalPrice;
              table.remainingPrice = resData.data.remainingPrice;
            }
            return table;
          });
          try {
            Printer.KitchenByGroup.printKitchenByGroup(commonProp.router.t, {
              ...params,
              user: user,
            });
          } catch (e: any) {
            Swal.fire({
              title: e.message,
              icon: "error",
            });
          }
          commonProp.setGlobalData({}, () => {
            Swal.fire({
              title: commonProp.router.t("order-created"),
              icon: "success",
              timer: 2000,
            });
            commonProp.router.navigate("/", { replace: true });
          });
        }
      });
    }
  }
};

export default SendOrder;
