import { Component, useState } from "react";
import Swal from "sweetalert2";
import Printer from "config/global/printers/index";
import { MODAL } from "constants/modalTypes";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import { GlobalStates } from "config/global";
import Services from "services/index";
import PageOrder, { PageOrderState } from "../../../orders";
import { payment_types } from "../../../../../../constants/paymentTypes";
import Print from "config/global/printers/print/print";
import Settings, { PrinterDefaultColumnHeight } from "config/global/printers/settings/settings";
import clone from "clone";
import {
  Dialog,
  DialogActions,
  Button,
  Grid,
  Select,
  MenuItem,
  Box,
  InputLabel,
  DialogContent,
  Typography,
  Stack,
  DialogTitle,
  TextField,
  FormHelperText,
  ButtonGroup,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import Currencies from "constants/currencies";
import checkPerm from "config/global/functions/perm/checkPerm";
import { payment_methods } from "constants/payment_methods";
const ipcRenderer: any = window.require("electron").ipcRenderer;

interface IOrders {
  orderNum: number;
  products: any[];
}

interface IProduct {
  id: string;
  quantity: number;
}

type PageState = {
  coma: boolean;
  personCount: number;
  allProducts: any[];
  aProducts: any[];
  covers: any[];
  selectedProducts: any[];
  paidProducts: any[];
  discounts: any[];
  totalPrice: number;
  willPayTotalPrice: number;
  remainingPrice: number;
  allOrders: any[];
  paidOrders: any[];
  selectedOrders: any[];
  selectedCovers: any[];
  notPayableDialog: boolean;
  createDialog: boolean;
  serviceDialog: boolean;
  totalServiceFee: number;
  tipDialog: boolean;
  tickDialog: boolean;
  payments: any[];
  selectedType: number;
  selectedCustomer: string;
  serviceFees: any;
};

type PageProps = {
  data: PageOrder;
} & PagePropCommonDocument;

class Checkout extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      coma: false,
      personCount: 1,
      allProducts: [],
      covers: [],
      selectedProducts: [],
      totalPrice: 0,
      paidProducts: [],
      willPayTotalPrice: 0,
      remainingPrice: 0,
      discounts: [],
      allOrders: [],
      paidOrders: [],
      selectedOrders: [],
      aProducts: [],
      selectedCovers: [],
      notPayableDialog: false,
      createDialog: false,
      serviceDialog: false,
      tipDialog: false,
      tickDialog: false,
      totalServiceFee: 0,
      payments: [],
      selectedType: 1,
      selectedCustomer: "",
      serviceFees: [],
    };
  }

  componentDidMount() {
    this.sets();
  }

  sets() {
    if (GlobalStates.CurrentTable.isSafeSales) {
      this.setState((state: PageState) => {
        state.paidProducts = [];
        state.allProducts = [];
        state.selectedProducts = [];
        let table = this.props.getGlobalData.AllTables.findSingle(
          "_id",
          GlobalStates.SelectedTableId
        );
        if (!table) return;
        if (table.serviceFee?.length > 0) {
          table.serviceFee?.map((service: any) => {
            state.totalServiceFee += service.amount;
          });
        }
        state.remainingPrice =
          this.props.data.state.totalAmount + table.remainingPrice;
        state.discounts = table.discount;
        let discountAmount = 0;
        state.discounts.map((d: any) => {
          discountAmount += Number(d.amount);
        });
        let orders = clone(this.props.data.state.newOrders);
        state.totalPrice =
          this.props.data.state.totalAmount +
          state.totalServiceFee -
          discountAmount;
        var pOrders: IOrders[] = [];
        var aOrders: IOrders[] = [];
        aOrders.push({ orderNum: 1, products: orders });
        aOrders.map((o: any) => {
          o.products = o.products.filter((p: any) => !p.isServe);
        });
        pOrders?.map((o: any) =>
          o.products?.map((p: any) => state.paidProducts.push(p))
        );
        pOrders?.map((o: any) => state.paidOrders.push(o));
        aOrders?.map((o: any) =>
          o.products
            ?.filter((p: any) => !p.isServe)
            .map((p: any) => state.allProducts.push(p))
        );
        aOrders?.map((o: any) =>
          o.products
            ?.filter((p: any) => !p.isServe)
            .map((p: any) => state.aProducts.push(clone(p)))
        );
        aOrders?.map((o: any) => state.allOrders.push(o));
        return state;
      });
    } else {
      this.setState((state: PageState) => {
        state.paidProducts = [];
        state.allProducts = [];
        state.selectedProducts = [];
        let table = this.props.getGlobalData.AllTables.findSingle(
          "_id",
          GlobalStates.SelectedTableId
        );
        if (!table) return;
        if (table.serviceFee?.length > 0) {
          table.serviceFee?.map((service: any) => {
            state.totalServiceFee += service.amount;
          });
        }
        state.remainingPrice = table.remainingPrice;
        state.covers = table.cover.filter((c: any) => !c.is_paid);
        let orders = table.orders;
        state.discounts = table.discount;
        let discountAmount = 0;
        state.discounts.map((d: any) => {
          discountAmount += Number(d.amount);
        });
        if (table.isSafeSales) {
          orders = clone(this.props.data.state.newOrders);
        }
        state.totalPrice = table.remainingPrice;
        var pOrders: IOrders[] = [];
        var aOrders: IOrders[] = [];

        //TODO
        if (!table.isSafeSales) {
          orders.forEach((order: any) => {
            let paidOrder = table.paid_orders.findSingle(
              "orderNum",
              order.orderNum
            );
            if (paidOrder) {
              let findIndex: number = table.paid_orders.findIndex(
                (o: any) => o.orderNum == order.orderNum
              ); //order interfaceleri
              order.products.forEach((product: any, index: number) => {
                //farklı product interface ayarlanacak
                let paidProduct: IProduct = table.paid_orders[
                  findIndex
                ].products.findSingle("_id", product._id);
                if (paidProduct) {
                  if (order.products[index].quantity == paidProduct.quantity) {
                    let find = pOrders.findSingle("orderNum", order.orderNum);
                    if (!find) {
                      let o = { orderNum: order.orderNum, products: [product] };
                      pOrders.push(o);
                    } else {
                      let findIndex: number = pOrders.findIndex(
                        (o: any) => o.orderNum == order.orderNum
                      );
                      pOrders[findIndex].products.push(product);
                    }
                    return;
                  } else {
                    let remainingQuantity: number =
                      order.products[index].quantity - paidProduct.quantity;
                    let unitPrice =
                      order.products[index].price /
                      order.products[index].quantity;
                    let paidAmount = paidProduct.quantity * unitPrice;
                    let remainingAmount =
                      order.products[index].price - paidAmount;
                    let pProduct = product;
                    pProduct.quantity = paidProduct.quantity;
                    pProduct.price = paidAmount;
                    let rProduct = product;
                    rProduct.quantity = remainingQuantity;
                    rProduct.price = remainingAmount;
                    let find = pOrders.findIndex(
                      (o: any) => o.orderNum == order.orderNum
                    );
                    if (find > -1) {
                      pOrders[find].products.push(pProduct);
                    } else {
                      let o = {
                        orderNum: order.orderNum,
                        products: [pProduct],
                      };
                      pOrders.push(o);
                    }
                    let aFind = aOrders.findIndex(
                      (o: any) => o.orderNum == order.orderNum
                    );
                    if (aFind > -1) {
                      aOrders[aFind].products.push(rProduct);
                    } else {
                      let o = {
                        orderNum: order.orderNum,
                        products: [rProduct],
                      };
                      aOrders.push(o);
                    }
                    return;
                  }
                }
                let find: any = aOrders.findSingle("orderNum", order.orderNum);
                if (!find) {
                  let o = { orderNum: order.orderNum, products: [product] };
                  aOrders.push(o);
                } else {
                  let findIndex: number = aOrders.findIndex(
                    (o: any) => o.orderNum == order.orderNum
                  );
                  aOrders[findIndex].products.push(product);
                }
                return;
              });
              return;
            } else {
              aOrders.push(order);
            }
            return;
          });
        } else {
          aOrders.push({ orderNum: 1, products: orders });
        }
        aOrders.map((o: any) => {
          o.products = o.products.filter((p: any) => !p.isServe);
        });
        pOrders?.map((o: any) =>
          o.products?.map((p: any) => state.paidProducts.push(p))
        );
        pOrders?.map((o: any) => state.paidOrders.push(o));
        aOrders?.map((o: any) =>
          o.products
            ?.filter((p: any) => !p.isServe)
            .map((p: any) => state.allProducts.push(p))
        );
        aOrders?.map((o: any) =>
          o.products
            ?.filter((p: any) => !p.isServe)
            .map((p: any) => state.aProducts.push(clone(p)))
        );
        aOrders?.map((o: any) => state.allOrders.push(o));
        state.remainingPrice = table.remainingPrice;
        let totalPrice = 0;

        if (table.isSafeSales || GlobalStates.isFastPayment) {
          if (table.isSafeSales) {
            orders.forEach((p: any) => {
              state.selectedProducts.push(p);
            });
          }
          if (GlobalStates.isFastPayment) {
            state.totalPrice = table.remainingPrice;
            state.willPayTotalPrice = table.remainingPrice;
            state.remainingPrice = 0;
            orders.map((o: any) =>
              o.products
                .filter((p: any) => !p.isServe && p.status != 0)
                .map((p: any) => state.selectedProducts.push(p))
            );
            table.cover?.map((c: any) => state.selectedCovers?.push(c));
            state.covers = [];
          }
          state.allOrders.map((o: any) => {
            o.products = o.products.filter(
              (p: any) => !p.isServe && p.status != 0
            );
          });
          state.allOrders.map((o: any) => state.selectedOrders.push(clone(o)));
          state.selectedProducts
            .filter((p: any) => !p.isServe && p.status != 0)
            .map((p: any) => (totalPrice += Number(p.price)));
          state.allProducts = [];
          if (table.isSafeSales) {
            state.willPayTotalPrice = state.totalPrice;
            state.allOrders = [{ orderNum: 1, products: [] }];
          }
          if (GlobalStates.isFastPayment) {
            state.allOrders.map((o: any) => {
              o.products = [];
            });
          }
        }

        GlobalStates.isFastPayment = false;

        return state;
      });
    }
  }

  CreateUserDialog = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);

    const createUser = async () => {
      if (!name) {
        toast.warning(this.props.router.t("provide-name"));
        return;
      }
      if (Number(discount) > 100) {
        toast.warning(this.props.router.t("discount-value-error"));
        return;
      }
      setLoading(true);
      let params = {
        name: name,
        gsm_no: phone || undefined,
        discount: discount || 0,
      };
      let res = await Services.Post.createSpecialCustomer(params);
      if (res.status) {
        let users = Services.Get.specialCustomers().data;
        this.props.setGlobalData({
          SpecialCustomers: users,
        });
        setLoading(false);
        toast.success("Başarılı");
        this.setState({ createDialog: false });
        return;
      }
      toast.error("Hata");
      setLoading(false);
    };
    return (
      <Dialog
        open={this.state.createDialog}
        onClose={() => this.setState({ createDialog: false })}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {this.props.router.t("create-special-customer")}
        </DialogTitle>
        <DialogContent>
          <Stack
            sx={{ marginTop: "1.5vw" }}
            direction="row"
            spacing={5}
            alignItems="center"
          >
            <TextField
              label={this.props.router.t("name")}
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />
            <Box>
              <TextField
                label={this.props.router.t("phone")}
                value={phone}
                onChange={(e: any) => setPhone(e.target.value)}
              />
              <FormHelperText>{this.props.router.t("optional")}</FormHelperText>
            </Box>
            <TextField
              label={this.props.router.t("discount") + " (%)"}
              value={discount}
              type="number"
              onChange={(e: any) => setDiscount(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={loading}
            color="success"
            onClick={() => createUser()}
          >
            {this.props.router.t("save")}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ createDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  NotPayableDialog = () => {
    const [note, setNote] = useState("");
    const [selectedNote, setSelectedNote] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );

    return (
      <Dialog
        open={this.state.notPayableDialog}
        onClose={() => this.setState({ notPayableDialog: false })}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          <Stack direction="column" spacing={4}>
            <Box>
              <Typography>{note}</Typography>
            </Box>
            <Box>
              <Stack direction="row" spacing={3} alignItems="center">
                <Box>
                  <InputLabel id="note">
                    {this.props.router.t("note")}
                  </InputLabel>
                  <Select
                    sx={{ minWidth: "10vw" }}
                    labelId="note"
                    value={selectedNote}
                    onChange={(e: any) => {
                      setSelectedNote(e.target.value);
                      let note = this.props.getGlobalData.Notes.find(
                        (n: any) => n._id == e.target.value
                      );
                      if (note) setNote(note.note);
                    }}
                  >
                    {this.props.getGlobalData.Notes.map((note: any) => (
                      <MenuItem value={note._id}>{note.title}</MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box>
                      <InputLabel id="account">
                        {this.props.router.t("select-special-customer")}
                      </InputLabel>
                      <Select
                        sx={{ minWidth: "10vw" }}
                        value={selectedUser}
                        onChange={(e: any) => setSelectedUser(e.target.value)}
                        labelId="account"
                      >
                        {this.props.getGlobalData.SpecialCustomers?.map(
                          (customer: any) => (
                            <MenuItem value={customer._id}>
                              {customer.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => this.setState({ createDialog: true })}
                        startIcon={<AddIcon />}
                      >
                        {this.props.router.t("create-special-customer")}
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              if (!selectedNote || !selectedUser) {
                toast.error(this.props.router.t("not-payable-note-error"));
                return;
              }
              this.handlePayment(
                16,
                table.isSafeSales,
                selectedNote,
                selectedUser
              );
              this.setState({
                notPayableDialog: false,
              });
            }}
          >
            {this.props.router.t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              if (!selectedUser) {
                toast.error(this.props.router.t("not-payable-user-error"));
                return;
              }
              this.handlePayment(
                16,
                table.isSafeSales,
                undefined,
                selectedUser
              );
              this.setState({
                notPayableDialog: false,
              });
            }}
          >
            {this.props.router.t("skip")}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => this.setState({ notPayableDialog: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  onRangeChange = (e: any, orderNum: number, product: any) => {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    this.setState((state: PageState) => {
      let findIndex = state.allOrders?.findIndex(
        (o: any) => o.orderNum == orderNum
      );
      let pIndex = state.allOrders[findIndex]?.products.findIndex(
        (p: any) => p._id == product._id
      );

      var unitPrice = product.price / product.quantity;
      if (Number(e.target.value) < product.quantity) {
        // all producttaki değer +1
        // selected producttaki değer -1
        if (state.willPayTotalPrice >= unitPrice) {
          state.willPayTotalPrice -= unitPrice;
          state.remainingPrice += unitPrice;
        } else {
          state.remainingPrice += state.willPayTotalPrice;
          state.willPayTotalPrice = 0;
        }
        if (pIndex > -1) {
          state.allOrders[findIndex].products[pIndex].quantity += 1;
          state.allOrders[findIndex].products[pIndex].price += unitPrice;
        } else {
          state.allOrders[findIndex].products.push(clone(product));
          let pIndex = state.allOrders[findIndex].products.findIndex(
            (p: any) => p._id == product._id
          );
          state.allOrders[findIndex].products[pIndex].quantity = 1;
          state.allOrders[findIndex].products[pIndex].price = unitPrice;
        }
        product.quantity -= 1;
        product.price = product.quantity * unitPrice;
        return state;
      } else {
        state.willPayTotalPrice += unitPrice;
        state.remainingPrice -= unitPrice;

        var aProduct = state.allOrders[findIndex].products[pIndex];
        // all producttaki değer -1
        // selected producttaki değer +1
        if (aProduct?.quantity) {
          aProduct.quantity -= 1;
          aProduct.price = aProduct.quantity * unitPrice;
        }
        if (aProduct?.quantity == 0) {
          state.allOrders[findIndex].products.remove(pIndex);
        }
        product.quantity += 1;
        product.price = product.quantity * unitPrice;
        let remaining = table.isSafeSales
          ? this.calculateRemaining()
          : table.remaining;
        if (state.willPayTotalPrice > remaining) {
          state.willPayTotalPrice = table.isSafeSales
            ? this.calculateRemaining()
            : table.remainingPrice;
        }
        if (state.remainingPrice < 0) state.remainingPrice = 0;
        return state;
      }
    });
  };

  Order = (order: any) => {
    order?.products?.map((p: any) => {
      if (!p.orderNum) {
        p.orderNum = order.orderNum;
      }
    });
    if (order.products.length > 0) {
      return (
        <>
          {this.props.router.t("order-no")}: {order.orderNum}
          {order?.products?.map((item: any) => {
            if (item.isServe || item.status == 0) {
              return null;
            }
            return (
              <div
                onClick={() => this.updateToBePaid(item, item.orderNum)}
                className="product list"
              >
                <div className="amount">
                  <span>{item.quantity}</span>
                </div>
                <div className="product">
                  <span>{item.productName}</span>
                </div>
                <div className="pricee">
                  <span>
                    {Number(item.price).toFixed(2)}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };

  updateToBePaidCover = (cover: any) => {
    this.setState((state: PageState) => {
      state.selectedCovers.push(cover);
      state.willPayTotalPrice += cover.price;
      state.remainingPrice -= cover.price;
      if (state.remainingPrice < 0) state.remainingPrice = 0;

      let index = state.covers.findIndex((c: any) => c._id == cover._id);
      if (index > -1) {
        state.covers.remove(index);
      }
      return state;
    });
  };

  updateCheckCover = (cover: any) => {
    this.setState((state: PageState) => {
      state.covers.push(cover);
      state.willPayTotalPrice -= cover.price;
      state.remainingPrice += cover.price;

      let index = state.selectedCovers.findIndex(
        (c: any) => c._id == cover._id
      );
      if (index > -1) {
        state.selectedCovers.remove(index);
      }
      return state;
    });
  };

  Cover = () => {
    if (this.state.covers.length > 0) {
      return (
        <>
          Covers
          {this.state.covers?.map((item: any) => {
            return (
              <div
                onClick={() => this.updateToBePaidCover(item)}
                className="product list"
              >
                <div className="amount">
                  <span>{item.quantity}</span>
                </div>
                <div className="product">
                  <span>{item.title}</span>
                </div>
                <div className="pricee">
                  <span>
                    {Number(item.price).toFixed(2)}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return null;
    }
  };

  SelectedCover = () => {
    if (this.state.selectedCovers.length > 0) {
      return (
        <>
          Covers
          {this.state.selectedCovers?.map((item: any) => {
            return (
              <div
                onClick={() => this.updateCheckCover(item)}
                className="product list"
              >
                <div className="amount">
                  <span>{item.quantity}</span>
                </div>
                <div className="product">
                  <span>{item.title}</span>
                </div>
                <div className="price">
                  <span>
                    {Number(item.price).toFixed(2)}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      );
    } else return null;
  };
  pOrder = (order: any) => {
    order?.products?.map((p: any) => {
      if (!p.orderNum) {
        p.orderNum = order.orderNum;
      }
    });
    if (order.products?.length > 0) {
      return (
        <>
          {this.props.router.t("order-no")}: {order.orderNum}
          {order?.products?.map((item: any) => {
            return (
              <>
                <div
                  onClick={() => this.updateCheck(item, item.orderNum)}
                  className="product list"
                >
                  <div className="amount">
                    <span>{item.quantity}</span>
                  </div>
                  <div className="product">
                    <span>{item.productName}</span>
                  </div>
                  <div className="price">
                    <span>{Number(item.price).toFixed(2)} </span>
                  </div>
                </div>
                <div className="product list">
                  <input
                    id={item._id}
                    type="range"
                    min={1}
                    max={
                      this.state.aProducts.find((p: any) => p._id == item._id)
                        .quantity
                    }
                    value={item.quantity}
                    onChange={(e: any) =>
                      this.onRangeChange(e, item.orderNum, item)
                    }
                  />
                </div>
              </>
            );
          })}
        </>
      );
    } else {
      return <></>;
    }
  };
  Product = (item: any) => {
    return (
      <div
        onClick={() => this.updateToBePaid(item, item.orderNum)}
        className="product list"
      >
        <div className="amount">
          <span>{item.quantity}</span>
        </div>
        <div className="product">
          <span>{item.productName}</span>
        </div>
        <div className="price">
          <span>
            {Number(item.price).toFixed(2)}{" "}
            {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
          </span>
        </div>
      </div>
    );
  };

  pProduct = (item: any) => {
    return (
      <div
        onClick={() => this.updateCheck(item, item.orderNum)}
        className="product list"
      >
        <div className="amount">
          <span>{item.quantity}</span>
        </div>
        <div className="product">
          <span>{item.productName}</span>
        </div>
        <div className="price">
          <span>
            {Number(item.price).toFixed(2)}{" "}
            {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
          </span>
        </div>
      </div>
    );
  };

  updateToBePaid(item: any, orderNum: number) {
    this.setState((state: PageState) => {
      state.allProducts = state.allProducts.filter(
        (product) => item._id != product._id
      );
      state.allOrders.map(
        (o: any) =>
          (o.products = o.products.filter((p: any) => p._id != item._id))
      );
      item.quantity = Number(item.quantity);
      var unitPrice = item.price / item.quantity;
      let findIndex = state.selectedOrders.findIndex(
        (o: any) => o.orderNum == orderNum
      );
      if (findIndex > -1) {
        let pIndex = state.selectedOrders[findIndex].products.findIndex(
          (p: any) => p._id == item._id
        );
        if (pIndex > -1) {
          state.selectedOrders[findIndex].products[pIndex].quantity +=
            item.quantity;
          state.selectedOrders[findIndex].products[pIndex].price +=
            unitPrice * item.quantity;
        } else {
          state.selectedOrders[findIndex].products.push(item);
        }
        state.willPayTotalPrice += item.price;
      } else {
        let o = { orderNum: orderNum, products: [item] };
        state.selectedOrders.push(o);
        state.willPayTotalPrice += item.price;
      }
      state.selectedProducts.push(item);
      if (state.willPayTotalPrice > state.totalPrice) {
        state.willPayTotalPrice = state.totalPrice;
      }
      state.remainingPrice -= item.price;
      if (state.remainingPrice < 0) state.remainingPrice = 0;

      return state;
    });
  }

  updateCheck(product: any, orderNum: number) {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    product.quantity = Number(product.quantity);
    this.setState((state: PageState) => {
      let aFindIndex = state.selectedProducts.indexOfKey("_id", product._id);
      let findIndex = state.selectedOrders.findIndex(
        (o: any) => o.orderNum == orderNum
      );
      let pFindIndex = state.selectedOrders[findIndex].products.findIndex(
        (p: any) => p._id == product._id
      );
      let allOrderIndex = state.allOrders.findIndex(
        (o: any) => o.orderNum == orderNum
      );
      var unitPrice = product.price / product.quantity;
      state.selectedProducts.remove(aFindIndex);
      state.selectedOrders[findIndex].products.remove(pFindIndex);
      state.allProducts.push(product);
      if (allOrderIndex > -1) {
        let pIndex = state.allOrders[allOrderIndex].products.findIndex(
          (p: any) => p._id == product._id
        );
        if (pIndex > -1) {
          state.allOrders[allOrderIndex].products[pIndex].quantity +=
            product.quantity;
          state.allOrders[allOrderIndex].products[pIndex].price +=
            unitPrice * product.quantity;
        } else {
          state.allOrders[allOrderIndex].products.push(product);
        }
      } else {
        let o = { orderNum: orderNum, products: [product] };
        state.allOrders.push(o);
      }

      state.willPayTotalPrice -= product.price;

      if (state.willPayTotalPrice < 0) {
        state.willPayTotalPrice = 0;
        state.remainingPrice = table.remainingPrice;
      } else {
        state.remainingPrice += product.price;
      }
      if (state.remainingPrice < 0) state.remainingPrice = 0;
      state.allProducts = state.allProducts.orderBy("_id", "asc");
      return state;
    });
  }

  successMessage() {
    Swal.fire({
      icon: "success",
      title: this.props.router.t("payment"),
      html: this.props.router.t("payment-success"),
    });
  }

  TipDialog = () => {
    const handleSubmit = async (answer: boolean) => {
      let tempPayments = clone(this.state.payments);
      let remaining = this.calculateRemaining();
      if (!answer) {
        let tipAmount = this.state.willPayTotalPrice - remaining;
        let findIndex = tempPayments.findIndex((p: any) => p.type == 14);
        if (findIndex > -1) {
          tempPayments[findIndex].amount += tipAmount;
        } else {
          tempPayments.push({
            type: 14,
            amount: tipAmount,
            currency: localStorage.getItem("defaultCurrency") || "TL",
            paidProducts: [],
          });
        }
      }
      this.setState({
        willPayTotalPrice: remaining,
      });
      let findType = tempPayments.findIndex(
        (p: any) => p.type == this.state.selectedType
      );
      if (findType > -1 && this.state.selectedType !== 6) {
        tempPayments[findType].amount += remaining;
      } else {
        tempPayments.push({
          type: this.state.selectedType,
          amount: remaining,
          tickId: this.state.selectedCustomer || "",
          currency: localStorage.getItem("defaultCurrency") || "TL",
        });
      }
      await this.setState((state: PageState) => {
        state.payments = tempPayments;
        state.tipDialog = false;
        state.tickDialog = false;
        state.willPayTotalPrice = 0;
        state.selectedOrders = [];
        state.selectedProducts = [];
        return state;
      });
      await this.completeCaseSaleOrder();
    };
    return (
      <Dialog
        open={this.state.tipDialog}
        onClose={() => this.setState({ tipDialog: false })}
        PaperProps={{
          style: {
            backgroundColor: "#313131",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
            {this.props.router.t("tip-title")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "1vw", color: "#fff" }}>
            {this.props.router.t("tip-question")}
          </Typography>
          <FormHelperText sx={{ color: "#fff", fontSize: "0.8vw" }}>
            {this.props.router.t("money-back-title")}:{" "}
            {(this.state.willPayTotalPrice - this.calculateRemaining()).toFixed(
              2
            )}{" "}
            {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}{" "}
          </FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            sx={{ width: "6vw", height: "2.5vw", backgroundColor: "#0D8012" }}
            onClick={() => handleSubmit(true)}
          >
            {this.props.router.t("yes")}
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={{ width: "6vw", height: "2.5vw", backgroundColor: "#BD0D0D" }}
            onClick={() => handleSubmit(false)}
          >
            {this.props.router.t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  TickCustomerDialog = () => {
    const [selectedTickCustomer, setSelectedTickCustomer] = useState("");
    const handleClose = () => {
      setSelectedTickCustomer("");
      this.setState({
        tickDialog: false,
      });
    };

    const handleSubmit = () => {
      if (!selectedTickCustomer) {
        toast.warning(this.props.router.t("please-select-customer"));
        return;
      }
      let remaining = this.calculateRemaining();
      if (this.state.willPayTotalPrice > remaining) {
        this.setState({
          tipDialog: true,
          selectedType: 6,
          selectedCustomer: selectedTickCustomer,
        });
        return;
      }
      this.setState((state: PageState) => {
        state.payments = [
          ...state.payments,
          {
            type: 6,
            amount: Number(this.state.willPayTotalPrice),
            currency:
              this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
              "TL",
            tickId: selectedTickCustomer || undefined,
          },
        ];
        state.willPayTotalPrice = 0;
        state.selectedOrders = [];
        state.selectedProducts = [];
        state.tickDialog = false;
        if (this.calculateRemaining() == 0) {
          this.completeCaseSaleOrder();
        }
        return state;
      });
    };
    return (
      <Dialog
        open={this.state.tickDialog}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: "#B4B4B4",
          },
        }}
      >
        <DialogTitle>
          <Box
            className="header"
            sx={{
              backgroundImage: "linear-gradient(#9A9999, #C06E6E)",
              padding: "1vw",
              width: "90%",
              margin: "auto",
              borderRadius: 3,
              marginBottom: "2vw",
            }}
          >
            <Typography
              sx={{ fontSize: "2vw" }}
              variant="h5"
              textAlign="center"
              margin="auto"
              fontWeight="bold"
            >
              {this.props.router.t("ticks")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TableContainer sx={{ overflowY: "scroll" }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#878787" }}>
                <TableRow sx={{ fontWeight: "bold" }}>
                  <TableCell>{this.props.router.t("name")}</TableCell>
                  <TableCell>{this.props.router.t("phone-number")}</TableCell>
                  <TableCell>{this.props.router.t("total-debt")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.getGlobalData.Ticks?.map((tick: any) => (
                  <TableRow
                    sx={{
                      backgroundColor:
                        selectedTickCustomer == tick._id
                          ? "#30B100"
                          : "#A9A9A9",
                    }}
                    onClick={() => setSelectedTickCustomer(tick._id)}
                  >
                    <TableCell>{tick.name}</TableCell>
                    <TableCell>{tick.phoneNum}</TableCell>
                    <TableCell>
                      {tick.totalAmount}{" "}
                      {Currencies[localStorage.getItem("defaultCurrency")!] || (
                        <>₺</>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="success"
            disabled={!selectedTickCustomer}
            onClick={handleSubmit}
          >
            {this.props.router.t("select")}
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * Only for case sale
   * @returns number
   */
  calculateRemaining() {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    let totalPayment = 0;
    let totalDiscount = 0;
    table?table.discount.map((d: any) => (totalDiscount += d.amount)):null;
    this.state.payments.map((p: any) => (totalPayment += p.amount));
    let remaining = this.state.totalPrice - totalPayment;
    return remaining < 0 ? 0 : remaining;
  }

  handlePayment(
    type: number,
    isCheckTable: boolean,
    note?: string,
    user?: string
  ): void {
    if (
      this.state.selectedProducts.length === 0 &&
      this.state.willPayTotalPrice === 0
    ) {
      Swal.fire({
        title: this.props.router.t("amount-payable"),
        icon: "error",
      });
      return;
    }

    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    let payData: any[] = [];
    let coverData: any[] = [];
    this.state.selectedCovers.map((c: any) => {
      coverData.push(c);
    });
    this.state.selectedOrders.forEach((o: any) => {
      o.products.map((p: any) => {
        this.state.aProducts.map((aProduct: any) => {
          if (p._id == aProduct._id) aProduct.quantity -= p.quantity;
        });
      });
      payData.push(o);

      let findIndex = this.state.allOrders.findIndex(
        (order: any) => order.orderNum == o.orderNum
      );
      if (findIndex > -1) {
        if (this.state.allOrders[findIndex].products.length == 0) {
          this.state.allOrders.remove(findIndex);
        }
      }
    });
    if (isCheckTable) {
      let remaining = this.calculateRemaining();

      if (table) {
        if (type == 6) {
          this.setState({
            tickDialog: true,
          });
          return;
        }
        if (this.state.willPayTotalPrice > remaining) {
          this.setState({
            tipDialog: true,
            selectedType: type,
          });
          return;
        }
        this.setState((state: PageState) => {
          let findPayment = state.payments.findIndex(
            (p: any) => p.type == type
          );
          if (findPayment > -1) {
            state.payments[findPayment].amount += Number(
              this.state.willPayTotalPrice
            );
          } else {
            state.payments = [
              ...state.payments,
              {
                type: type,
                amount: Number(this.state.willPayTotalPrice),
                currency:
                  this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                  "TL",
                note: note || undefined,
                specialCustomer: user || undefined,
              },
            ];
          }
          state.willPayTotalPrice = 0;
          state.selectedOrders = [];
          state.selectedProducts = [];
          if (this.calculateRemaining() == 0) {
            this.completeCaseSaleOrder();
          }
          return state;
        });
        return;
      }
      return;
    }
    let isCustomTable = table.customTable;

    let printOrder: any[] = [];
    this.state.selectedOrders.map((o: any) => {
      let or: any = { orderNum: o.orderNum, products: [] };
      o.products.map((p: any) => or.products.push(p));
      printOrder.push(or);
    });
    let printData: any = {
      discount: table.discount,
      cover: table.cover,
      orders: printOrder,
      tableInfo: table,
      total: {
        amount: table.totalPrice,
        currency:
          this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      },
      remaining: {
        amount: table.remainingPrice,
        currency:
          this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      },
      paymentReceived: {
        amount: this.state.willPayTotalPrice,
        currency:
          this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      },
    };

    if (type == 6) {
      this.props.openModal(MODAL.GET_CUSTOMER, {
        orders: payData,
        isRedirect: this.state.allProducts.length < 1 || table.totalPrice <= 0,
        willPayTotalPrice: this.state.willPayTotalPrice,
        pageOrder: this.props.data,
      });
      return;
    }

    Swal.fire({
      title: this.props.router.t("pleaseWait").toCapitalizeCase(),
      html: this.props.router.t("uploading") + "...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      willOpen(popup: HTMLElement) {
        Swal.showLoading();
      },
    });
    if (table.remainingPrice < this.state.willPayTotalPrice) {
      Swal.fire({
        icon: "question",
        title: this.props.router.t("money-back"),
        showCancelButton: true,
        confirmButtonText: this.props.router.t("yes"),
        cancelButtonText: this.props.router.t("no"),
      }).then((result: any) => {
        if (!result.isConfirmed) {
          let tipAmount = this.state.willPayTotalPrice - table.remainingPrice;
          this.setPayment([
            {
              tableId: GlobalStates.SelectedTableId,
              orders: Array(),
              payments: [
                {
                  type: 14,
                  amount: tipAmount,
                  currency:
                    this.props.getGlobalData?.CurrencySettings
                      ?.defaultCurrency || "TL",
                },
              ],
            },
            {
              tableId: GlobalStates.SelectedTableId,
              orders: Array(),
              payments: [
                {
                  type: type,
                  amount: Number(this.state.willPayTotalPrice) - tipAmount,
                  currency:
                    this.props.getGlobalData?.CurrencySettings
                      ?.defaultCurrency || "TL",
                  note: note || undefined,
                  specialCustomer: user || undefined,
                },
              ],
            },
          ]).then(() => {
            let generalPrinter = localStorage.getItem('barcodePrinter')
            if(generalPrinter){
           Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
           }
            Swal.fire({
              title: this.props.router.t("receipt-question"),
              icon: "question",
              showConfirmButton: true,
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                let section = this.props.getGlobalData.Sections.findSingle(
                  "_id",
                  GlobalStates.CurrentTable.section
                );
                Printer.ProductsInOrder.printProductsInOrder(
                  {
                    ...printData,
                    tableInfo: table,
                    table: GlobalStates.CurrentTable.isSafeSales
                      ? this.props.router.t("case-sale")
                      : `${section?.title} ${GlobalStates.CurrentTable.title}`,
                  },
                  this.props.getGlobalData?.Products,
                  this.props.router.t
                );
              }
              this.closeTable(isCustomTable);
            });
          });
        } else {
          this.setPayment([
            {
              tableId: GlobalStates.SelectedTableId,
              orders: Array(),
              payments: [
                {
                  type: type,
                  amount: Number(table.remainingPrice),
                  currency:
                    this.props.getGlobalData?.CurrencySettings
                      ?.defaultCurrency || "TL",
                  note: note || undefined,
                  specialCustomer: user || undefined,
                },
              ],
            },
          ]).then(() => {
            let generalPrinter = localStorage.getItem('barcodePrinter')
            if(generalPrinter){
           Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
           }
            Swal.fire({
              title: this.props.router.t("receipt-question"),
              icon: "question",
              showConfirmButton: true,
              showCancelButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                let section = this.props.getGlobalData.Sections.findSingle(
                  "_id",
                  GlobalStates.CurrentTable.section
                );
                Printer.ProductsInOrder.printProductsInOrder(
                  {
                    ...printData,
                    table: GlobalStates.CurrentTable.isSafeSales
                      ? this.props.router.t("case-sale")
                      : `${section?.title} ${GlobalStates.CurrentTable.title}`,
                  },
                  this.props.getGlobalData?.Products,
                  this.props.router.t
                );
              }
              this.closeTable(isCustomTable);
            });
          });
        }
      });
      return;
    }
    if (this.state.selectedOrders.length === 0 && coverData.length === 0) {
      if (table.remainingPrice <= this.state.willPayTotalPrice) {
        this.setPayment([
          {
            tableId: GlobalStates.SelectedTableId,
            orders: Array(),
            payments: [
              {
                type: type,
                amount: Number(table.remainingPrice),
                currency:
                  this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                  "TL",
                note: note || undefined,
                specialCustomer: user || undefined,
              },
            ],
          },
        ]).then(() => {
          let generalPrinter = localStorage.getItem('barcodePrinter')
          if(generalPrinter){
         Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
         }
          Swal.fire({
            title: this.props.router.t("receipt-question"),
            icon: "question",
            showConfirmButton: true,
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              let section = this.props.getGlobalData.Sections.findSingle(
                "_id",
                GlobalStates.CurrentTable.section
              );
              Printer.ProductsInOrder.printProductsInOrder(
                {
                  ...printData,
                  table: GlobalStates.CurrentTable.isSafeSales
                    ? this.props.router.t("case-sale")
                    : `${section?.title} ${GlobalStates.CurrentTable.title}`,
                },
                this.props.getGlobalData?.Products,
                this.props.router.t
              );
            }
            this.closeTable(isCustomTable);
          });
        });
        return;
      }
      this.setPayment([
        {
          tableId: GlobalStates.SelectedTableId,
          orders: Array(),
          payments: [
            {
              type: type,
              amount: Number(this.state.willPayTotalPrice),
              currency:
                this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                "TL",
              note: note || undefined,
              specialCustomer: user || undefined,
            },
          ],
        },
      ]).then(() => {
        let generalPrinter = localStorage.getItem('barcodePrinter')
        if(generalPrinter){
       Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
       }
        Swal.fire({
          title: this.props.router.t("receipt-question"),
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let section = this.props.getGlobalData.Sections.findSingle(
              "_id",
              GlobalStates.CurrentTable.section
            );
            Printer.ProductsInOrder.printProductsInOrder(
              {
                ...printData,
                table: GlobalStates.CurrentTable.isSafeSales
                  ? this.props.router.t("case-sale")
                  : `${section?.title} ${GlobalStates.CurrentTable.title}`,
              },
              this.props.getGlobalData?.Products,
              this.props.router.t
            );
          }
          this.successMessage();
        });
      });
      return;
    }
    if (table.remainingPrice <= this.state.willPayTotalPrice) {
      this.setPayment([
        {
          tableId: GlobalStates.SelectedTableId,
          orders: Array(),
          payments: [
            {
              type: type,
              amount: Number(table.remainingPrice),
              currency:
                this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                "TL",
              note: note || undefined,
              specialCustomer: user || undefined,
            },
          ],
        },
      ]).then(() => {
        let generalPrinter = localStorage.getItem('barcodePrinter')
            if(generalPrinter){
           Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
           }
        Swal.fire({
          title: this.props.router.t("receipt-question"),
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let section = this.props.getGlobalData.Sections.findSingle(
              "_id",
              GlobalStates.CurrentTable.section
            );
            Printer.ProductsInOrder.printProductsInOrder(
              {
                ...printData,
                table: GlobalStates.CurrentTable.isSafeSales
                  ? this.props.router.t("case-sale")
                  : `${section?.title} ${GlobalStates.CurrentTable.title}`,
              },
              this.props.getGlobalData?.Products,
              this.props.router.t
            );
          }
          this.closeTable(isCustomTable);
        });
      });
      return;
    }
    this.setPayment([
      {
        tableId: GlobalStates.SelectedTableId,
        orders: payData ? payData : [],
        covers: coverData ? coverData : [],
        payments: [
          {
            type: type,
            amount: Number(this.state.willPayTotalPrice),
            currency:
              this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
              "TL",
            note: note || undefined,
            specialCustomer: user || undefined,
          },
        ],
      },
    ]).then((resData) => {
      let tables = this.props.getGlobalData.AllTables.map((table) => {
        if (table._id == GlobalStates.SelectedTableId) {
          table.paid_orders = table.paid_orders.concat(payData);
        }
        return table;
      });
      this.props.setGlobalData(
        {
          AllTables: tables,
        },
        () => {
          this.setStatePayment(resData).then((value) => {
            this.setState(
              (state: PageState) => {
                state.selectedProducts.forEach((item) => {
                  state.allProducts = state.allProducts.filter(
                    (product) => product.uid !== item.uid
                  );
                  state.paidProducts.push(item);
                });
                state.selectedProducts = [];
                state.selectedOrders = [];
                state.selectedCovers = [];
                state.willPayTotalPrice = 0;
                return state;
              },
              () => {
                let generalPrinter = localStorage.getItem('barcodePrinter')
            if(generalPrinter){
           Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
           }
                Swal.fire({
                  title: this.props.router.t("receipt-question"),
                  icon: "question",
                  showConfirmButton: true,
                  showCancelButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    let section = this.props.getGlobalData.Sections.findSingle(
                      "_id",
                      GlobalStates.CurrentTable.section
                    );
                    Printer.ProductsInOrder.printProductsInOrder(
                      {
                        ...printData,
                        table: GlobalStates.CurrentTable.isSafeSales
                          ? this.props.router.t("case-sale")
                          : `${section?.title} ${GlobalStates.CurrentTable.title}`,
                      },
                      this.props.getGlobalData?.Products,
                      this.props.router.t
                    );
                  }
                  if (table.totalPrice <= 0) {
                    this.setState({
                      coma: false,
                    });

                    if (GlobalStates.CurrentTable.isSafeSales) {
                      this.props.closeModal();
                    } else {
                      this.closeTable(isCustomTable);
                    }
                  } else {
                    this.successMessage();
                  }
                });
              }
            );
          });
        }
      );
    });
  }

  setOrder(products: any[]) {
    return new Promise<any>(async (resolve) => {
      let params = {
        tableId: GlobalStates.SelectedTableId,
        orderNum: GlobalStates.SelectedOrderNum,
        products: products.map((item) => ({
          product: item.productId,
          quantity: item.quantity,
          price: GlobalStates.CurrentTable.isSafeSales
            ? item.priceId
            : item.price,
          options: item.options,
        })),
      };
      if (!GlobalStates.CurrentTable.isSafeSales) {
        (GlobalStates.CurrentTable.orders.length === 0
          ? Services.Post.order(params)
          : Services.Put.orders(params)
        ).then((resData) => {
          if (resData.status) {
            let tables = this.props.getGlobalData.AllTables.map((table) => {
              if (table._id == GlobalStates.SelectedTableId) {
                table.orders.concat(resData.data);
              }
              return table;
            });
            this.props.setGlobalData(
              {
                AllTables: tables,
              },
              () => {
                this.setState(
                  (state: PageState) => {
                    if (state.selectedProducts.length === 0) {
                      state.allProducts = resData.data;
                    }
                    return state;
                  },
                  () => {
                    this.props.data.setState(
                      (state: PageOrderState) => {
                        if (this.state.selectedProducts.length === 0) {
                          state.newOrders = resData.data;
                        }
                      },
                      () => {
                        resolve(resData);
                      }
                    );
                  }
                );
              }
            );
          } else {
            resolve(resData);
          }
        });
      } else {
        let resData = await Services.Post.order(params);
        if (resData.status) {
          let tables = this.props.getGlobalData.AllTables.map((table) => {
            if (table._id == GlobalStates.SelectedTableId) {
              table.orders.concat(resData.data.orders);
            }
            return table;
          });
          this.props.setGlobalData(
            {
              AllTables: tables,
            },
            () => {
              this.setState(
                (state: PageState) => {
                  if (state.selectedProducts.length === 0) {
                    state.allProducts = resData.data.orders[0].products;
                    state.allOrders = resData.data.orders;
                  }
                  return state;
                },
                () => {
                  this.props.data.setState(
                    (state: PageOrderState) => {
                      if (this.state.selectedProducts.length === 0) {
                        state.newOrders = resData.data.orders[0].products;
                      }
                    },
                    () => {
                      resolve(resData);
                    }
                  );
                }
              );
            }
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Unexcepted Error - Internal Server Error",
          });
        }
      }
    });
  }

  async completeCaseSaleOrder() {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    const data = {
      products: this.props.data.state.newOrders,
      discounts: table.discount,
      payments: this.state.payments,
      caseId: this.props.getGlobalData.caseId,
    };
    Swal.fire({
      title: this.props.router.t("pleaseWait").toCapitalizeCase(),
      html: this.props.router.t("uploading") + "...",
      showConfirmButton: false,
      showCancelButton: false,
      allowOutsideClick: false,
      willOpen(popup: HTMLElement) {
        Swal.showLoading();
      },
    });
    const response = await Services.Post.createCaseSale(data);
    if (response.status) {
      let generalPrinter = localStorage.getItem('barcodePrinter')
            if(generalPrinter){
           Print.print(Settings.payload_with_settings([]), -20, generalPrinter.toString());
           }
      Swal.fire({
        title: this.props.router.t("receipt-question"),
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let printData = {
            discount: table.discount,
            cover: [],
            tableInfo: table,
            checkNo: this.props.router.t("case-sale"),
            orders: [
              {
                orderNum: this.props.router.t("case-sale"),
                products: this.props.data.state.newOrders,
              },
            ],
            total: {
              amount: this.state.totalPrice,
              currency:
                this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                "TL",
            },
          };
          let section = this.props.getGlobalData.Sections.findSingle(
            "_id",
            GlobalStates.CurrentTable.section
          );
          Printer.ProductsInOrder.printProductsInOrder(
            {
              ...printData,
              tableInfo: table,
              table: GlobalStates.CurrentTable.isSafeSales
                ? this.props.router.t("case-sale")
                : `${section?.title} ${GlobalStates.CurrentTable.title}`,
            },
            this.props.getGlobalData?.Products,
            this.props.router.t
          );
        }
        this.closeTable();
        this.props.data.setState({
          newOrders: [],
          totalAmount: 0,
        });
      });
    }
  }

  setPayment(payments: any[]) {
    this.setState({
      totalPrice: this.state.remainingPrice,
    });
    return new Promise<any[]>((resolve) => {
      new Promise<any[]>(async (resolveService) => {
        let payedPayments: any[] = [];
        for (let i = 0; i < payments.length; i++) {
          let payment = payments[i];
          await Services.Post.checks(payment).then(async (resData) => {
            if (resData.status) {
              // payedPayments = payedPayments.concat(payment.payments);
              let tables = await Services.Get.allTablesSync().data.filter((table:any) => table.order_type !== 8);
              this.props.setGlobalData({
                AllTables: tables,
              });
            } else {
              Swal.fire({
                title: this.props.router.t("error"),
                icon: "error",
              });
            }
          });
        }
        resolveService(payedPayments);
      }).then((resData) => {
        this.setState(
          {
            willPayTotalPrice: 0,
          },
          () => {
            resolve(resData);
          }
        );
      });
    });
  }

  setStatePayment(payments: any[]) {
    return new Promise((resolve) => {
      let tables = this.props.getGlobalData.AllTables.map((table) => {
        if (table._id == GlobalStates.SelectedTableId) {
          let totalPaymentPrice = 0;
          payments.forEach((payment) => {
            let paymentIndex = table.payments.indexOfKey("type", payment.type);
            if (paymentIndex > -1) {
              table.payments[paymentIndex].amount += Number(payment.amount);
            } else {
              table.payments.push(payment);
            }
            totalPaymentPrice += Number(payment.amount);
          });
          table.remainingPrice -= totalPaymentPrice;
        }
        return table;
      });
      this.props.setGlobalData(
        {
          AllTables: tables,
        },
        () => {
          let table = this.props.getGlobalData.AllTables.findSingle(
            "_id",
            GlobalStates.SelectedTableId
          );
          this.setState(
            (state: PageState) => {
              // state.remainingPrice = table.remainingPrice;
              return state;
            },
            () => {
              resolve(0);
            }
          );
        }
      );
    });
  }

  closeTable(customTable?: boolean) {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    if (customTable) {
      let tableIndex = this.props.getGlobalData.AllTables.findIndex(
        (t: any) => t._id == table?._id
      );
      let tables = this.props.getGlobalData.AllTables;
      if (tableIndex > -1) {
        tables.splice(tableIndex, 1);
      }
      this.props.setGlobalData(
        {
          AllTables: tables,
        },
        () => {
          this.props.closeModal();
          Swal.fire({
            title: this.props.router.t("paid-succes"),
            icon: "success",
          }).then(() => {
            if (!GlobalStates.CurrentTable.isSafeSales) {
              this.props.router.navigate("/", { replace: true });
            }
          });
        }
      );
    } else {
      let tables = this.props.getGlobalData.AllTables.map((table) => {
        if (table._id == GlobalStates.SelectedTableId) {
          table.paid_orders = [];
          table.payments = [];
          table.orders = [];
          table.cover = [];
          table.discount = [];
          table.busy = false;
          table.totalPrice = 0;
          table.remainingPrice = 0;
        }
        return table;
      });
      this.props.setGlobalData(
        {
          AllTables: tables,
        },
        () => {
          this.props.closeModal();
          Swal.fire({
            title: this.props.router.t("paid-succes"),
            icon: "success",
          }).then(() => {
            if (!GlobalStates.CurrentTable.isSafeSales) {
              this.props.router.navigate("/", { replace: true });
            }
          });
        }
      );
    }
  }

  splitByPerson() {
    Swal.fire({
      title: this.props.router.t("split-question"),
      icon: "question",
      input: "number",
      inputValue: "1",
      inputAttributes: {
        min: "1",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        let table = this.props.getGlobalData.AllTables.findSingle(
          "_id",
          GlobalStates.SelectedTableId
        );

        this.setState({
          personCount: result.value,
        });
        let self = this;
        if (table.isSafeSales) {
          let totalPayment = 0;
          self.state.payments.map((p: any) => (totalPayment += p.amount));
          const remaining = self.state.totalPrice - totalPayment;
          let willPay = remaining / result.value;
          this.setState({
            willPayTotalPrice: willPay,
            remainingPrice: remaining - willPay,
          });
          return;
        }
        this.setState({
          willPayTotalPrice: table.totalPrice / result.value,
        });
      }
    });
  }

  handleCalculator(value: number | string): void {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    let self = this;
    if (this.state.selectedProducts.length > 0) {
      Swal.fire({
        title: this.props.router.t("first-pay"),
        icon: "warning",
      });
      return;
    }

    function updateWillPayTotalPrice(price: number) {
      let remainingPrice =
        table.remainingPrice - price < 0 ? 0 : table.remainingPrice - price;
      if (table.isSafeSales) {
        let totalPayment = 0;
        self.state.payments.map((p: any) => (totalPayment += p.amount));
        const remaining = self.state.totalPrice - totalPayment;
        remainingPrice = remaining - price < 0 ? 0 : remaining - price;
      }
      self.setState({
        willPayTotalPrice: price,
        remainingPrice: remainingPrice,
      });
    }

    switch (value) {
      case "C":
        updateWillPayTotalPrice(0);
        this.setState({ coma: false });
        GlobalStates.Cash = false;
        break;
      case "floor":
        updateWillPayTotalPrice(this.state.willPayTotalPrice | 0);
        break;
      case "byPerson":
        this.splitByPerson();
        break;
      case "00":
        if (this.state.coma) {
        } else {
          updateWillPayTotalPrice(this.state.willPayTotalPrice * 100);
        }
        break;
      case ",":
        this.setState({ coma: true });
        break;
      case "%":
        if (table.isSafeSales) {
          toast.warning("Kasa satış için devre dışı.");
          return;
        }
        this.props.openModal(MODAL.DISCOUNT, this.props.data);
        GlobalStates.CurrentModal = MODAL.DISCOUNT;
        GlobalStates.ModalOpen = true;
        break;
      case "split":
        break;
      case "Servis":
        break;
      default:
        GlobalStates.Cash = true;
        if (this.state.coma) {
          let arr = this.state.willPayTotalPrice.toString().split(".");
          let sum = Number(value) / Math.pow(10, arr.length);
          updateWillPayTotalPrice(sum + this.state.willPayTotalPrice);
        } else {
          updateWillPayTotalPrice(
            this.state.willPayTotalPrice * 10 + Number(value)
          );
        }

        break;
    }
  }

  ServiceDialog = () => {
    const [serviceFee, setServiceFee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(1); //1 = percent
    const addServiceFee = async (amount?: number) => {
      let table = this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      );

      if (!amount && serviceFee <= 0) {
        toast.warning(this.props.router.t("percentile-must-be-greater"));
        return;
      }
      this.setState({ serviceDialog: false });
      setLoading(true);
      if (table.isSafeSales) {
        let per = serviceFee || amount || 0;
        let amnt =
          type == 1 ? this.props.data.state.totalAmount * (per / 100) : per;

        const info = toast.loading(this.props.router.t("pleaseWait"));
        let res = await Services.Post.addServiceFee({
          amount: amount || serviceFee,
          tableId: table._id,
          type: type,
          total_price: this.props.data.state.totalAmount,
        });
        if (res.status) {
          toast.update(info, {
            render: this.props.router.t("add-service-fee-success"),
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          this.setState({
            totalPrice: this.props.data.state.totalAmount + amnt,
            remainingPrice: this.calculateRemaining() + amnt,
            totalServiceFee: this.state.totalServiceFee + amnt,
          });
          setLoading(false);
          return;
        }
        toast.update(info, {
          render: this.props.router.t("error"),
          type: "error",
          isLoading: false,
          autoClose: 2000,
        });
        setLoading(false);
        return;
      }
      const info = toast.loading(this.props.router.t("pleaseWait"));
      let per = amount || serviceFee;
      let res = await Services.Post.addServiceFee({
        amount: per,
        tableId: GlobalStates.SelectedTableId,
        type: type,
      });
      if (res.status) {
        toast.update(info, {
          render: this.props.router.t("add-service-fee-success"),
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        this.setState({
          totalPrice: res.data.totalPrice,
          remainingPrice: res.data.remainingPrice,
        });
        let tables = await Services.Get.allTablesSync().data.filter((table:any) => table.order_type !== 8);
        this.props.setGlobalData({ AllTables: tables });
        setLoading(false);
        return;
      }
      toast.update(info, {
        render: this.props.router.t("error"),
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
      setLoading(false);
    };
    if (checkPerm("524")) {
      return (
        <Dialog
          open={this.state.serviceDialog}
          onClose={() => this.setState({ serviceDialog: false })}
          fullWidth
        >
          <DialogTitle>{this.props.router.t("set-service-fee")}</DialogTitle>
          <DialogContent>
            <Stack direction="column" spacing={5}>
              <Grid
                container
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                justifyContent="center"
              >
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(2.5)}
                    variant="contained"
                    color="success"
                  >
                    %2.5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(5)}
                    variant="contained"
                    color="success"
                  >
                    %5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(7.5)}
                    variant="contained"
                    color="success"
                  >
                    %7.5
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(10)}
                    variant="contained"
                    color="success"
                  >
                    %10
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() => addServiceFee(20)}
                    variant="contained"
                    color="success"
                  >
                    %20
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    onClick={() => ipcRenderer.send("showKeyboard")}
                    value={serviceFee}
                    onChange={(e: any) => setServiceFee(e.target.value)}
                    label={`${this.props.router.t("special-price")} ${
                      type == 1 ? "(%)" : ""
                    }`}
                    type="number"
                    sx={{ minWidth: "10vw" }}
                  />
                  <ButtonGroup>
                    <Button
                      variant="contained"
                      color={type == 1 ? "success" : "info"}
                      sx={{ width: "7vw", height: "2vw", fontSize: "1vw" }}
                      onClick={() => setType(1)}
                    >
                      {this.props.router.t("percentile")}
                    </Button>
                    <Button
                      variant="contained"
                      color={type == 2 ? "success" : "info"}
                      sx={{ width: "7vw", height: "2vw", fontSize: "1vw" }}
                      onClick={() => setType(2)}
                    >
                      {this.props.router.t("_amount")}
                    </Button>
                  </ButtonGroup>
                </Stack>
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => addServiceFee()}
              color="success"
            >
              {this.props.router.t("save")}
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => this.setState({ serviceDialog: false })}
            >
              {this.props.router.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return <div></div>;
    }
  };

  async handleSelectAll() {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    this.state.selectedOrders?.map((order: any) => {
      this.setState((state: any) => {
        let f = state.allOrders?.findIndex(
          (o: any) => o.orderNum == order.orderNum
        );
        if (f > -1) {
          order.products?.map((p: any) => {
            state.allOrders[f]?.products?.push(p);
            state.allProducts?.push(p);
          });
        }
        return state;
      });
    });
    await this.setState({
      selectedOrders: [],
      selectedCovers: [],
      selectedProducts: [],
      willPayTotalPrice: 0,
      remainingPrice: table.remainingPrice,
    });
    let tbp = [...this.state.selectedOrders];
    let tbp2 = [...this.state.selectedProducts];
    let covers = [...this.state.selectedCovers];

    this.setState((state: any) => {
      state.allOrders.forEach((element: any) => {
        tbp.push(clone(element));
      });
      state.allProducts.forEach((element: any) => {
        tbp2.push(clone(element));
      });
      state.selectedOrders = clone(tbp);
      state.selectedProducts = clone(tbp2);
      state.willPayTotalPrice = table.isSafeSales
        ? this.calculateRemaining()
        : table.remainingPrice;
      state.remainingPrice = 0;
      state.allOrders.map((order: any) => {
        order.products = [];
      });
      state.covers.map((cover: any) => {
        covers.push(cover);
      });
      state.selectedCovers = covers;
      state.covers = [];

      return state;
    });

    // this.state.allOrders.forEach((element: any) => {
    //     tbp.push(clone(element));
    // });
    // this.state.allProducts.forEach((element: any) => {
    //     tbp2.push(clone(element));
    // })
    // this.setState({
    //     selectedOrders: tbp
    // })
    // this.setState({
    //     selectedProducts: tbp2
    // })
    // this.setState({
    //     willPayTotalPrice: table.remainingPrice
    // })
    // this.setState({
    //     remainingPrice: 0
    // })
    // this.state.allOrders.forEach((element: any) => {
    //     element.products = [];
    // })
    // this.state.covers.forEach((element: any) => {
    //     covers.push(element);
    // })
    // this.setState({
    //     selectedCovers: covers
    // })
    // this.setState({
    //     covers: []
    // })
  }

  handlePrint(): void {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );

    if (table.orders.length > 0) {
      Swal.fire({
        icon: "question",
        title: this.props.router.t("not-print-question"),
        showCancelButton: true,
      }).then((result: any) => {
        if (result.isConfirmed) {
          Services.Put.table({
            tableId: GlobalStates.SelectedTableId,
            print: true,
          }).then((resData) => {
            let printData = {
              discount: table.discount,
              cover: GlobalStates.CurrentTable.cover,
              tableInfo: table,
              checkNo: table.checkNo,
              orders: table.orders.map((order: any) => ({
                orderNum: order.orderNum,
                products: order.products.map((product: any) => ({
                  productId: product.product,
                  id: product._id,
                  productName: product.productName,
                  quantity: product.quantity,
                  priceId: product.priceId,
                  price: product.price,
                  isFirst: product.isFirst,
                  isServe: product.isServe,
                  status: product.status,
                })),
              })),
              total: {
                amount: table.totalPrice,
                currency:
                  this.props.getGlobalData?.CurrencySettings?.defaultCurrency ||
                  "TL",
              },
            };

            let sectionTitle = this.props.getGlobalData.Sections.findSingle(
              "_id",
              GlobalStates.CurrentTable.section
            )?.title;
            printData = Object.assign(printData, {
              table: `${sectionTitle} - ${GlobalStates.CurrentTable.title}`,
            });

            Printer.ProductsInOrder.printProductsInOrder(
              printData,
              this.props.getGlobalData?.Products,
              this.props.router.t
            );
            Swal.fire({
              title: this.props.router.t("print-product-success"),
              icon: "success",
            });
          });
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: this.props.router.t("error"),
      });
    }
  }

  render() {
    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      GlobalStates.SelectedTableId
    );
    return (
      <div className="checkout">
        <this.NotPayableDialog />
        <this.CreateUserDialog />
        <this.ServiceDialog />
        <this.TipDialog />
        <this.TickCustomerDialog />
        <div>
          {this.props.router.t("collections")}
          <div className="product">
            <div className="paymetType">
              <span>{this.props.router.t("payment-type")}</span>
            </div>
            <div className="priceTitle">
              <span>{this.props.router.t("price")}</span>
            </div>
          </div>
          <div className="collectionTable collectionTableHeight">
            {!table?.isSafeSales
              ? table?.payments?.map((payment: any) => (
                  <div className="product list">
                    <div className="paymetTypeProduct">
                      <span>
                        {this.props.router.t(
                          payment_types[Number(payment.type) - 1]
                        )}
                      </span>
                    </div>
                    <div className="paymetTypePrice">
                      <span>
                        {Number(payment.amount).toFixed(2)} {payment.currency}
                      </span>
                    </div>
                  </div>
                ))
              : this.state.payments.map((payment: any) => (
                  <div className="product list">
                    <div className="paymetTypeProduct">
                      <span>
                        {this.props.router.t(
                          payment_types[Number(payment.type) - 1]
                        )}
                      </span>
                    </div>
                    <div className="paymetTypePrice">
                      <span>
                        {Number(payment.amount).toFixed(2)} {payment.currency}
                      </span>
                    </div>
                  </div>
                ))}
            {this.state.discounts.map((item: any) => {
              return (
                <div className="product list">
                  <div className="paymetTypeProduct">
                    <span>{this.props.router.t("discount")}</span>
                  </div>
                  <div className="paymetTypePrice">
                    <span>
                      {Number(item.amount).toFixed(2)}{" "}
                      {Currencies[localStorage.getItem("defaultCurrency")!] || (
                        <>₺</>
                      )}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.router.t("all-products")}
          <div className="product">
            <div className="amount">
              <span>{this.props.router.t("quantity")}</span>
            </div>
            <div className="product">
              <span>{this.props.router.t("product")}</span>
            </div>
            <div className="price">
              <span>{this.props.router.t("price")}</span>
            </div>
          </div>
          <div className="product list">
            <div className="amount">
              <span>1</span>
            </div>
            <div className="product">
              <span>{this.props.router.t("service-fee")}</span>
            </div>
            <div className="pricee">
              <span>{this.state.totalServiceFee?.toFixed(2)} TL</span>
            </div>
          </div>
          <div className="collectionTable allProductHeight">
            {this.state.allOrders.map((o: any) => {
              return <this.Order {...o} />;
            })}
            {<this.Cover />}
          </div>
        </div>
        <div className="middle">
          {this.props.router.t("payment")}
          <div className="table">
            <div className="product">
              <div className="amount">
                <span>{this.props.router.t("quantity")}</span>
              </div>
              <div className="product">
                <span>{this.props.router.t("product")}</span>
              </div>
              <div className="price">
                <span>{this.props.router.t("price")}</span>
              </div>
            </div>
            <div className="collectionTable currentlypaying">
              {this.state.selectedOrders.map((o: any) => (
                <this.pOrder {...o} />
              ))}
              {<this.SelectedCover />}
            </div>
          </div>
          <div className="calculator">
            <div onClick={() => this.handleCalculator(1)}>1</div>
            <div onClick={() => this.handleCalculator(2)}>2</div>
            <div onClick={() => this.handleCalculator(3)}>3</div>

            <div
              className="rightbuttons"
              onClick={() => this.handleCalculator("C")}
            >
              {this.props.router.t("reset")}
            </div>

            <div onClick={() => this.handleCalculator(4)}>4</div>
            <div onClick={() => this.handleCalculator(5)}>5</div>
            <div onClick={() => this.handleCalculator(6)}>6</div>

            <div
              className="rightbuttons"
              onClick={() => this.setState({ serviceDialog: true })}
            >
              {this.props.router.t("service")}
            </div>

            <div onClick={() => this.handleCalculator(7)}>7</div>
            <div onClick={() => this.handleCalculator(8)}>8</div>
            <div onClick={() => this.handleCalculator(9)}>9</div>

            <div
              className="rightbuttons"
              onClick={() => this.handleSelectAll()}
            >
              {this.props.router.t("all")}
            </div>

            <div onClick={() => this.handleCalculator("00")}>00</div>
            <div onClick={() => this.handleCalculator(0)}>0</div>
            <div onClick={() => this.handleCalculator(",")}>,</div>

            <div
              className="rightbuttons"
              onClick={() => this.handleCalculator("byPerson")}
            >
              1/{this.props.router.t("person")}
            </div>

            <div
              onClick={() => this.handleCalculator("%")}
              className="bottom discount"
            >
              % {this.props.router.t("discount")}
            </div>
            <div className="bottom print" onClick={(_) => this.handlePrint()}>
              {this.props.router.t("print")}
            </div>
          </div>
        </div>
        <div className="totalDiv">
          <div className="totals">
            <div className="total">
              <div>{this.props.router.t("total-amount")}:</div>
              <div className="price">
                {this.state.totalPrice?.toFixed(2)}{" "}
                {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
              </div>
            </div>
            <div className="total">
              <div>{this.props.router.t("amount-paid")}:</div>
              <div className="price">
                {this.state.willPayTotalPrice.toFixed(2)}{" "}
                {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
              </div>
            </div>
            <div className="total">
              <div>{this.props.router.t("remaining-amount")}:</div>
              <div className="price">
                {this.state.remainingPrice.toFixed(2)}{" "}
                {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
              </div>
            </div>
            {!table?.isSafeSales && !GlobalStates.isFastPayment ? (
              table?.remainingPrice - this.state.willPayTotalPrice < 0 ? (
                <div className="total">
                  <div>{this.props.router.t("money-back-title")}:</div>
                  <div className="price">
                    {(
                      this.state.willPayTotalPrice - table?.remainingPrice
                    ).toFixed(2)}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
          <div className="payment">
            {this.props.getGlobalData.PaymentMethods?.map((method: any) => (
              <div
                onClick={() => {
                  if (method.type == 16) {
                    if (this.state.willPayTotalPrice > 0) {
                      this.setState({ notPayableDialog: true });
                      return;
                    }
                    Swal.fire({
                      title: this.props.router.t("amount-payable"),
                      icon: "error",
                    });
                    return;
                  }
                  this.handlePayment(method.type, table.isSafeSales);
                }}
              >
                {this.props.router.t((payment_methods as any)[method.type])}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;
