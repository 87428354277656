import Settings, { PrinterDefaultColumnHeight } from "../settings/settings";
import Print from "../print/print";
import { payment_types } from "constants/paymentTypes";

export default class Report {
  static printReport(report: any, type: string, t:any) {
    if (!report) return;
    let perDefaultHeight = PrinterDefaultColumnHeight;
    let height = 0;
    const payments = (): String => {
      let element = `<table style="font-weight: 700, font-size:22px; width: 100%">
            <thead>
                <tr >
                    <th style="text-align: start;">
                        <strong>${t("payment-type")}</strong>
                    </th>
                    <th style="text-align: end;">
                        <strong>${t("price")}</strong>
                    </th>
                </tr>
            </thead>
            <tbody>`;

      report?.payments?.map((payment: any) => {
        height += perDefaultHeight;
        element += `<tr>
                        <td style="text-align: start;">
                            <strong>${payment_types[payment.type - 1]}</strong>
                        </td>
                        <td style="text-align: end;">
                            <strong>${Number(payment.amount).toFixed(2)}${
          payment.currency
        }</strong>
                        </td>
                    </tr>`;
      });
      element += `
            </tbody>
        </table>`;
      return element;
    };
    const products = () => {
      let element = `
            <table>
                <thead>
                <tr >
                    <th style="text-align: start;">
                        <strong>${t("product-number")}</strong>
                    </th>
                    <th style="text-align: end;">
                        <strong>${t("price")}</strong>
                    </th>
                </tr>
                </thead>
                <tbody>`;
      report?.products?.map((product: any) => {
        if(type == "Z") product.name = product.productName
        height += perDefaultHeight;
        element += `
                            <tr>
                            <td style="text-align: start;">
                                <strong> ${
                                  product.quantity + "x " + product.name
                                }</strong>
                            </td>
                            <td style="text-align: end;">
                                <strong>${Number(product.price).toFixed(
                                  2
                                )}${localStorage.getItem("defaultCurrency") ? localStorage.getItem("defaultCurrency") : "TL"}</strong>
                            </td>
                        </tr>`;
      });
      element += `
                    </tbody>
                </table>`;
      return element;
    };

    const expenses = () => {
      let element = `<table style="font-weight: 700, font-size:22px; width: 100%">
      <thead>
          <tr >
              <th style="text-align: start;">
                  <strong>${t("cost-name")}</strong>
              </th>
              <th style="text-align: end;">
                  <strong>${t("price")}</strong>
              </th>
          </tr>
      </thead>
      <tbody>`;

      report?.expenses?.map((expense: any) => {
        height += perDefaultHeight;
        element += `<tr>
                  <td style="text-align: start;">
                      <strong>${expense.title}</strong>
                  </td>
                  <td style="text-align: end;">
                      <strong>${Number(expense.expense_amount).toFixed(2)}${
          expense.currency?expense.currency:""
        }${expense.expense_type == 1 ? `(${t("open-account")})` : ""}</strong>
                  </td>
              </tr>`;
      });
      element += `
      </tbody>
  </table>`;
      return element;
    };

    const total = () => {
      let element = `<table style="font-weight: 700, font-size:22px; width: 100%">
      <thead>
          <tr >
              <th style="text-align: start;">
                  <strong>${t("name")}</strong>
              </th>
              <th style="text-align: end;">
                  <strong>${t("price")}</strong>
              </th>
          </tr>
      </thead>
      <tbody>`;

      report?.allTotal?.map((total: any) => {
        height += perDefaultHeight;
        element += `<tr>
                  <td style="text-align: start;">
                      <strong>${total.name}</strong>
                  </td>
                  <td style="text-align: end;">
                      <strong>${Number(total.amount).toFixed(2)}
        </strong>
                  </td>
              </tr>`;
      });
      element += `
      </tbody>
  </table>`;
      return element;
    };

    const date = new Date().toLocaleDateString();
    const data = [
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${type}</div>`,
      },
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px">${t("printTime")}:  ${date} </div>`,
        style: `text-align:center;`,
      },
      {
        type: "text",
        value: `<div style="display: flex; flex-direction: column; justify-content: center; width: 100%; text-align:center; font-weight: 700; font-size:22px"">
                            --------------------------
                        </div>`,
      },
      {
        type: "text",
        value:
        `<div style= "font-weight: 700;font-size:22px; text-align:center;">${t("paidInfo")}</div>`,
      },
      {
        type: "text",
        value: payments(),
      },
      {
        type: "text",
        value: `<div style="display: flex; flex-direction: column; justify-content: center; width: 100%; text-align:center; font-weight: 700; font-size:22px"">
                --------------------------
            </div>`,
      },
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${t("products")}</div>`,
      },
      {
        type: "text",
        value: products(),
      },
      {
        type: "text",
        value: `<div style="display: flex; flex-direction: column; justify-content: center; width: 100%; text-align:center; font-weight: 700; font-size:22px"">
                --------------------------
            </div>`,
      },
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${t("expenses")}</div>`,
      },
      {
        type: "text",
        value: expenses(),
      },
      {
        type: "text",
        value: `<div style="display: flex; flex-direction: column; justify-content: center; width: 100%; text-align:center; font-weight: 700; font-size:22px"">
                --------------------------
            </div>`,
      },
      {
        type: "text",
        value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${t("total")}</div>`,
      },
      {
        type: "text",
        value: total(),
      },
    ];
    Print.print(Settings.payload_with_settings(data), height);
  }
}
