import Settings, { PrinterDefaultColumnHeight } from "../settings/settings";
import Print from "../print/print";
import { payment_types } from "constants/paymentTypes";
import ReactDOMServer from "react-dom/server";
import DateFormat from "../date/date";
import Currencies from "constants/currencies";

export const bill_types = {
    1:"pos",
    2:"mobilApp",
    3:"addressDelivery",
    4:"comeAndGet",
    5:"caseSale",
    6:"getir",
    7:"trendyol",
    8:"digitalMenu" 
}

const PaymentTypes = {
    99: "opening-amount",
    98: "total-amount",
    1: "pos_bank",
    2: "pos_cash",
    3: "app_payment",
    6: "tick",
    7: "sodexo",
    8: "smart",
    9: "winwin",
    10: "multinet",
    11: "setcard",
    12: "metropol",
    13: "edended",
    14: "tips",
    15: "jetKurye",
    16: "not-payable",
    17: "migros-hemen",
    18: "getir-online",
    19: "trendyol",
    20: "yemek-sepeti"
}

export default class ZReport {
    static printZReport(report: any,t:any) {
        if (!report) return;
        let perDefaultHeight = PrinterDefaultColumnHeight;
        let height = 0;
        const products = report.products?.map((group: any) => {
            height += perDefaultHeight;
            return (
                <>
                    <table cellSpacing={3}>
                        <tr>
                            <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("product")}</td>
                            <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("quantity")}</td>
                            <td colSpan={3} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                        </tr>
                        <span style={{ margin: "auto", marginBottom: 1, textAlign: "center", fontWeight: 'bold' }}>{group.categoryTitle}</span>
                        <hr />
                        {group.products?.map((p: any) => (
                            <tr key={p.id}>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{p.name}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "center" }}>{p.quantity}</td>
                                <td colSpan={3} style={{ fontWeight: 'bold', textAlign: "right" }}>{p.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                            </tr>
                        ))}
                    </table>
                </>
            )
        })
        const cancelledProducts = report.cancelledProducts?.map((p: any) => {
            height += perDefaultHeight;
            return (
                <tr key={p.id}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{p.name}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "center" }}>{p.quantity}</td>
                    <td colSpan={3} style={{ fontWeight: 'bold', textAlign: "right" }}>{p.price} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                </tr>
            )
        })

        const cateringProducts = report.caterings?.map((p: any) => {
            height += perDefaultHeight;
            return (
                <tr key={p.id}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{p.name}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "center" }}>{p.quantity}</td>
                    <td colSpan={3} style={{ fontWeight: 'bold', textAlign: "right" }}>{p.price} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                </tr>
            )
        })

        const expenses = report.expenses?.map((expense: any) => {
            height += perDefaultHeight;
            return (
                <tr key={expense}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{expense.title}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "center" }}>{expense.amount} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    <td colSpan={3} style={{ fontWeight: 'bold', textAlign: "right" }}>{expense.expense_type == 2 ? (<>{t("open-account")}</>) : (<>{t(PaymentTypes[(expense.payment_type) as keyof typeof PaymentTypes])}</>)}</td>
                    <td colSpan={4} style={{fontWeight: "bold", textAlign: "right"}}>{expense.expense_type == 2 ? <>{expense.openAccount?.name}</> : <>-</>}</td>
                </tr>
            )
        })

        const billType = report.billType?.map((type: any) => {
            height += perDefaultHeight;
            return (
                <tr key={type}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{t(bill_types[(type.type) as keyof typeof bill_types])}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "center" }}>{type.percent}%</td>
                </tr>
            )
        })

        const payments = report.payments?.map((payment: any) => {
            height += perDefaultHeight;
            return (
                <tr key={payment}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{t(PaymentTypes[(payment.type) as keyof typeof PaymentTypes])}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "right" }}>{payment.amount} {payment.currency}</td>
                </tr>
            )
        })

        const tips = report.tips?.map((tip: any) => {
            height += perDefaultHeight;
            return (
                <tr key={tip}>
                    <td colSpan={6} style={{ fontWeight: 'bold' }}>{tip.currency}</td>
                    <td colSpan={4} style={{ fontWeight: 'bold', textAlign: "right" }}>{tip.amount} {tip.currency}</td>
                </tr>
            )
        })

        
        const general =
            (
                <table>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("totalPaid")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalRevenue?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("total-service-fee")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalServiceFeeAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("total-tips")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalTipAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("totalExpense")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalExpenseAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("total-discount")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalDiscountAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("total-catering")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.totalCateringAmount?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                    <tr>
                        <td style={{fontWeight: 'bold'}}>{t("turnover")}</td>
                        <td style={{fontWeight: 'bold'}}>{report.general?.endorsement?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</> }</td>
                    </tr>
                </table>
            )


        const dataTop = [
            {
                type: "text",
                value: `<div style="font-weight: 900;font-size:22px; text-align:center;">${t("x-report")}</div>`
            },
            {
                type: "text",
                value: `
                <div style=" display: flex; flex-direction: column; justify-content: center; width: 100%; text-align: center">
                    <span>${t("date")}: ${DateFormat.formatDate(new Date())}</span><br>
                </div>
                `,
            }
        ];

        const dataProducts = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("sold-products")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(products)
            },
        ]
        const dataCancelledProducts = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("cancelled-products")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("product")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("quantity")}</td>
                                <td colSpan={3} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                            </td>
                            {cancelledProducts}
                        </table>
                        <hr />
                    </>
                )
            },
        ]
        const dataCateringProducts = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("served-products")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("product")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("quantity")}</td>
                                <td colSpan={3} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                            </td>
                            {cateringProducts}
                        </table>
                        <hr />
                    </>
                )
            },
        ]

        const billTypeData = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("checkType")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("check-type")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("_percent")}</td>
                            </td>
                            {billType}
                        </table>
                        <hr />
                    </>
                )
            },
        ]

        const paymentsData = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("paid-type")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("payment-type")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                            </td>
                            {payments}
                        </table>
                        <hr/>
                    </>
                )
            },
        ]

        const tipsData = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("tips")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("currency")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                            </td>
                            {tips}
                        </table>
                        <hr/>
                    </>
                )
            },
        ]

        const generalData = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("allInfo")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(general)
            },
        ]

        const expensesData = [
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <h3>{t("expenses")}</h3>
                    </>
                )
            },
            {
                type: 'text',
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <table>
                            <td>
                                <td colSpan={6} style={{ fontWeight: 'bold' }}>{t("_title")}</td>
                                <td colSpan={4} style={{ fontWeight: 'bold' }}>{t("_amount")}</td>
                                <td colSpan={3} style={{ fontWeight: 'bold' }}>{t("payment-type")}</td>
                                <td colSpan={3} style={{ fontWeight: 'bold' }}>{t("open-account")}</td>
                            </td>
                            {expenses}
                        </table>
                        <hr />
                    </>
                )
            },
        ]


        let data: any[] = [];
        data = data.concat(dataTop);
        data = data.concat(dataProducts.concat(dataCancelledProducts.concat(dataCateringProducts.concat(billTypeData.concat(paymentsData.concat(tipsData.concat(expensesData.concat(generalData))))))));

        Print.print(Settings.payload_with_settings(data), height)
    }
}