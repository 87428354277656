import Api from "./api";
import {ServicePages} from "../public/ajax";
import {CourierDeleteParamDocument} from "../modules/services/couriers";
import {CancelHomeDelivery, DeleteHomeDeliveryProduct, OrderDeleteParamDocument} from "../modules/services/orders";
import {CostDeleteParamDocument} from "../modules/services/cost";
import { DeleteCoverParamDocument } from "modules/services/cover";
import { DiscountDeleteParamDocumet } from "modules/services/discount";
import { DeleteSpecialTable } from "modules/services/tables";

const Delete = {
    couriers(params: CourierDeleteParamDocument) {
        return Api.delete({
            url: [ServicePages.couriers, params.courierId],
            data: params
        });
    },
    discount(params: DiscountDeleteParamDocumet) {
        return Api.delete({
            url: [ServicePages.discount, params.tableId],
            data: params
        });
    },
    cost(params: CostDeleteParamDocument) {
        return Api.delete({
            url: [ServicePages.costs, params.expenseId],
            data: params
        });
    },
    cover(params: DeleteCoverParamDocument) {
        return Api.delete({
            url: [ServicePages.covers, params.tableId],
            data: params
        });
    },
    homeDeliveryProduct(params: DeleteHomeDeliveryProduct) {
        return Api.delete({
            url: [ServicePages.takeaway],
            data: params
        })
    },
    deleteHomeDelivery(params: CancelHomeDelivery) {
        return Api.delete({
            url: [ServicePages.takeaway, params.orderNum.toString(), 'delete'],
            data: params
        })
    },
    clearNotifications(){
        return Api.delete({
            url: ["notifications"]
        })
    },
    deleteCustomTable(params: DeleteSpecialTable) {
        return Api.delete({
            url: ["special-table", params.tableId]
        })
    },
    deleteServiceFee(params: {tableId: string, serviceId: string}){
        return Api.delete({
            url: ["table", "service", params.tableId, params.serviceId],
            data: params
        })
    },
    deleteOpenAccount(id: string){
        return Api.delete({
            url: ['open-account', id]
        })
    },
    unpairBarcode(productId: string){
        return Api.delete({
            url: ['products', 'barcode', productId]
        })
    }
}

export default Delete;

