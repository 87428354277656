import { Button, Input } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Component } from "react";
import { FaSearch } from "react-icons/fa";
import Swal from "sweetalert2";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import { GlobalStates, getGlobalAuthData } from "config/global";
import MuiButton from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Services from "services/index";
import { toast } from "react-toastify";
import functions from "config/global/functions";
import { Typography } from "@mui/material";
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    user: any;
    date: any;
    val: number;
    opt: string;
};

type PageProps = {
    newOrders: any[]
    multiplier: number,
    onClickMultiplier: any
    onSearchProduct: any
    onSearchCategory: any
} & PagePropCommonDocument;

class Navbar extends Component<PageProps, PageState> {
    interval: any;
    constructor(props: any) {
        super(props);
        this.state = {
            user: "",
            date: new Date(),
            val: 1,
            opt: "C",
        };
    }

    sets() {
        const setTimer = () => {
            this.interval = setInterval(() =>
                this.setState({
                    date: new Date()
                })
            );
        };
        this.setState({
            user: getGlobalAuthData().user
        })

        setTimer();
    }

    componentDidMount(): void {
        this.sets();
    }

    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    handleMultiplier(value: any): void {
        let multiplier = this.state.val;
        switch (value) {
            case "X":
                this.setState({
                    opt: value,
                });
                break;
            case "C":
                multiplier = 1;
                this.setState({
                    val: 1,
                    opt: value,
                });
                break;
            default:
                if (this.state.opt == "X") {
                    multiplier = multiplier * value;
                    this.setState({
                        val: multiplier
                    })
                } else {
                    this.setState({
                        val: value
                    })
                    multiplier = value;
                }
                this.setState({
                    opt: "C",
                });
                break;
        }
        this.props.onClickMultiplier(multiplier);
    }

    handleReturn(): void {
        GlobalStates.Type = "";
        if (
            GlobalStates.CurrentTable.isSafeSales &&
            this.props.newOrders.length > 0
        ) {
            Swal.fire({
                icon: "warning",
                title: this.props.router.t("payment-cashier"),
            });
            return;
        }

        if (this.props.newOrders.length > 0) {
            Swal.fire({
                icon: "question",
                title: this.props.router.t("product-return"),
                showCancelButton: true,
            }).then((result: any) => {
                if (result.isConfirmed) {
                    GlobalStates.SelectedTableId = "";
                    this.props.router.navigate("/", { replace: true });
                    ipcRenderer.send('object-to-second', null);
                }
            });
        } else {
            GlobalStates.SelectedTableId = "";
            this.props.router.navigate("/", { replace: true });
            ipcRenderer.send('object-to-second', null);
        }
    }

    productOnclick(event: any): void {
        throw new Error("Function not implemented.");
    }

    handleDeleteTable = async () => {
        if (!functions.checkPerm('519')) {
            Swal.fire({
                icon: "error",
                title: this.props.router.t('auth-err')
            });
            return;
        }
        let table = this.props.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
        if (table.payments?.length > 0) {
            toast.warning(this.props.router.t('delete-table-payment-error'), { autoClose: 10000 });
            return;
        }
        Swal.fire({
            icon: "question",
            title: this.props.router.t('special-table-delete-question'),
            text: this.props.router.t('special-table-delete-text'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.props.router.t('yes'),
            cancelButtonText: this.props.router.t('cancel')
        }).then(async (res: any) => {
            if (res.isConfirmed) {
                let table = this.props.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
                let response = await Services.Delete.deleteCustomTable({ tableId: table._id });
                if (response.status) {

                    let allTables = this.props.getGlobalData?.AllTables;
                    let index = allTables.findIndex((t: any) => t._id == table._id);
                    allTables.remove(index);
                    this.props.setGlobalData({
                        AllTables: allTables
                    });
                    Swal.fire({
                        icon: "success",
                        title: this.props.router.t('special-table-deleted-success')
                    });
                    this.props.router.navigate('/');
                    return;
                }
                toast.error(this.props.router.t('error'));
            }
        })
    }

    handleCompleteOrder = () => {
        if (!functions.checkPerm('519')) {
            Swal.fire({
                icon: "error",
                title: this.props.router.t('auth-err')
            });
            return;
        }
        Swal.fire({
            icon: "question",
            title: this.props.router.t('complete-order-question'),
            text: this.props.router.t('complete-order-text'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.props.router.t('yes'),
            cancelButtonText: this.props.router.t('cancel')
        }).then(async (res: any) => {
            if (res.isConfirmed) {
                let table = this.props.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
                let response = await Services.Put.completeOrder({ tableId: table._id });
                if (response.status) {
                    let allTables = this.props.getGlobalData?.AllTables.map((t: any) => {
                        if (table._id == t._id) {
                            t.paid_orders = [];
                            t.payments = [];
                            t.orders = [];
                            t.cover = [];
                            t.discount = [];
                            t.busy = false;
                            t.totalPrice = 0;
                            t.remainingPrice = 0;
                            return t;
                        }
                        return t;
                    });
                    this.props.setGlobalData({
                        AllTables: allTables
                    });
                    Swal.fire({
                        icon: "success",
                        title: this.props.router.t('order-completed')
                    });
                    this.props.router.navigate('/');
                    return;
                }
                toast.error(this.props.router.t('error'));
            }
        })
    }

    render() {
        let table = this.props.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);

        let tableName = `${this.props.getGlobalData.Sections.findSingle("_id", GlobalStates.CurrentTable.section)?.title || ""} ${GlobalStates.CurrentTable.title}`;
        if (GlobalStates.CurrentTable.isSafeSales) {
            tableName = this.props.router.t("case-sale");
        }
        return (
            <>
                <div className="row">
                    <div className="col">
                        <Button
                            onClick={() => this.handleReturn()}
                            className="dg-back-button"
                            type="primary"
                            icon={<ArrowLeftOutlined />}
                        />
                        <div className="dg-title-explain-info">
                        <span>
                            <strong>{tableName=== undefined? "" :tableName}</strong>
                         </span>
                         <span> 
                            {table=== undefined? null : table.checkNo == 0 ? null : (
                                <>
                                    <strong>{this.props.router.t('noInfo')}: </strong>
                                    {table.checkNo}
                                </>
                            )}
                            {table=== undefined? null :table.customerCount ? (
                                <>
                                    <strong>{" "+ this.props.router.t('customer-count')}: </strong>
                                    {table.customerCount}
                                </>
                            ) : null}
                        </span>
                        </div>
                    </div>
                    <div className="col">
                        <Button
                            onClick={() => this.handleMultiplier(2)}
                            className="dg-nonepad"
                        >
                            2
                        </Button>
                        <Button
                            onClick={() => this.handleMultiplier(3)}
                            className="dg-nonepad"
                        >
                            3
                        </Button>
                        <Button
                            onClick={() => this.handleMultiplier(4)}
                            className="dg-nonepad"
                        >
                            4
                        </Button>
                        <Button
                            onClick={() => this.handleMultiplier(5)}
                            className="dg-nonepad"
                        >
                            5
                        </Button>
                        <Button
                            onClick={() => this.handleMultiplier("X")}
                            className="dg-nonepad dg-nonepad-op"
                        >
                            X
                        </Button>
                        <Button
                            onClick={() => this.handleMultiplier("C")}
                            className="dg-nonepad dg-nonepad-del"
                        >
                            C
                        </Button>
                        <Button className="dg-nonepadNumber dg-nonepad dg-nonepad-sum ">
                            {this.props.multiplier}
                        </Button>
                    </div>
                    <div className="col" style={{ width: "35%" }}>
                        <Input
                            disabled
                            className="dg-date"
                            placeholder={
                                this.state.date.toLocaleDateString() +
                                "  " +
                                this.state.date.toLocaleTimeString("en-US",{hour: '2-digit', minute: '2-digit', hour12: false, })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{ display: "block" }}>
                        <div className="adisyon-label">
                            <div className="label-text">{this.props.router.t("ticket")}</div>
                            {table?.customTable ? (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <MuiButton onClick={() => this.handleDeleteTable()} variant="contained" color="inherit" sx={{ float: "right", color: "black" }} startIcon={<DeleteIcon />}>{this.props.router.t('delete-table')}</MuiButton>
                                </div>
                            ) : null}
                            {table?.orders?.length != 0 && table?.remainingPrice == 0 && table.title != "Home-Delivery" ? (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <MuiButton onClick={() => this.handleCompleteOrder()} variant="contained" color="inherit" sx={{ float: "right", color: "black" }} startIcon={<DeleteIcon />}>{this.props.router.t('complete-order')}</MuiButton>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col" style={{ display: "block" }}>
                        <div className="adisyon-label">
                            <div className="label-text">
                                {GlobalStates.SelectedCourier == -1
                                    ? this.props.router.t("courier-name")
                                    : this.props.router.t("product-name")}
                            </div>
                            <div className="searchbar">
                                <button className="searchbar-button">
                                    <FaSearch />
                                </button>
                                <input
                                    onChange={(event) => this.props.onSearchProduct(event.target.value)}
                                    className="searchbar-input"
                                    placeholder={this.props.router.t("search")}
                                    onClick={() => {
                                        ipcRenderer.send('showKeyboard')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col" style={{ display: "block", width: "35%" }}>
                        <div className="adisyon-label">
                            <div className="label-text">
                                {this.props.router.t("categories")}
                            </div>
                            <div className="searchbar">
                                <button className="searchbar-button">
                                    <FaSearch />
                                </button>
                                <input
                                    className="searchbar-input"
                                    onChange={(event) => this.props.onSearchCategory(event.target.value)}
                                    placeholder={this.props.router.t("search")}
                                    onClick={() => {
                                        ipcRenderer.send('showKeyboard')
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Navbar;
