import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import clone from "clone";
import Thread from "../../../../library/thread";
import { FaRegSadTear } from "react-icons/fa";
import { info } from "console";
import reasons from "public/reasons";

const deleteHomeDeliveryProduct = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: commonProp.router.t("delete-question"),
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            let params = {
                products: pageOrderTable.state.selectedProducts,
                orderNum: pageOrderTable.props.or.orderNum
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });

            const res = await Services.Delete.homeDeliveryProduct(params);
            if(res.status) {
                let unapproved = pageOrderTable.props.or.unapproved.filter((p: any) => !pageOrderTable.state.selectedProductId.includes(p._id));
                let products =pageOrderTable.props.or.products.filter((p: any) => !pageOrderTable.state.selectedProductId.includes(p._id));
                let paid = pageOrderTable.props.or.paid.filter((p: any) => !pageOrderTable.state.selectedProductId.includes(p._id));
                let cancelled = pageOrderTable.props.or.cancelled.filter((p: any) => !pageOrderTable.state.selectedProductId.includes(p._id));;
                let onWay = pageOrderTable.props.or.onWay.filter((p: any) => !pageOrderTable.state.selectedProductId.includes(p._id));
                let deletedPrice = 0;
                pageOrderTable.state.selectedProducts.map((p: any) => deletedPrice += p.price);
                pageOrderTable.setState({
                    or: {
                        unapproved: unapproved,
                        products: products,
                        paid: paid,
                        cancelled: cancelled,
                        onWay: onWay,
                        totalPrice: pageOrderTable.state.or.totalPrice - deletedPrice,
                        remainingPrice: pageOrderTable.state.or.remainingPrice - deletedPrice
                    },
                    selectedProductId: [],
                    selectedProducts: []
                });
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('delete-success')
                })
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default deleteHomeDeliveryProduct;
