import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import clone from "clone";
import Thread from "../../../../library/thread";
import { FaRegSadTear } from "react-icons/fa";
import { info } from "console";
import reasons from "public/reasons";

const onWayHomeDelivery = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: commonProp.router.t("onway-all-question"),
        icon: "question",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            let params = {
                orderNum: pageOrderTable.props.or.orderNum,
                courier: GlobalStates.SelectedCourierId._id
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            const res = await Services.Put.onWayHomeDelivery(params);

            if(res.status) {
                commonProp.setGlobalData({AllCourier: res.data.data});
                let onway = pageOrderTable.state.or.onWay.concat(pageOrderTable.state.or.products);

                pageOrderTable.setState({
                    or: {
                        products: [],
                        cancelled: pageOrderTable.props.or.cancelled,
                        onWay: onway,
                        paid: pageOrderTable.props.or.paid,
                        unapproved: pageOrderTable.props.or.unapproved
                    }
                });
                pageOrderTable.props.or.onWay = onway;
                pageOrderTable.props.or.products = [];
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('onway-all-success')
                })
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default onWayHomeDelivery;
