import { Component, FormEvent, FormEventHandler } from "react";
import { PagePropCommonDocument } from "../../../modules/views/pages/pageProps";
import { Formik } from "formik";
import Services from "services/index";
import HandleForm from "../../../library/react/handles/form";
import functions from "config/global/functions";
import { CircularProgress } from "@material-ui/core";
import { Button } from '@mui/material';
import { GlobalLocalStorages, GlobalStates } from "config/global";
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    formData: {
        amount: number
    }
    cases: any[],
    timer: number,
    progress: number,
}

type PageProps = {} & PagePropCommonDocument


class NewCase extends Component<PageProps, PageState> {
    interval: any = {};
    timer: any = {};
    constructor(props: PageProps) {
        super(props);
        this.state = {
            formData: {
                amount: 0
            },
            cases: Services.Get.allCases().data,
            timer: 5,
            progress: 0
        }
    }

    componentDidMount() {
        if (this.state.cases.length == 1 && !functions.checkPerm('511')) {
            this.onCaseSelect(this.state.cases[0]);
            this.interval = setInterval(() => {
                let cases = Services.Get.allCases().data;
                this.setState({
                    cases: cases,
                    progress: 0,
                });
            }, 5000)
            this.timer = setInterval(() => {
                this.setState({
                    progress: this.state.progress + 1.25,
                });
            }, 62.5)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        clearInterval(this.timer);
    }
    onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        Services.Post.case({
            start_balance: [
                {
                    type: 1,
                    amount: this.state.formData.amount,
                    currency: this.props.getGlobalData?.CurrencySettings?.defaultCurrency ? this.props.getGlobalData?.CurrencySettings?.defaultCurrency : "TL" 
                }
            ]
        }).then(resData => {
            if (resData.status) {
                window.location.href = "/";
            }
        })
    }
    onCaseSelect = (_case: any) => {
        localStorage.setItem('caseId', _case._id);
        this.props.setGlobalData({
            caseId: _case._id
        }, () => {
            window.location.href = "/";
        });
    }

    render() {
        if (functions.checkPerm('511')) {
            return (
                <div className="Home">
                    <div className="OpenCase">
                        <form className="form" onSubmit={(e) => this.onSubmit(e)}>
                            <div className="caseClosed">{this.props.router.t("open-case-description")}</div>
                            <input
                                type="number"
                                name="amount"
                                value={this.state.formData.amount}
                                onChange={e => HandleForm.onChangeInput(e, this)}
                                onClick={() => ipcRenderer.send('showKeyboard')}
                            />
                            <button className="button" type="submit">{this.props.router.t("open-case")}</button>
                        </form>
                        <Button variant="contained" color="error" sx={{
                            fontWeight: "bold",
                            fontSize: "1vw",
                            width: "12vw",
                            height: "4vw",
                            position: "absolute",
                            right: 10,
                            bottom: 20
                        }}
                            onClick={() => {
                                GlobalLocalStorages.Token.delete();
                                GlobalLocalStorages.User.delete();
                                GlobalStates.firstNavigate = true;
                                window.location.href = '/sign-in'

                            }}
                        >{this.props.router.t('sign-out')}</Button>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <div style={{
                    color: "#fff",
                    fontWeight: 900,
                    fontSize: "2rem",
                    textAlign: "center"
                }}>
                    {this.props.router.t('no-permission-for-open-case')}
                </div>
                <div className="cases" style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <>

                        {this.state.cases.length > 0 ? (
                            <>
                                {this.state.cases.map((_case: any) => (
                                    <div style={{
                                        border: "2px solid black",
                                        borderRadius: "10px",
                                        padding: 15,
                                        cursor: "pointer",
                                        marginBottom: 2
                                    }}
                                        key={_case._id}
                                        onClick={() => this.onCaseSelect(_case)}
                                    >
                                        <p style={{ color: "#fff" }}><b>{this.props.router.t('user')}:</b> {_case?.user?.name} {_case?.user?.lastname}</p>
                                        <p style={{ color: "#fff" }}><b>{this.props.router.t('opening-date')}:</b> {new Date(_case.createdAt).toLocaleString()}</p>


                                    </div>
                                ))}
                            </>
                        ) : (
                            <p style={{
                                color: "red",
                                fontWeight: 700,
                                fontSize: "1.2rem"
                            }}>{this.props.router.t('no-open-cases')}</p>
                        )}
                        <CircularProgress variant="determinate" value={this.state.progress} />

                    </>


                </div>
                <Button variant="contained" color="error" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "12vw",
                    height: "4vw",
                    position: "absolute",
                    right: 10,
                    bottom: 20
                }}
                    onClick={() => {
                        GlobalLocalStorages.Token.delete();
                        GlobalLocalStorages.User.delete();
                        GlobalStates.firstNavigate = true;
                        window.location.href = '/sign-in'

                    }}
                >{this.props.router.t('sign-out')}</Button>
            </div>
        )

    }
}

export default NewCase;