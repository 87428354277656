import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { getGlobalAuthData } from 'config/global';

export const Informations = () => {
    const { t } = useTranslation();
    return (
        <Box sx={{ color: "#fff", border: "1px solid white", width: "30%", margin: "auto", textAlign: "center", marginBottom: "2vw", padding: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t('username')}</Typography>
            <Typography sx={{fontSize: "1.5vw"}}>
                {getGlobalAuthData().user?.name} {getGlobalAuthData().user?.lastname}
            </Typography>
            <hr />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>{t('branch-title')}</Typography>
            <Typography>
                {getGlobalAuthData().user?.branchTitle}
            </Typography>
        </Box>
    )
}
