import ReactDOM from "react-dom";
import App from "./app/";

import "./library/variable/array"
import "./library/variable/string"
import "./library/variable/number"
import "./library/variable/date"
import "./library/variable/math"
import { HashRouter } from "react-router-dom";

import "app/views/pages/orders/products/products.css"
import NoConnection from "app/views/pages/no-connection";

ReactDOM.render(
    <HashRouter>
        <App />
    </HashRouter>,
    document.getElementById("root")
);