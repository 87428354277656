import GlobalStates from "config/global/states";
import Settings from "../settings/settings";
import PrintKitchenCancelled from './printKitchenCancelled';

export default class KitchenCancelledByGroup {
    static printKitchenCancelledByGroup(t: any,check: any) {
        const Groups = Settings.Groups.map((group: any) => ({
            ...group,
            products: check.products.filter((p: any) => group.categories.includes(p.category)),
            table: GlobalStates.CurrentTable.title,
            section: check.section
        }));
        Groups.length ?
            Groups.forEach((data) => data.products.length && PrintKitchenCancelled.printKitchenCancelled(t,{
                ...check,
                products: data.products,
                table: data.table,
                section: data.section
            },
                data.printer
            )) : null;
    }
}