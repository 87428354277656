import ReactDOMServer from "react-dom/server";
import DateFormat from "../date/date";
import Print from "../print/print";
import Settings, { PrinterDefaultColumnHeight } from "../settings/settings";


export default class KitchenCancelled {
    static printKitchenCancelled(t:any, check: any, printer?: any) {
        if (check.products?.length == 0) return;

        let perDefaultHeight = PrinterDefaultColumnHeight;
        let height = 0;

        const products = check.products.map((product: any) => {
            height += perDefaultHeight;
            return (
                <>
                    <div style={{
                        marginBottom: 3,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        width: "100%",
                    }}>
                        <div style={{ width: "fit-content" }}>
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                                {product.quantity}x{" "}
                            </span>
                            <span style={{ fontSize: 18, fontWeight: 600 }}>
                                {" "}
                                {product.productName}{" "}
                            </span>
                        </div>
                    </div>
                </>
            )
        });
        const dataTop = [
            {
                type: "text",
                value: `<div style="font-weight: 700;font-size:22px; text-align:center;">${Settings.CurrentSafePrinterName === (undefined||"") ? "DIGIGARSON" : Settings.CurrentSafePrinterName}</div>`
            },
            {
                type: "text",
                value: `
                <div style=" display: flex; flex-direction: column; justify-content: center; width: 100%; text-align: center">
                    ${check.invoiceName ? `<span>${check.invoiceName}</span><br>` : ""}
                    <span>${t("date")}: ${DateFormat.formatDate(new Date())}</span><br>
                    <span>${t("waiter")} / ${t("authorised")}: ${check.waiter ? check.waiter : check.user?.name + " " + check.user?.lastname}</span><br/>
                    <span>${check.customer ? `${t("address")}: ${check.address}` : `${t("section")}: ${check.section} ${t("table")}: ${check.table}`}</span><br>
                    <span>${check.customer ? `${t("person")}: ${check.customer.full_name}` : ``}</span><br>
                    <h3>${t("cancelProducts")}</h3>
                </div>
                <hr />`,
            }
        ];

        const dataProducts = [
            {
                type: "text",
                value: ReactDOMServer.renderToStaticMarkup(products)
            },
            {
                type: "text",
                value: ReactDOMServer.renderToStaticMarkup(
                    <>
                        <hr />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <span style={{ left: 0 }}>..</span>
                            <span style={{ right: 0 }}></span>
                        </div>
                        <hr />
                    </>
                )
            }
        ];

        let data: any[] = [];
        data = dataTop.concat(dataProducts);

        Print.print(Settings.payload_with_settings(data), height, printer);
    }
}