interface ICurrencies {
    [key: string]: string;
}

const Currencies: ICurrencies = {
    "TL": "₺",
    "USD": "$",
    "EUR": "€",
    "GBP": "£"

}

export default Currencies;