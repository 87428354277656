import React from 'react'
import { useTranslation } from 'react-i18next'
import { Informations } from '../components/informations';
import { Box, Typography, Button } from "@mui/material";
import { GlobalLocalStorages, GlobalStates } from 'config/global';
export const Banned = () => {

    const { t } = useTranslation();

    return (
        <Box>
            <Informations />
            <Typography style={{ fontSize: "1.8vw", color: "red", fontWeight: "bold", padding: 2, margin: "auto" }} textAlign="center">{t('banned')}</Typography>
            <Typography style={{ fontSize: "1.8vw", color: "#fff", padding: 2, margin: "auto" }} textAlign="center">{t('banned-info')}</Typography>
            <Box>
                <Button variant="contained" color="error" sx={{
                    fontWeight: "bold",
                    fontSize: "1vw",
                    width: "12vw",
                    height: "4vw",
                    position: "absolute",
                    right: 10,
                    bottom: 20
                }} onClick={() => {
                    GlobalLocalStorages.Token.delete();
                    GlobalLocalStorages.User.delete();
                    GlobalStates.firstNavigate = true;
                    window.location.href = '/sign-in'

                }}>{t('sign-out')}</Button>
            </Box>
        </Box>
    )
}
