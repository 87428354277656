import { PagePropCommonDocument } from 'modules/views/pages/pageProps';
import React, { Component, useState, useEffect } from 'react';
import { Box, Stack, Button, Grid, Typography, TextField, AppBar, Toolbar, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText, IconButton } from '@mui/material';
import { KeyboardReturn } from '@mui/icons-material';
import Currencies from 'constants/currencies';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import printers from 'config/global/printers';
import { getGlobalAuthData } from 'config/global';
import Swal from 'sweetalert2';
import Services from 'services/index';
import { isEqual } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import clone from 'clone';

const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageProps = {} & PagePropCommonDocument;

type PageState = {
    products: any[];
    selectedProduct: any;
    searchText: string;
    editDialogOpen: boolean;

}

export default class Barcode extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            products: [],
            selectedProduct: null,
            searchText: "",
            editDialogOpen: false
        }
    }

    componentDidMount(): void {
        this.setState({
            products: this.props.getGlobalData?.Products?.filter((product: any) => product.barcode)
        })
    }

    componentDidUpdate(prevProps: Readonly<PagePropCommonDocument>, prevState: Readonly<PageState>, snapshot?: any): void {
        if (!isEqual(this.state.products, this.props.getGlobalData?.Products?.filter((p: any) => p.barcode))) {
            this.setState({
                products: this.props.getGlobalData?.Products?.filter((p: any) => p.barcode)
            });
        }
    }

    getCategory = (product: any) => {
        return this.props.getGlobalData.Categories?.find((c: any) => c._id == product?.category)?.title
    }

    printBarcode = () => {
        if (!this.state.selectedProduct) return;
        let res = printers.PrintBarcode.printBarcode({
            productName: this.state.selectedProduct.title,
            price: this.state.selectedProduct.prices[0].price,
            currency: this.state.selectedProduct.prices[0].currency,
            value: this.state.selectedProduct.sale_type == 2 ? `${this.state.selectedProduct.barcode} 01000` : `${this.state.selectedProduct.barcode}`,
            user: `${getGlobalAuthData()?.user?.name} ${getGlobalAuthData()?.user?.lastname}`,
            branch: getGlobalAuthData()?.user?.branchTitle,
            weight: 1000,
            unitPrice: this.state.selectedProduct.prices[0].price,
            pairCode: true,
            sale_type: this.state.selectedProduct.sale_type
        });
        if (!res) return;
        toast.success(this.props.router.t('barcode-printed-success'));
    }

    unpairProduct = () => {
        Swal.fire({
            icon: "question",
            title: this.props.router.t('sure-unpair'),
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: this.props.router.t('yes'),
            cancelButtonText: this.props.router.t('cancel')
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: this.props.router.t("pleaseWait").toCapitalizeCase(),
                    html: this.props.router.t("uploading") + "...",
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    willOpen(popup: HTMLElement) {
                        Swal.showLoading()
                    }
                });
                let response = await Services.Delete.unpairBarcode(this.state.selectedProduct?._id);
                if (response.status) {
                    this.setState({
                        selectedProduct: null
                    })
                    Swal.fire({
                        icon: "success",
                        title: this.props.router.t('unpaired-success')
                    })
                    return;
                }
                Swal.fire({
                    icon: "error",
                    title: this.props.router.t('error')
                })
            }
        })
    }

    EditDialog = () => {
        const [barcode, setBarcode] = useState("");
        const [loading, setLoading] = useState(false);
        const [plu, setPlu] = useState("");

        const scannerSubmit = async (code: string) => {
            let check = this.props.getGlobalData.Products?.find((p: any) => p.barcode == code);
            if (check) {
                toast.warning(this.props.router.t('barcode-already-in-use'));
                return;
            }
            setLoading(true);
            let barcode = clone(code);
            let response = await Services.Post.pairBarcode({ productId: this.state.selectedProduct?._id, barcode: barcode });
            if (response.status) {
                toast.success(this.props.router.t('barcode-edit-success'));
                let product = this.state.selectedProduct;
                product.barcode = barcode;
                this.setState({
                    selectedProduct: product
                })
                setLoading(false);
                handleClose();
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }

        useEffect(() => {
            var interval: any;
            var code = "";
            window.addEventListener("keydown", (e: any) => {
                if (!this.state.editDialogOpen) return;
                if (interval) clearInterval(interval);
                if (e.code == "Enter") {
                    if (code) {
                        scannerSubmit(code);
                    }
                    code = ""
                    return;
                }
                if (e.code != "Shift") {
                    code = code + e.key;
                }
                interval = setInterval(() => code = "", 20);
            });
            return () => clearInterval(interval);
        }, [])

        const handleClose = () => {
            setBarcode("");
            this.setState({
                editDialogOpen: false
            });
        }



        const handleSubmit = async () => {
            if (!barcode) {
                toast.warning(this.props.router.t('invalid-barcode'));
                return;
            }
            let check = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == barcode);
            if (check && check._id?.toString() != this.state.selectedProduct?._id?.toString()) {
                toast.warning(this.props.router.t('barcode-already-in-use'));
                return;
            }
            setLoading(true);
            let response = await Services.Post.pairBarcode({ productId: this.state.selectedProduct?._id, barcode: barcode, plu: plu });
            if (response.status) {
                toast.success(this.props.router.t('barcode-edit-success'));
                let product = this.state.selectedProduct;
                product.barcode = barcode;
                this.setState({
                    selectedProduct: product
                })
                setLoading(false);
                handleClose();
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }
        return (
            <Dialog open={this.state.editDialogOpen} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{
                        backgroundColor: "#C12E2E",
                        padding: "0.5vw",
                        borderRadius: 8,
                        width: "60%",
                        margin: "auto",
                        color: "#fff"
                    }}>
                        <Typography variant='h5' textAlign="center">
                            {this.props.router.t('edit-product-barcode')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={1} justifyContent="center" alignItems="center">
                        <TextField label={this.props.router.t('barcode')} value={barcode} onChange={(e: any) => setBarcode(e.target.value)} onClick={() => ipcRenderer.send('showKeyboard')} />
                        <FormHelperText>
                            {this.props.router.t('edit-barcode-helper')}
                            <FormHelperText error={true}>{this.props.router.t('edit-barcode-helper-2')}</FormHelperText>
                        </FormHelperText>
                        <TextField label={this.props.router.t('plu')} value={plu} onChange={(e: any) => setPlu(e.target.value)} onClick={() => ipcRenderer.send('showKeyboard')} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' disabled={loading} color="success" onClick={handleSubmit}>{this.props.router.t('save')}</Button>
                    <Button variant='outlined' disabled={loading} color="error" onClick={handleClose}>{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    render() {
        return (
            <Box>
                <ToastContainer />
                <this.EditDialog />
                <AppBar sx={{ backgroundColor: "#2F353E", padding: 2 }}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" sx={{ height: "50%" }}>
                            <Button variant='contained' sx={{ height: "auto", width: "10vw", fontSize: "1vw" }} onClick={() => this.props.router.navigate("/", { replace: true })} startIcon={<KeyboardReturn />} color="error">{this.props.router.t('back')}</Button>
                            <Box sx={{ width: "25vw", margin: "auto", marginLeft: "2vw", marginRight: "2vw", backgroundColor: "#3F4E6D", padding: "0.7vw", borderRadius: "8px" }}>
                                <Typography textAlign="center" variant="h4" sx={{ fontWeight: "bold", fontFamily: "serif", fontSize: "1.8vw" }}>{this.props.router.t('paired-products')}</Typography>
                            </Box>
                            <Box sx={{ height: "auto" }}>
                                <TextField onClick={() => ipcRenderer.send('showKeyboard')} onChange={(e: any) => this.setState({ searchText: e.target.value })} placeholder={this.props.router.t('search')} sx={{
                                    width: "20vw", border: "none", fontSize: "1vw",
                                    '.MuiInputBase-input': { backgroundColor: "#fff", borderRadius: "20px", border: "none" },
                                    '.MuiOutlinedInput-root': { borderRadius: "20px" }
                                }} />
                            </Box>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Button sx={{ width: "10vw", fontSize: "1vw" }} variant="contained" color="warning" onClick={() => this.props.router.navigate('/barcode/pairing', { replace: true })}>{this.props.router.t('pair-product')}</Button>
                            </Stack>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center" sx={{ overflowY: "scroll", flexDirection: "row" }}>
                    <Box className="content" sx={{ height: "85vh", padding: 5 }}>
                        {this.state.products?.length == 0 ? (
                            <Typography variant="h3" textAlign="center" sx={{ color: "#fff", fontFamily: "serif" }}>{this.props.router.t('has-no-paired-product')}</Typography>
                        ) : (
                            <Box sx={{ display: "flex", margin: "auto", marginTop: 0, width: "95%" }}>
                                <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
                                    alignItems="center" sx={{ margin: "auto", width: "50%" }}
                                >
                                    {this.state.products?.filter((product: any) => product.title?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()))?.map((product: any, i: number) => (
                                        <Grid item xs={4} sm={8} md={4} key={i}>
                                            <Box onClick={() => this.setState({ selectedProduct: product })} sx={{ backgroundColor: "#C14C4C", width: "20vw", padding: "1vw", borderRadius: "5px", "&:hover": { transform: "scale(1.1)", boxShadow: "3px 5px #fff" }, "&:active": { backgroundColor: "#B96666" } }}>
                                                <Stack alignItems="center" spacing={1} sx={{ color: "#fff" }}>
                                                    <Avatar src={product.image} variant='rounded' sx={{ width: "10vw", height: "7vw" }} />
                                                    <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}:</span> {product.title}</Typography>
                                                    <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('category')}:</span> {this.getCategory(product)}</Typography>
                                                    <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('price')}:</span> {product.prices[0].price} {Currencies[product.prices[0]?.currency] || "₺"}</Typography>
                                                    <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('barcode')}:</span> {product.barcode}</Typography>
                                                    {product.plu ? (
                                                        <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('plu-text')}:</span> {product.plu}</Typography>
                                                    ) : null}
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>

                        )}
                    </Box>
                    {this.state.selectedProduct ? (
                        <Box className="selectedProduct" sx={{ height: "85vh", padding: 5 }}>
                            <Box sx={{ backgroundColor: "#C62F2F", padding: 6, borderRadius: 8, color: "#fff", fontSize: "0.8vw" }}>
                                <IconButton onClick={() => this.setState({ selectedProduct: null })} sx={{ position: "absolute", right: 60, top: 115, color: "#fff" }}><CloseIcon /></IconButton>

                                <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                                    <Box>
                                        <Avatar src={this.state.selectedProduct.image} variant='rounded' sx={{ width: "20vw", height: "15vw" }} />
                                    </Box>
                                    <Box>
                                        <Stack direction="column" spacing={4} justifyContent="center" alignItems="center">
                                            <Stack direction="row" spacing={2} justifyContent="cener" alignItems="center">
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}:</span> {this.state.selectedProduct?.title}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('category')}:</span> {this.getCategory(this.state.selectedProduct)}</Typography>
                                            </Stack>
                                            <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('price')}:</span> {this.state.selectedProduct?.prices[0].price} {this.state.selectedProduct?.prices[0]?.currency}</Typography>
                                            <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('barcode')}:</span> {this.state.selectedProduct?.barcode}</Typography>
                                        </Stack>
                                    </Box>
                                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                                        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                            <Button variant="contained" onClick={() => this.setState({ editDialogOpen: true })} startIcon={<ListIcon />} color="warning" sx={{ fontSize: "0.8vw" }}>{this.props.router.t('edit')}</Button>
                                            <Button variant="contained" onClick={this.unpairProduct} startIcon={<LinkOffIcon />} color="error" sx={{ fontSize: "0.6vw", backgroundColor: "#BA0000" }}>{this.props.router.t('unpair')}</Button>
                                        </Stack>
                                        <Button variant="contained" onClick={this.printBarcode} startIcon={<PrintIcon />} color="warning" sx={{ fontSize: "0.8vw" }}>{this.props.router.t('print-barcode')}</Button>
                                    </Stack>
                                </Stack>
                            </Box>

                        </Box>
                    ) : null}
                </Box>
            </Box>
        )
    }
}
