import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import clone from "clone";
import Thread from "../../../../library/thread";
import { FaRegSadTear } from "react-icons/fa";
import { info } from "console";
import reasons from "public/reasons";
import ProductsInOrder from "config/global/printers/order/printProductsInOrder";

const cancelHomeDeliveryProduct = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: "Seçilen ürünler iptal edilsin mi?",
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            pageOrderTable.state.selectedProductId.map((p: any, pIndex: number) => {
                let cIndex = pageOrderTable.props.or.cancelled.findIndex((c: any) => c._id == p);
                if(cIndex > -1) pageOrderTable.state.selectedProductId.remove(pIndex)
            })
            let state = pageOrderTable.state.selectedProducts.filter((p: any) => pageOrderTable.state.selectedProductId.includes(p._id));
            pageOrderTable.setState({
                selectedProducts: state
            })
            let params = {
                products: pageOrderTable.state.selectedProductId,
                orderNum: pageOrderTable.props.or.orderNum
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });

            const res = await Services.Put.cancelHomeDeliveryProduct(params);
            if(res.status) {
                let unapproved = pageOrderTable.props.or.unapproved.filter((p: any) => !params.products.includes(p._id));
                let products = pageOrderTable.props.or.products.filter((p: any) => !params.products.includes(p._id));
                let onWay = pageOrderTable.props.or.onWay.filter((p: any) => !params.products.includes(p._id));
                let cancelled = pageOrderTable.props.or.cancelled.concat(pageOrderTable.state.selectedProducts);
                let paid = pageOrderTable.props.or.paid.filter((p: any) => !params.products.includes(p._id));
                let deletedPrice = 0;
                pageOrderTable.state.selectedProducts.map((p: any) => deletedPrice += p.price);
                pageOrderTable.setState({
                    or: {
                        unapproved: unapproved,
                        products: products,
                        onWay: onWay,
                        cancelled: cancelled,
                        paid: paid,
                        totalPrice: pageOrderTable.state.or.totalPrice - deletedPrice,
                        remainingPrice: pageOrderTable.state.or.remainingPrice - deletedPrice
                    },
                    selectedProducts: [],
                    selectedProductId: []
                });
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('cancel-success')
                })
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default cancelHomeDeliveryProduct;
