import { Component, useState, useEffect } from "react";
import Printer from "../../../config/global/printers/index";
import Operations from "../components/operations";
import Sections from "../components/sections";
import "../../../assets/app/styles/tables.css";
import Table from "../components/tables";
import Swal from "sweetalert2";
import "../../../assets/app/styles/order.css";
import { PagePropCommonDocument } from "../../../modules/views/pages/pageProps";
import { GlobalLocalStorages, GlobalStates } from "../../../config/global";
import Services from "../../../services/index";
import { toast, ToastContainer } from "react-toastify";
import NotificationsIcon from "@mui/icons-material/Notifications";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Button,
  Dialog,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import printers from "../../../config/global/printers/index";
import clone from "clone";
import AddIcon from "@mui/icons-material/Add";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import { InputLabel } from "@material-ui/core";
import functions from "config/global/functions";
import fileDownload from "js-file-download";
import Currencies from "constants/currencies";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import checkPerm from "config/global/functions/perm/checkPerm";

const ipcRenderer: any = window.require("electron").ipcRenderer;

type PageState = {
  currentSection: any;
  caseId: string;
  modal: {
    isModalOpen: boolean;
    currentModal: any;
    data: any;
  };
  notificationCenter: boolean;
  anchorEl: any;
  muteNotifications: string;
  notifications: any[];
  specialTableDialogOpen: boolean;
};

type PageProps = {} & PagePropCommonDocument;

class PageIndex extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSection: "all",
      caseId: "",
      modal: {
        isModalOpen: false,
        currentModal: null,
        data: null,
      },
      notificationCenter: false,
      anchorEl: null,
      muteNotifications: localStorage.getItem("mute-notifications") || "false",
      notifications: JSON.parse(localStorage.getItem("notifications")!) || [],
      specialTableDialogOpen: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<PageProps>) {
    if (
      JSON.parse(localStorage.getItem("notifications")!)?.toString() &&
      this.state.notifications?.toString() !=
        JSON.parse(localStorage.getItem("notifications")!)?.toString()
    ) {
      this.setState({
        notifications: JSON.parse(localStorage.getItem("notifications")!) || [],
      });
    }
    if (GlobalStates.Type != "moveTable") {
      this.clearGlobalStates();
    }
  }

  componentDidMount() {
    if (GlobalStates.Type != "moveTable") {
      this.clearGlobalStates();
    }
  }

  clearGlobalStates(isForce?: boolean) {
    if (isForce || GlobalStates.Type != "moveProduct") {
      GlobalStates.CustomerId = "";
      GlobalStates.SelectedCourierId = "";
      GlobalStates.SelectedPaymentTypeId = 0;
      GlobalStates.SelectedTableId = "";
      GlobalStates.Type = "";
      GlobalStates.CurrentModal = {};
    }
  }

  checkForOpenTable() {
    let retunValue = false;
    GlobalStates.AllTables?.forEach((table: any) => {
      if (table.data.busy) {
        retunValue = true;
        return;
      }
    });
    return retunValue;
  }

  handleMoveProduct(targetTableId: string, type: string) {
    let orderId: string[] = [];

    if (type == "table") {
      GlobalStates.ProductsToBeMoving.forEach((order) => {
        orderId.push(order._id);
      });
      Services.Put.moveOrder({
        currentTable: GlobalStates.SelectedTableId,
        targetTable: targetTableId,
        orderIds: orderId,
      }).then(async (resData) => {
        if (resData.status) {
          let allTables = this.props.getGlobalData.AllTables.map((table) => {
            if (table._id == targetTableId) {
              table.orders = resData.data.orders;
              table.cover = resData.data.cover;
              table.discount = resData.data.discount;
              table.totalPrice = resData.data.totalPrice;
              table.remainingPrice = resData.data.remainingPrice;
            } else if (table._id == GlobalStates.SelectedTableId) {
              table.orders = table.orders.filter(
                (order: any) => !orderId.includes(order._id)
              );
            }
            return table;
          });

          let findTable = this.props.getGlobalData.AllTables.findSingle(
            "_id",
            GlobalStates.SelectedTableId
          );
          if (findTable) {
            if (findTable.orders.findMulti("isDeleted", false)?.length === 0) {
              allTables.map((table) => {
                if (table._id == targetTableId) {
                  table.cover = findTable.cover;
                  table.discount = findTable.discount;
                  table.payments = findTable.payments;
                  table.totalPrice = findTable.totalPrice;
                  table.remainingPrice = findTable.remainingPrice;
                } else if (table._id == GlobalStates.SelectedTableId) {
                  table.cover = [];
                  table.discount = [];
                  table.payments = [];
                  table.busy = false;
                  table.totalPrice = 0;
                  table.remainingPrice = 0;
                }
                return table;
              });
            }
          }
          await Services.Get.allTables().then((resData) => {
            if (resData.status) {
              this.props.setGlobalData(
                {
                  AllTables: resData.data,
                },
                () => {
                  Swal.fire({
                    title: this.props.router.t("products-moved"),
                    icon: "success",
                  });
                  GlobalStates.ProductsToBeMoving = [];
                  this.clearGlobalStates(true);
                  this.handleTableClick(null, targetTableId);
                }
              );
            }
          });
        } else {
          Swal.fire({
            title: this.props.router.t("auth-err"),
            icon: "error",
          }).then(() =>
            this.props.router.navigate(
              "/table/" + GlobalStates.SelectedTableId,
              { replace: true }
            )
          );
        }
      });
    } else if (type == "product") {
      GlobalStates.ProductsToBeMoving.forEach((product) => {
        orderId.push(product);
      });
      Services.Put.moveProduct({
        currentTable: GlobalStates.SelectedTableId,
        targetTable: targetTableId,
        orderIds: orderId,
      }).then((resData) => {
        if (resData.status) {
          let allTables = this.props.getGlobalData.AllTables.map((table) => {
            if (table._id == targetTableId) {
              table.orders = resData.data.transferredTable.orders;
              table.totalPrice = resData.data.transferredTable.totalPrice;
              table.remainingPrice =
                resData.data.transferredTable.remainingPrice;
            } else if (table._id == GlobalStates.SelectedTableId) {
              table.orders = table.orders.filter(
                (order: any) => !orderId.includes(order._id)
              );
            }
            return table;
          });
          this.props.setGlobalData(
            {
              AllTables: allTables,
            },
            () => {
              Swal.fire({
                title: this.props.router.t("products-moved"),
                icon: "success",
              });
              GlobalStates.ProductsToBeMoving = [];
              this.clearGlobalStates(true);
              this.handleTableClick(null, targetTableId);
            }
          );
        } else {
          Swal.fire({
            title: this.props.router.t("auth-err"),
            icon: "error",
          }).then(() =>
            this.props.router.navigate(
              "/table/" + GlobalStates.SelectedTableId,
              { replace: true }
            )
          );
        }
      });
    }
  }

  handleTableClick(
    table: any,
    tableId?: any,
    index = -1,
    getirOrderId?: string
  ) {
    if (index > -1) {
      GlobalStates.CurrentTakeaway = index;
    }
    if (tableId)
      table = this.props.getGlobalData.AllTables.findSingle("_id", tableId);

    if (table.order_type === 6 && !checkPerm("526")) {
      toast.error(this.props.router.t("auth-err"));
      return;
    }

    if (GlobalStates.Type == "moveTable") {
      if (GlobalStates.SelectedTableId == table._id) {
        Swal.fire({
          title: this.props.router.t("cancel-action"),
          html: this.props.router.t("cancel-question-act"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.props.router.t("cancel"),
          confirmButtonText: this.props.router.t("yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            GlobalStates.ProductsToBeMoving = [];
            this.clearGlobalStates(true);
            this.handleTableClick(null, table._id);
          }
        });
      } else {
        Swal.fire({
          title: this.props.router.t("pleaseWait").toCapitalizeCase(),
          html: this.props.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });
        this.handleMoveProduct(table._id, "table");
      }
      return;
    } else if (GlobalStates.Type == "moveProduct") {
      if (GlobalStates.SelectedTableId == table._id) {
        Swal.fire({
          title: this.props.router.t("cancel-action"),
          html: this.props.router.t("cancel-question-act"),
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: this.props.router.t("cancel"),
          confirmButtonText: this.props.router.t("yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            GlobalStates.ProductsToBeMoving = [];
            this.clearGlobalStates(true);
            this.handleTableClick(null, table._id);
          }
        });
      } else {
        Swal.fire({
          title: this.props.router.t("pleaseWait").toCapitalizeCase(),
          html: this.props.router.t("uploading") + "...",
          showConfirmButton: false,
          showCancelButton: false,
          allowOutsideClick: false,
          willOpen(popup: HTMLElement) {
            Swal.showLoading();
          },
        });
        this.handleMoveProduct(table._id, "product");
      }
      return;
    }

    GlobalStates.CurrentTable = table;
    GlobalStates.SelectedTableId = table._id;
    if (table.order_type === 6) {
      GlobalStates.CurrentGetirOrder = getirOrderId || "";
    }

    this.props.router.navigate("/table/" + table._id, { replace: true });
  }

  getTableBusyRatio() {
    let busy = 0;
    if (this.props.getGlobalData.AllTables.length > 0) {
      this.props.getGlobalData.AllTables?.filter(
        (table: any) =>
          table.order_type != 3 &&
          !table.isSafeSales &&
          table.title != "special-table"
      )?.forEach((table: any) => {
        if (table.checkNo > 0) busy++;
      });
      return Math.floor(
        (busy /
          this.props.getGlobalData.AllTables.filter(
            (t: any) =>
              t.order_type != 3 &&
              t.title != "special-table" &&
              !t.isSafeSales &&
              t.order_type != 6
          ).length) *
          100
      );
    }
    return 0;
  }

  SpecialTableDialog = () => {
    const [section, setSection] = useState(
      this.props.getGlobalData.Sections[0]?._id
    );
    const [tableName, setTableName] = useState("");
    const [customerCount, setCustomerCount]: any = useState(undefined);
    const [loading, setLoading] = useState(false);

    const handleSave = async () => {
      if (section.length == 0 || tableName.length == 0) {
        toast.warning(this.props.router.t("fill-required-fields"));
        return;
      }
      setLoading(true);
      let response = await Services.Post.createSpecialTable({
        title: tableName,
        customerCount: customerCount || null,
        section: section,
      });
      if (response.status) {
        toast.success(this.props.router.t("special-table-created-success"));
        this.setState({
          specialTableDialogOpen: false,
        });
        setLoading(false);
        setTableName("");
        setCustomerCount(undefined);
        setSection(this.props.getGlobalData.Sections[0]?._id);
        return;
      }
      setLoading(false);
      toast.error(this.props.router.t("error"));
    };
    return (
      <Dialog
        open={this.state.specialTableDialogOpen}
        onClose={() => this.setState({ specialTableDialogOpen: false })}
        fullWidth
      >
        <DialogTitle>{this.props.router.t("special-table-create")}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <TextField
              onClick={() => ipcRenderer.send("showKeyboard")}
              value={tableName}
              onChange={(e: any) => setTableName(e.target.value)}
              sx={{ marginTop: "0.5vw", marginBottom: "1vw" }}
              label={this.props.router.t("table-name")}
            />
            <TextField
              onClick={() => ipcRenderer.send("showKeyboard")}
              value={customerCount}
              onChange={(e: any) => setCustomerCount(Number(e.target.value))}
              helperText={this.props.router.t("optional")}
              label={this.props.router.t("customer-count")}
            />
            <Box sx={{ marginTop: "1vw" }}>
              <InputLabel id="sectionLabel">
                {this.props.router.t("section")}
              </InputLabel>
              <Select
                sx={{ minWidth: "8vw" }}
                labelId="sectionLabel"
                value={section}
                onChange={(e: any) => setSection(e.target.value)}
              >
                {this.props.getGlobalData.Sections.filter(
                  (section: any) => section.title != "Home-Delivery"
                ).map((section: any) => (
                  <MenuItem key={section._id} value={section._id}>
                    {section.title}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={loading}
            color="success"
            onClick={() => handleSave()}
          >
            {this.props.router.t("save")}
          </Button>
          <Button
            variant="contained"
            disabled={loading}
            color="error"
            onClick={() => this.setState({ specialTableDialogOpen: false })}
          >
            {this.props.router.t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  Section = () => {
    const [sections, setSections]: any[] = useState([]);

    useEffect(() => {
      let localSections = JSON.parse(localStorage.getItem("sections")!);
      let sections = [
        {
          title: this.props.router.t("all"),
          _id: "all",
        },
      ].concat(this.props.getGlobalData.Sections);
      if (!localSections || localSections?.length != sections?.length) {
        setSections(sections);
        return;
      }
      setSections(localSections);
    }, []);

    const reorder = (list: any, startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    };

    const onDragEnd = (result: any) => {
      if (!result.destination) {
        return;
      }

      const items = reorder(
        sections,
        result.source.index,
        result.destination.index
      );
      setSections(items);
      localStorage.setItem("sections", JSON.stringify(items));
    };

    const grid = 8;

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      padding: grid * 2,
      margin: `0 ${grid}px 0 0`,

      // change background colour if dragging
      background: isDragging ? "lightgreen" : "grey",

      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const getListStyle = (isDraggingOver: any) => ({
      display: "flex",
      padding: grid,
    });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {sections.map((item: any, index: number) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Sections
                        isSelected={this.state.currentSection === item._id}
                        sectionName={item.title}
                        {...this.props}
                        onClick={() =>
                          this.setState({ currentSection: item._id })
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  NonRankingTables = () => {
    const [tables, setTables]: any[] = useState([]);
    useEffect(() => {
      let localTables = JSON.parse(localStorage.getItem("tables")!);
      let t = this.props.getGlobalData.AllTables?.filter(
        (t: any) =>
          !t.isSafeSales &&
          t.title != "special-table" &&
          t.order_type != 3 &&
          t.order_type != 6
      );
      if (
        !localTables ||
        localTables?.length != t?.filter((t: any) => !t.customTable)?.length
      ) {
        setTables(t);
        return;
      }
      setTables(localTables);
    }, []);

    const TableItem = (props: any) => {
      let table = this.props.getGlobalData.AllTables.find(
        (t: any) => t._id == props.id
      );

      return (
        <div>
          <Table
            key={props.id}
            router={this.props.router}
            onClick={() => this.handleTableClick(table)}
            tableTitle={table?.title}
            sectionTitle={
              this.props.getGlobalData.Sections?.findSingle(
                "_id",
                table?.section
              )?.title
            }
            createdAt={
              table?.orders?.length > 0
                ? table?.orders[table.orders?.length - 1].createdAt
                : table?.updatedAt
            }
            updatedAt={table?.updatedAt}
            isSelected={GlobalStates.SelectedTableId == table?._id}
            isBusy={table?.orders?.length > 0}
            isPrinted={table?.isPrinted}
            totalPrice={table?.remainingPrice?.toFixed(2)}
          />
        </div>
      );
    };

    return (
      <>
        <Box flexWrap="wrap" display="flex" flexDirection="row">
          {tables
            .filter(
              (table: any) =>
                (this.state.currentSection == "all" ||
                  this.state.currentSection == table.section) &&
                !table.customTable
            )
            .map((table: any) => {
              return (
                <>
                  {TableItem({
                    key: table._id,
                    id: table._id,
                    table: this.props.getGlobalData.AllTables.find(
                      (t: any) => t._id == table._id
                    ),
                  })}
                </>
              );
            })}
          {this.props.getGlobalData?.AllTables?.filter(
            (table: any) => table.customTable
          )?.map((table: any) => (
            <>{TableItem({ key: table._id, id: table._id, table: table })}</>
          ))}
        </Box>
      </>
    );
  };

  Tables = () => {
    const [tables, setTables]: any[] = useState([]);

    const sensors = useSensors(
      useSensor(PointerSensor, {
        activationConstraint: {
          distance: 8,
        },
      })
    );

    useEffect(() => {
      let localTables = JSON.parse(localStorage.getItem("tables")!);
      let tables = this.props.getGlobalData.AllTables?.filter(
        (t: any) =>
          !t.isSafeSales &&
          t.title != "special-table" &&
          t.order_type != 3 &&
          t.order_type != 6
      );
      if (
        !localTables ||
        localTables?.length !=
          tables?.filter((t: any) => !t.customTable)?.length
      ) {
        setTables(tables);
        return;
      }
      setTables(localTables);
    }, []);

    const SortableItem = (props: any) => {
      const [table, setTable]: any = useState(
        this.props.getGlobalData.AllTables.find((t: any) => t._id == props.id)
      );

      const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

      const style = {
        transform: CSS.Transform.toString(transform),
        transition,
      };

      return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <Table
            key={props.id}
            router={this.props.router}
            onClick={() => this.handleTableClick(table)}
            tableTitle={table?.title}
            sectionTitle={
              this.props.getGlobalData.Sections?.findSingle(
                "_id",
                table?.section
              )?.title
            }
            createdAt={
              table?.orders?.length > 0
                ? table?.orders[table.orders?.length - 1].createdAt
                : table?.updatedAt
            }
            updatedAt={table?.updatedAt}
            isSelected={GlobalStates.SelectedTableId == table?._id}
            isBusy={table?.orders?.length > 0}
            isPrinted={table?.isPrinted}
            totalPrice={table?.remainingPrice?.toFixed(2)}
          />
        </div>
      );
    };

    function handleDragEnd(event: any) {
      const { active, over } = event;

      if (active.id !== over.id) {
        setTables((items: any) => {
          const oldIndex = items.findIndex(
            (table: any) => table.id == active.id
          );
          const newIndex = items.findIndex((table: any) => table.id == over.id);
          let i = arrayMove(items, oldIndex, newIndex);
          localStorage.setItem(
            "tables",
            JSON.stringify(i.filter((t: any) => !t.customTable))
          );
          return i;
        });
      }
    }

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Box flexWrap="wrap" display="flex" flexDirection="row">
          <SortableContext items={tables}>
            {tables
              .filter(
                (table: any) =>
                  (this.state.currentSection == "all" ||
                    this.state.currentSection == table.section) &&
                  !table.customTable
              )
              .map((table: any) => {
                table.id = table._id;
                return (
                  <SortableItem key={table.id} id={table.id} table={table} />
                );
              })}
          </SortableContext>
        </Box>
      </DndContext>
    );
  };
  render() {
    let activeOrder = JSON.parse(localStorage.getItem("activeOrder")!) || false;
    return (
      <div className="Home">
        <this.SpecialTableDialog />
        <div className="TableAndSections">
          <main className="Tables">
            <div className="headerBar">
              <Stack direction="row" spacing={5} alignItems="center">
                <Typography
                  textAlign="center"
                  sx={{
                    padding: "1vw",
                    width: "90%",
                    height: "auto",
                    backgroundColor: "#e13e1c",
                    borderRadius: "12px",
                  }}
                >
                  {this.props.router.t("occupancy")}: {this.getTableBusyRatio()}
                  %
                </Typography>
                <Button
                  onClick={() => {
                    if (!functions.checkPerm("519")) {
                      Swal.fire({
                        icon: "error",
                        title: this.props.router.t("auth-err"),
                      });
                      return;
                    }
                    this.setState({ specialTableDialogOpen: true });
                  }}
                  variant="contained"
                  color="error"
                  startIcon={<AddIcon />}
                  sx={{
                    width: "40%",
                    height: "auto",
                    padding: "1vw",
                    fontSize: "1vw",
                  }}
                >
                  {this.props.router.t("special-table-create")}
                </Button>
              </Stack>

              <IconButton
                onClick={() => {
                  let check = localStorage.getItem("mute-notifications");
                  if (check == "false" || !check) {
                    localStorage.setItem("mute-notifications", "true");
                    this.setState({
                      muteNotifications: "true",
                    });
                    return;
                  }
                  localStorage.setItem("mute-notifications", "false");
                  this.setState({
                    muteNotifications: "false",
                  });
                }}
              >
                {this.state.muteNotifications == "false" ||
                !this.state.muteNotifications ? (
                  <VolumeUpIcon fontSize="large" />
                ) : (
                  <VolumeOffIcon fontSize="large" />
                )}
              </IconButton>
              <IconButton
                sx={{ marginRight: "2vw", marginTop: "0.28vw" }}
                aria-controls={
                  this.state.notificationCenter
                    ? "notification-menu"
                    : undefined
                }
                aria-haspopup="true"
                aria-expanded={
                  this.state.notificationCenter ? "true" : undefined
                }
                onClick={(event: any) => {
                  this.setState({
                    notificationCenter: !this.state.notificationCenter,
                    anchorEl: event.currentTarget,
                  });
                  let notifications = JSON.parse(
                    localStorage.getItem("notifications")!
                  );
                  notifications?.map((n: any) => (n.readed = true));
                  localStorage.setItem(
                    "notifications",
                    JSON.stringify(notifications)
                  );
                }}
              >
                <Badge
                  badgeContent={
                    JSON.parse(localStorage.getItem("notifications")!)?.filter(
                      (n: any) => !n.readed
                    )?.length
                  }
                  color="secondary"
                >
                  <NotificationsIcon color="info" fontSize="large" />
                </Badge>
              </IconButton>
              <Menu
                id="notification-menu"
                open={this.state.notificationCenter}
                onClose={() => this.setState({ notificationCenter: false })}
                anchorEl={this.state.anchorEl}
              >
                {this.state.notifications?.length > 0 ? (
                  <>
                    <MenuItem
                      key={Math.random() * 100}
                      onClick={() => {
                        localStorage.removeItem("notifications");
                        this.setState({
                          notifications: [],
                        });
                      }}
                    >
                      <div key={Math.random() * 100}>
                        {this.props.router.t("clear-all")}
                      </div>
                    </MenuItem>
                    <Divider />
                    {this.state.notifications?.map((n: any, index: number) => (
                      <MenuItem key={Math.random() * 100}>
                        <div key={Math.random() * 100}>
                          {n.type == 0 ? (
                            <div
                              onClick={() =>
                                this.handleTableClick(
                                  this.props.getGlobalData?.AllTables.find(
                                    (t: any) => t._id == n.info.tableId
                                  )
                                )
                              }
                              key={Math.random() * 100}
                            >
                              <h2>
                                <strong>
                                  {this.props.router.t("new-order")}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("table")}: {n.info?.table}
                              </p>
                              <p>
                                {this.props.router.t("section")}:{" "}
                                {n.info?.section}
                              </p>
                              <p>
                                {this.props.router.t("total-product-amount")}:{" "}
                                {n.info?.quantity}
                              </p>
                              <p>
                                {this.props.router.t("total-amount")}:{" "}
                                {n.info?.amount}{" "}
                                {Currencies[
                                  localStorage.getItem("defaultCurrency")!
                                ] || <>₺</>}
                              </p>
                            </div>
                          ) : n.type == 1 ? (
                            <div
                              key={index}
                              onClick={() =>
                                this.handleTableClick(
                                  this.props.getGlobalData?.AllTables.find(
                                    (t: any) => t._id == n.info.tableId
                                  )
                                )
                              }
                            >
                              <h2>
                                <strong>
                                  {this.props.router.t("new-service")}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("table")}: {n.info?.table}
                              </p>
                              <p>
                                {this.props.router.t("section")}:{" "}
                                {n.info?.section}
                              </p>
                              <p>
                                {this.props.router.t("customer")}:{" "}
                                {n.info?.customer}
                              </p>
                              <p>
                                {this.props.router.t("request")}:{" "}
                                {n.info?.service}
                              </p>
                            </div>
                          ) : n.type == 2 ? (
                            <div key={index}>
                              <h2>
                                <strong>
                                  {this.props.router.t("new-home-delivery")}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("customer")}:{" "}
                                {n.info?.customer}
                              </p>
                              <p>
                                {this.props.router.t("total-product-amount")}:{" "}
                                {n.info?.quantity}
                              </p>
                              <p>
                                {this.props.router.t("total-amount")}:{" "}
                                {n.info?.amount}{" "}
                                {Currencies[
                                  localStorage.getItem("defaultCurrency")!
                                ] || <>₺</>}
                              </p>
                            </div>
                          ) : n.type == 3 ? (
                            <div
                              key={index}
                              onClick={() =>
                                this.handleTableClick(
                                  this.props.getGlobalData?.AllTables.find(
                                    (t: any) => t._id == n.info.tableId
                                  )
                                )
                              }
                            >
                              <h2>
                                <strong>
                                  {this.props.router.t("new-waiter-order")}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("table")}: {n.info?.table}
                              </p>
                              <p>
                                {this.props.router.t("section")}:{" "}
                                {n.info?.section}
                              </p>
                              <p>
                                {this.props.router.t("total-product-amount")}:{" "}
                                {n.info?.quantity}
                              </p>
                              <p>
                                {this.props.router.t("total-amount")}:{" "}
                                {n.info.amount}{" "}
                                {Currencies[
                                  localStorage.getItem("defaultCurrency")!
                                ] || <>₺</>}
                              </p>
                              <p>
                                {this.props.router.t("waiter")}: {n.info.waiter}
                              </p>
                            </div>
                          ) : n.type == 4 ? (
                            <div key={index}>
                              <h2>
                                <strong>
                                  {this.props.router.t("new-getir-order")}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("confirmation-id")}:{" "}
                                {n.info?.confirmationId}
                              </p>
                              <p>
                                {this.props.router.t("customer")}:{" "}
                                {n.info?.customer}
                              </p>
                              <p>
                                {this.props.router.t("total-amount")}:{" "}
                                {n.info.amount}{" "}
                                {Currencies[
                                  localStorage.getItem("defaultCurrency")!
                                ] || <>₺</>}
                              </p>
                              <p>
                                {this.props.router.t("delivery-type")}:{" "}
                                {n.info?.deliveryType}
                              </p>
                            </div>
                          ) : n.type == 5 ? (
                            <div key={index}>
                              <h2>
                                <strong>
                                  {this.props.router.t(
                                    "getir-order-cancellation"
                                  )}
                                </strong>
                              </h2>
                              <p>
                                {this.props.router.t("confirmation-id")}:{" "}
                                {n.info?.confirmationId}
                              </p>
                              <p>
                                {this.props.router.t("customer")}:{" "}
                                {n.info?.customer}
                              </p>
                              <p>
                                {this.props.router.t("total-amount")}:{" "}
                                {n.info.amount}{" "}
                                {Currencies[
                                  localStorage.getItem("defaultCurrency")!
                                ] || <>₺</>}
                              </p>
                              <p>
                                {this.props.router.t("delivery-type")}:{" "}
                                {n.info?.deliveryType}
                              </p>
                            </div>
                          ) : (
                            <div key={index}>
                              <h2>
                                <strong>
                                  {this.props.router.t(
                                    "unidentified-notification"
                                  )}
                                </strong>
                              </h2>
                            </div>
                          )}
                        </div>
                      </MenuItem>
                    ))}
                  </>
                ) : (
                  <MenuItem>
                    <div key={Math.random() * 100}>
                      {this.props.router.t("no-notification")}
                    </div>
                  </MenuItem>
                )}
              </Menu>
            </div>

            <section style={{ padding: "1vw", paddingBottom: "5vw" }}>
              {activeOrder ? (
                <>
                  {this.props?.getGlobalData?.AllTables?.length > 0 ? (
                    <this.Tables />
                  ) : (
                    <div className="caseClosed">
                      {this.props.router.t("no-tables")}
                    </div>
                  )}
                  {this.props?.getGlobalData?.AllTables?.length > 0 ? (
                    <>
                      {this.props.getGlobalData.AllTables.filter(
                        (table: any) => table.customTable
                      )?.map((table: any) => (
                        <Table
                          key={table._id}
                          router={this.props.router}
                          onClick={() => this.handleTableClick(table)}
                          tableTitle={table?.title}
                          sectionTitle={
                            this.props.getGlobalData.Sections?.findSingle(
                              "_id",
                              table?.section
                            )?.title
                          }
                          createdAt={
                            table?.orders?.length > 0
                              ? table?.orders[table.orders?.length - 1]
                                  .createdAt
                              : table?.updatedAt
                          }
                          updatedAt={table?.updatedAt}
                          isSelected={
                            GlobalStates.SelectedTableId == table?._id
                          }
                          isBusy={table?.orders?.length > 0}
                          isPrinted={table?.isPrinted}
                          totalPrice={table?.remainingPrice?.toFixed(2)}
                        />
                      ))}
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {this.props?.getGlobalData?.AllTables?.length > 0 ? (
                    <>
                      <this.NonRankingTables />
                    </>
                  ) : (
                    <div className="caseClosed">
                      {this.props.router.t("no-tables")}
                    </div>
                  )}
                </>
              )}

              {this.props?.getGlobalData?.AllTables?.length > 0
                ? this.props?.getGlobalData?.AllTables?.filter(
                    (table) =>
                      (this.state?.currentSection == "all" ||
                        this.state?.currentSection == table.section) &&
                      table.order_type == 3
                  ).map((table, index) => (
                    <>
                      {localStorage.getItem("isHomeDeliveryOpen") == "true" ? (
                        <>
                          {table.orders.map((order: any, index: number) => {
                            if (order.status == 0 || order.status == 4) return;
                            return (
                              <Table
                                key={index}
                                router={this.props.router}
                                onClick={() =>
                                  this.handleTableClick(table, null, index)
                                }
                                tableTitle={
                                  order.customer?.full_name
                                    ? order.customer?.full_name
                                    : order.user?.name +
                                      " " +
                                      order.user?.lastname
                                }
                                sectionTitle={
                                  this.props.getGlobalData.Sections?.findSingle(
                                    "_id",
                                    table.section
                                  )?.title
                                }
                                createdAt={order.createdAt}
                                updatedAt={order.updatedAt}
                                isSelected={
                                  GlobalStates.SelectedTableId == table._id
                                }
                                isBusy={table.orders.length > 0}
                                isPrinted={table.isPrint?.status}
                                order_type={3}
                                totalPrice={order.totalPrice?.toFixed(2)}
                              />
                            );
                          })}
                        </>
                      ) : null}
                    </>
                  ))
                : null}
              {this.props?.getGlobalData?.AllTables?.length > 0
                ? this.props?.getGlobalData?.AllTables?.filter(
                    (table) =>
                      (this.state?.currentSection == "all" ||
                        this.state?.currentSection == table.section) &&
                      table.order_type == 6
                  ).map((table, index) => (
                    <>
                      {true ? (
                        <>
                          {table.getirOrders.map(
                            (order: any, index: number) => {
                              if (order.status == 0 || order.status == 4)
                                return;
                              return (
                                <Table
                                  key={index}
                                  router={this.props.router}
                                  onClick={() =>
                                    this.handleTableClick(
                                      table,
                                      null,
                                      index,
                                      order._id
                                    )
                                  }
                                  tableTitle={
                                    order?.confirmationId || "Getir Müşterisi"
                                  }
                                  sectionTitle={
                                    this.props.getGlobalData.Sections?.findSingle(
                                      "_id",
                                      table.section
                                    )?.title
                                  }
                                  createdAt={order.createdAt}
                                  updatedAt={order.updatedAt}
                                  isSelected={
                                    GlobalStates.SelectedTableId == table._id
                                  }
                                  isBusy={true}
                                  isPrinted={table.isPrint?.status}
                                  order_type={6}
                                  totalPrice={
                                    order.totalDiscountedPrice
                                      ? order.totalDiscountedPrice.toFixed(2)
                                      : order.totalPrice.toFixed(2)
                                  }
                                  status={order.status}
                                />
                              );
                            }
                          )}
                        </>
                      ) : null}
                    </>
                  ))
                : null}
            </section>
          </main>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "scroll",
            }}
          >
            <this.Section />
          </div>
        </div>
        <Operations {...this.props} pageIndex={this} />
        <ToastContainer />
      </div>
    );
  }
}

export default PageIndex;
