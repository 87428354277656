import { Component } from "react";
import { MODAL } from "constants/modalTypes";
import { payment_types } from "constants/paymentTypes";
import "./oldcheck.css";
import Services from "../../../../../../services/index";
import { PagePropCommonDocument } from "modules/views/pages/pageProps";
import Callcheck from "./callcheck";
import Printer from "../../../../../../config/global/printers/index";
import Currencies from "constants/currencies";

type PageState = {
  orders: any;
  payment: any;
  oldcheck: any;
  discount: any;
  cover: any;
};

type PageProps = {
  data: Callcheck;
} & PagePropCommonDocument;

class OldCheck extends Component<PageProps, PageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      orders: [],
      payment: [],
      oldcheck: {},
      discount: [],
      cover: [],
    };
  }

  componentDidMount() {
    this.sets();
  }

  sets() {
    const getOldCheck = Services.Get.checks({
      checkId: this.props.data.state.checkID,
    }).data;
    const payments = getOldCheck.payments;
    const orders = getOldCheck.orders;
    const discounts = getOldCheck.discounts;
    const covers = getOldCheck.covers;
    const getoldCheckData = getOldCheck;
    this.setState({
      orders: orders,
      payment: payments,
      oldcheck: getoldCheckData,
      discount: discounts,
      cover: covers,
    });
  }

  getTotalPayment() {
    let sum = 0;
    this.state.payment?.forEach((payment: any) =>
      payment.type == 14 ? undefined : (sum += payment.amount)
    );
    return sum;
  }

  getTotalOrders() {
    let sum = 0;
    this.state.orders?.map((o: any) =>
      o.products
        ?.filter((p: any) => p.status != 0 && !p.isServe)
        .map((p: any) => (sum += p.price))
    );
    return sum;
  }

  getProductCount() {
    let sum = 0;
    this.state.orders?.map((o: any) =>
      o.products
        ?.filter((p: any) => p.status != 0 && !p.isServe)
        .map((p: any) => (sum += p.quantity))
    );
    return sum;
  }
  Orders = (order: any) => {
    return (
      <>
        <h1>
          {this.props.router.t("order-no")}: {order.orderNum}
        </h1>
        {order.products?.map((product: any) => {
          return (
            <table cellSpacing="0" cellPadding="1" style={{ width: "37vw" }}>
              <tr
                style={{
                  backgroundColor: product.status == 0 ? "red" : "#fff",
                }}
              >
                <th style={{ width: "3vw" }}>
                  {product.quantity.toFixed(2).replace(/\.?0+$/, "")}
                </th>
                <th style={{ width: "16vw" }}>{product.productName}</th>
                <th style={{ width: "6vw" }}>
                  {product.isServe ? (
                    this.props.router.t("catering")
                  ) : (
                    <>
                      {product.price.toFixed(2).replace(/\.?0+$/, "")}{" "}
                      {Currencies[
                        this.props.getGlobalData?.CurrencySettings
                          ?.defaultCurrency
                      ] || <>₺</>}
                    </>
                  )}{" "}
                </th>
              </tr>
            </table>
          );
        })}
      </>
    );
  };

  print = () => {
    let total = 0;
    this.state.payment.map((p: any) => (total += p.amount));
    const foundTable = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      this.state.oldcheck.table
    );
    const clonedTable = { ...foundTable };
    clonedTable.checkNo = this.state.oldcheck.checkNo;

    let printData: any = {
      orders: this.state.orders,
      tableInfo: clonedTable,
      payments: this.state.payment.map((p: any) => ({
        type: this.props.router.t(p.type),
        amount: p.amount,
      })),
      discount: this.state.discount,
      cover: this.state.cover,
      service: this.state.oldcheck?.serviceFee,
      total: {
        amount: total,
        currency:
          this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL",
      },
    };

    let table = this.props.getGlobalData.AllTables.findSingle(
      "_id",
      this.state.oldcheck.table
    );
    if (table) {
      let section = this.props.getGlobalData.Sections.findSingle(
        "_id",
        table?.section
      )?.title;
      if (section) {
        printData = {
          ...printData,
          table: `${section} - ${table.title}`,
        };
      }
    }
    Printer.ProductsInOrder.printProductsInOrder(
      printData,
      this.props.getGlobalData?.Products,
      this.props.router.t
    );
  };
  tableTitle = (table: any) => {
    if (table) {
      let section = this.props.getGlobalData.Sections.findSingle(
        "_id",
        table?.section
      )?.title;
      if (section) {
        return `${section} ${table.title}`;
      }
      return "";
    }
  };
  render() {
    return (
      <>
        <div className="oldCheckContainer">
          <table style={{ width: "70vw", border: "1px solid" }}>
            <tr
              style={{
                width: "24vw",
                background: "grey",
                color: "white",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <th>{this.props.router.t("table")}</th>
              <th>{this.props.router.t("payment-status")}</th>
              <th>{this.props.router.t("date")}</th>
            </tr>
            <tr style={{ width: "24vw", whiteSpace: "nowrap" }}>
              <th>{this.tableTitle(this.props.data.state.table)}</th>
              <th>
                {this.state.oldcheck.is_it_paid
                  ? this.props.router.t("paid")
                  : this.state.oldcheck?.order_type === 6
                  ? this.props.router.t("cancelled")
                  : this.props.router.t("not-paid")}
              </th>
              <th>
                {new Date(this.state.oldcheck.createdAt).toLocaleString([], {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </th>
            </tr>
          </table>
          <div className="orderTableDiv">
            <div>
              <div style={{ textAlign: "center" }}>
                {" "}
                <strong>{this.props.router.t("orders")}</strong>{" "}
              </div>
              <table className="orderDiv" cellSpacing="0" cellPadding="0">
                <tr>
                  <td>
                    <table
                      className="orderTableTitle"
                      cellSpacing="0"
                      cellPadding="1"
                    >
                      <tr style={{ color: "white", background: "chocolate" }}>
                        <th style={{ width: "3vw", height: "3vw" }}>
                          {this.props.router.t("number")}
                        </th>
                        <th style={{ width: "16vw", height: "3vw" }}>
                          {this.props.router.t("product-name")}
                        </th>
                        <th style={{ width: "7vw", height: "3vw" }}>
                          {this.props.router.t("price")}
                        </th>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="orderTable">
                      {/* <table cellSpacing="0" cellPadding="1"> */}
                      {this.state.orders?.map((order: any) => (
                        <this.Orders {...order} />
                      ))}

                      {this.state.cover
                        ? this.state.cover.map((cover: any) => (
                            <tr>
                              <th style={{ width: "3vw" }}>
                                {cover.quantity
                                  .toFixed(2)
                                  .replace(/\.?0+$/, "")}
                              </th>
                              <th style={{ width: "18vw" }}>{cover.title}</th>
                              <th style={{ width: "6vw" }}>
                                {cover.price.toFixed(2).replace(/\.?0+$/, "")}{" "}
                                {Currencies[
                                  this.props.getGlobalData?.CurrencySettings
                                    ?.defaultCurrency
                                ] || <>₺</>}
                              </th>
                            </tr>
                          ))
                        : ""}
                      <hr />
                      <div>
                        {this.state.oldcheck?.serviceFee?.length > 0 ? (
                          <>
                            <div style={{ backgroundColor: "orange" }}>
                              <h3
                                style={{
                                  fontWeight: "bold",
                                  margin: "auto",
                                  width: "100%",
                                }}
                              >
                                {this.props.router.t("service-fees")}
                              </h3>
                            </div>
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ width: "3vw" }}>
                                    {this.props.router.t("percentile")}
                                  </th>
                                  <th style={{ width: "9vw" }}>
                                    {this.props.router.t("_title")}
                                  </th>
                                  <th style={{ width: "12vw" }}>
                                    {this.props.router.t("amount")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.oldcheck.serviceFee.map(
                                  (service: any) => (
                                    <tr>
                                      <th style={{ width: "3vw" }}>
                                        {service.percentile
                                          .toFixed(2)
                                          .replace(/\.?0+$/, "")}
                                      </th>
                                      <th style={{ width: "9vw" }}>
                                        {this.props.router.t("service-fee")}
                                      </th>
                                      <th style={{ width: "12vw" }}>
                                        {service.amount
                                          .toFixed(2)
                                          .replace(/\.?0+$/, "")}{" "}
                                        {Currencies[
                                          this.props.getGlobalData
                                            ?.CurrencySettings?.defaultCurrency
                                        ] || "₺"}
                                      </th>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
              <div>
                <tr>
                  <th style={{ width: "5vw", border: "1px solid" }}>
                    {this.props.router.t("total")}
                  </th>
                  <th style={{ maxWidth: "18vw", border: "1px solid" }}>
                    {this.getTotalOrders()}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </th>
                </tr>
              </div>
            </div>

            <div>
              <div style={{ textAlign: "center" }}>
                {" "}
                <strong>{this.props.router.t("payments")}</strong>{" "}
              </div>
              <table style={{ width: "25vw" }} cellSpacing="0" cellPadding="0">
                <tr>
                  <td>
                    <table
                      className="orderTableTitle"
                      cellSpacing="0"
                      cellPadding="1"
                    >
                      <tr style={{ color: "white", background: "green" }}>
                        <th style={{ width: "6vw", height: "3vw" }}>
                          {this.props.router.t("type")}
                        </th>
                        <th style={{ width: "10vw", height: "3vw" }}>
                          {this.props.router.t("quantity")}
                        </th>
                        <th style={{ width: "4vw", height: "3vw" }}>
                          {this.props.router.t("hour")}
                        </th>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="orderTable">
                      <table
                        cellSpacing="0"
                        cellPadding="1"
                        className="orderTableTitle"
                      >
                        {this.state.payment?.map((payment: any) => (
                          <tr>
                            <th
                              style={{
                                width: "6vw",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {this.props.router.t(
                                payment_types[Number(payment.type) - 1]
                              )}
                            </th>
                            <th
                              style={{
                                width: "10vw",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {payment.amount}
                              {payment.currency}
                            </th>
                            <th
                              style={{
                                width: "4vw",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {new Date(payment.createdAt).toLocaleTimeString(
                                [],
                                { hour: "numeric", minute: "2-digit" }
                              )}
                            </th>
                          </tr>
                        ))}
                        {this.state.discount
                          ? this.state.discount.map((discount: any) => (
                              <tr>
                                <th>{discount.note}</th>
                                <th>
                                  {discount.amount}{" "}
                                  {Currencies[
                                    localStorage.getItem("defaultCurrency")!
                                  ] || <>₺</>}
                                </th>
                                <th>{this.props.router.t("discount")}</th>
                              </tr>
                            ))
                          : ""}
                      </table>
                    </div>
                  </td>
                </tr>
              </table>

              <div>
                <tr>
                  <th style={{ width: "5vw", border: "1px solid" }}>
                    {this.props.router.t("total")}
                  </th>
                  <th style={{ maxWidth: "20vw", border: "1px solid" }}>
                    {this.getTotalPayment()}{" "}
                    {Currencies[localStorage.getItem("defaultCurrency")!] || (
                      <>₺</>
                    )}
                  </th>
                </tr>
              </div>
            </div>
          </div>
        </div>
        <div className="changePriceButtons">
          <button
            style={{ background: "blue" }}
            onClick={() => this.props.openModal(MODAL.CALL_CHECK)}
          >
            {" "}
            {this.props.router.t("back-tickets")}{" "}
          </button>
          <button style={{ background: "green" }} onClick={() => this.print()}>
            {" "}
            {this.props.router.t("print")}{" "}
          </button>
          {this.state.oldcheck?.order_type !== 6 && (
            <button
              style={{ background: "green" }}
              onClick={() =>
                this.props.openModal(MODAL.UPDATE_OLD_CHECK, this.props.data)
              }
            >
              {" "}
              {this.props.router.t("update-tickets")}{" "}
            </button>
          )}
        </div>
      </>
    );
  }
}

export default OldCheck;
