const ErrorCodes = {
        success: 0,
        incorrectData: 1,
        emptyValue: 2,
        wrongValue: 3,
        registeredValue: 4,
        sqlSyntax: 5,
        notFound: 6,
        uploadError: 7,
        notLoggedIn: 8,
        noPerm: 9,
        ipBlock: 10,
        alreadyData: 11,
        wrongEmailOrPassword: 12,
        wrongPassword: 13,
        notSameValues: 14
};

export default ErrorCodes;