import { Component } from "react";
import "./category.css";
import { PagePropCommonDocument } from "../../../../../modules/views/pages/pageProps";
import { Box } from '@mui/material'
import { GlobalStates, getGlobalAuthData, getGlobalBranchData } from "config/global";
type PageState = {
  categories: any[]
};

type PageProps = {
  selectedCategories: any[],
  selectedCategoryIds: string[],
  onClick: any,
  pageOrder: any,
  showImages:boolean
  textColor:string 
} & PagePropCommonDocument;

var width = 200;
var ref = 0;

class Category extends Component<PageProps, PageState> {
  state = {
    categories: this.props.getGlobalData.Categories.filter((c: any) => !c.is_sub_category),
  }
  page = 0;
  constructor(props: any) {
    super(props);
  }

  getWidth = (category: any) => {
    let a = 0;
    while (category.is_sub_category) {
      category = this.props.getGlobalData.Categories.filter((c: any) => c._id == category.parent_category)[0]
      a++;
    }
    return 90 - (a * 6);
  }

  subCategory = (category: any): any => {
    return (
      <>
        {category.is_sub_category ? (
          <button className="subButtons" id={category._id} style={{
            backgroundImage: this.props.showImages ? `url(${category.image})` ? `url(${category.image})` : this.props.getGlobalData.BranchImage : "none",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundColor: this.props.selectedCategoryIds.includes(category._id) ? "green" : "hsl(3, 81%, 46%)",
            width: `${this.getWidth(category)}%`,
            transform: this.props.selectedCategoryIds.includes(category._id) ? "scale(1.1)" : 'scale(1)',
            color: this.props.textColor
          }} onClick={() => {
            this.props.onClick(category);

          }}>
            {category.title}
          </button>
        ) : null}

        {this.props.selectedCategoryIds.includes(category._id) ?
          this.props.getGlobalData.Categories.filter((c: any) => c.parent_category == category._id).filter((ca: any) => ca._id != category._id).map((ca: any, index: any) => {
            ref = ref - 20
            return (
              <>
                {this.subCategory(ca)}
              </>
            )
          })

          : null}

      </>


    )

  }

  render() {
    let showImages = localStorage.getItem("showImages") ? JSON.parse(localStorage.getItem("showImages")!) : true;
    let textColor = localStorage.getItem("textColor") ? localStorage.getItem("textColor")! : "#fff";
    return (
      <>
        {[{ _id: "all", title: this.props.router.t("all") }].concat(this.state.categories).map((category: any, index: number) => {
          ref = width - 20;
          return (
            <>
              <button className="mainButtons" id={category._id} style={{
                backgroundImage: showImages ? `url(${category.image})` ? `url(${category.image})` : this.props.getGlobalData.BranchImage : "none",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundColor: this.props.selectedCategoryIds.includes(category._id) ? "green" : "hsl(3, 81%, 46%)",
                transform: this.props.selectedCategoryIds.includes(category._id) ? "scale(1.1)" : 'scale(1)',
                color: textColor
              }} onClick={() => {
                this.props.onClick(category);
                this.page = 0

              }}>
                {category.title}
              </button>
              {this.props.selectedCategoryIds.includes(category._id) ? this.subCategory(category) : null}
            </>
          )

        })}

      </>
    )
  }
}

export default Category;