import { Component } from "react";
import "./index.css";
import { PagePropCommonDocument, } from "modules/views/pages/pageProps";
import { GlobalStates } from "config/global";
import Services from "../../../../../../services/index";
import clone from "clone";
import V from "../../../../../../library/variable";
import Swal from "sweetalert2";
import Currencies from "constants/currencies";
import { Box, Button, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { orderBy } from "lodash";
const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageState = {
    orders: any[];
    oldOrders: any[]
};

type PageProps = {
    data: string[]
} & PagePropCommonDocument;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: "90%",
            height: "80%",
            margin: "auto"
        },
        tableHeadCell: {
            fontWeight: "bold",
            color: theme.palette.common.white,
            backgroundColor: "#3f51b5"
        },
        orderHeader: {
            fontWeight: "bold",
            color: theme.palette.info.dark,
            marginTop: theme.spacing(1)
        },
        nameCell: {
            width: "30vw",
            flexWrap: "wrap",
            fontSize: "1vw",
            fontWeight: "bold"
        },
        changePriceCell: {
            width: "20vw"
        },
        priceCell: {
            width: "10vw",
            fontSize: "1vw"
        }
    })
)

class Index extends Component<PageProps, PageState> {
    constructor(props: PageProps) {
        super(props);
        this.state = {
            orders: [],
            oldOrders: []
        };
    }

    componentDidMount() {
        this.sets();
    }

    sets() {
        let table = this.props.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
        var orderArray: any[] = [];
        table.orders.forEach((order: any) => {
            order.products.map((p: any) => {
                if (this.props.data.includes(p._id)) {
                    let findIndex = orderArray.findIndex((o: any) => o.orderNum == order.orderNum);
                    if (findIndex > -1) {
                        orderArray[findIndex].products.push(p);
                    } else {
                        let o = { orderNum: order.orderNum, products: [p] }
                        orderArray.push(o);
                    }
                }
            });

        })
        this.setState({
            orders: clone(orderArray),
            oldOrders: clone(orderArray)
        })
    }

    onSubmit() {
        const data: any = {
            tableId: GlobalStates.SelectedTableId,
            orders: [],
        }
        data.orders = this.state.orders;
        Services.Put.updateOrders(data).then(resData => {
            if (resData.status) {
                Swal.fire({
                    title: this.props.router.t("change-price-success"),
                    icon: "success"
                })
                let tables = this.props.getGlobalData.AllTables.map(table => {
                    if (table._id == data.tableId) {
                        table = resData.data;
                    }
                    return table;
                })
                this.props.setGlobalData({
                    AllTables: tables
                })
            }
        });
        this.props.closeModal();
    };

    handlePriceChange = (order: any, newPrice: string) => {
        this.setState((state: PageState) => {

            this.state.orders.forEach((o: any) => {
                o.products.forEach((p: any) => {
                    if (p._id == order._id) {
                        p.price = (V.isEmpty(newPrice)) ? order.price : Number(newPrice)
                    }
                })
            });
            return state;
        })
    };

    PriceTable = () => {

        const getPrices = (product: any) => {
            let p = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.product);
            return p.prices;
        }

        const handlePriceIdChange = (product: any, priceId: string) => {
            let p = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.product);
            let price = p.prices?.find((price: any) => price._id == priceId);
            this.setState((state: PageState) => {
                state.orders.map((o: any) => {
                    o.products.map((_: any) => {
                        if (_._id == product._id) {
                            _.priceId = price._id
                            let defaultCurrency = this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL";
                            if (defaultCurrency != price.currency) {
                                if (!this.props.getGlobalData?.CurrencySettings?.defaultCurrency) {
                                    toast.warning(this.props.router.t('currency-features-info'));
                                    return;
                                }
                                let exchange = 1;
                                switch (price.currency) {
                                    case "TL":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.TL;
                                        break;
                                    case "USD":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.USD;
                                        break;
                                    case "EUR":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.EUR;
                                        break;
                                    case "GBP":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.GBP;
                                        break;
                                    default:
                                        break;

                                }
                                _.price = price.price * exchange;
                                let element = document.getElementById(_._id?.toString()) as HTMLInputElement;
                                element.value = _.price?.toFixed(2)
                            } else {
                                _.price = price.price;
                                let element = document.getElementById(_._id?.toString()) as HTMLInputElement;
                                element.value = _.price?.toFixed(2)
                            }
                        }
                    })
                })
                return state;
            })

        }

        const getPriceId = (product: any) => {
            let price = ""
            this.state.orders?.map((o: any) => {
                let find = o.products?.find((p: any) => p._id == product._id);
                if (find) {
                    price = find.priceId
                }
            })
            return price;
        }

        const classes = useStyles();
        return (
            <TableContainer className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeadCell}>{this.props.router.t('product')}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{this.props.router.t('price-name')}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{this.props.router.t('price')}</TableCell>
                            <TableCell className={classes.tableHeadCell}>{this.props.router.t('change-price')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.oldOrders?.map((order: any) => (
                            <>
                                <Typography className={classes.orderHeader} textAlign="center">{this.props.router.t('order-no')}: {order.orderNum}</Typography>
                                {order.products?.map((product: any) => (
                                    <TableRow>
                                        <TableCell className={classes.nameCell}>{product.productName}</TableCell>
                                        <TableCell>
                                            <Select sx={{ width: "8vw" }} value={getPriceId(product)} onChange={(e: any) => handlePriceIdChange(product, e.target.value)}>
                                                {getPrices(product)?.map((price: any) => (
                                                    <MenuItem value={price._id}>
                                                        {price.priceName}
                                                    </MenuItem>
                                                )
                                                )}
                                            </Select>
                                        </TableCell>
                                        <TableCell className={classes.priceCell}>{product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}</TableCell>
                                        <TableCell className={classes.changePriceCell}>
                                            <input
                                                id={product._id?.toString()}
                                                type="number"
                                                onChange={(event) =>
                                                    this.handlePriceChange(product, event.target.value)
                                                }
                                                onClick={() => {
                                                    ipcRenderer.send('showKeyboard');
                                                }}
                                                style={{ width: "40%" }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    Order = (order: any) => {

        const getPrices = (product: any) => {
            let p = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.product);
            return p.prices;
        }

        const handlePriceIdChange = (product: any, priceId: string) => {
            let p = this.props.getGlobalData?.Products?.find((_: any) => _._id == product.product);
            let price = p.prices?.find((price: any) => price._id == priceId);
            this.setState((state: PageState) => {
                state.orders.map((o: any) => {
                    o.products.map((_: any) => {
                        if (_._id == product._id) {
                            _.priceId = price._id
                            let defaultCurrency = this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL";
                            if (defaultCurrency != price.currency) {
                                if (!this.props.getGlobalData?.CurrencySettings?.defaultCurrency) {
                                    toast.warning(this.props.router.t('currency-features-info'));
                                    return;
                                }
                                let exchange = 1;
                                switch (price.currency) {
                                    case "TL":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.TL;
                                        break;
                                    case "USD":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.USD;
                                        break;
                                    case "EUR":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.EUR;
                                        break;
                                    case "GBP":
                                        exchange = this.props.getGlobalData?.CurrencySettings?.currencies.GBP;
                                        break;
                                    default:
                                        break;

                                }
                                _.price = price.price * exchange;
                                let element = document.getElementById(_.product?.toString()) as HTMLInputElement;
                                element.value = _.price?.toFixed(2)
                            } else {
                                _.price = price.price;
                                let element = document.getElementById(_.product?.toString()) as HTMLInputElement;
                                element.value = _.price?.toFixed(2)
                            }
                        }
                    })
                })
                return state;
            })

        }

        const getPriceId = (product: any) => {
            let price = ""
            this.state.orders?.map((o: any) => {
                let find = o.products?.find((p: any) => p.product == product.product);
                if (find) {
                    price = find.priceId
                }
            })
            return price;
        }

        return (
            <>
                {this.props.router.t('order-no')}: {order.orderNum}
                {order.products.map((product: any) => {
                    return (
                        <tr>
                            <td style={{ width: "34vw", textAlign: "center" }}>
                                {product.productName}
                            </td>
                            <td>
                                <Select sx={{ width: "8vw" }} value={getPriceId(product)} onChange={(e: any) => handlePriceIdChange(product, e.target.value)}>
                                    {getPrices(product)?.map((price: any) => (
                                        <MenuItem value={price._id}>
                                            {price.priceName}
                                        </MenuItem>
                                    )
                                    )}
                                </Select>
                            </td>
                            {/* <td style={{ width: "10vw", textAlign: "center" }}>{getPriceName(product)}</td> */}
                            <td style={{ width: "5vw", textAlign: "center" }}>
                                {product.price?.toFixed(2)} {Currencies[localStorage.getItem("defaultCurrency")!] || <>₺</>}
                            </td>
                            <td style={{ width: "20vw", textAlign: "center" }}>
                                <input
                                    id={product.product?.toString()}
                                    type="number"
                                    onChange={(event) =>
                                        this.handlePriceChange(product, event.target.value)
                                    }
                                    onClick={() => {
                                        ipcRenderer.send('showKeyboard');
                                    }}
                                    style={{ width: "40%" }}
                                />
                            </td>
                        </tr>
                    )
                })}

            </>
        )

    }

    render() {
        return (
            <>
                <this.PriceTable />
                <Box>
                    <Stack direction="row" spacing={3} alignItems="center" margin="auto" alignContent="center" width="50%">
                        <Button variant="contained" color="success" onClick={() => this.onSubmit()} sx={{width: "20vw", height: "4vw"}}>{this.props.router.t("submit")}</Button>
                        <Button variant="contained" color="error" onClick={() => this.props.closeModal()} sx={{width: "20vw", height: "4vw"}}>{this.props.router.t("cancel-ticket")}</Button>
                    </Stack>
                </Box>
            </>
        )
    }
}

export default Index;
