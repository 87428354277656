import Api from "./api";
import { ServicePages } from "../public/ajax";
import { CasePutParamDocument } from "../modules/services/case";
import { CheckPutParamDocument } from "../modules/services/checks";
import {
  addProductToHomeDelivery,
  cancelCatering,
  CancelHomeDelivery,
  CancelHomeDeliveryProduct,
  CancelProduct,
  markAsCatering,
  MoveOrderPutParamDocument,
  OnWayHomeDelivery,
  OrdersPutParamDocument,
  payHomeDelivery,
  ProductOrderPutParamDocument,
  UpdateOrderDocument,
} from "../modules/services/orders";
import {
  PrintHomeDeliveryPutParamDocument,
  PrintOrderPutParamDocument,
} from "../modules/services/print";
import {
  TablePutParamDocument,
  UpdateCustomerCount,
} from "../modules/services/tables";
import {
  TickPayParamDocument,
  TickUpdateParamDocument,
} from "modules/services/tick";

const Put = {
  case(params: CasePutParamDocument) {
    let urlExt = params.isClose ? [`close/${params.caseId}`] : [];
    return Api.put({
      url: [ServicePages.case].concat(urlExt),
      data: params,
    });
  },
  checks(params: CheckPutParamDocument) {
    return Api.put({
      url: [ServicePages.checks, "old", params.checkId],
      data: params,
    });
  },
  orders(params: OrdersPutParamDocument) {
    return Api.put({
      url: [ServicePages.orders, params.tableId, params.orderNum.toString()],
      data: params,
    });
  },
  updateOrders(params: UpdateOrderDocument) {
    return Api.put({
      url: [ServicePages.orders, params.tableId],
      data: params,
    });
  },
  productOrders(params: ProductOrderPutParamDocument) {
    return Api.put({
      url: [
        ServicePages.orders,
        params.tableId,
        params.orderNum.toString(),
        params.orderId,
      ],
      data: params,
    });
  },
  orderPrint(params: PrintOrderPutParamDocument) {
    return Api.put({
      url: [ServicePages.print, params.tableId],
      data: params,
    });
  },
  homeDeliveryPrint(params: PrintHomeDeliveryPutParamDocument) {
    return Api.put({
      url: [ServicePages.takeaway, params.checkId, ServicePages.print],
      data: params,
    });
  },
  moveOrder(params: MoveOrderPutParamDocument) {
    return Api.put({
      url: [ServicePages.move, params.currentTable, params.targetTable],
      data: params,
    });
  },
  table(params: TablePutParamDocument) {
    return Api.put({
      url: ["table", params.tableId],
      data: params,
    });
  },
  tick(params: TickUpdateParamDocument) {
    return Api.put({
      url: [ServicePages.tick, params.tickId],
      data: params,
    });
  },
  payTick(params: TickPayParamDocument) {
    return Api.put({
      url: [ServicePages.checks, params.branchTickId, ServicePages.tick],
      data: params,
    });
  },
  moveProduct(params: MoveOrderPutParamDocument) {
    return Api.put({
      url: [ServicePages.productMove, params.currentTable, params.targetTable],
      data: params,
    });
  },
  cancelHomeDelivery(params: CancelHomeDelivery) {
    return Api.put({
      url: [ServicePages.takeaway, params.orderNum.toString(), "cancel"],
      data: params,
    });
  },
  cancelHomeDeliveryProduct(params: CancelHomeDeliveryProduct) {
    return Api.put({
      url: [ServicePages.takeaway, "cancel"],
      data: params,
    });
  },
  approveHomeDelivery(params: CancelHomeDelivery) {
    return Api.put({
      url: [ServicePages.takeaway, params.orderNum.toString(), "approve"],
      data: params,
    });
  },
  onWayHomeDelivery(params: OnWayHomeDelivery) {
    return Api.put({
      url: [ServicePages.takeaway, params.orderNum.toString(), "onway"],
      data: params,
    });
  },
  addProductToHomeDelivery(params: addProductToHomeDelivery) {
    return Api.put({
      url: [ServicePages.takeaway],
      data: params,
    });
  },
  payHomeDelivery(params: payHomeDelivery) {
    return Api.put({
      url: [ServicePages.takeaway, params.orderNum.toString(), "pay"],
      data: params,
    });
  },
  markAsCatering(params: markAsCatering) {
    return Api.put({
      url: [
        ServicePages.catering,
        params.tableId,
        params.orderNum.toString(),
        params.orderId,
      ],
      data: params,
    });
  },
  cancelCatering(params: cancelCatering) {
    return Api.put({
      url: [ServicePages.catering, params.tableId, "cancel"],
      data: params,
    });
  },
  approveQrOrder(params: cancelCatering) {
    return Api.put({
      url: ["qr", params.tableId, "approve"],
      data: params,
    });
  },
  cancelQrOrder(params: cancelCatering) {
    return Api.put({
      url: ["qr", params.tableId, "cancel"],
      data: params,
    });
  },
  cancelProduct(params: CancelProduct) {
    return Api.put({
      url: ["order", "products", params.tableId, "cancel"],
      data: params,
    });
  },
  updateCustomerCount(params: UpdateCustomerCount) {
    return Api.put({
      url: ["table", "customer-count", params.tableId, params.type],
      data: params,
    });
  },
  completeOrder(params: { tableId: string }) {
    return Api.put({
      url: ["table", "complete-order", params.tableId],
      data: params,
    });
  },
  updateTick(params: {
    checkId: string;
    tickId: string;
    amount: number;
    paymentType: number;
  }) {
    return Api.put({
      url: ["tick", params.tickId],
      data: params,
    });
  },
  updateTickCustomer(params: {
    tickId: string;
    discount: number;
    name: string;
    phoneNumber: string;
  }) {
    return Api.put({
      url: ["ticks", params.tickId, "update"],
      data: params,
    });
  },
  applyDiscountTick(params: {
    customerId: string;
    tickId: string;
    discount: number;
  }) {
    return Api.put({
      url: ["ticks", "discount", params.customerId, params.tickId],
      data: params,
    });
  },
  updateBranchCustomer(params: {
    customerId: string;
    title?: string;
    full_name?: string;
    gsm_no?: string;
    description?: string;
    address?: { title?: string; address?: string };
  }) {
    return Api.put({
      url: ["branchcustomers", params.customerId],
      data: params,
    });
  },
};

export default Put;
