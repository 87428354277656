import Api from "./api";

const GetirService = {
  verifyOrder(orderId: string) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "verify"],
    });
  },
  verifyScheduledOrder(orderId: string) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "verify-scheduled"],
    });
  },
  prepareOrder(orderId: string, data: { deliveryType: number }) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "prepare"],
      data,
    });
  },
  deliverOrder(orderId: string) {
    // restoran kuryesiyle
    return Api.getirPost({
      url: ["getir", "orders", orderId, "deliver"],
    });
  },
  manualDeliverOrder(orderId: string) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "manual-deliver"],
      data: {},
    });
  },
  handoverOrder(orderId: string) {
    // getir kuryesiyle
    return Api.getirPost({
      url: ["getir", "orders", orderId, "handover"],
    });
  },
  getCancelOptions(orderId: string) {
    return Api.getirGet({
      url: ["getir", "orders", orderId, "cancel-options"],
    });
  },
  cancelOrder(
    orderId: string,
    data: { cancelReason: string; cancelNote: string }
  ) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "cancel"],
      data: data,
    });
  },
  manualCancelOrder(orderId: string) {
    return Api.getirPost({
      url: ["getir", "orders", orderId, "manual-cancel"],
      data: {},
    });
  },
  openRestaurant() {
    return Api.getirPut({
      url: ["getir", "restaurants", "open"],
      data: {},
    });
  },
  closeRestaurant() {
    return Api.getirPut({
      url: ["getir", "restaurants", "close"],
      data: {},
    });
  },
};

export default GetirService;
