import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Box, Stack, TextField, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GlobalLocalStorages, GlobalStates, getGlobalAuthData } from 'config/global';
import { Informations } from '../components/informations';
import { LanguageSettings } from '../components/language-settings';
import { ToastContainer, toast } from 'react-toastify';
import { getMacAddress } from 'utilities/helpers';
import Services from 'services/index';
const ipcRenderer: any = window.require('electron').ipcRenderer;


const CustomButton = styled(Button)(({theme}) => ({
    backgroundColor: "#818080",
    width: "4.2vw",
    height: "4.2vw",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "0.88vw"
})
)

export const PairedAnotherDevice = () => {
    const { t } = useTranslation();
    const [pairing, setPairing] = useState(false);
    const [pairCode, setPairCode] = useState("");
    
    const handleSubmit = async () => {
        let pCode = pairCode.replace(/\s/g, "");
        if(pCode.length != 6){
            toast.error(t('invalid-pair-code'));
            return;
        }
        let macAddress = getMacAddress();
        let deviceName = ipcRenderer.sendSync("getDeviceName");
        let response = await Services.Post.pairWithCode({pairCode: Number(pCode), macAddress, deviceName});
        if(response.status){
            window.location.reload();
            return;
        }
        if(response?.customData?.status == 400){
            toast.error(t('invalid-pair-code'));
            return;
        }
        toast.error(t('error'));
    }

    const Numpad = () => {

        const handleClick = (value: string) => {
            if(value == "clear"){
                setPairCode("");
                return;
            }
            if(value == "delete"){
                setPairCode(pairCode.slice(0, -1));
                return;
            }
            setPairCode(pairCode + value);
        }

        return (
            <Box sx={{display: "flex", justifyContent: "center", width: "50%", margin: "auto", marginTop: "2vw"}}>
                <Stack direction="column" spacing={2} alignItems="center">
                    <Stack spacing={3} direction="row" alignItems="center">
                        <CustomButton onClick={() => handleClick("1")}>1</CustomButton>
                        <CustomButton onClick={() => handleClick("2")}>2</CustomButton>
                        <CustomButton onClick={() => handleClick("3")}>3</CustomButton>
                    </Stack>
                    <Stack spacing={3} direction="row" alignItems="center">
                        <CustomButton onClick={() => handleClick("4")}>4</CustomButton>
                        <CustomButton onClick={() => handleClick("5")}>5</CustomButton>
                        <CustomButton onClick={() => handleClick("6")}>6</CustomButton>
                    </Stack>
                    <Stack spacing={3} direction="row" alignItems="center">
                        <CustomButton onClick={() => handleClick("7")}>7</CustomButton>
                        <CustomButton onClick={() => handleClick("8")}>8</CustomButton>
                        <CustomButton onClick={() => handleClick("9")}>9</CustomButton>
                    </Stack>
                    <Stack spacing={3} direction="row" alignItems="center">
                        <CustomButton onClick={() => handleClick("clear")}>{t('clear')}</CustomButton>
                        <CustomButton onClick={() => handleClick("0")}>0</CustomButton>
                        <CustomButton onClick={() => handleClick("delete")}>{t('delete')}</CustomButton>
                    </Stack>
                </Stack>
            </Box>
        )
    }

    if (pairing) {
        return (
            <Box>
                <ToastContainer />
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: 3 }}>
                    <Typography textAlign="center" sx={{
                        color: "#fff"
                    }} variant='h4'>{t('pairing-code-info')}</Typography>
                    <TextField sx={{
                        input: {
                            color: "#fff"
                        },
                        width: "25%",
                        margin: "auto",
                        fontSize: "1.9vw",
                        height: "4vw",
                        marginTop: "1.5vw",
                        marginBottom: "1.5vw",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: "#fff",
                                backgroundColor: "#818080",
                                zIndex: -1
                            }
                        },
                    }} label={t('pairing-code')} value={pairCode} onChange={(e: any) => setPairCode(e.target.value)} />
                    <Numpad />
                    <Button variant='contained' onClick={handleSubmit} sx={{ backgroundColor: "#818080", width: "25%", margin: "auto", marginTop: "2vw" }}>{t('confirm')}</Button>
                </Box>
                <Stack direction="row" spacing={6} sx={{ position: "absolute", bottom: 20, right: 20 }}>
                    <Button variant='contained' color="warning" sx={{
                        fontWeight: "bold",
                        fontSize: "1vw",
                        width: "12vw",
                        height: "4vw",
                    }} onClick={() => setPairing(false)}>{t('back')}</Button>
                    <Button variant="contained" color="error" sx={{
                        fontWeight: "bold",
                        fontSize: "1vw",
                        width: "12vw",
                        height: "4vw",
                    }} onClick={() => {
                        GlobalLocalStorages.Token.delete();
                        GlobalLocalStorages.User.delete();
                        GlobalStates.firstNavigate = true;
                        window.location.href = '/sign-in'
                    }}>{t('sign-out')}</Button>
                </Stack>
            </Box>
        )
    }
    return (
        <div>
            <LanguageSettings />
            <Informations />
            <Typography style={{ fontSize: "1.8vw", color: "#fff", padding: 2, margin: "auto" }} textAlign="center">
                {t('paired-another-device')}
            </Typography>
            <div>
                <Stack direction="row" spacing={6} sx={{ position: "absolute", bottom: 20, right: 20 }}>
                    <Button variant="contained" sx={{
                        fontWeight: "bold",
                        fontSize: "1vw",
                        width: "12vw",
                        height: "4vw",
                    }} onClick={() => {
                        setPairing(true);
                    }}>{t('use-pair-code')}</Button>
                    <Button variant="contained" color="error" sx={{
                        fontWeight: "bold",
                        fontSize: "1vw",
                        width: "12vw",
                        height: "4vw",
                    }} onClick={() => {
                        GlobalLocalStorages.Token.delete();
                        GlobalLocalStorages.User.delete();
                        GlobalStates.firstNavigate = true;
                        window.location.href = '/sign-in'
                    }}>{t('sign-out')}</Button>
                </Stack>
            </div>
        </div>
    )
}
