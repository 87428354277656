import GlobalStates from "config/global/states";
import Services from "../../../../services/index";
import Swal from "sweetalert2";
import {PagePropCommonDocument} from "../../../../modules/views/pages/pageProps";
import PageOrder, {PageOrderState} from "../../../../app/views/pages/orders";
import Table from "../../../../app/views/pages/orders/check/components/table";
import clone from "clone";
import Thread from "../../../../library/thread";
import { FaRegSadTear } from "react-icons/fa";
import { info } from "console";
import reasons from "public/reasons";

const cancelHomeDelivery = async (
    commonProp: PagePropCommonDocument,
    pageOrder: PageOrder,
    pageOrderTable: Table,
) => {
    let table = commonProp.getGlobalData.AllTables.findSingle("_id", GlobalStates.SelectedTableId);
    Swal.fire({
        title: commonProp.router.t('cancel-all-question'),
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: commonProp.router.t("cancel"),
        confirmButtonText: commonProp.router.t("yes")
    }).then(async result => {
        if(result.isConfirmed) {
            let params = {
                orderNum: pageOrderTable.props.or.orderNum
            }
            Swal.fire({
                title: commonProp.router.t("pleaseWait").toCapitalizeCase(),
                html: commonProp.router.t("uploading") + "...",
                showConfirmButton: false,
                showCancelButton: false,
                allowOutsideClick: false,
                willOpen(popup: HTMLElement) {
                    Swal.showLoading()
                }
            });
            const res = await Services.Put.cancelHomeDelivery(params);

            if(res.status) {
                let cancelled = pageOrderTable.props.or.cancelled.concat(pageOrderTable.props.or.unapproved).concat(pageOrderTable.props.or.products).concat(pageOrderTable.props.or.onWay);
                pageOrderTable.props.or.onWay = [];
                pageOrderTable.props.or.products = [];
                pageOrderTable.props.or.unapproved = [];
                pageOrderTable.props.or.cancelled = cancelled;
                pageOrderTable.setState({
                    or: {
                        products: [],
                        unapproved: [],
                        cancelled: cancelled,
                        onWay: []
                    }
                });
                Swal.fire({
                    icon: "success",
                    title: commonProp.router.t('cancel-success-all')
                })
                pageOrderTable.props.router.navigate('/');
            }else {
                Swal.fire({
                    icon: "error",
                    title: commonProp.router.t('error-try-again')
                })
            }
        }
    })
    

};

export default cancelHomeDelivery;
