export const payment_types = [
    "pos_bank",
    "pos_cash",
    "app_payment",
    "service",
    "discount",
    "tick",
    "sodexo",
    "smart",
    "winwin",
    "multinet",
    "setcard",
    "metropol",
    "edended", 
    "tips",
    "jetKurye",
    "not-payable",
    "migros-hemen",
    "getir-online",
    "trendyol",
    "yemek-sepeti"
]
