import { MODAL } from "constants/modalTypes";
import { GlobalStateDocument } from "modules/config/global/states";

let GlobalStates: GlobalStateDocument = {
  isFastPayment: false,
  ProductsToBeMoving: [],
  LastClickedButton: {},
  SelectedPaymentTypeId: 0,
  CurrentGetirOrder: "",
  Ticks: [],
  CurrentModal: {},
  AllTables: [
    {
      orders: {},
    },
  ],
  MyCase: [
    {
      balance: [],
      checks: [],
      start_balance: [],
    },
  ],
  AllCheck: [
    {
      order: [],
    },
  ],
  ModalOpen: false,
  CurrentTable: {},
  CustomerId: "",
  Type: "",
  SelectedCourier: 0,
  LastClickedButtonPos: {
    y: "",
    x: "",
  },
  RightButtons: [],
  BottomButtons: [],
  TakeawayButtons: [],
  CaseSaleButtons: [],
  GetirButtons: [],
  Cash: false,
  SelectedTableId: "",
  SelectedCourierId: "",
  SelectedOrderNum: -1,
  SelectedOrderNums: [],
  Customer: {},
  CurrentTakeaway: -1,
  homeDeliveryButtons: [],
  firstNavigate: false,
};

export default GlobalStates;
