import { PagePropCommonDocument } from 'modules/views/pages/pageProps';
import React, { Component, useState, useEffect } from 'react';
import { Box, Stack, Button, Grid, Typography, TextField, AppBar, Toolbar, Avatar, Dialog, DialogTitle, DialogActions, DialogContent, FormHelperText } from '@mui/material';
import { KeyboardReturn } from '@mui/icons-material';
import Currencies from 'constants/currencies';
import Services from 'services/index';
import { ToastContainer, toast } from 'react-toastify';
import { isEqual } from 'lodash';
import clone from 'clone';

const ipcRenderer: any = window.require('electron').ipcRenderer;

type PageProps = {} & PagePropCommonDocument;

type PageState = {
    products: any[];
    searchText: string;
    pairProductDialog: boolean;
    selectedProduct: any;
}

export default class BarcodePairing extends Component<PageProps, PageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            products: [],
            searchText: "",
            pairProductDialog: false,
            selectedProduct: null
        }
    }

    componentDidMount(): void {
        this.setState({
            products: this.props.getGlobalData.Products?.filter((product: any) => !product.barcode) //set unpaired products
        });
    }

    componentDidUpdate(prevProps: Readonly<PagePropCommonDocument>, prevState: Readonly<PageState>, snapshot?: any): void {
        if(!isEqual(this.state.products, this.props.getGlobalData?.Products?.filter((p: any) => !p.barcode))){
            this.setState({
                products: this.props.getGlobalData?.Products?.filter((p: any) => !p.barcode)
            });
        }
    }

    getCategory = (product: any) => {
        return this.props.getGlobalData?.Categories?.find((c: any) => c._id == product.category)?.title
    }

    checkUnique = (barcode: string) => {
        let products = this.props.getGlobalData.Products;
        if (products.find((p: any) => p.barcode == barcode)) {
            let newCode = Math.floor(Math.random() * 90000) + 10000;
            barcode = this.checkUnique(newCode.toString());
        }
        return barcode;
    }

    createBarcode = (): any => {
        let code = Math.floor(Math.random() * 900000) + 100000;
        let uniqueCode = this.checkUnique(code.toString());
        return uniqueCode;
    }

    handleProductClick = (product: any) => {
        this.setState({
            selectedProduct: product,
            pairProductDialog: true
        });
    }

    PairProductDialog = () => {
        const [barcode, setBarcode] = useState("");
        const [loading, setLoading] = useState(false);
        const [plu, setPlu] = useState("");

        const handleClose = () => {
            this.setState({
                selectedProduct: null,
                pairProductDialog: false
            });
            setBarcode("");
        }

        const scannerSubmit = async (code: string) => {
            let check = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == code);
            if(check){
                toast.warning(this.props.router.t('barcode-already-in-use'));
                return;
            }
            setLoading(true);
            let barcode = clone(code);
            let response = await Services.Post.pairBarcode({ productId: this.state.selectedProduct?._id, barcode: barcode, plu: plu || undefined });
            if (response.status) {
                toast.success(this.props.router.t('barcode-edit-success'));
                setBarcode("");
                setPlu("");
                setLoading(false);
                handleClose();
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }

        useEffect(() => {
            var interval: any;
            var code = "";
            window.addEventListener("keydown", (e: any) => {
                if(!this.state.pairProductDialog) return;
                if (interval) clearInterval(interval);
                if (e.code == "Enter") {
                    if (code) {
                        scannerSubmit(code);
                    }
                    code = ""
                    return;
                }
                if (e.code != "Shift") {
                    code = code + e.key;
                }
                interval = setInterval(() => code = "", 20);
            });
            return () => clearInterval(interval);
        }, [])

        const handleSubmit = async () => {
            if(!barcode) {
                toast.warning(this.props.router.t('invalid-barcode'));
                return;
            }
            let check = this.props.getGlobalData?.Products?.find((p: any) => p.barcode == barcode);
            if(check){
                toast.warning(this.props.router.t('barcode-already-in-use'));
                return;
            }
            setLoading(true);
            let response = await Services.Post.pairBarcode({ productId: this.state.selectedProduct?._id, barcode: barcode, plu: plu || undefined });
            if (response.status) {
                toast.success(this.props.router.t('barcode-pair-success'));
                let product = this.state.selectedProduct;
                product.barcode = barcode;
                this.setState({
                    selectedProduct: product
                })
                setLoading(false);
                setBarcode("");
                setPlu("");
                handleClose();
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }

        const createAndPair = async () => {
            setLoading(true);
            let code;
            if(this.state.selectedProduct?.sale_type != 2){
                code = `${this.createBarcode()} ${this.createBarcode()}`
            }else {
                code = this.createBarcode();
            }
            let response = await Services.Post.pairBarcode({productId: this.state.selectedProduct?._id, barcode: code, plu: plu || undefined});
            if(response.status){
                toast.success(this.props.router.t('barcode-pair-success'));
                setLoading(false);
                setBarcode("");
                setPlu("");
                handleClose();
                return;
            }
            toast.error(this.props.router.t('error'));
            setLoading(false);
        }

        return (
            <Dialog open={this.state.pairProductDialog} onClose={handleClose} fullWidth>
                <DialogTitle>
                    <Box sx={{
                        backgroundColor: "#C12E2E",
                        padding: "0.5vw",
                        borderRadius: 8,
                        width: "60%",
                        margin: "auto",
                        color: "#fff"
                    }}>
                        <Typography variant='h4' textAlign="center">
                            {this.props.router.t('pair-product')}
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" justifyContent="center" alignItems="center" spacing={4}>
                        <Stack direction="column" justifyContent="center" alignItems="center">
                            <TextField label={this.props.router.t('barcode')} disabled={loading} value={barcode} onClick={() => ipcRenderer.send('showKeyboard')} onChange={(e: any) => setBarcode(e.target.value)} />
                            <FormHelperText>{this.props.router.t('barcode-helper')}</FormHelperText>
                            <TextField label={this.props.router.t('plu')} disabled={loading} value={plu} onClick={() => ipcRenderer.send('showKeyboard')} onChange={(e: any) => setPlu(e.target.value)} />
                        </Stack>
                        <Stack direction="row" spacing={5} alignItems="center" justifyContent="center">
                            <Button disabled={loading} variant="contained" onClick={handleSubmit} sx={{ backgroundColor: "#1EB632", "&:hover": {backgroundColor: "#68C12E"} }}>{this.props.router.t('use-barcode')}</Button>
                            <Button disabled={loading} onClick={createAndPair} variant="contained" sx={{ backgroundColor: "#B6AD1E", "&:hover": {backgroundColor: "#A4C12E"} }}>{this.props.router.t('create-barcode')}</Button>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} variant="outlined" onClick={handleClose} color="error">{this.props.router.t('cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    pairProduct = (product: any) => {
        if (product.barcode) {
            return;
        }
    }

    render() {
        return (
            <Box>
                <ToastContainer />
                <this.PairProductDialog />
                <AppBar sx={{ backgroundColor: "#2F353E", padding: 2 }}>
                    <Toolbar>
                        <Grid container justifyContent="space-between" sx={{ height: "50%" }}>
                            <Button variant='contained' sx={{ height: "auto" }} onClick={() => this.props.router.navigate("/barcode", { replace: true })} startIcon={<KeyboardReturn />} color="error">{this.props.router.t('back')}</Button>
                            <Box sx={{ width: "25vw", margin: "auto", marginLeft: "2vw", marginRight: "2vw", backgroundColor: "#3F4E6D", padding: "0.7vw", borderRadius: "8px" }}>
                                <Typography textAlign="center" variant="h4" sx={{ fontWeight: "bold", fontFamily: "serif", fontSize: "1.8vw" }}>{this.props.router.t('pair-product')}</Typography>
                            </Box>
                            <Box sx={{ height: "auto" }}>
                                <TextField placeholder={this.props.router.t('search')} onClick={() => ipcRenderer.send('showKeyboard')} onChange={(e: any) => this.setState({ searchText: e.target.value })} sx={{
                                    width: "20vw", border: "none",
                                    '.MuiInputBase-input': { backgroundColor: "#fff", borderRadius: "20px", border: "none" },
                                    '.MuiOutlinedInput-root': { borderRadius: "20px" }
                                }} />
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Box className="content" sx={{ height: "85vh", overflowY: "scroll", padding: 5 }}>
                    {this.state.products?.length == 0 ? (
                        <Typography variant="h3" textAlign="center" sx={{ color: "#fff", fontFamily: "serif" }}>{this.props.router.t('has-no-unpaired-product')}</Typography>
                    ) : (
                        <Box sx={{ display: "flex", margin: "auto", marginTop: 0, width: "95%" }}>
                            <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}
                                alignItems="center" sx={{ margin: "auto", width: "50%" }}
                            >
                                {this.state.products?.filter((product: any) => product.title?.toLowerCase()?.includes(this.state.searchText?.toLowerCase()))?.map((product: any, i: number) => (
                                    <Grid item xs={2} sm={3} md={3} key={i}>
                                        <Box onClick={(e: any) => this.handleProductClick(product)} sx={{ backgroundColor: "#C14C4C", width: "20vw", padding: "1vw", borderRadius: "5px", "&:hover": { transform: "scale(1.1)", boxShadow: "3px 5px #fff" }, "&:active": { backgroundColor: "#B96666" } }}>
                                            <Stack alignItems="center" spacing={1} sx={{ color: "#fff" }}>
                                                <Avatar src={product.image} variant='rounded' sx={{ width: "10vw", height: "7vw" }} />
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('product-name-upper')}:</span> {product.title}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('category')}:</span> {this.getCategory(product)}</Typography>
                                                <Typography><span style={{ fontWeight: "bold" }}>{this.props.router.t('price')}:</span> {product.prices[0].price} {Currencies[product.prices[0]?.currency] || "₺"}</Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                    )}
                </Box>
            </Box>
        )
    }
}

