import { Component } from "react";

import Navbar from "./navbar";
import Category from "./category/category";
import { Snackbar, Alert, Box, Stack } from "@mui/material";
import Printer from "../../../../config/global/printers/index";
import { PagePropCommonDocument } from "../../../../modules/views/pages/pageProps";
import { GlobalStates } from "config/global";
import Functions from "../../../../config/global/functions/index";
import Product from "./products/product";
import { MODAL } from "../../../../constants/modalTypes";
import Table from "./check/components/table";
import clone from "clone";
import Payments from "./payments";
import { Navigate } from "react-router-dom";
import { AlertColor } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
const { ipcRenderer } = window.require("electron");

export type PageOrderState = {
  clickedProduct: any;
  clickedOrder: any;
  totalAmount: number;
  currentCategoryId: string;
  selectedSubCategoryId: string;
  selectedCategories: any[];
  selectedCategoryIds: string[];
  subCategories: string[];
  multiplier: number;
  newOrders: any[];
  newDiscounts: any[];
  selectedProduct: any;
  modal: {
    isOpen: boolean;
    type: any;
    data: any;
  };
  searchKeyProduct: string;
  searchKeyCategory: string;
  searchKeyCustomerPhone: string;
  searchKeyCustomerName: string;
  isSelectCourier: boolean;
  isSelectPayment: boolean;
  courier: any;
  snackbar: boolean;
  snackbar_title: string;
  snackbar_severity: string;
  mounted: boolean;
};

type PageProps = {} & PagePropCommonDocument;

class PageOrder extends Component<PageProps, PageOrderState> {
  _ismounted = false;
  interval: any;
  constructor(props: PageProps) {
    super(props);
    this.state = {
      clickedProduct: "",
      clickedOrder: "",
      totalAmount: 0,
      currentCategoryId: "all",
      selectedSubCategoryId: "",
      selectedCategories: [{ _id: "all", title: this.props.router.t("all") }],
      selectedCategoryIds: ["all"],
      multiplier: 1,
      newOrders: [],
      newDiscounts: [],
      selectedProduct: [],
      searchKeyCategory: "",
      searchKeyProduct: "",
      searchKeyCustomerPhone: "",
      searchKeyCustomerName: "",
      isSelectCourier: false,
      isSelectPayment: false,
      subCategories: [],
      modal: {
        isOpen: false,
        type: null,
        data: null,
      },
      courier: {
        title: "",
        phone_number: "",
      },
      snackbar: false,
      snackbar_title: "",
      snackbar_severity: "",
      mounted: false,
    };
  }

  componentDidMount() {
    var code = "";
    this._ismounted = true;
    window.addEventListener("keydown", (e: any) => {
      if (!this._ismounted) return;
      if (this.interval) clearInterval(this.interval);
      if (e.code == "Enter" || e.code == "NumpadEnter") {
        if (code) {
          this.scanProduct(code);
        }
        code = "";
        return;
      }
      if (e.code != "Shift") {
        code = code + String(e.key);
      }
      this.interval = setInterval(() => (code = ""), 20);
    });
  }

  componentWillUnmount() {
    this._ismounted = false;
    clearInterval(this.interval);
  }

  scanProduct(barcode: string) {
    if (!barcode) return;

    let product = this.props.getGlobalData?.Products?.find(
      (p: any) => p.barcode == barcode
    );
    if (!product) {
      let formattedBarcode = barcode.split(" ")[0];
      if (!formattedBarcode) {
        toast.warning(this.props.router.t("product-not-found"));
        return;
      }
      product = this.props.getGlobalData?.Products?.find(
        (p: any) => p.barcode == formattedBarcode
      );
      if (!product) {
        formattedBarcode = barcode.slice(1, 7);
        product = this.props.getGlobalData?.Products?.find(
          (p: any) => p.barcode == formattedBarcode
        );
        if (!product) {
          formattedBarcode = barcode.slice(0, 6);
          product = this.props.getGlobalData?.Products?.find(
            (p: any) => p.barcode == formattedBarcode
          );
          if (!product) {
            formattedBarcode = barcode.slice(0, 7);
            product = this.props.getGlobalData?.Products?.find(
              (p: any) => p.barcode == formattedBarcode
            );
            if (!product) {
              toast.warning(this.props.router.t("product-not-found"));
              return;
            }
            let forced = false;
            product.options?.forEach((option: any) => {
              if (option?.is_forced_choice) {
                forced = true;
                return;
              }
            });
            if (forced) {
              this.handleNoteClick(product);
              return;
            }
            if (product.sale_type == 2) {
              let weight = barcode.split(" ")[1];
              if (!weight) {
                weight = barcode.slice(7, -1);
                if (!weight) {
                  toast.warning(this.props.router.t("weight-not-found"));
                  return;
                }
              }
              this.setNewOrderProduct(product, Number(weight) / 1000);
              return;
            }
            return;
          }
          let forced = false;
          product.options?.forEach((option: any) => {
            if (option?.is_forced_choice) {
              forced = true;
              return;
            }
          });
          if (forced) {
            this.handleNoteClick(product);
            return;
          }
          if (product.sale_type == 2) {
            let weight = barcode.split(" ")[1];
            if (!weight) {
              weight = barcode.slice(7, -1);
              if (!weight) {
                toast.warning(this.props.router.t("weight-not-found"));
                return;
              }
            }
            this.setNewOrderProduct(product, Number(weight) / 1000);
            return;
          }
          return;
        }
      }
      let forced = false;
      product.options?.forEach((option: any) => {
        if (option?.is_forced_choice) {
          forced = true;
          return;
        }
      });
      if (forced) {
        this.handleNoteClick(product);
        return;
      }
      if (product.sale_type == 2) {
        let weight = barcode.split(" ")[1];
        if (!weight) {
          weight = barcode.slice(7, -1);
          if (!weight) {
            toast.warning(this.props.router.t("weight-not-found"));
            return;
          }
        }
        this.setNewOrderProduct(product, Number(weight) / 1000);
        return;
      }
    }
    this.setNewOrderProduct(product, this.state.multiplier);
  }

  productHandleClick(product: any, priceId?: string) {
    let forced = false;
    if (
      product.sale_type == 5 ||
      product.sale_type == 2 ||
      product.prices?.length > 1 ||
      product.sale_type == 3
    ) {
      this.handleNoteClick(product);
      return;
    }
    product.options?.forEach((option: any) => {
      this.handleNoteClick(product);
      forced = true;
      return;
    });
    if (forced) {
      return;
    }
    this.setNewOrderProduct(product, this.state.multiplier);
  }

  orderHandleClick(order: any) {
    GlobalStates.SelectedOrderNum = order.orderNum;
  }

  handleNoteClick(product: any) {
    product.options.forEach((item: any) => {
      item.linked = this.props.getGlobalData.ProductOptions?.findSingle(
        "_id",
        item.option_id
      );
    });
    this.setState(
      {
        selectedProduct: product,
      },
      () => this.props.openModal(MODAL.OPTION, this)
    );
  }

  setNewOrderProduct(
    product: any,
    addCount: number,
    isHaveOptions?: boolean,
    note?: string,
    priceId?: string
  ) {
    this.setState((state: PageOrderState) => {
      product = clone(product);
      let priceIndex =
        typeof priceId !== "undefined"
          ? product.prices.indexOfKey("_id", priceId)
          : 0;
      let defaultCurrency =
        this.props.getGlobalData?.CurrencySettings?.defaultCurrency || "TL";
      if (product.prices[priceIndex].currency != defaultCurrency) {
        if (!this.props.getGlobalData?.CurrencySettings?.defaultCurrency) {
          toast.warning(this.props.router.t("currency-features-info"));
          return;
        }
        let exchange = 1;
        switch (product.prices[priceIndex].currency) {
          case "TL":
            exchange =
              this.props.getGlobalData?.CurrencySettings?.currencies.TL;
            break;
          case "USD":
            exchange =
              this.props.getGlobalData?.CurrencySettings?.currencies.USD;
            break;
          case "EUR":
            exchange =
              this.props.getGlobalData?.CurrencySettings?.currencies.EUR;
            break;
          case "GBP":
            exchange =
              this.props.getGlobalData?.CurrencySettings?.currencies.GBP;
            break;
          default:
            break;
        }
        product.prices[priceIndex].price =
          product.prices[priceIndex].price * exchange;
      }
      priceId = product.prices[priceIndex]._id;
      product.prices[priceIndex].amount *=
        product.prices[priceIndex].amount * addCount;
      product.prices[priceIndex].price *= product.prices[priceIndex].amount;
      if (product.options) {
        product.options.forEach((option: any) => {
          option.linked.items.forEach((item: any) => {
            product.prices[priceIndex].price +=
              Number(item.price) * Number(product.prices[priceIndex].amount);
          });
        });
      }
      let isNew: boolean = true;
      state.newOrders.map((order: any) => {
        if (
          order.productId == product._id &&
          JSON.stringify(order.options) == JSON.stringify(product.options) &&
          priceId == order.priceId &&
          !order.isServe
        ) {
          order.quantity += product.prices[priceIndex].amount;
          order.price += product.prices[priceIndex].price;
          isNew = false;
        }
        return order;
      });

      if (isNew) {
        let newOrderId = Functions.createUUID();
        state.newOrders.push({
          productId: product._id,
          productName: product.title,
          category: product.category,
          _id: newOrderId,
          price: product.prices[priceIndex].price,
          priceId: priceId,
          priceName: product.prices[priceIndex].priceName,
          quantity: product.prices[priceIndex].amount,
          options: !isHaveOptions
            ? Array()
            : product?.options?.map((option: any) => ({
                option_id: option?.linked?._id,
                name: option?.linked?.name,
                items: option?.linked?.items?.map((option_item: any) => ({
                  item_id: option_item._id,
                  price:
                    Number(option_item.price) *
                    Number(product.prices[priceIndex].amount),
                  name: option_item.item_name,
                })),
              })),
          note: note ?? "",
        });
      }
      state.multiplier = 1;
      if (
        GlobalStates.CurrentTable?.isSafeSales ||
        GlobalStates.CustomerId ||
        !GlobalStates.CustomerId
      ) {
        state.totalAmount += product.prices[priceIndex].price;
      }
      return state;
    });
  }

  categoryOnClick(category: any) {
    if (!category.is_sub_category || category._id == "all") {
      this.setState({
        selectedCategories: [category],
        selectedCategoryIds: [category._id],
        currentCategoryId: category._id,
      });
      return;
    }
    this.state.selectedCategories.map((c: any, index: number) => {
      if (c.parent_category == category.parent_category) {
        let idIndex = this.state.selectedCategoryIds.findIndex(
          (id: any) => id == c._id
        );
        this.state.selectedCategoryIds.remove(idIndex);
        this.state.selectedCategories.remove(index);
      }
    });
    this.setState({
      selectedCategories: this.state.selectedCategories.concat([category]),
      selectedCategoryIds: this.state.selectedCategoryIds.concat([
        category._id,
      ]),
      currentCategoryId: category._id,
    });
  }

  print() {
    Printer.Check.printCheck(GlobalStates.AllCheck);
    //Printer.printKitchen(Fetchs.Check.data)
  }

  onClickMultiplier(number: number) {
    this.setState({
      multiplier: number,
    });
  }

  onSearchProduct(search: string) {
    this.setState({
      searchKeyProduct: search,
    });
  }

  onSearchCategory(search: string) {
    this.setState({
      searchKeyCategory: search,
    });
  }

  getSubCategories(category: string) {
    let subCategories = this.props.getGlobalData.Categories.filter(
      (c: any) => c.parent_category == category
    );

    subCategories.forEach((c: any) => {
      subCategories = [...subCategories, ...this.getSubCategories(c._id)];
    });

    return subCategories;
  }
  flattenData(data: any) {
    const flattenedData = {
      cover: data.cover ? (data.cover.length > 0 ? data.cover : []) : [],
      discount: data.discount
        ? data.discount.length > 0
          ? data.discount
          : []
        : [],
      orders: [],
      paid_orders: data.paid_orders
        ? data.paid_orders.length > 0
          ? data.paid_orders
          : []
        : [],
      payments: data.payments
        ? data.payments.length > 0
          ? data.payments
          : []
        : [],
      serviceFee: data.serviceFee
        ? data.serviceFee.length > 0
          ? data.serviceFee
          : []
        : [],
    };

    flattenedData.orders = data.orders.flatMap((order: any) =>
      order.products.map((product: any) => ({
        productName: product.productName,
        price: product.price,
        optionsString: product.optionsString,
        options: product.options,
        quantity: product.quantity,
        status: product.status,
        isServe: product.isServe,
        note: product.note,
      }))
    );
    return flattenedData;
  }
  sendOrderSecondScreen(table: any, tableNewOrder: any) {
    let table_order = clone(table);
    tableNewOrder.forEach((order: any) => {
      let product = {
        products: [
          {
            productName: order.productName,
            price: order.price,
            options: order.options,
            quantity: order.quantity,
          },
        ],
      };
      table_order.orders.push(product);
    });
    const flattenedData = this.flattenData(table_order);
    const jsonData = JSON.stringify(flattenedData);

    ipcRenderer.send("object-to-second", jsonData);
  }
  render() {
    let showImages = localStorage.getItem("showImages")
      ? JSON.parse(localStorage.getItem("showImages")!)
      : true;
    let textColor = localStorage.getItem("textColor")
      ? localStorage.getItem("textColor")!
      : "#fff";
    let table =
      this.props.getGlobalData.AllTables.findSingle(
        "_id",
        GlobalStates.SelectedTableId
      ) || GlobalStates.CurrentTable;
    this.sendOrderSecondScreen(table, this.state.newOrders);
    if (table.order_type == 3) {
      let takeaway =
        this.props.getGlobalData.AllTakeaway[GlobalStates.CurrentTakeaway];
      let orders = {
        orderNum: takeaway?.orderNum,
        products: takeaway?.products?.filter((p: any) => p.status == 2) || [],
        unapproved: takeaway?.products?.filter((p: any) => p.status == 1) || [],
        cancelled: takeaway?.products?.filter((p: any) => p.status == 0) || [],
        onWay: takeaway?.products?.filter((p: any) => p.status == 3) || [],
        paid: takeaway?.products?.filter((p: any) => p.status == 4) || [],
        totalPrice: takeaway?.totalPrice || 0,
        remainingPrice: takeaway?.remainingPrice || 0,
        customer: takeaway?.customer || takeaway?.user, //qr kod siparişlerinde user - normal paket servislerde customer propertysi
        address: takeaway?.address,
      };

      return takeaway ? (
        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <Navbar
            newOrders={this.state.newOrders}
            multiplier={this.state.multiplier}
            onClickMultiplier={(number: number) =>
              this.onClickMultiplier(number)
            }
            onSearchProduct={(search: string) => this.onSearchProduct(search)}
            onSearchCategory={(search: string) => this.onSearchCategory(search)}
            {...this.props}
          />
          <div className="row">
            <div id="account" className="col">
              <div className="adisyonContainer">
                <Table
                  {...this.props}
                  pageOrder={this}
                  or={orders}
                  covers={table.cover}
                />
              </div>
            </div>
            <div id="products" className="col">
              <div
                className="productsContainer"
                style={{ marginBottom: "0.5vw" }}
              >
                {clone(this.props.getGlobalData.Products)
                  .filter(
                    (item) =>
                      item.title
                        ?.toString()
                        .toLowerCase()
                        .search(this.state.searchKeyProduct.toLowerCase()) > -1
                  )
                  .filter(
                    (item) =>
                      this.state.currentCategoryId == "all" ||
                      this.getSubCategories(this.state.currentCategoryId)
                        .map((c: any) => c._id)
                        .concat([this.state.currentCategoryId])
                        .includes(item.category)
                  )
                  .filter((item) => item.active_list.includes(3))
                  .map((item: any, index) => (
                    <Product
                      key={index}
                      title={item.title}
                      price={item.prices[0].price}
                      currency={item.prices[0].currency}
                      image={item.image}
                      onClick={() => this.productHandleClick(item)}
                      onClickNote={() => this.handleNoteClick(item)}
                      showImages={showImages}
                      textColor={textColor}
                    />
                  ))}
              </div>
            </div>
            <div
              id="btnCategory"
              style={{ display: "block", width: "35%" }}
              className="col"
            >
              <div className="categoryButton">
                {
                  <Category
                    {...this.props}
                    onClick={(category: any) => this.categoryOnClick(category)}
                    selectedCategories={this.state.selectedCategories}
                    selectedCategoryIds={this.state.selectedCategoryIds}
                    pageOrder={this}
                    showImages={showImages}
                    textColor={textColor}
                  />
                }
              </div>
            </div>
          </div>
          <Snackbar
            open={this.state.snackbar}
            autoHideDuration={6000}
            onClose={() => this.setState({ snackbar: false })}
          >
            <Alert
              severity={this.state.snackbar_severity as AlertColor}
              sx={{ width: "100%" }}
            >
              {this.props.router.t(this.state.snackbar_title)}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <Navigate to="/" />
      );
    } else if (table.order_type === 6) {
      let order = table.getirOrders?.find(
        (order: any) => order._id === GlobalStates.CurrentGetirOrder
      );
      return order ? (
        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Navbar
            newOrders={this.state.newOrders}
            multiplier={this.state.multiplier}
            onClickMultiplier={(number: number) =>
              this.onClickMultiplier(number)
            }
            onSearchProduct={(search: string) => this.onSearchProduct(search)}
            onSearchCategory={(search: string) => this.onSearchCategory(search)}
            {...this.props}
          />
          <div className="row">
            <div id="account" className="col">
              <div className="adisyonContainer">
                <Table
                  {...this.props}
                  pageOrder={this}
                  or={[order]}
                  covers={table.cover}
                />
              </div>
            </div>
            <div id="products" className="col">
              <Box className="getirCustomerInfo" sx={{ color: "#fff" }}>
                <Stack direction="column" spacing={2}>
                  <Box>
                    <strong>{this.props.router.t("customer-name")}: </strong>
                    {order?.client?.name}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("address")}: </strong>
                    {order?.client?.deliveryAddress?.address}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("phone")}: </strong>
                    {order?.client?.clientPhoneNumber}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("note")}: </strong>
                    {order?.clientNote}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("payment-method")}: </strong>
                    {order?.paymentMethodText?.tr}
                  </Box>
                  <Box>
                    <strong>
                      {this.props.router.t("scheduled-delivery")}:{" "}
                    </strong>
                    {order?.isScheduled
                      ? this.props.router.t("yes")
                      : this.props.router.t("no")}
                  </Box>
                  {order?.isScheduled && (
                    <Box>
                      <strong>
                        {this.props.router.t("scheduled-delivery-date")}:{" "}
                      </strong>
                      {new Date(order?.scheduledDate)?.toLocaleString()}
                    </Box>
                  )}
                  <Box>
                    <strong>{this.props.router.t("delivery-type")}: </strong>
                    {order?.deliveryType === 2
                      ? this.props.router.t("restaurant-courier")
                      : this.props.router.t("getir-courier")}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("order-status")}: </strong>
                    {order?.status === "unapproved"
                      ? this.props.router.t("unapproved")
                      : order?.status === "scheduled"
                      ? this.props.router.t("scheduled")
                      : order?.status === "verified" ||
                        order?.status === "verified-scheduled"
                      ? this.props.router.t("verified")
                      : order?.status === "prepared"
                      ? this.props.router.t("prepared")
                      : order?.status === "on-way"
                      ? this.props.router.t("on-way")
                      : order?.status === "handovered"
                      ? this.props.router.t("handovered")
                      : order?.status === "delivered"
                      ? this.props.router.t("delivered")
                      : order?.status === "cancelled"
                      ? this.props.router.t("cancelled")
                      : this.props.router.t("unknown")}
                  </Box>
                  <Box>
                    <strong>{this.props.router.t("do-not-knock")}: </strong>
                    {order?.doNotKnock
                      ? this.props.router.t("yes")
                      : this.props.router.t("no")}
                  </Box>
                  <Box>
                    <strong>
                      {this.props.router.t("drop-off-at-the-door")}:{" "}
                    </strong>
                    {order?.dropOffAtDoor
                      ? this.props.router.t("yes")
                      : this.props.router.t("no")}
                  </Box>
                  <Box>
                    <strong>
                      {this.props.router.t("eco-friendly-delivery")}:{" "}
                    </strong>
                    {order?.isEcoFriendly
                      ? this.props.router.t("yes")
                      : this.props.router.t("no")}
                  </Box>
                  {order?.verifyDate && (
                    <Box>
                      <strong>{this.props.router.t("verify-date")}: </strong>
                      {new Date(order?.verifyDate)?.toLocaleString()}
                    </Box>
                  )}
                  {order?.prepareDate && (
                    <Box>
                      <strong>{this.props.router.t("prepare-date")}: </strong>
                      {new Date(order?.prepareDate)?.toLocaleString()}
                    </Box>
                  )}
                  {order?.onWayDate && (
                    <Box>
                      <strong>{this.props.router.t("on-way-date")}: </strong>
                      {new Date(order?.onWayDate)?.toLocaleString()}
                    </Box>
                  )}
                  {order?.handoverDate && (
                    <Box>
                      <strong>{this.props.router.t("handover-date")}: </strong>
                      {new Date(order?.handoverDate)?.toLocaleString()}
                    </Box>
                  )}
                  {order?.deliverDate && (
                    <Box>
                      <strong>{this.props.router.t("deliver-date")}: </strong>
                      {new Date(order?.deliverDate)?.toLocaleString()}
                    </Box>
                  )}
                </Stack>
              </Box>
            </div>
            <div
              id="btnCategory"
              style={{ display: "block", width: "35%" }}
              className="col"
            ></div>
          </div>
        </div>
      ) : (
        <div>
          <h1>Order not found</h1>
        </div>
      );
    } else {
      let orders = table?.orders;
      return table ? (
        <div style={{ position: "absolute", top: "0", width: "100%" }}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Navbar
            newOrders={this.state.newOrders}
            multiplier={this.state.multiplier}
            onClickMultiplier={(number: number) =>
              this.onClickMultiplier(number)
            }
            onSearchProduct={(search: string) => this.onSearchProduct(search)}
            onSearchCategory={(search: string) => this.onSearchCategory(search)}
            {...this.props}
          />
          <div className="row">
            <div id="account" className="col">
              <div className="adisyonContainer">
                <Table
                  {...this.props}
                  pageOrder={this}
                  or={orders}
                  covers={table.cover}
                />
              </div>
            </div>
            <div id="products" className="col">
              {this.state.isSelectPayment ? (
                <Payments pageOrder={this} {...this.props} />
              ) : (
                <div
                  className="productsContainer"
                  style={{ marginBottom: "0.5vw" }}
                >
                  {clone(this.props.getGlobalData.Products)
                    .filter(
                      (item) =>
                        item.title
                          ?.toString()
                          .toLowerCase()
                          .search(this.state.searchKeyProduct.toLowerCase()) >
                        -1
                    )
                    .filter(
                      (item) =>
                        this.state.currentCategoryId == "all" ||
                        this.getSubCategories(this.state.currentCategoryId)
                          .map((c: any) => c._id)
                          .concat([this.state.currentCategoryId])
                          .includes(item.category)
                    )
                    .filter((item) => item.active_list.includes(1))
                    .map((item: any, index) => (
                      <Product
                        key={index}
                        title={item.title}
                        price={item.prices[0].price}
                        currency={item.prices[0].currency}
                        image={item.image}
                        onClick={() => this.productHandleClick(item)}
                        onClickNote={() => this.handleNoteClick(item)}
                        showImages={showImages}
                        textColor={textColor}
                      />
                    ))}
                </div>
              )}
            </div>
            <div
              id="btnCategory"
              style={{ display: "block", width: "35%" }}
              className="col"
            >
              <div className="categoryBtn">
                {
                  <Category
                    {...this.props}
                    onClick={(category: any) => this.categoryOnClick(category)}
                    selectedCategories={this.state.selectedCategories}
                    selectedCategoryIds={this.state.selectedCategoryIds}
                    pageOrder={this}
                    showImages={showImages}
                    textColor={textColor}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Navigate to="/" />
      );
    }
  }
}

export default PageOrder;
